import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Drawer } from "@mui/material";

import { styles } from "./UsersList.styles";
import {
  deleteUser,
  getUsers,
  selectFormSuccess,
  selectUsersFilter,
  selectUsersList,
  selectUsersListPagination,
} from "../usersSlice";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import UsersDataGrid from "./UsersDataGrid/UsersDataGrid";
import SimpleScroll from "../../../common/components/displays/SimpleScroll/SimpleScroll";
import UsersForm from "./UsersForm/UsersForm";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import { selectLoggedUser } from "../../authentication/authenticationSlice";

function UsersList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const usersList = useSelector(selectUsersList);
  const usersPagination = useSelector(selectUsersListPagination);
  const filter = useSelector(selectUsersFilter);
  const loggedUser = useSelector(selectLoggedUser);
  const formSuccess = useSelector(selectFormSuccess);
  const [drawerUserForm, setDrawerUserFormOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmDeleteUserDialogOpen, setConfirmDeleteUserDialogOpen] = useState(false);

  console.log("usersList: %o", usersList);
  console.log("loggedUser: %o", loggedUser);

  useEffect(() => {
    dispatch(getUsers({ clear: true, filter }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formSuccess) {
      setDrawerUserFormOpen(false);
      setSelectedUser();
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const onAddHandler = () => {
    setSelectedUser();
    setDrawerUserFormOpen(true);
  };

  const onDeleteHandler = (project) => {
    setSelectedUser(project);
    setConfirmDeleteUserDialogOpen(true);
  };

  const onEditHandler = (project) => {
    setSelectedUser(project);
    setDrawerUserFormOpen(true);
  };

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search: search ? search.trim() : undefined,
    };
    dispatch(getUsers({ clear: true, filter: nFilter }));
  };

  const onFilterChangeHandler = (options) => {
    dispatch(getUsers(options));
  };

  return (
    <div>
      <Toolbar onSearchChanged={onSearchHandler} addPermission="override" onAddClick={onAddHandler} />
      <div className={classes.root}>
        <UsersDataGrid
          data={usersList}
          onDelete={onDeleteHandler}
          onEdit={onEditHandler}
          onFilterChange={onFilterChangeHandler}
          pagination={usersPagination}
          filter={filter}
        />
        <Drawer anchor="right" open={drawerUserForm} onClose={() => setDrawerUserFormOpen(false)}>
          <div className={classes.drawer}>
            <SimpleScroll className={classes.drawerScroll}>
              <UsersForm selectedUser={selectedUser} />
            </SimpleScroll>
          </div>
        </Drawer>

        <ConfirmationDialog
          labels={{
            title: t("users.UsersList.deleteConfirm.title"),
            description: t("users.UsersList.deleteConfirm.description"),
            ok: t("users.UsersList.deleteConfirm.ok"),
            cancel: t("users.UsersList.deleteConfirm.cancel"),
          }}
          open={confirmDeleteUserDialogOpen}
          onCancel={() => {
            setConfirmDeleteUserDialogOpen(false);
            setSelectedUser(null);
          }}
          onConfirm={() => {
            setConfirmDeleteUserDialogOpen(false);
            dispatch(deleteUser(selectedUser._id));
            setSelectedUser(null);
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(UsersList, styles);
