export const styles = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottom: {
    color: "#dddddd",
  },
  top: {
    position: "absolute",
  },
});
