import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formFields(t) {
  return [
    {
      size: 12,
      field: (
        <TextField
          id="designation"
          type="designation"
          label={t("structure.BoxForm.form.designation")}
          required={true}
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="description"
          type="description"
          label={t("structure.BoxForm.form.description")}
        />
      ),
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({
    designation: Yup.string().required(t("structure.BoxForm.form.requiredDesignation")),
    description: Yup.string(),
  });
}