import { requestHelper } from "../../../app/coreSlice";
import { getLogs } from "../../../api/apiv2/logs/getLogs";

const getLogsCall = async ({ setLogs, setFilter }, dispatch, state, filter) => {
  let rh = requestHelper(dispatch, "GET_LOGS");
  try {
    const { type, startDate, endDate, pod } = filter;
    dispatch(setFilter(filter));
    let project = state.core.selectedProject?._id;
    let result = await getLogs({
      limit: 20000,
      project,
      sortField: "logDate",
      sortType: "DESC",
      type,
      startDate,
      endDate,
      pod,
    });
    if (result.success) {
      dispatch(setLogs(result.data || []));
    }
  } catch (ex) {
    rh.error("logs", "GET_LOGS_ERROR", ex);
  }
  rh.close();
};

export default getLogsCall;
