import React from "react";
// import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Button } from "@mui/material";
import SensorIcon from "../../../common/components/displays/SensorIcon";

import { styles } from "./SensorDetails.styles";
// import { 
//   // getSensorReadings, 
//   selectSensorReadings 
// } from "../structureSlice";
// import TemperatureChart from "../../../common/charts/TemperatureChart";
// import HumidityChart from "../../../common/charts/HumidityChart";
// import RelayChart from "../../../common/charts/RelayChart";
// import { selectLoader } from "../../../app/coreSlice";
import Permission from "../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";

function SensorDetails({ classes, sensor, onEditSensor, onDeleteSensor }) {
  // const readings = useSelector(selectSensorReadings);
  // const loading = useSelector(selectLoader);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (sensor && sensor.id) {
  //     dispatch(getSensorReadings(selectedProject, sensor.code, sensor.id, sensor.type));
  //   }
  //   // eslint-disable-next-line
  // }, [sensor]);

  // const setGroupDate = (date) => {
  //   return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), 0, 0);
  // };

  // const groupReadingsRelay = (readings) => {
  //   let deviance = 5;
  //   let current = null;
  //   let r = [];
  //   if (readings && readings.length > 0) {
  //     readings.forEach((item) => {
  //       if (!current) {
  //         current = item.value;
  //         r.push(item);
  //         return;
  //       }
  //       if (
  //         parseInt(item.value) > parseInt(current) + deviance ||
  //         parseInt(item.value) < parseInt(current) - deviance
  //       ) {
  //         // console.log('item: %s current: %s', item.value, current);
  //         //if (item.value !== current) {
  //         current = item.value;
  //         r.push(item);
  //       }
  //     });
  //     r.push(readings[readings.length - 1]);
  //   }
  //   return r;
  // };

  // const groupReadingsRelay2 = (readings) => {
  //   let current = null;
  //   let r = [];
  //   if (readings && readings.length > 0) {
  //     readings.forEach((item) => {
  //       if (!current) {
  //         current = item.value;
  //         r.push(item);
  //         return;
  //       }
  //       if (parseInt(item.value) !== parseInt(current)) {
  //         // console.log('item: %s current: %s', item.value, current);
  //         //if (item.value !== current) {
  //         current = item.value;
  //         r.push(item);
  //       }
  //     });
  //     r.push(readings[readings.length - 1]);
  //   }
  //   return r;
  // };

  // const groupReadings = (readings) => {
  //   let r = [];
  //   let sum = 0,
  //     count = 0,
  //     date = null;
  //   readings.forEach((item) => {
  //     let d = new Date(item.date);
  //     if (!date) {
  //       date = setGroupDate(d);
  //     } else {
  //       if (date.getHours() !== d.getHours()) {
  //         r.push({
  //           date: date,
  //           value: (sum / count).toFixed(1),
  //         });
  //         date = setGroupDate(d);
  //         sum = 0;
  //         count = 0;
  //       }
  //     }
  //     sum += parseFloat(item.value);
  //     count++;
  //   });
  //   return r;
  // };

  return sensor ? (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.title}>
        <SensorIcon type={sensor.type} /> {sensor.designation}
      </Typography>
      <Typography variant="h6" className={classes.description}>
        {sensor.description}
      </Typography>
      <br />
      <Grid container direction="row">
        <Grid item xs={6}>
          <Typography className={classes.label}>Code</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.value}>{sensor.code}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.label}>Model</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.value}>{sensor.model}</Typography>
        </Grid>
        {/* <Grid item xs={6}>
          <Typography className={classes.label}>Offset</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.value}>{sensor.config.offset}</Typography>
        </Grid> */}
      </Grid>
      {/* <br />
      {sensor.type === "TEMPERATURE" && !loading && <TemperatureChart data={groupReadings(readings)} />}
      {sensor.type === "HUMIDITY" && !loading && <HumidityChart data={groupReadings(readings)} />}
      {sensor.type === "RELAY" && !loading && (
        <RelayChart
          data={sensor.model === "LATCH-RELAY" ? groupReadingsRelay2(readings) : groupReadingsRelay(readings)}
        />
      )} */}
      <br />
      <Permission code="structure.write">
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={6}>
            <Button variant="contained" size="small" fullWidth color="secondary" onClick={() => onDeleteSensor(sensor)}>
              Delete
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button variant="contained" size="small" fullWidth color="primary" onClick={() => onEditSensor(sensor)}>
              Edit
            </Button>
          </Grid>
        </Grid>
      </Permission>
    </div>
  ) : null;
}

export default withStyles(SensorDetails, styles);
