import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { removeTrigger } from "../../../api/nodeRedApi/triggers";

const triggersDeleteCall = async ({ getTriggers }, dispatch, state, { id }) => {
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "TRIGGERS_DELETE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await removeTrigger(api, id);
      dispatch(setSuccess("triggers", "TRIGGERS_DELETE"));
      dispatch(getTriggers());
    }
  } catch (ex) {
    rh.error("triggers", "TRIGGERS_DELETE_ERROR", ex);
  }
  rh.close();
};

export default triggersDeleteCall;
