import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updateProject } from "../../../api/apiv2/projects/updateProject";

const updateProjectCall = async (
  { getProjects, setFormSuccess },
  dispatch,
  state,
  { _id: id, code, name, features }
) => {
  let rh = requestHelper(dispatch, "PROJECT_UPDATE");
  try {
    const filter = state.projects.filter;
    let result = await updateProject(id, code, name, features);
    if (result.success) {
      dispatch(setSuccess("projects", "PROJECT_UPDATE"));
      dispatch(getProjects({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("projects", "PROJECT_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateProjectCall;
