import { api, getToken } from "../api";

export const getLogs = async ({ project, sortField, sortType, page, limit, type, startDate, endDate, pod }) => {
  let token = getToken();

  let params = {
    project,
    type,
  };

  if (sortField && sortType) {
    let field = (sortType === "ASC" ? "" : "-") + sortField;
    params = {
      ...params,
      sort: field,
    };
  }

  if (page || limit) {
    params = {
      ...params,
      limit,
      page,
    };
  }

  if (startDate) {
    params = {
      ...params,
      "logDate[gt]": startDate
    };
  }
  if (endDate) {
    params = {
      ...params,
      "logDate[lt]": endDate
    };
  }
  if (pod) {
    params = {
      ...params,
      "log[regex]": pod,
      "log[options]": "i",
    };
  }

  let result = await api.get("/api/v1/logs", { headers: { Authorization: `Bearer ${token}` }, params });

  return result?.data || { success: false };
};
