import React from "react";
import { withStyles } from "tss-react/mui";
import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

import { styles } from "./_base.styles";

function MultiSelectInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  error,
  options,
  variant = "outlined",
  required,
}) {
  return (
    <div className={classes.root + (className ? " " + className : "")}>
      {variant === "outlined" && label && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <FormControl className={classes.formControl} fullWidth variant={variant} size="small">
        {variant !== "outlined" && (
          <InputLabel>
            {label}
            {required && <span> *</span>}
          </InputLabel>
        )}
        <Select
          id={id}
          value={value || []}
          multiple
          onChange={(e) => onChange && onChange(e.target.value)}
          className={classes.select + (disabled ? " " + classes.disabledInput : "")}
          fullWidth
          error={error}
          disabled={disabled}
          renderValue={(selected) => {
            return (
              <Grid container spacing={1}>
                {selected.map((value) => {
                  let item = options.find((op) => op.value === value);
                  return (
                    <Grid item key={value}>
                      <Chip size="small" label={item && item.text} className={classes.chip} avatar={item?.icon} />
                    </Grid>
                  );
                })}
              </Grid>
            );
          }}
        >
          {(options || []).map((item, index) => (
            <MenuItem key={"option_" + index} value={item.value}>
              <Checkbox color="primary" checked={value.indexOf(item.value) > -1} />
              {item?.icon && <ListItemAvatar className={classes.selectIcon}>{item.icon}</ListItemAvatar>}
              <ListItemText primary={item.text} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default withStyles(MultiSelectInput, styles);
