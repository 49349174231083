import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./AlertForm.styles.js";
import { formFields, formSchema } from "./AlertForm.schema";

import { addAlert, updateAlert } from "../../alertsSlice";
import { Form, FormFields } from "../../../../common/forms";

function AlertForm({ classes, onSubmit, payload }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const initialValues = payload?.id ? payload : { ...payload, type: "MAX", severity: "HIGH" };

  const onSubmitHandler = async (values) => {
    if (!values.id) {
      dispatch(addAlert(values));
    } else {
      dispatch(updateAlert(values));
    }
    onSubmit && onSubmit();
  };

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <Form
        schema={formSchema(t)}
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(t, values)} />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                Submit
              </Button>
            </>
          );
        }}
      />
    </Grid>
  );
}

export default withStyles(AlertForm, styles);
