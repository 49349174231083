import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Grid } from "@mui/material";

import raspberryIcon from "../../../../../res/raspberry_pi_icon.svg";

import { styles } from "./RaspberryInfo.styles";

import { getStatus, selectStatus } from "../../../dashboardSlice";
import { usePrevious } from "../../../../../common/hooks/usePrevious";

import CircularStatus from "./CircularStatus/CircularStatus";
import { selectSelectedProject } from "../../../../../app/coreSlice";

function RaspberryInfo({ classes }) {
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectSelectedProject);

  const prevSelectedProject = usePrevious(selectedProject);

  useEffect(() => {
    if (!status || prevSelectedProject?.code !== selectedProject?.code) {
      dispatch(getStatus(selectedProject));
    }
    let cycle = setInterval(() => {
      dispatch(getStatus(selectedProject));
    }, 120000);
    return () => {
      clearInterval(cycle);
    };
    // eslint-disable-next-line
  }, [selectedProject]);

  const formatUptime = (uptime) => {
    let d = Math.floor(uptime / 86400);
    let h = Math.floor((uptime % 86400) / 3600);
    let m = Math.floor(((uptime % 86400) % 3600) / 60);
    let s = Math.floor(((uptime % 86400) % 3600) % 60);
    let value = d > 0 ? d + "d" : "";
    value += h > 0 ? h + "h" : "";
    value += m > 0 ? m + "m" : "";
    value += s > 0 ? s + "s" : "";
    return value;
  };
  return (
    <Paper className={classes.root}>
      <Grid container justifyContent="space-between" direction="column" spacing={2}>
        <img src={raspberryIcon} className={classes.icon} alt="rPI" />
        <Grid item>
          <CircularStatus value={status?.disk} label="DISK" postfix="%" />
        </Grid>
        <Grid item>
          <CircularStatus value={status?.cpu} label="CPU" postfix="%" />
        </Grid>
        <Grid item>
          <CircularStatus value={status?.memory} label="MEMORY" postfix="%" />
        </Grid>
        <Grid item>
          <CircularStatus value={status?.temperature} label="TEMP" postfix="ºC" />
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography>UPTIME</Typography>
            <Typography className={classes.uptime}>{formatUptime(status?.uptime)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(RaspberryInfo, styles);
