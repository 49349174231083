//import restAPI from "./localApi";
import { guid } from "../../common/utils";

export const structureGet = async (restAPI) => {
  return restAPI.get("/structure/get");
};

export const addZone = async (restAPI, designation, description) => {
  return restAPI.post("zones/add", { id: guid(), designation, description });
};

export const updateZone = async (restAPI, id, designation, description) => {
  return restAPI.post("zones/update", { id, designation, description });
};

export const removeZone = async (restAPI, id) => {
  return restAPI.post("zones/delete", { id });
};

// export const getBoxs = (zoneId) => {
//   return restAPI.get("/structure/get");
//   let boxsCollection = boxs.get();
//   return boxsCollection.filter((item) => item.zoneId === zoneId);
// };

// export const getBox = (boxId) => {
//   return boxs.get(boxId);
// }

export const addBox = async (restAPI, designation, description, zoneId) => {
  return restAPI.post("boxs/add", {
    id: guid(),
    zoneId,
    designation,
    description,
  });
};

export const updateBox = async (restAPI, id, designation, description, zoneId) => {
  return restAPI.post("boxs/update", { id, zoneId, designation, description });
};

export const removeBox = async (restAPI, id) => {
  return restAPI.post("boxs/delete", { id });
};

// export const getSensors = (boxId) => {
//   let sensorsCollection = sensors.get();
//   return sensorsCollection.filter((item) => item.boxId === boxId);
// };

// export const getSensor = (sensorId) => {
//   return sensors.get(sensorId);
// };

export const addSensor = async (
  restAPI,
  designation,
  description,
  type,
  model,
  code,
  config,
  boxId
) => {
  return restAPI.post("sensors/add", {
    id: guid(),
    designation,
    description,
    type,
    model,
    code,
    config,
    boxId,
  });
};

export const updateSensor = async (
  restAPI,
  id,
  designation,
  description,
  type,
  model,
  code,
  config,
  boxId
) => {
  return restAPI.post("sensors/update", {
    id,
    designation,
    description,
    type,
    model,
    code,
    config,
    boxId,
  });
};

export const removeSensor = async (restAPI, id) => {
  return restAPI.post("sensors/delete", {
    id,
  });
};

export const getSensorReadings = async (restAPI, code) => {
  return restAPI.get("sensors/readings?code=" + code);
};

// export const getSensorLastReadings = async (restAPI, codes) => {
//   return restAPI.get("sensors/lastreadings?codes=" + codes);
// };
