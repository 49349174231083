export const styles = theme => ({
  root: {
    padding: 20
  },
  drawer: {
    width: "30vw",
    padding: 20,
    marginTop: theme.myvars.toolbarHeight,
    [theme.breakpoints.down('md')]: {
      width: "40vw",
    },
    [theme.breakpoints.down('xs')]: {
      width: "80vw",
    },
  }
});