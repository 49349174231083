import { createSlice } from "@reduxjs/toolkit";
import listSmartLinksCall from "./_sliceCalls/listSmartLinksCall";
import createSmartLinkCall from "./_sliceCalls/createSmartLinkCall";
import deleteSmartLinkCall from "./_sliceCalls/deleteSmartLinkCall";
import updateSmartLinkCall from "./_sliceCalls/updateSmartLinkCall";
import createSmartLinkInputCall from "./_sliceCalls/createSmartLinkInputCall";
import createSmartLinkOutputCall from "./_sliceCalls/createSmartLinkOutputCall";
import updateSmartLinkInputCall from "./_sliceCalls/updateSmartLinkInputCall";
import updateSmartLinkOutputCall from "./_sliceCalls/updateSmartLinkOutputCall";
import deleteSmartLinkInputCall from "./_sliceCalls/deleteSmartLinkInputCall";
import deleteSmartLinkOutputCall from "./_sliceCalls/deleteSmartLinkOutputCall";

export const smartlinksSlice = createSlice({
  name: "smartlinks",
  initialState: {
    collection: [],
    formSuccess: false,
  },
  reducers: {
    setSmartLinksCollection: (state, action) => {
      state.collection = action.payload;
    },
    addSmartLink: (state, action) => {
      state.collection = [...state.collection, action.payload];
    },
    removeSmartLink: (state, action) => {
      state.collection = state.collection.filter(item => item._id !== action.payload);
    },
    refreshSmartLink: (state, action) => {
      let nCollection = [];
      state.collection.forEach(item => {
        if (item._id === action.payload._id) {
          nCollection.push(action.payload);
        }
        else {
          nCollection.push(item);
        }
      })
      state.collection = nCollection;
    },
    setFormSuccess: (state, action) => {
      state.formSuccess = action.payload;
    },
  },
});

export default smartlinksSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectSmartLinks = (state) => state.smartlinks.collection;
export const selectFormSuccess = (state) => state.smartlinks.formSuccess;

// CALLS
export const listSmartLinks = () => (dispatch, getState) => {
  listSmartLinksCall(smartlinksSlice.actions, dispatch, getState());
}
export const createSmartLink = ({ designation, tolerance }) => (dispatch, getState) => {
  createSmartLinkCall(smartlinksSlice.actions, dispatch, getState(), { designation, tolerance });
};

export const updateSmartLink = ({ _id, designation, tolerance, active, inputMode }) => (dispatch) => {
  updateSmartLinkCall(smartlinksSlice.actions, dispatch, { id: _id, designation, tolerance, active, inputMode });
};

export const deleteSmartLink = (id) => (dispatch) => {
  deleteSmartLinkCall(smartlinksSlice.actions, dispatch, { id });
};

export const createSmartLinkInput = ({ smartLinkId, tag, device, configs }) => (dispatch) => {
  createSmartLinkInputCall(smartlinksSlice.actions, dispatch, { smartLinkId, tag, device, configs });
};

export const createSmartLinkOutput = ({ smartLinkId, tag, configs, device }) => (dispatch) => {
  createSmartLinkOutputCall(smartlinksSlice.actions, dispatch, { smartLinkId, tag, configs, device });
};

export const updateSmartLinkInput = ({ smartLinkId, _id, tag, device, configs }) => (dispatch) => {
  updateSmartLinkInputCall(smartlinksSlice.actions, dispatch, { smartLinkId, id: _id, tag, device, configs });
};

export const updateSmartLinkOutput = ({ smartLinkId, _id, tag, configs, device }) => (dispatch) => {
  updateSmartLinkOutputCall(smartlinksSlice.actions, dispatch, { smartLinkId, id: _id, tag, configs, device });
};

export const deleteSmartLinkInput = ({ smartLinkId, id }) => (dispatch) => {
  deleteSmartLinkInputCall(smartlinksSlice.actions, dispatch, { smartLinkId, id });
};

export const deleteSmartLinkOutput = ({ smartLinkId, id }) => (dispatch) => {
  deleteSmartLinkOutputCall(smartlinksSlice.actions, dispatch, { smartLinkId, id });
};