export const styles = (theme) => ({
  root: {
    border: "2px solid " + theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    minHeight: 160,
    backgroundColor: "#FFF",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddf1db",
    },
  },
  selected: {
    backgroundColor: "#ddf1db80",
  },
  title: {
    margin: 0,
    fontWeight: "bold",
    fontSize: 18
  },
  description: {
    fontSize: 12,
    color: "#555"
  }
});