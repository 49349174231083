export const styles = (theme) => ({
  root: {},
  filters: {
    position: "absolute",
    top: 0,
    right: 120,
    [theme.breakpoints.down("md")]: {
      top: -20,
      right: 0,
    },
  },
});
