import React from "react";
import { Paper } from "@mui/material";

import LastReadingsChart from "./components/LastReadingsChart";

function BatteryChart({ classes, data }) {
  return (
    <Paper style={{ padding: 20 }}>
      <LastReadingsChart
        title="Last 24h readings"
        defaultDataFilter="max"
        data={data}
        seriesLabel="Battery"
        yaxis={{
          title: {
            text: "Battery",
          },
          min: 0,
        }}
        height={350}
      />
    </Paper>
  );
}

export default BatteryChart;
