import * as Yup from "yup";
import { NumberField, TextField } from "../../../../common/forms";

export function formSchema({ t }) {
  return Yup.object().shape({
    designation: Yup.string().required(t("smartlinks.SmartLinksForm.form.designationRequired")),
    tolerance: Yup.string().required(t("smartlinks.SmartLinksForm.form.toleranceRequired")),
  });
}

export const formFields = ({ t }) => {
  return [
    {
      size: 12,
      field: <TextField
        id="designation"
        label={t("smartlinks.SmartLinksForm.form.designation")}
        required
      />,
    },
    {
      size: 12,
      field: <NumberField
        id="tolerance"
        label={t("smartlinks.SmartLinksForm.form.tolerance")}
        required
      />,
    },
  ]
}