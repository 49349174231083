import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import moment from "moment";

import { styles } from "./MainChartFilter.styles";
import { formFields, formSchema } from "./MainChartFilter.schema";
import { Form, FormFields } from "../../../../common/forms";
import { analyticsReadings, selectDevices, selectMainChartFilter } from "../../analyticsSlice";

function MainChartFilter({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector(selectDevices);
  const filter = useSelector(selectMainChartFilter);

  const onSubmitHandler = (values) => {
    console.log("values: %o", values);
    dispatch(analyticsReadings({ ...values, devices: devices.filter(item => (values.devices || []).includes(item.code)) }));
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={filter || { startDate: moment().add(-24, "hours").toISOString(), endDate: moment().toISOString() }}
        onSubmit={onSubmitHandler}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(t, values, devices)} />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                Submit
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(MainChartFilter, styles);
