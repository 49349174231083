import React from "react";
import { withStyles } from "tss-react/mui";
import { Box, Typography, CircularProgress } from "@mui/material";

import { styles } from "./CircularStatus.styles";

function CircularStatus({ classes, value, label, postfix }) {
  return (
    <div className={classes.root}>
      {label && <Typography>{label} </Typography>}
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          value={100}
        />
        <CircularProgress
          variant="determinate"
          className={classes.top}
          value={value}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {`${Math.round(value)}${postfix}`}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default withStyles(CircularStatus, styles);
