import axios from "axios";

export const getAPI = (projectId) => {
  switch (projectId) {
    case "LOCAL":
      return localAPI;
    case "ANANDA":
      return anandaAPI;
    case "SCRIPTHORIUMPI":
      return scriptAPI;
    case "UPFARMING":
      return upfarmingAPI;
    case "TESTBERRY1":
      return testberry1API;
    default:
      return null;
  }
};

export const scriptAPI = axios.create({
  baseURL: "https://scripthoriumpi.solarpunk.pro:1880",
  headers: {
    "content-type": "application/json",
    authorization: "Basic W2FkbWluXTpbc2UwbWFuRm9kYXhd",
  },
});

export const localAPI = axios.create({
  baseURL: "https://manuelslourenco.solarpunk.pro:1880",
  headers: {
    "content-type": "application/json",
    authorization: "Basic bWFudWVsc2xvdXJlbmNvQGdtYWlsLmNvbTpEM2ZhdWx0UGFzcy4=",
  },
});

export const anandaAPI = axios.create({
  baseURL: "https://anandavalley.solarpunk.pt:1880",
  headers: {
    "content-type": "application/json",
    authorization: "Basic YW5hbmRhLm5vZ2FybGlj",
  },
});

export const upfarmingAPI = axios.create({
  baseURL: "https://upfarming.solarpunk.pt:1880",
  headers: {
    "content-type": "application/json",
    authorization: "Basic dXBmYXJtaW5nQXBpOk1OOGhWMmpuQWNTNFpRNTI=",
  },
});

export const testberry1API = axios.create({
  baseURL: "https://testberry1.solarpunk.pt:1880",
  headers: {
    "content-type": "application/json",
    authorization: "Basic dGVzdGJlcnJ5MTpzcHVuazIwMjI=",
  },
});

upfarmingAPI.interceptors.request.use(
  function (config) {
    console.log(
      "%c::: [UPFARMING] %s %s ::: %o",
      "color:#498fb8; font-weight:bold",
      (config?.method || "").toUpperCase(),
      config?.url || "",
      config?.params || {}
    );
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

upfarmingAPI.interceptors.response.use(
  function (response) {
    console.log(
      "%c::: [UPFARMING] %s %s ::: %o",
      "color:#55B849; font-weight:bold",
      (response?.config?.method || "").toUpperCase(),
      response?.config?.url,
      response?.data
    );
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

testberry1API.interceptors.request.use(
  function (config) {
    console.log(
      "%c::: [TESTBERRY1] %s %s ::: %o",
      "color:#498fb8; font-weight:bold",
      (config?.method || "").toUpperCase(),
      config?.url || "",
      config?.params || {}
    );
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

testberry1API.interceptors.response.use(
  function (response) {
    console.log(
      "%c::: [TESTBERRY1] %s %s ::: %o",
      "color:#55B849; font-weight:bold",
      (response?.config?.method || "").toUpperCase(),
      response?.config?.url,
      response?.data
    );
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
