


import axios from "axios";

const ipmaAPI = axios.create({
  baseURL: "https://api.ipma.pt/open-data",
});

export const getLastData = async (stationID) => {
  let result = await ipmaAPI.get('/observation/meteorology/stations/obs-surface.geojson');
  return (result?.data?.features || []).find(item => item.properties.idEstacao === stationID);
}

export const getForecast = async (globalIdLocal) => {
  let result = await ipmaAPI.get(`/forecast/meteorology/cities/daily/${globalIdLocal}.json`);
  return result.data;
}
