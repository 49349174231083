import { requestHelper } from "../../../app/coreSlice";
import { getWidgets } from "../../../api/apiv2/widgets/getWidgets";

const getWidgetsCall = async ({ setWidgets }, dispatch, state) => {
  let projectId = state.core.selectedProject?._id;
  let rh = requestHelper(dispatch, "GET_WIDGETS");
  try {
    let result = await getWidgets({limit: 10000, sortField: "order", projectId});
    console.log("result: %o", result);
    if (result.success) {
      dispatch(setWidgets(result.data));
    }
  } catch (ex) {
    rh.error("roles", "GET_WIDGETS_ERROR", ex);
  }
  rh.close();
};

export default getWidgetsCall;
