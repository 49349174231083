import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, IconButton } from "@mui/material";

import { styles } from "./ActionsPopoverItem.styles";

function ActionsPopoverItem({ classes, onClick, icon, label }) {
  return (
    <Grid container className={classes.root} direction="row" onClick={onClick} spacing={1} alignItems="center">
      <Grid item style={{ display: "flex" }}>
        <IconButton size="small">{icon}</IconButton>
      </Grid>
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(ActionsPopoverItem, styles);
