import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Switch, IconButton, Button, Chip } from "@mui/material";
import { withStyles } from "tss-react/mui";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { styles } from "./ScheduleItem.styles";
import Weekdays from "../../../../../common/components/displays/Weekdays";
import Permission from "../../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";
import { selectDevices } from "../../../../devices/devicesSlice";

function ScheduleItem({
  classes,
  schedule,
  onActivate,
  loading,
  onEditSchedule,
  onDeleteSchedule,
  onStopInstance,
  instance,
}) {
  const [hover, sethover] = useState(false);
  const devices = useSelector(selectDevices) || [];
  let deviceInfo = devices.find((item) => item._id === schedule?.sensorId);
  console.log('schedule: %o', schedule);
  console.log('deviceInfo: %o', deviceInfo);
  return (
    <div
      className={
        classes.scheduleItem +
        " " +
        (!schedule.active ? classes["disabled"] : classes["active"]) +
        " " +
        (instance && classes["inuse"])
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        spacing={1}
        onMouseOver={() => sethover(true)}
        onMouseOut={() => sethover(false)}
      >
        <Grid item>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <b>{schedule.designation} </b>
            </Grid>
            <Grid item>
              {deviceInfo?.box?.designation + " - " + deviceInfo?.box?.designation}
              <Chip className={classes.chip} size="small" color="secondary" label={deviceInfo?.code} />
            </Grid>
            {instance && (
              <Grid item>
                <b>running</b>
                <Permission code="schedules.write">
                  <Button
                    className={classes.smallerBtn}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => onStopInstance(instance)}
                  >
                    Stop
                  </Button>
                </Permission>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Grid container direction="column" alignItems="center" spacing={1}>
            <Grid item>
              starts @ {schedule.startTime} ({schedule.duration} mins)
            </Grid>
            <Grid item>
              <Weekdays weekdays={schedule.weekdays} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="flex-end" spacing={1}>
            <Grid item>
              <Permission code="schedules.write">
                <div style={{ height: "20px", display: "block" }}>
                  <IconButton
                    aria-label="edit"
                    disableRipple
                    size="small"
                    onClick={() => onEditSchedule(schedule)}
                    className={classes.icon}
                    style={hover ? { display: "inline" } : { display: "none" }}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    disableRipple
                    size="small"
                    onClick={() => onDeleteSchedule(schedule)}
                    className={classes.icon}
                    style={hover ? { display: "inline" } : { display: "none" }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </div>
              </Permission>
            </Grid>
            <Permission code="schedules.write">
              <Grid item>
                <Grid container direction="row" alignItems="center" justifyContent="flex-end">
                  <Grid item>
                    <Switch
                      checked={schedule.active}
                      color="primary"
                      onClick={(e) => onActivate(schedule.id, e.target.checked)}
                      disabled={Boolean(loading)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Permission>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(ScheduleItem, styles);
