import * as Yup from "yup";
import { NumberField } from "../../../../../../common/forms";


export const formSchema = (t) => {
  return Yup.object().shape({
    duration: Yup.string().required(),
  });
}

export const formFields = (t, stopDevice) => {
  return [
    {
      size: 12,
      field: <NumberField
        id="duration"
        label="Duration (seconds)"
        required
        disabled={stopDevice}
      />,
    },
  ];
}
