import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { deleteProject } from "../../../api/apiv2/projects/deleteProject";

const deleteProjectCall = async ({ getProjects }, dispatch, state, id) => {
  let rh = requestHelper(dispatch, "PROJECT_DELETE");
  try {
    const filter = state.projects.filter;
    let result = await deleteProject(id);
    if (result.success) {
      dispatch(setSuccess("projects", "PROJECT_DELETE"));
      dispatch(getProjects({ clear: true, filter }));
    }
  } catch (ex) {
    rh.error("projects", "PROJECT_DELETE_ERROR", ex);
  }
  rh.close();
};

export default deleteProjectCall;
