import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./_base.styles";

function CardSelectInput({ classes, options, xs, selectedValue, textAlign, label, disabled, required, onChange }) {
  let alignItems = textAlign === "right" ? "flex-end" : textAlign === "left" ? "flex-start" : "center";
  return (
    <div className={classes.root}>
      {label && (
        <Typography
          component="span"
          variant="caption"
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <Grid container spacing={2}>
        {(options || []).map((item) => {
          return (
            <Grid item key={`option_${item.value}`} xs={xs}>
              <div
                className={
                  classes.card +
                  (item.value === selectedValue ? " " + classes.cardSelected : "") +
                  (disabled ? " " + classes.cardDisabled : "")
                }
                onClick={() => !disabled && onChange && onChange(item.value)}
              >
                <Grid
                  className={classes.cardContainer}
                  container
                  direction="column"
                  alignItems={alignItems}
                  justifyContent="center"
                >
                  {item.icon && (
                    <Grid item style={{ display: "flex" }}>
                      {item.icon}
                    </Grid>
                  )}
                  {item.text && (
                    <Grid item style={{ display: "flex" }}>
                      <Typography variant="caption" color="textPrimary">
                        {item.text}
                      </Typography>
                    </Grid>
                  )}
                  {item.description && (
                    <Grid item style={{ display: "flex" }}>
                      <Typography variant="caption" color="textSecondary">
                        {item.description}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withStyles(CardSelectInput, styles);
