export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const base64ToArrayBuffer = (base64) => {
  if (base64.indexOf("base64,") > 0) {
    base64 = base64.substr(base64.indexOf("base64,") + 7);
  }
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
};

export const getImageInfoFromUrl = (url) => {
  if (!url) {
    return null;
  }
  let ext = url.slice(url.lastIndexOf(".") + 1);
  return {
    name: url.slice(url.lastIndexOf("/") + 1),
    type: "image/" + ext === "jpg" ? "jpeg" : ext,
    url,
  };
};

export const getEntityImageUrl = (promoterCode, entityId) => {
  return promoterCode.toUpperCase() + "/" + process.env.REACT_APP_ENV + "/avatar/" + entityId + ".jpg";
};
