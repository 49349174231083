import React from "react";
import { FaTemperatureHigh, FaDoorOpen } from "react-icons/fa";
import { ImSwitch } from "react-icons/im";
import { WiHumidity } from "react-icons/wi";
import { GiBatteryPack } from "react-icons/gi";
import { SlEnergy } from "react-icons/sl";
import { AiOutlineColumnHeight } from "react-icons/ai";
import { BsCloudRainFill } from "react-icons/bs";
import { MdSensorOccupied } from "react-icons/md";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

function SensorIcon({ type, style }) {
  switch (type) {
    case "TEMPERATURE":
      return <FaTemperatureHigh style={style} />;
    case "HUMIDITY":
      return <WiHumidity size="2em" style={{ ...style, margin: "-0.5em" }} />;
    case "RELAY":
      return <ImSwitch style={style} />;
    case "BATTERY":
      return <GiBatteryPack style={style} />;
    case "WATERLEVEL":
      return <AiOutlineColumnHeight style={style} />;
    case "CURRENT_SENSOR":
      return <SlEnergy style={style} />;
    case "DOOR":
      return <FaDoorOpen style={style} />;
    case "MOTION":
      return <MdSensorOccupied style={style} />;
    case "RAIN":
      return <BsCloudRainFill style={style} />;
    default:
      return <HelpOutlineIcon style={style} />;
  }
}

export default SensorIcon;
