import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { activateAlert } from "../../../api/nodeRedApi/alerts";

const alertsActivateCall = async ({ getAlerts }, dispatch, state, { id, active }) => {
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "ALERTS_ACTIVATE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await activateAlert(api, id, active);
      dispatch(setSuccess("alerts", active ? "ALERTS_ACTIVATE" : "ALERTS_DEACTIVATE"));
      dispatch(getAlerts());
    }
  } catch (ex) {
    rh.error("alerts", "ALERTS_ACTIVATE", ex);
  }
  rh.close();
};

export default alertsActivateCall;
