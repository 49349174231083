import { api, storeToken } from "../api";

export const login = async (email, password) => {
  console.log("login: %o", { email, password });
  let result = await api.post("/api/v1/auth/login", {
    email,
    password,
  });
  if (result?.data?.token) {
    storeToken(result?.data?.token);
  }

  return result?.data || { success: false };
};
