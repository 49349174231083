import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { CheckboxInput } from "../../inputs";

function CheckboxField({ classes, id, label, disabled }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <CheckboxInput
              id={id}
              label={label}
              value={input.value}
              onChange={(value) => onChangeHandler(value, input)}
              disabled={disabled}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(CheckboxField, styles);
