import CheckIcon from "@mui/icons-material/Check";
import { Tooltip } from "@mui/material";
import moment from "moment";

export function listColumns(t) {
  return [
    {
      field: "podCode",
      headerName: t("podsettings.PodSettingsList.columns.podCode"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "address",
      headerName: t("podsettings.PodSettingsList.columns.address"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "value",
      headerName: t("podsettings.PodSettingsList.columns.value"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "createdAt",
      headerName: t("podsettings.PodSettingsList.columns.createdAt"),
      align: "center",
      headerAlign: "center",
      customValue: (v) => moment(v.createdAt).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: "syncedAt",
      headerName: t("podsettings.PodSettingsList.columns.syncedAt"),
      align: "center",
      headerAlign: "center",
      customValue: (v) =>
        v?.syncedAt ? (
          <Tooltip title={moment(v?.syncedAt).fromNow() + " @ " + moment(v?.syncedAt).format("HH:mm:ss")}>
            <CheckIcon color="primary" />
          </Tooltip>
        ) : (
          ""
        ),
    },
    {
      field: "executedAt",
      headerName: t("podsettings.PodSettingsList.columns.executedAt"),
      align: "center",
      headerAlign: "center",
      customValue: (v) =>
        v?.executedAt ? (
          <Tooltip title={moment(v?.executedAt).fromNow() + " @ " + moment(v?.executedAt).format("HH:mm:ss")}>
            <CheckIcon color="primary" />
          </Tooltip>
        ) : (
          ""
        ),
    },
  ];
}
