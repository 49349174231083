import { createSlice } from "@reduxjs/toolkit";
import { getAPI } from "../../api/nodeRedApi/axiosApi";
import { getSystemStatus } from "../../api/nodeRedApi/system";
import { sleep } from "../../common/utils/utils";
import getLastDayReadingsCall from "./_sliceCalls/getLastDayReadingsCall";
import getLastReadingsCall from "./_sliceCalls/getLastReadingsCall";
import getWidgetsCall from "./_sliceCalls/getWidgetsCall";
import createActionCall from "./_sliceCalls/createActionCall";
import getActionsCall from "./_sliceCalls/getActionsCall";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    widgets: [],
    loader: null,
    error: null,
    history: [],
    status: null,
    lastReadings: [],
    // meteoData: null,
    actions: [],
    actionLocker: false
  },
  reducers: {
    setWidgets: (state, action) => {
      state.widgets = action.payload;
    },
    setWidgetsData: (state, action) => {
      if (state.widgets[action.payload.index]) {
        state.widgets[action.payload.index].data = action.payload.data;
      }
    },
    ////////////////////
    setLoader: (state, action) => {
      state.loader = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setStatus: (state, action) => {
      state.status = { ...action.payload, timestamp: new Date().getTime() };
      //state.history = [...state.history, action.payload];
    },

    setLastReadings: (state, action) => {
      state.lastReadings = action.payload;
    },

    setActions: (state, action) => {
      state.actions = action.payload;
    },

    setActionLocker: (state, action) => {
      state.actionLocker = action.payload;
    },

    // setMeteoData: (state, action) => {
    //   state.meteoData = action.payload;
    // },
  },
});

export const { setLoader, setError, setStatus, setLastReadings, setMeteoData } = dashboardSlice.actions;

export const getStatus = (project) => async (dispatch) => {
  dispatch(setLoader("LOADING"));
  let status;
  let api = getAPI(project?.code);
  if (api) {
    let result = await getSystemStatus(api);
    status = result.data;
  } else {
    status = {
      cpu: 45,
      memory: 83,
      disk: 55,
      uptime: 3600,
      temperature: 44.5,
    };
    await sleep(1000);
  }
  dispatch(setStatus(status));
  dispatch(setLoader(null));
};

export default dashboardSlice.reducer;

export const selectStatus = (state) => state.dashboard.status;
export const selectLastReadings = (state) => state.dashboard.lastReadings;
export const selectActions = (state) => state.dashboard.actions;
export const selectActionLocker = (state) => state.dashboard.actionLocker;
// export const selectMeteoData = (state) => state.dashboard.meteoData;

// CALLS
export const getWidgets = () => (dispatch, getState) => {
  getWidgetsCall(dashboardSlice.actions, dispatch, getState());
};

export const getLastReadings = (codes, index) => (dispatch, getState) => {
  getLastReadingsCall(dashboardSlice.actions, dispatch, getState(), { codes, index });
};

export const getLastDayReadings = (code, index) => (dispatch, getState) => {
  getLastDayReadingsCall(dashboardSlice.actions, dispatch, getState(), { code, index });
};

export const getActions = (codes) => (dispatch, getState) => {
  getActionsCall(dashboardSlice.actions, dispatch, getState(), codes);
};

export const createAction = ({ device, value, duration, codes }) => (dispatch, getState) => {
  createActionCall({ ...dashboardSlice.actions, getActions }, dispatch, getState(), { device, value, duration, codes });
};

