import React from "react";
import { withStyles } from "tss-react/mui";
import { FormControlLabel, Switch, Typography } from "@mui/material";

import { styles } from "./_base.styles";

function SwitchInput({ id, classes, className, label, value, onChange, disabled, size = "small" }) {
  return (
    <div className={classes.root + (className ? " " + className : "")}>
      <FormControlLabel
        className={classes.switch}
        control={
          <Switch
            color="primary"
            id={id}
            size={size}
            checked={value}
            onChange={(e) => onChange && onChange(e.target.checked)}
            disabled={disabled}
          />
        }
        label={
          <Typography
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
            style={{ marginRight: 5 }}
          >
            {label}
          </Typography>
        }
        labelPlacement="start"
        disabled={disabled}
      />
    </div>
  );
}

export default withStyles(SwitchInput, styles);
