import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { styles } from "./BlocksForm.styles";
import { formSchema, formFields } from "./BlocksForm.schema";
import { Form, FormFields } from "../../../../common/forms";
import { addBlock, updateBlock } from "../../blocksCalls";
import { selectDevices } from "../../../devices/devicesSlice";

function BlocksForm({ classes, selectedBlock }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector(selectDevices);

  const onSubmitHandler = (values) => {
    console.log("values: %o", values);
    if (selectedBlock) {
      dispatch(updateBlock(values));
    } else {
      dispatch(addBlock(values));
    }
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={selectedBlock || {}}
        onSubmit={onSubmitHandler}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(t, values, devices)} />
              <br />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                Submit
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(BlocksForm, styles);
