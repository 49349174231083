import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { createRole } from "../../../api/apiv2/roles/createRole";

const createRoleCall = async ({ getRoles, setFormSuccess }, dispatch, state, { name, permissions }) => {
  let rh = requestHelper(dispatch, "ROLE_CREATE");
  try {
    const filter = state.roles.filter;
    let result = await createRole(name, permissions);
    if (result.success) {
      dispatch(setSuccess("roles", "ROLE_CREATE"));
      dispatch(getRoles({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("roles", "ROLE_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createRoleCall;
