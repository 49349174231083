import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import SensorsIcon from '@mui/icons-material/Sensors';
import TelegramIcon from '@mui/icons-material/Telegram';
import MailIcon from '@mui/icons-material/Mail';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

import { styles } from "./IOBadge.styles";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";
import { classJoin } from "../../../../common/utils";
import { Chip } from "@mui/material";

function IOBadge({ classes, tag, tooltip, small, disabled, deviceCode }) {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const size = small ? "small" : "medium";

  return (
    <div className={classes.root}>
      <CustomTooltip title={tooltip}>
        <div>
          <div className={classJoin([classes.badge, small ? classes.smallBadge : undefined, disabled ? classes.disabled : undefined])}>
            {/* INPUTS */}
            {tag === "READ_RANGE" && <SensorsIcon className={classes.icon} fontSize={size} />}
            {tag === "WEEK_SCHEDULE" && <AccessTimeIcon className={classes.icon} fontSize={size} />}
            {tag === "NO_READING" && <DoNotDisturbAltIcon className={classes.icon} fontSize={size} />}
            {/* OUTPUTS */}
            {tag === "TELEGRAM" && <TelegramIcon className={classes.icon} fontSize={size} />}
            {tag === "EMAIL" && <MailIcon className={classes.icon} fontSize={size} />}
            {tag === "ACTION" && <PowerSettingsNewIcon className={classes.icon} fontSize={size} />}

          </div>
          {deviceCode && <Chip className={classes.device} size="small" color="default" label={deviceCode} />}
        </div>
      </CustomTooltip>
    </div>
  );
}

export default withStyles(IOBadge, styles);