import { getActions } from "../../../api/apiv2/actions/getActions";
import { requestHelper } from "../../../app/coreSlice";

const getActionsCall = async ({ setActions }, dispatch, state, codes) => {
  let project = state.core.selectedProject?._id;
  let rh = requestHelper(dispatch, "GET_ACTIONS");
  try {
    let result = await getActions({ project, codes });
    console.log("result: %o", result);
    if (result.success) {
      dispatch(setActions(result.data));
    }
  } catch (ex) {
    rh.error("actions", "GET_ACTIONS_ERROR", ex);
  }
  rh.close();
};

export default getActionsCall;
