import { guid, jsonTryParse } from "../../common/utils";

export const getTriggers = async (restAPI) => {
  let result = await restAPI.get("/triggers/get");
  //let triggersList = r?.data || [];
  // console.log("triggersList: %o", triggersList);
  console.log("%c::: getTriggers RESULT ::: %o", "color:#55B849; font-weight:bold", result);
  return (result?.data || []).map((trigger) => {
    return {
      ...trigger,
      active: trigger.active.toString() === "1" ? true : false,
      input: jsonTryParse(trigger.input),
      output: jsonTryParse(trigger.output),
    };
  });
};

export const addTrigger = async (restAPI, designation, type, input, output, delay) => {
  let obj = {
    id: guid(),
    designation,
    type,
    input: JSON.stringify(input),
    output: JSON.stringify(output),
    delay,
  };
  // console.log("obj: %o", obj);
  return restAPI.post("triggers/add", obj);
};

export const activateTrigger = async (restAPI, id, active) => {
  return restAPI.post("triggers/activate", {
    id,
    active,
  });
};

export const updateTrigger = async (restAPI, id, designation, type, input, output, delay) => {
  return restAPI.post("triggers/update", {
    id,
    designation,
    type,
    input: JSON.stringify(input),
    output: JSON.stringify(output),
    delay,
  });
};

export const removeTrigger = async (restAPI, id) => {
  console.log("removeTrigger id: %o", id);
  return restAPI.post("triggers/delete", {
    id,
  });
};
