export const styles = () => ({
  root: {
    width: "100%"
  },
  chip: {
    height: "auto",
    padding: "2px 0"
  },
  fullWidth: {
    width: "100%",
    "& .MuiChip-label": {
      width: "100%"
    }
  },
  labelBox: {
    minWidth: 80,
  },
  label: {
    fontSize: 12,
    whiteSpace: "break-spaces"
  },
  labelValue: {
    textAlign: "right",
    fontSize: 12,
    fontWeight: "bold",
    whiteSpace: "nowrap"
  },
  chart: {
    width: 24,
    height: 24,
    transform: "rotate(-180deg)",
    marginTop: "-10px"
  },
  iconBox: {
    height: 26,
    display: "flex",
    alignItems: "center",
    width: 26,
    minWidth: 26,
    justifyContent: "center",
    borderRadius: 13,
    border: "1px solid #ddd",
    backgroundColor: "#eee"
  }
});