import * as Yup from "yup";
import { TextField } from "../../../../../common/forms";

export const formSchema = (t) => {
  return Yup.object().shape({
    emails: Yup.string().required(t("smartlinks.IOPicker.form.emailRequired")),
    subject: Yup.string().required(t("smartlinks.IOPicker.form.subjectRequired")),
    message: Yup.string().required(t("smartlinks.IOPicker.form.messageRequired")),
  });
}

export const formFields = (t) => {
  return [
    {
      size: 12,
      field: <TextField
        id="emails"
        label={t("smartlinks.IOPicker.form.email")}
        required
      />,
    }, 
    {
      size: 12,
      field: <TextField
        id="subject"
        label={t("smartlinks.IOPicker.form.subject")}
        required
      />,
    }, 
    {
      size: 12,
      field: <TextField
        id="message"
        label={t("smartlinks.IOPicker.form.message")}
        rows={3}
        required
      />,
    },
  ];
}

const EMAIL = {
  formSchema, formFields
}

export default EMAIL