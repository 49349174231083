import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { styles } from "./SeverityInfo.styles";

function SeverityInfo({ classes, severity }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography className={classes.tableText}>{t("alerts.SeverityInfo.info.description")}</Typography>
      <br />
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography className={classes.tableText}>{t("alerts.SeverityInfo.table.headerIteration")}</Typography>
              </TableCell>
              <TableCell>
                <Typography className={classes.tableText}>{t("alerts.SeverityInfo.table.headerTime")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {severity === "HIGH" ? (
            <TableBody>
              {(t("alerts.SeverityInfo.table.high", { returnObjects: true }) || [])?.map((item, index, collection) => (
                <TableRow key={`high_${index}`}>
                  <TableCell>
                    <Typography className={classes.tableText}>
                      {index + 1 === collection.length ? "*" : index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableText}>{item}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : severity === "MEDIUM" ? (
            <TableBody>
              {(t("alerts.SeverityInfo.table.medium", { returnObjects: true }) || [])?.map(
                (item, index, collection) => (
                  <TableRow key={`medium_${index}`}>
                    <TableCell>
                      <Typography className={classes.tableText}>
                        {index + 1 === collection.length ? "*" : index + 1}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.tableText}>{item}</Typography>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          ) : (
            <TableBody>
              {(t("alerts.SeverityInfo.table.low", { returnObjects: true }) || [])?.map((item, index, collection) => (
                <TableRow key={`low_${index}`}>
                  <TableCell>
                    <Typography className={classes.tableText}>
                      {index + 1 === collection.length ? "*" : index + 1}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableText}>{item}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
}

export default withStyles(SeverityInfo, styles);
