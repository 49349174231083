export const styles = (theme) => ({
  root: {},
  signIcon: {
    height: 12,
  },
  chip: {
    height: 15,
    marginRight: 5,
    "& span": {
      padding: "0 5px",
      lineHeight: 1,
    },
  },
});
