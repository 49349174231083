import React from "react";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Chip } from "@mui/material";
import SensorIcon from "../../../../../common/components/displays/SensorIcon";

import { styles } from "./SensorItem.styles";

function SensorItem({ classes, sensor, onSensorSelected }) {
  return (
    <Paper className={classes.root} elevation={3} onClick={() => onSensorSelected(sensor)}>
      <Typography variant="h5" className={classes.title}>
        <SensorIcon type={sensor.type} /> {sensor.designation}
      </Typography>
      <Typography variant="h6" className={classes.description}>
        <Chip size="small" color="secondary" label={sensor.code} />
        {sensor.description}
      </Typography>
    </Paper>
  );
}

export default withStyles(SensorItem, styles);
