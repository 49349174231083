import { createSlice } from "@reduxjs/toolkit";
import { compareObjects, guid, sleep } from "../common/utils";
import i18n from "i18next";
import { setLocalStorage } from "../common/utils/utils";

export const coreSlice = createSlice({
  name: "core",
  initialState: {
    loader: [],
    snacks: [],
    projects: null,
    selectedProject: null,
  },
  reducers: {
    addToLoader: (state, action) => {
      let nLoader = state.loader;
      nLoader.push(action.payload);
      state.loader = nLoader;
    },

    removeFromLoader: (state, action) => {
      let nLoader = state.loader;
      state.loader = nLoader.filter((item) => !compareObjects(item, action.payload));
    },

    addSnack: (state, action) => {
      state.snacks = [...state.snacks, action.payload];
    },

    removeSnack: (state, action) => {
      state.snacks = [...state.snacks.filter((item) => item.id !== action.payload)];
    },

    setProjects: (state, action) => {
      state.projects = action.payload;
    },

    setSelectedProject: (state, action) => {
      setLocalStorage("selectedProject", action.payload?.code);
      state.selectedProject = action.payload;
    },
  },
});

export const { addToLoader, removeFromLoader, addSnack, removeSnack, setUser, setProjects, setSelectedProject } =
  coreSlice.actions;

export const addSnackAsync = (type, msg) => async (dispatch) => {
  let snack = {
    id: guid(),
    type,
    msg,
  };
  dispatch(addSnack(snack));
  await sleep(5000);
  dispatch(removeSnack(snack.id));
};

export const setError = (feature, error) => async (dispatch) => {
  if (error) {
    let i18nKey = `${feature}.messages.error.${error}`;
    let msg = i18n.t(i18nKey) === i18nKey ? error : i18n.t(i18nKey);

    let snack = {
      id: guid(),
      type: "error",
      msg,
    };
    dispatch(addSnack(snack));
    await sleep(8000);
    dispatch(removeSnack(snack.id));
  }
};

export const setSuccess = (feature, code) => async (dispatch) => {
  if (code) {
    let i18nKey = `${feature}.messages.success.${code}`;
    let msg = i18n.t(i18nKey) === i18nKey ? code : i18n.t(i18nKey);

    let snack = {
      id: guid(),
      type: "success",
      msg,
    };
    // console.log("SNACK... %o", snack);
    dispatch(addSnack(snack));
    await sleep(8000);
    dispatch(removeSnack(snack.id));
  }
};

export const requestHelper = (dispatch, loader) => {
  let timestamp = new Date().getTime();
  if (loader) {
    dispatch(addToLoader({ call: loader, date: timestamp }));
  }
  console.log("%c::: %s :::", "color:#43bb98; font-weight:bold", loader);
  return {
    error: (feature, error, ex) => {
      console.log("%c::: ERROR ::: %o", "color:#ff9a38; font-weight:bold", error);
      console.log("%c::: %o", "color:#ff9a38; font-weight:bold", ex);
      dispatch(setError(feature, error));
    },
    close: () => {
      if (loader) {
        dispatch(removeFromLoader({ call: loader, date: timestamp }));
      }
    },
  };
};

export const selectLoader = (state) => state.core.loader && state.core.loader.length > 0;
export const selectSnacks = (state) => state.core.snacks;

export const selectProjects = (state) => state.core.projects;
export const selectSelectedProject = (state) => state.core.selectedProject;

export default coreSlice.reducer;
