import { createSlice } from "@reduxjs/toolkit";
import createDeviceCall from "./_sliceCalls/createDeviceCall";
import deleteDeviceCall from "./_sliceCalls/deleteDeviceCall";
import getBoxesCall from "./_sliceCalls/getBoxesCall";
import getDevicesCall from "./_sliceCalls/getDevicesCall";
import updateBoxCall from "./_sliceCalls/updateBoxCall";
import updateDeviceCall from "./_sliceCalls/updateDeviceCall";
import updateZoneCall from "./_sliceCalls/updateZoneCall";

export const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    list: null,
    boxes: null,
    formSuccess: false,
  },
  reducers: {
    setDevices: (state, action) => {
      state.list = action.payload;
    },
    setBoxes: (state, action) => {
      state.boxes = action.payload;
    },
    setFormSuccess: (state, action) => {
      state.formSuccess = action.payload;
    },
  },
});

export default devicesSlice.reducer;
export const { setDevices } = devicesSlice.actions;

// SELECTS
export const selectDevices = (state) => state.devices.list;
export const selectBoxes = (state) => state.devices.boxes;
export const selectFormSuccess = (state) => state.devices.formSuccess;

// CALLS
export const getDevices = () => (dispatch, getState) => {
  getDevicesCall(devicesSlice.actions, dispatch, getState());
};

export const getBoxes = () => (dispatch, getState) => {
  getBoxesCall(devicesSlice.actions, dispatch, getState());
};

export const updateZone =
  ({ _id: id, designation, description }) =>
    (dispatch) => {
      updateZoneCall({ ...devicesSlice.actions, getBoxes }, dispatch, { id, designation, description });
    };

export const updateBox =
  ({ _id: id, designation, description, code }) =>
    (dispatch) => {
      updateBoxCall({ ...devicesSlice.actions, getBoxes }, dispatch, { id, designation, description, code });
    };

export const updateDevice =
  ({ _id: id, type, code, designation, description, model, configs, boxId }) =>
    (dispatch) => {
      updateDeviceCall({ ...devicesSlice.actions, getDevices }, dispatch, {
        id,
        type,
        code,
        designation,
        description,
        model,
        configs,
        boxId,
      });
    };

export const createDevice =
  ({ type, code, designation, description, model, configs, boxId }) =>
    (dispatch, getState) => {
      createDeviceCall({ ...devicesSlice.actions, getDevices }, dispatch, getState(), {
        type,
        code,
        designation,
        description,
        model,
        configs,
        boxId,
      });
    };

export const deleteDevice = (id) => (dispatch) => {
  deleteDeviceCall({ ...devicesSlice.actions, getDevices }, dispatch, { id });
};
