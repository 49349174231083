import React from "react";
import { withStyles } from "tss-react/mui";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import { styles } from "./_base.styles";

function SelectInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  error,
  options,
  variant = "outlined",
  required,
  allowEmpty,
}) {
  return (
    <div className={classes.root + (className ? " " + className : "")}>
      {variant === "outlined" && label && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <FormControl className={classes.formControl} fullWidth variant={variant} size="small">
        {variant !== "outlined" && (
          <InputLabel>
            {label}
            {required && <span> *</span>}
          </InputLabel>
        )}
        <Select
          id={id}
          value={value}
          onChange={(e) => onChange && onChange(e.target.value)}
          className={classes.select + (disabled ? " " + classes.disabledInput : "")}
          fullWidth
          error={error}
          disabled={disabled}
        >
          {allowEmpty && <MenuItem key={"option_none"} value="" disabled></MenuItem>}
          {(options || []).map((item, index) => (
            <MenuItem key={"option_" + index} value={item.value} disabled={item.disabled}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default withStyles(SelectInput, styles);
