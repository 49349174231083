import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { styles } from "./SmarLinkItem.styles";
import IOBadge from "../../_common/IOBadge/IOBadge";
import { classJoin } from "../../../../common/utils";

function SmarLinkItem({ classes, smartlink, onSelect, selected }) {
  const { designation, inputMode, inputs, outputs } = smartlink;
  const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <div className={classJoin([classes.root, selected ? classes.selected : undefined, !smartlink.active ? classes.disabled : undefined])} onClick={() => onSelect && onSelect(smartlink)}>
      <Grid container spacing={4} justifyContent={isMobile ? "center" : "space-between"} alignItems="center" direction={isMobile ? "column" : "row"}>
        <Grid item>
          <Typography className={classes.title}>{designation}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Grid container spacing={1} wrap="nowrap" alignItems="center">
                {(inputs || []).map((item, index) => (
                  <Grid item key={"input_" + index}>
                    <Grid container spacing={1} wrap="nowrap" alignItems="center">
                      {index > 0 && inputMode === "AND" && <Grid item><Typography className={classes.modeConnector}>+</Typography></Grid>}
                      {index > 0 && inputMode === "OR" && <Grid item><Typography className={classes.modeConnector}>/</Typography></Grid>}
                      <Grid item >
                        {console.log('item: %o', item)}
                        <IOBadge
                          tag={item.tag}
                          tooltip={item?.device && index === 0 ?
                            <Typography className={classes.deviceInfo}>
                              {`${item.device?.code} - ${item.device?.designation}`}
                            </Typography> : undefined
                          }
                          deviceCode={item?.device?.code}
                          disabled={!smartlink.active}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item><KeyboardDoubleArrowRightIcon fontSize="large" className={classes.icon} /></Grid>
            <Grid item>
              <Grid container spacing={1} wrap="nowrap">
                {(outputs || []).map((item, index) => (
                  <Grid item key={"output_" + index}>
                    <IOBadge
                      tag={item.tag}
                      // tooltip={item?.device &&
                      //   <Typography className={classes.deviceInfo}>
                      //     {`${item.device?.code} - ${item.device?.designation}`}
                      //   </Typography>
                      // }
                      deviceCode={item?.device?.code}
                      disabled={!smartlink.active}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </div>
  );
}

export default withStyles(SmarLinkItem, styles);