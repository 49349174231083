import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const getTriggerTypeOptions = (t) => {
  return ["AND", "OR"].map((item) => {
    return {
      value: item,
      text: t("common.enum.triggerType." + item),
      description: t("common.enum.triggerTypeDesc." + item),
    };
  });
};

export const getSignOptions = () => {
  return ["gt", "lt"].map((item) => {
    return {
      value: item,
      icon: item === "gt" ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />,
    };
  });
};

export const getDeviceOptions = (
  structure,
  filterTypes = ["TEMPERATURE", "HUMIDITY", "RELAY", "BATTERY", "WATERLEVEL"]
) => {
  let options = [];
  if (structure?.length > 0) {
    structure.forEach((zone) => {
      zone.boxs?.forEach((box) => {
        box.sensors?.forEach((sensor) => {
          if (filterTypes.indexOf(sensor.type) > -1) {
            options.push({ value: sensor.id, text: sensor.code + " - " + sensor.designation });
          }
        });
      });
    });
  }
  return options;
};
