import React from "react";
import { Paper } from "@mui/material";

import LastReadingsChart from "./components/LastReadingsChart";

function TemperatureChart({ classes, data }) {
  if (!data) {
    return null;
  }
  // console.log("data: %o ", data);
  const min = data.reduce(
    (total, current) => (total < parseFloat(current.value) ? total : parseFloat(current.value)),
    0
  );
  const max = data.reduce(
    (total, current) => (total > parseFloat(current.value) ? total : parseFloat(current.value)),
    -20
  );
  return (
    <Paper style={{ padding: 20 }}>
      <LastReadingsChart
        title="Last 24h readings"
        defaultDataFilter="max"
        data={data}
        seriesLabel="Temperature"
        yaxis={{
          title: {
            text: "Temperature",
          },
          min,
          max,
        }}
        height={350}
      />
    </Paper>
  );
}

export default TemperatureChart;
