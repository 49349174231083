import { requestHelper } from "../../../app/coreSlice";
import { getProjects } from "../../../api/apiv2/projects/getProjects";

const form_getProjectsCall = async ({ setFormProjects }, dispatch) => {
  console.log("form_getProjectsCall");
  let rh = requestHelper(dispatch, "GET_PROJECTS");
  try {
    let result = await getProjects({ limit: 10000, select: "_id,name" });
    console.log("form_getProjectsCall - result: %o", result);

    if (result.success) {
      dispatch(setFormProjects(result.data));
    }
  } catch (ex) {
    rh.error("users", "GET_PROJECTS_ERROR", ex);
  }
  rh.close();
};

export default form_getProjectsCall;
