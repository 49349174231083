import { deleteSmartLinkOutput } from "../../../api/apiv2/smartlinks/outputs/deleteSmartLinkOutput";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const deleteSmartLinkOutputCall = async ({ refreshSmartLink, setFormSuccess }, dispatch, { smartLinkId, id }) => {
  let rh = requestHelper(dispatch, "SMARTLINKOUTPUT_DELETE");
  try {
    let result = await deleteSmartLinkOutput({ smartLinkId, id });
    if (result.success) {
      dispatch(setSuccess("smartlinks", "SMARTLINKOUTPUT_DELETE"));
      dispatch(refreshSmartLink(result.data));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("smartlinks", "SMARTLINKOUTPUT_DELETE_ERROR", ex);
  }
  rh.close();
};

export default deleteSmartLinkOutputCall;
