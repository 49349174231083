import { guid, jsonTryParse } from "../../common/utils";

export const getBlocks = async (restAPI) => {
  let r = await restAPI.get("/blocks/get");
  return (r?.data || []).map((block) => {
    return {
      ...block,
      config: jsonTryParse(block.config),
    };
  });
};

export const addBlock = async (restAPI, designation, code, type, config) => {
  let obj = {
    id: guid(),
    designation,
    code,
    type,
    config: JSON.stringify(config),
  };
  return restAPI.post("blocks/add", obj);
};

export const updateBlock = async (restAPI, id, designation, code, type, config) => {
  return restAPI.post("blocks/update", {
    id,
    designation,
    code,
    type,
    config: JSON.stringify(config),
  });
};

export const removeBlock = async (restAPI, id) => {
  // console.log("removeBlock id: %o", id);
  return restAPI.post("blocks/delete", {
    id,
  });
};
