import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectSelectedProject } from "../../app/coreSlice";
import { usePrevious } from "./usePrevious";

export function useFirstLoad(cb) {
  const [loaded, setLoaded] = useState(false);
  const selectedProject = useSelector(selectSelectedProject);
  const prevSelectedProject = usePrevious(selectedProject);

  let unmountCb = useRef();

  useEffect(() => {
    return () => {
      unmountCb.current && unmountCb.current();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loaded && selectedProject) {
      if (cb) {
        unmountCb.current = cb();
      }
      setLoaded(true);
    }
    if (loaded && selectedProject && selectedProject !== prevSelectedProject) {
      cb();
    }
    // eslint-disable-next-line
  }, [selectedProject]);
}
