import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Grid, Drawer, Chip } from "@mui/material";
import moment from "moment";
// import { useTranslation } from "react-i18next";

import { styles } from "./Towers.styles";
import { selectSelectedProject } from "../../../app/coreSlice";
import { usePrevious } from "../../../common/hooks";
import { getTowers, selectFormSuccess, selectTowers } from "../towersSlice";
import Tray from "./Tray/Tray";
import TrayForm from "./TrayForm/TrayForm";
import SimpleScroll from "../../../common/components/displays/SimpleScroll/SimpleScroll";

function Towers({ classes }) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectSelectedProject);
  const prevSelectedProject = usePrevious(selectedProject);
  const formSuccess = useSelector(selectFormSuccess);

  const towers = useSelector(selectTowers);
  const [loaded, setLoaded] = useState(false);
  const [drawerTrayFormOpen, setDrawerTrayFormOpen] = useState(false);
  const [selectedTray, setSelectedTray] = useState();

  // console.log("towers: %o", towers);

  useEffect(() => {
    if (formSuccess) {
      setDrawerTrayFormOpen(false);
      setSelectedTray();
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  useEffect(() => {
    if (!loaded || prevSelectedProject?.code !== selectedProject?.code) {
      dispatch(getTowers());
      setLoaded(true);
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  const trayClickHandler = (tray) => {
    console.log("tray: %o", tray);
    setSelectedTray(tray);
    setDrawerTrayFormOpen(true);
  };

  const tower = (code, trays) => {
    let lastTray = getLast(trays);
    let nextTray = trays.find((tray) => tray?.tray === (lastTray?.tray === 22 ? 1 : lastTray?.tray + 1));
    trays.sort((a, b) => moment(a.lastReadingAt).diff(moment(b.lastReadingAt)));
    let nTrays = [];
    let found = false;
    let topCheckCount = [...trays].reduce((max, obj) => Math.max(max, obj.readingsCheckCount), 0)

    trays.forEach((el) => {
      if (el.code === nextTray?.code) {
        found = true;
      }
      if (found) {
        nTrays.push({ ...el, topCheckCount });
      } else {
        nTrays.push({ ...el, warn: true, topCheckCount });
      }
    });

    nTrays = [{ ...lastTray, topCheckCount }, { ...nextTray, topCheckCount }, ...nTrays.filter((el) => el?.code !== lastTray?.code && el.code !== nextTray?.code)];
    // let hasStop = moment(nextTray?.lastReadingAt).isBefore(moment().add(-1, "day"));
    let hasStop = nTrays.filter(Boolean).every(({ lastReadingAt }) => moment(lastReadingAt).isBefore(moment().add(-1, "day")));

    return (
      <Grid item sm={3} xs={12}>
        <Paper className={classes.paper}>
          <Typography variant="h5">Tower {code}</Typography>
          {hasStop && <Chip size="small" className={classes.stopChip} label="Tower has stoped" variant="outlined" color="warning" />}
          <Grid container spacing={1} className={hasStop ? classes.stoped : ""}>
            {nTrays.map((item) =>
              item ? (
                <Grid item key={code + item?.tray + item?.code} xs={12}>
                  <Tray
                    item={item}
                    last={item?.code === lastTray?.code}
                    next={item?.code === nextTray?.code}
                    onClick={trayClickHandler}
                  />
                </Grid>
              ) : (
                ""
              )
            )}
          </Grid>
        </Paper>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {tower(
          "U0A",
          (towers || []).filter((ele) => ele.pod === "U0A")
        )}
        {tower(
          "U0B",
          (towers || []).filter((ele) => ele.pod === "U0B")
        )}
        {tower(
          "U0C",
          (towers || []).filter((ele) => ele.pod === "U0C")
        )}
        {tower(
          "U0D",
          (towers || []).filter((ele) => ele.pod === "U0D")
        )}
      </Grid>
      <Drawer
        anchor="right"
        open={Boolean(drawerTrayFormOpen && selectedTray)}
        onClose={() => setDrawerTrayFormOpen(false)}
      >
        <div className={classes.drawer}>
          <SimpleScroll className={classes.drawerScroll}>
            <TrayForm selectedTray={selectedTray} />
          </SimpleScroll>
        </div>
      </Drawer>
    </div>
  );
}

export default withStyles(Towers, styles);

const getLast = (trays) => {
  let lastDate = moment("2000-12-12");
  let lastTray = null;
  trays.forEach((tray) => {
    if (tray.lastReadingAt && moment(tray.lastReadingAt).isAfter(lastDate)) {
      lastDate = moment(tray.lastReadingAt);
      lastTray = tray;
    }
  });
  return lastTray;
};
