import React from "react";
import { Grid } from "@mui/material";

function FormFields({ fields }) {
  return (
    <Grid container spacing={2}>
      {fields.map(
        (item, idx) =>
          !item.hidden && (
            <Grid item xs={item.size} key={idx}>
              {item.field}
            </Grid>
          )
      )}
    </Grid>
  );
}

export default FormFields;
