import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Divider, Typography, Grid, Button, IconButton } from "@mui/material";

import { styles } from "./ZoneItem.styles.js";
import BoxItem from "./BoxItem/BoxItem.js";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Permission from "../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission.js";

function ZoneItem({
  classes,
  selectedProject,
  zone,
  onEditZone,
  onDeleteZone,
  onAddBox,
  onEditBox,
  onDeleteBox,
  onSensorSelected,
  onAddSensor,
}) {
  const [hover, sethover] = useState(false);
  //console.log("zone: %o", zone);
  return (
    <div className={classes.root} onMouseOver={() => sethover(true)} onMouseOut={() => sethover(false)}>
      <Typography variant="h2" className={classes.title}>
        {zone.designation}
        <Permission code="structure.write">
          <IconButton
            aria-label="edit"
            disableRipple
            size="small"
            onClick={onEditZone}
            className={classes.icon}
            style={hover ? { display: "inline" } : { display: "none" }}
            // style={{ display: "inline" }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="edit"
            disableRipple
            size="small"
            onClick={() => onDeleteZone(zone)}
            className={classes.icon}
            style={hover ? { display: "inline" } : { display: "none" }}
            // style={{ display: "inline" }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Permission>
      </Typography>
      <Typography variant="h6" className={classes.description}>
        {zone.description}
      </Typography>
      <Grid container spacing={1}>
        {Array.isArray(zone.boxs) &&
          zone.boxs.map((box) => (
            <Grid item xs={12} sm={6} key={box.id}>
              <BoxItem
                selectedProject={selectedProject}
                box={box}
                onEditBox={onEditBox}
                onDeleteBox={onDeleteBox}
                onSensorSelected={onSensorSelected}
                onAddSensor={onAddSensor}
              />
            </Grid>
          ))}
        <Permission code="structure.write">
          <Grid item xs={12} sm={6}>
            <Button variant="contained" size="small" color="secondary" onClick={() => onAddBox(zone.id)}>
              Add New Box
            </Button>
          </Grid>
        </Permission>
      </Grid>

      <Divider className={classes.divider} />
    </div>
  );
}

export default withStyles(ZoneItem, styles);
