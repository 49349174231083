export const styles = (theme) => ({
  root: {},
  scheduleItem: {
    padding: 5,
    borderRadius: 5,
  },
  active: {
    backgroundColor: "#def6de",
  },
  disabled: {
    backgroundColor: "#ddd",
  },
  inuse: {
    border: "2px solid #55b849"
  },
  smallerBtn: {
    padding: "0 5px",
    minWidth: "unset",
  },
  chip: {
    height: 15,
      marginRight: 5,
      "& span": {
        padding: "0 5px",
        lineHeight: 1,
      },
  }
});
