import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { addTrigger } from "../../../api/nodeRedApi/triggers";

const triggersCreateCall = async (
  { getTriggers, setFormSuccess },
  dispatch,
  state,
  { designation, type, input, output, delay }
) => {
  let rh = requestHelper(dispatch, "TRIGGERS_CREATE");
  let selectedProject = state.core.selectedProject?.code;
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await addTrigger(api, designation, type, input, output, delay);
      dispatch(setSuccess("triggers", "TRIGGERS_CREATE"));
      dispatch(getTriggers());
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("triggers", "TRIGGERS_CREATE_ERROR", ex);
  }
  rh.close();
};

export default triggersCreateCall;
