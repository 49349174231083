import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { requestHelper } from "../../../app/coreSlice";
import { blocksSlice } from "../blocksSlice";
import { getBlocks as getBlocksAPI } from "../../../api/nodeRedApi/blocks";

const { setBlocks } = blocksSlice.actions;

const getBlocks = () => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;

  let rh = requestHelper(dispatch, "GET_BLOCKS");
  try {
    let blocks = [];
    let api = getAPI(selectedProject);
    if (api) {
      blocks = await getBlocksAPI(api);

      blocks = blocks.map((block) => {
        return {
          ...block,
          //add info to devices accordingly the type
        };
      });
    }

    dispatch(setBlocks(blocks));
  } catch (ex) {
    rh.error("blocks", "GET_BLOCKS_ERROR", ex);
  }
  rh.close();
};

export default getBlocks;
