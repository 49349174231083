import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updateZone } from "../../../api/apiv2/zones/updateZone";

const updateZoneCall = async ({ getBoxes, setFormSuccess }, dispatch, { id, designation, description }) => {
  let rh = requestHelper(dispatch, "ZONE_UPDATE");
  try {
    let result = await updateZone({ id, designation, description });
    if (result.success) {
      dispatch(setSuccess("zones", "ZONE_UPDATE"));
      dispatch(getBoxes());
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("zones", "ZONE_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateZoneCall;
