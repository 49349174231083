import { requestHelper } from "../../../app/coreSlice";
import { getTowers } from "../../../api/apiv2/towers/getTowers";
import moment from "moment";

const getTowersCall = async ({ setTowers }, dispatch, state) => {
  let rh = requestHelper(dispatch, "GET_TOWERS");
  try {
    let project = state.core.selectedProject?._id;
    let result = await getTowers({ project });
    console.log("result: %o", result);
    if (result.success) {
      let parsedData = [];
      (result.data || []).forEach(element => {
        parsedData.push({
          ...element,
          readings: (element.readings || []).sort((a, b) => a > b ? -1 : 1),
          readingsCheckCount: (element.readings || []).filter(item => moment(item).isAfter(moment().add(-3, "days")))?.length
        })
      });
      console.log('parsedData: %o', parsedData);
      dispatch(setTowers(parsedData));
    }
  } catch (ex) {
    rh.error("towers", "GET_TOWERS_ERROR", ex);
  }
  rh.close();
};

export default getTowersCall;
