export const styles = (theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      boxShadow: "inset 0 0 15px #00000060",
    },
  },

  inverted: {
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "5px",
      boxShadow: "inset 0 0 15px #ffffff60",
    },
    direction: "rtl",
    "& *": {
      direction: "ltr",
    },
  }
});
