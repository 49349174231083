import { createSlice } from "@reduxjs/toolkit";
import createRoleCall from "./_sliceCalls/createRoleCall";
import deleteRoleCall from "./_sliceCalls/deleteRoleCall";

import getRolesCall from "./_sliceCalls/getRolesCall";
import updateRoleCall from "./_sliceCalls/updateRoleCall";

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    collection: [],
    collectionPagination: {},
    filter: {
      search: "",
      sortField: "name",
      sortType: "ASC",
      limit: 25,
      page: 1,
    },
    formResultSuccess: false,
  },
  reducers: {
    clearRolesList: (state) => {
      state.collection = [];
    },
    appendRolesList: (state, action) => {
      state.collection = [...state.collection, ...action.payload];
    },
    setFormSuccess: (state, action) => {
      state.formResultSuccess = action.payload;
    },
    setRolesFilter: (state, action) => {
      state.filter = action.payload;
    },
    setRolesCollectionPagination: (state, action) => {
      state.collectionPagination = action.payload;
    },
  },
});

export default rolesSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectRolesList = (state) => state.roles.collection;
export const selectRolesFilter = (state) => state.roles.filter;
export const selectFormSuccess = (state) => state.roles.formResultSuccess;
export const selectRolesListPagination = (state) => state.roles.collectionPagination;
export const selectFeatures = (state) => state.roles.features;

// CALLS
export const getRoles =
  ({ clear, filter }) =>
  (dispatch, getState) => {
    getRolesCall(rolesSlice.actions, dispatch, getState(), { clear, filter });
  };

export const createRole = (fields) => (dispatch, getState) => {
  createRoleCall({ ...rolesSlice.actions, getRoles }, dispatch, getState(), fields);
};

export const deleteRole = (id) => (dispatch, getState) => {
  deleteRoleCall({ getRoles }, dispatch, getState(), id);
};

export const updateRole = (fields) => (dispatch, getState) => {
  updateRoleCall({ ...rolesSlice.actions, getRoles }, dispatch, getState(), fields);
};
