import * as Yup from "yup";
import { TextField, CardSelectField, SelectField, NumberField } from "../../../../common/forms";
import { getDeviceOptionsByTypes } from "./BlocksForm.options";

export function formSchema(t) {
  return Yup.object().shape({
    designation: Yup.string().required("designation is required"),
    code: Yup.string().required("designation is required"),
  });
}

export function formFields(t, { type, id }, devices) {
  console.log("devices 123: %o", devices);
  let fields = [
    {
      size: 12,
      field: <TextField id="designation" label="Designation" required />,
    },
    {
      size: 12,
      field: <TextField id="code" label="Code" required />,
    },
    {
      size: 12,
      field: (
        <CardSelectField
          disabled={Boolean(id)}
          id="type"
          label="Block Type"
          options={[
            { value: "WINDOW", text: "Window", description: "Open/Close window" },
            { value: "PUMP", text: "Water Pump", description: "Turn water pump on for some time" },
          ]}
          xs={6}
        />
      ),
    },
  ];

  switch (type) {
    case "WINDOW":
      fields = fields.concat([
        {
          size: 12,
          field: (
            <SelectField
              id="config.openRelay"
              label="Open Relay"
              required
              options={getDeviceOptionsByTypes(devices, ["RELAY"])}
            />
          ),
        },
        {
          size: 12,
          field: (
            <SelectField
              id="config.closeRelay"
              label="Close Relay"
              required
              options={getDeviceOptionsByTypes(devices, ["RELAY"])}
            />
          ),
        },
        {
          size: 12,
          field: <NumberField decimalScale={0} id="config.timeToOpen" label="Time to fully open (s)" />,
        },
      ]);
      break;
    case "PUMP":
      fields = fields.concat([
        {
          size: 12,
          field: (
            <SelectField
              id="config.relay"
              label="Relay"
              required
              options={getDeviceOptionsByTypes(devices, ["RELAY"])}
            />
          ),
        },
        {
          size: 12,
          field: (
            <SelectField
              id="config.sensor"
              label="Current Sensor"
              required
              options={getDeviceOptionsByTypes(devices, ["CURRENT_SENSOR"])}
            />
          ),
        },
      ]);
      break;
    default:
      break;
  }

  return fields;
}
