import { api, getToken } from "../api";

export const updateBox = async ({ id, designation, description, code }) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/boxes/${id}`,
    { designation, description, code },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
