export const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const sleep = (delay) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
};

export const getLocalStorage = (key) => {
  let value = localStorage.getItem(key);
  try {
    value = JSON.parse(value);
  } catch (ex) {}
  return value;
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const jsonTryParse = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (ex) {
    return jsonString;
  }
};

export const compareObjects = (obj1, obj2) => {
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);
  if (keys1?.length !== keys2?.length) {
    return false;
  }
  for (let i = 0; i < keys1.length; i++) {
    let eq = false;
    if (Array.isArray(obj1[keys1[i]])) {
      eq = compareArrays(obj1[keys1[i]], obj2[keys1[i]]);
    } else if (typeof obj1[keys1[i]] === "object") {
      eq = compareObjects(obj1[keys1[i]], obj2[keys1[i]]);
    } else {
      eq = obj1[keys1[i]] === obj2[keys1[i]];
    }
    if (!eq) {
      return false;
    }
  }
  return true;
};

export const compareArrays = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    let eq = false;
    if (Array.isArray(arr1[i])) {
      eq = compareArrays(arr1[i], arr2[i]);
    } else if (typeof arr1[i] === "object") {
      eq = compareObjects(arr1[i], arr2[i]);
    } else {
      eq = arr1[i] === arr2[i];
    }
    if (!eq) {
      return false;
    }
  }
  return true;
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < (array || []).length; index++) {
    await callback(array[index], index, array);
  }
};

export const parseJwt = (token) => {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

export const classJoin = (arr) => {
  let filtered = (arr || []).filter((item) => item !== undefined);
  return filtered.join(" ");
};
