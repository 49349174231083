import { createSlice } from "@reduxjs/toolkit";
import getLogsCall from "./_sliceCalls/getLogsCall";
import moment from "moment";

export const logsSlice = createSlice({
  name: "logs",
  initialState: {
    loader: null,
    error: null,
    logs: null,
    filter: {
      startDate: moment().add(-1, "days").toISOString(),
      endDate: moment().toISOString(),
      type: "log",
    },
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setLogs: (state, action) => {
      state.logs = action.payload;
    },

    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const { setLoader, setError, setLogs, setFilter } = logsSlice.actions;
export default logsSlice.reducer;

// SELECTS
export const selectLoader = (state) => state.logs.loader;
export const selectError = (state) => state.logs.error;
export const selectLogs = (state) => state.logs.logs;
export const selectFilter = (state) => state.logs.filter;

// CALLS
export const getLogs = (filter) => async (dispatch, getState) => {
  // let logs = [];
  getLogsCall(logsSlice.actions, dispatch, getState(), filter);
  // if (type === "helium") {
  // } else {
  //   dispatch(setLoader("LOADING"));
  //   let api = getAPI(project?.code);
  //   if (api) {
  //     logs = await getLogsAPI(api, type);
  //   } else {
  //     await sleep(1000);
  //   }
  //   dispatch(setLogs(logs));
  //   dispatch(setLoader(null));
  // }
};
