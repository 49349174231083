import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { deleteRole } from "../../../api/apiv2/roles/deleteRole";

const deleteRoleCall = async ({ getRoles }, dispatch, state, id) => {
  let rh = requestHelper(dispatch, "ROLE_DELETE");
  try {
    const filter = state.roles.filter;
    let result = await deleteRole(id);
    if (result.success) {
      dispatch(setSuccess("roles", "ROLE_DELETE"));
      dispatch(getRoles({ clear: true, filter }));
    }
  } catch (ex) {
    rh.error("roles", "ROLE_DELETE_ERROR", ex);
  }
  rh.close();
};

export default deleteRoleCall;
