import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Typography, Grid, FormControlLabel, Checkbox } from "@mui/material";

import { styles } from "./PermissionSelect.styles";
import { Field } from "react-final-form";

const permissionsBase = [
  "alerts",
  "blocks",
  "charts",
  "dashboard",
  "logs",
  "schedules",
  "structure",
  "triggers",
  "users",
  "roles",
  "projects",
  "towers",
  "podsettings",
  "smartlinks"
];

export const permissionsBaseValues = () => {
  let permissions = {};
  permissionsBase.forEach((item) => {
    permissions[item] = { write: false, read: false };
  });
  return permissions;
};

function PermissionSelect({ classes, id, label }) {
  // const { t } = useTranslation();

  const onWriteChange = (checked, input) => {
    let nValue = input.value || {};
    if (checked) {
      nValue = { read: true, write: true };
    } else {
      nValue = { ...nValue, write: false };
    }
    input.onChange && input.onChange(nValue);
  };

  const onReadChange = (checked, input) => {
    let nValue = input.value || {};
    if (checked) {
      nValue = { ...nValue, read: true };
    } else {
      nValue = { write: false, read: false };
    }
    input.onChange && input.onChange(nValue);
  };

  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography>{label}</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="column">
            {permissionsBase.map((perm) => (
              <Field name={`${id}.${perm}`} key={perm}>
                {({ input, meta }) => {
                  return (
                    <>
                      <Grid item>
                        <Grid container direction="row" wrap="nowrap" alignItems="center">
                          <Grid item xs={6}>
                            {perm}
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={Boolean(input.value?.read)}
                                  onChange={(e) => onReadChange(e.target.checked, input)}
                                />
                              }
                              label="R"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  size="small"
                                  checked={Boolean(input.value?.write)}
                                  onChange={(e) => onWriteChange(e.target.checked, input)}
                                />
                              }
                              label="W"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  );
                }}
              </Field>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(PermissionSelect, styles);
