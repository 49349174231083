import { api, getToken } from "../api";

export const getLastReadingsV2 = async (codes) => {
  const token = getToken();
  try {
    let result = await api.post(
      "/api/v1/elastic/readings/get-last",
      { codes },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("%c::: getLastReadings RESULT ::: %o", "color:#55B849; font-weight:bold", result);
    return result?.data;
  } catch (ex) {
    console.log("ERROR: %o", ex);
  }
};
