export const styles = (theme) => ({
  root: {
    padding: 20,
  },
  logsContainer: {
    padding: 10,
    width: "100%",
    // height: "calc(100vh - 210px)",
    // overflowY: "scroll",
  },
  log: {
    fontSize: 13,
    fontFamily: "monospace",
  },
  logDay: {
    textAlign: "center",
    fontSize: 13,
    fontFamily: "monospace",
  },
  highlight: {
    backgroundColor: "#bbe3b6",
    padding: 0,
  },
  dateInput: {
    width: 220,
  },
  podSelect: {
    minWidth: 220,
    backgroundColor: "#fff"
  },
  jsonContainer: {
    height: "calc(100vh - 180px) !important",
    "& #selected-log-outer-box": {
      height: "calc(100vh - 180px) !important",
    },
    "& #selected-log-container": {
      height: "calc(100vh - 180px) !important",
    },
    "& #selected-log": {
      height: "100% !important",
      "&::-webkit-scrollbar": {
        width: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        boxShadow: "inset 0 0 15px #009bcc60",
      },
    },
  }
});
