import { createSlice } from "@reduxjs/toolkit";
import triggersActivateCall from "./_sliceCalls/triggersActivate";
import triggersCreateCall from "./_sliceCalls/triggersCreate";
import triggersDeleteCall from "./_sliceCalls/triggersDelete";
import triggersListCall from "./_sliceCalls/triggersList";
import triggersUpdateCall from "./_sliceCalls/triggersUpdate";

export const triggersSlice = createSlice({
  name: "triggers",
  initialState: {
    triggers: [],
    formResultSuccess: false,
  },
  reducers: {
    setTriggers: (state, action) => {
      state.triggers = action.payload;
    },
    setFormSuccess: (state, action) => {
      state.formResultSuccess = action.payload;
    },
  },
});

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectTriggers = (state) => state.triggers.triggers;
export const selectFormSuccess = (state) => state.triggers.formResultSuccess;

// CALLS
export const getTriggers = () => (dispatch, getState) => {
  triggersListCall(triggersSlice.actions, dispatch, getState());
};

export const addTrigger = (fields) => (dispatch, getState) => {
  triggersCreateCall({ ...triggersSlice.actions, getTriggers }, dispatch, getState(), fields);
};

export const updateTrigger =
  ({ id, type, designation, input, output, delay }) =>
  (dispatch, getState) => {
    triggersUpdateCall({ ...triggersSlice.actions, getTriggers }, dispatch, getState(), {
      id,
      type,
      designation,
      input,
      output,
      delay,
    });
  };

export const activateTrigger =
  ({ id, active }) =>
  async (dispatch, getState) => {
    triggersActivateCall({ getTriggers }, dispatch, getState(), { id, active });
  };

export const removeTrigger =
  ({ id }) =>
  async (dispatch, getState) => {
    triggersDeleteCall({ getTriggers }, dispatch, getState(), { id });
  };

export default triggersSlice.reducer;
