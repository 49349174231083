import React from "react";
import ApexChart from "react-apexcharts";

function Chart({ options, series, type, height }) {
  let _options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      ...options.chart,
    },
    colors: ["#90F1EF", "#55B849", "#088574", "#BAA100"],
    dataLabels: {
      show: false,
      enabled: false,
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    ...options,
  };

  return (
    <div>
      <ApexChart options={_options} series={series} type={type} height={height} />
    </div>
  );
}

export default Chart;
