import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Typography, Stack, Chip, Box, LinearProgress } from "@mui/material";

import { styles } from "./DeviceReadingChip.styles";
import SensorIcon from "../../components/displays/SensorIcon";
import moment from "moment";
import { classJoin } from "../../utils";

function DeviceReadingChip({ classes, label, read, device, fullWidth }) {
  const { type, value, date } = read || {};
  // const { t } = useTranslation();
  let valueFlt = parseFloat(value);

  let valueStr = "", valueStr2 = "";
  let progressValue = 0;
  let deviceLabel = device ? `${device?.code} - ${device?.designation}` : "-";
  switch (type) {
    case "TEMPERATURE":
      valueStr = `${valueFlt}ºC`;
      progressValue = scaleValue(valueFlt, -20, 70);
      break;
    case "HUMIDITY":
      valueStr = `${valueFlt}%`;
      break;
    case "BATTERY":
      progressValue = scaleValue(valueFlt, device?.configs?.voltageEmpty, device?.configs?.voltageFull);
      valueStr = `${progressValue.toFixed(0)}%`;
      break;
    case "DOOR":
      valueStr = `${value === 1 ? "OPEN" : "CLOSE"}`;
      valueStr2 = `${moment(date).format("DD-MMM HH:mm")}`;
      break;
    case "MOTION":
      valueStr = `Last movement`;
      valueStr2 = `${moment(date).format("DD-MMM HH:mm")}`;
      break;
    default:
      valueStr = valueFlt;
      progressValue = valueFlt;
      break;
  }

  return (
    <div className={classes.root}>
      <Chip
        className={classJoin([classes.chip, fullWidth && classes.fullWidth])}
        variant="outlined"
        size="medium"
        onClick={() => {
          console.log('read: %o', read);
          console.log('device: %o', device);
        }}
        icon={
          <Box className={classes.iconBox}>
            <SensorIcon type={type} />
          </Box>
        }
        label={
          <Stack direction="column" alignItems="center" className={classes.labelBox}>
            <Stack direction="row" justifyContent="space-between" className={classJoin([classes.labelBox, fullWidth && classes.fullWidth])} alignItems="center" spacing={2}>
              <Typography className={classes.label}>{label || deviceLabel}</Typography>
              <Stack>
                <Typography className={classes.labelValue}>{valueStr}</Typography>
                {valueStr2 && <Typography className={classes.labelValue}>{valueStr2}</Typography>}
              </Stack>
            </Stack>
            {type !== "MOTION" && type !== "DOOR" &&
              <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={progressValue} />
              </Box>
            }
          </Stack>
        }
      />
    </div>
  );
}

export default withStyles(DeviceReadingChip, styles);

function scaleValue(value, fromLow, fromHigh) {
  let scaledValue = ((value - fromLow) / (fromHigh - fromLow)) * 100;
  if (scaledValue > 100) {
    scaledValue = 100;
  }
  if (scaledValue < 0) {
    scaledValue = 0;
  }
  return scaledValue;
}