import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography } from "@mui/material";

import { styles } from "./IOItem.styles";

function IOItem({ classes, title, icon, description, type, onClick, selected }) {
  return (
    <Grid
      className={classes.root + (selected ? (" " + classes.selected) : "")}
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => onClick && onClick(type)}
    >
      <Grid item>
        <Typography variant="caption" className={classes.title}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        {icon}
      </Grid>
      <Grid item>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(IOItem, styles);