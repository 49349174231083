import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid, Checkbox } from "@mui/material";

import { styles } from "./_base.styles";

function CheckBoxInput({ classes, id, className, label, disabled, value, onChange }) {
  return (
    <Grid
      className={className}
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      style={{ cursor: disabled ? "default" : "pointer" }}
      onClick={(e) => {
        !disabled && onChange(!value);
      }}
    >
      <Grid item>
        <Checkbox id={id} disabled={disabled} color="primary" checked={Boolean(value)} />
      </Grid>
      <Grid item>
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(CheckBoxInput, styles);
