import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Switch, FormControlLabel } from "@mui/material";

import { styles } from "./HeliumTest.styles";
import { useDispatch, useSelector } from "react-redux";
import { getSwitcherLastReading, selectSwitcherState } from "../testsSlice";

function HeliumTest({ classes }) {
  const dispatch = useDispatch();
  const [isRunning, setIsRunning] = useState(false);
  const switchState = useSelector(selectSwitcherState);

  console.log("switchState: %o", switchState);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        dispatch(getSwitcherLastReading());
      }, 5000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [isRunning]);

  const loppHandler = (_e, checked) => {
    setIsRunning(checked);
  };

  // "Mg==": false,
  // "MQ==": false,
  // "Mw==": false,
  // "NA==": false,

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <FormControlLabel control={<Switch onChange={loppHandler} />} label="Listener" />
        </Grid>
        <Grid item>
          <hr />
        </Grid>
        <Grid item>
          <FormControlLabel control={<Switch checked={switchState?.["MQ=="]} />} label="MQ==" disabled />
        </Grid>
        <Grid item>
          <FormControlLabel control={<Switch checked={switchState?.["Mg=="]} />} label="Mg==" disabled />
        </Grid>
        <Grid item>
          <FormControlLabel control={<Switch checked={switchState?.["Mw=="]} />} label="Mw==" disabled />
        </Grid>
        <Grid item>
          <FormControlLabel control={<Switch checked={switchState?.["NA=="]} />} label="NA==" disabled />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(HeliumTest, styles);
