import { createSlice } from "@reduxjs/toolkit";
import createProjectCall from "./_sliceCalls/createProjectCall";
import deleteProjectCall from "./_sliceCalls/deleteProjectCall";
import getFeaturesCall from "./_sliceCalls/getFeaturesCall";
import getProjectsCall from "./_sliceCalls/getProjectsCall";
import updateProjectCall from "./_sliceCalls/updateProjectCall";

export const projectsSlice = createSlice({
  name: "projects",
  initialState: {
    collection: [],
    collectionPagination: {},
    filter: {
      search: "",
      sortField: "name",
      sortType: "ASC",
      limit: 25,
      page: 1,
    },
    formResultSuccess: false,
    features: undefined,
  },
  reducers: {
    clearProjectsList: (state) => {
      state.collection = [];
    },
    appendProjectsList: (state, action) => {
      state.collection = [...state.collection, ...action.payload];
    },
    setFormSuccess: (state, action) => {
      state.formResultSuccess = action.payload;
    },
    setProjectsFilter: (state, action) => {
      state.filter = action.payload;
    },
    setProjectsCollectionPagination: (state, action) => {
      state.collectionPagination = action.payload;
    },
    setFeatures: (state, action) => {
      state.features = action.payload;
    },
  },
});

export default projectsSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectProjectsList = (state) => state.projects.collection;
export const selectProjectsFilter = (state) => state.projects.filter;
export const selectFormSuccess = (state) => state.projects.formResultSuccess;
export const selectProjectsListPagination = (state) => state.projects.collectionPagination;
export const selectFeatures = (state) => state.projects.features;

// CALLS
export const getProjects =
  ({ clear, filter }) =>
  (dispatch, getState) => {
    getProjectsCall(projectsSlice.actions, dispatch, getState(), { clear, filter });
  };

export const createProject = (fields) => (dispatch, getState) => {
  createProjectCall({ ...projectsSlice.actions, getProjects }, dispatch, getState(), fields);
};

export const deleteProject = (id) => (dispatch, getState) => {
  deleteProjectCall({ getProjects }, dispatch, getState(), id);
};

export const updateProject = (fields) => (dispatch, getState) => {
  updateProjectCall({ ...projectsSlice.actions, getProjects }, dispatch, getState(), fields);
};

export const getFeatures = () => (dispatch, getState) => {
  getFeaturesCall(projectsSlice.actions, dispatch, getState());
};
