import { createSlice } from "@reduxjs/toolkit";
import { setSuccess, requestHelper } from "../../app/coreSlice";
import { getAPI } from "../../api/nodeRedApi/axiosApi";

import {
  structureGet,
  addZone as addZoneAPI,
  updateZone as updateZoneAPI,
  removeZone as removeZoneAPI,
  addBox as addBoxAPI,
  updateBox as updateBoxAPI,
  removeBox as removeBoxAPI,
  addSensor as addSensorAPI,
  updateSensor as updateSensorAPI,
  removeSensor as removeSensorAPI,
  getSensorReadings as getSensorReadingsAPI,
} from "../../api/nodeRedApi/project";

import { sleep } from "../../common/utils/utils";

export const structureSlice = createSlice({
  name: "structure",
  initialState: {
    error: null,
    structure: [],
    status: null,
    sensorReadings: [],
    allDevices: null,
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },

    setStructure: (state, action) => {
      state.structure = action.payload;
      if (action.payload?.length > 0) {
        state.allDevices = action.payload.reduce((agg, zone) => {
          return agg.concat(
            zone.boxs.reduce((aggSensor, box) => {
              return aggSensor.concat(
                box.sensors.map((sensor) => {
                  return {
                    ...sensor,
                    boxDesignation: box.designation,
                    boxDescription: box.description,
                    zoneId: zone.id,
                    zoneDesignation: zone.designation,
                  };
                })
              );
            }, [])
          );
        }, []);
      }
    },

    setStatus: (state, action) => {
      state.status = action.payload;
    },

    setSensorReadings: (state, action) => {
      state.sensorReadings = action.payload;
    },
  },
});

export const { setError, setStructure, setStatus, setSensorReadings } = structureSlice.actions;

export const getStructure = () => async (dispatch, getState) => {
  dispatch(setStructure([]));
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "GET_STRUCTURE");
  try {
    let zones = [];
    let api = getAPI(selectedProject);
    if (api) {
      let struct = await structureGet(api);
      zones = struct.data;
    }
    dispatch(setStructure(zones));
  } catch (ex) {
    rh.error("structure", "GET_STRUCTURE_ERROR", ex);
  }
  rh.close();
};

export const addZone =
  (project, { designation, description }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "ADD_ZONE");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await addZoneAPI(api, designation, description);
        dispatch(getStructure());
      }
      dispatch(setSuccess("structure", "ZONE_ADDED"));
    } catch (ex) {
      rh.error("structure", "ADD_ZONE_ERROR", ex);
    }
    rh.close();
  };

export const updateZone =
  (project, { id, designation, description }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "UPDATE_ZONE");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await updateZoneAPI(api, id, designation, description);
        dispatch(getStructure());
      }
      dispatch(setSuccess("structure", "ZONE_UPDATED"));
    } catch (ex) {
      rh.error("structure", "UPDATE_ZONE_ERROR", ex);
    }
    rh.close();
  };

export const removeZone = (project, id) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "REMOVE_ZONE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await removeZoneAPI(api, id);
      dispatch(getStructure());
    }
    dispatch(setSuccess("structure", "ZONE_REMOVED"));
  } catch (ex) {
    rh.error("structure", "REMOVE_ZONE_ERROR", ex);
  }
  rh.close();
};

export const addBox =
  (project, { designation, description, zoneId }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "ADD_BOX");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await addBoxAPI(api, designation, description, zoneId);
        dispatch(getStructure());
      }
      dispatch(setStatus("BOX_ADDED"));
      await sleep(10000);
      dispatch(setStatus(null));
    } catch (ex) {
      rh.error("structure", "ADD_BOX_ERROR", ex);
    }
    rh.close();
  };

export const updateBox =
  (project, { id, designation, description, zoneId }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "UPDATE_BOX");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await updateBoxAPI(api, id, designation, description, zoneId);
        dispatch(getStructure());
      }

      dispatch(setStatus("BOX_UPDATED"));

      await sleep(10000);
      dispatch(setStatus(null));
    } catch (ex) {
      rh.error("structure", "UPDATE_BOX_ERROR", ex);
    }
    rh.close();
  };

export const removeBox = (project, id) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "REMOVE_BOX");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await removeBoxAPI(api, id);
      dispatch(getStructure());
    }
    dispatch(setStatus("BOX_REMOVED"));

    await sleep(10000);
    dispatch(setStatus(null));
  } catch (ex) {
    rh.error("structure", "REMOVE_BOX_ERROR", ex);
  }
  rh.close();
};

export const addSensor =
  (project, { designation, description, type, model, code, config, boxId }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "ADD_SENSOR");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await addSensorAPI(api, designation, description, type, model, code, config, boxId);
        dispatch(getStructure());
      }

      dispatch(setStatus("SENSOR_ADDED"));

      await sleep(10000);
      dispatch(setStatus(null));
    } catch (ex) {
      rh.error("structure", "ADD_SENSOR_ERROR", ex);
    }
    rh.close();
  };

export const updateSensor =
  (project, { id, designation, description, type, model, code, config, boxId }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "UPDATE_SENSOR");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await updateSensorAPI(api, id, designation, description, type, model, code, config, boxId);
        dispatch(getStructure());
      }

      dispatch(setStatus("SENSOR_UPDATED"));

      await sleep(10000);
      dispatch(setStatus(null));
    } catch (ex) {
      rh.error("structure", "UPDATE_SENSOR_ERROR", ex);
    }
    rh.close();
  };

export const removeSensor = (project, id) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "REMOVE_SENSOR");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await removeSensorAPI(api, id);
      dispatch(getStructure());
    }
    dispatch(setStatus("SENSOR_REMOVED"));

    await sleep(10000);
    dispatch(setStatus(null));
  } catch (ex) {
    rh.error("structure", "REMOVE_SENSOR_ERROR", ex);
  }
  rh.close();
};

export const getSensorReadings = (project, code, id, type) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "GET_SENSOR_READINGS");
  try {
    let readings = [];
    let api = getAPI(selectedProject);
    if (api) {
      let result = await getSensorReadingsAPI(api, code);
      readings = result.data;
    }

    dispatch(setSensorReadings(readings));
  } catch (ex) {
    rh.error("structure", "GET_SENSOR_READINGS_ERROR", ex);
  }
  rh.close();
};

export const selectError = (state) => state.structure.error;
export const selectStructure = (state) => state.structure.structure;
export const selectStatus = (state) => state.structure.status;
export const selectSensorReadings = (state) => state.structure.sensorReadings;

export default structureSlice.reducer;
