import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { styles } from "./UsersForm.styles";
import { Form, FormFields } from "../../../../common/forms";
import { formFields, formSchema } from "./UsersForm.schema";
import {
  createUser,
  getFormProjects,
  getFormRoles,
  selectFormProjects,
  selectFormRoles,
  updateUser,
} from "../../usersSlice";
import { useSelector } from "react-redux";

function UsersForm({ classes, selectedUser }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const roles = useSelector(selectFormRoles);
  const projects = useSelector(selectFormProjects);

  useEffect(() => {
    if (!roles) {
      dispatch(getFormRoles());
    }
    if (!projects) {
      dispatch(getFormProjects());
    }
    // eslint-disable-next-line
  }, []);

  const onSubmitHandler = (values) => {
    if (selectedUser) {
      dispatch(updateUser(values));
    } else {
      dispatch(createUser(values));
    }
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={selectedUser || {}}
        onSubmit={onSubmitHandler}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(t, roles, projects, Boolean(selectedUser))} />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                {t("users.UsersForm.form.submit")}
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(UsersForm, styles);
