import * as Yup from "yup";
import { DeviceField, TextField } from "../../../../../common/forms";

export const formSchema = (t) => {
  return Yup.object().shape({
    deviceId: Yup.string().required(),
    time: Yup.string().required(),
  });
}

export const formFields = (t) => {
  return [
    {
      size: 12,
      field: <DeviceField
        id="deviceId"
        label={t("alerts.AlertForm.form.device")}
        types={["TEMPERATURE", "HUMIDITY", "BATTERY", "WATERLEVEL"]}
        required
      />,
    },
    {
      size: 6,
      field: <TextField
        id="time"
        label="Time"
        required
      />,
    },
  ];
}


const NO_READING = {
  formSchema, formFields
}

export default NO_READING