export const styles = (theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    marginTop: 30
  },
  header: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid #ddd",
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: 14,
    fontWeight: "bold"
  },
  content: {
    padding: theme.spacing(4),
    height: "100%"
  },
  small: {
    width: 300,
    minHeight: 300,
    "& .CustomModalHeader span": {
      width: 240,
    },
  },
  medium: {
    width: 440
  },
  large: {
    width: 560
  },
  extralarge: {
    width: 1000,
    minHeight: 600
  },
  mobile: {
    width: "90vw",
    minHeight: "60vh"
  }

});
