import { createSlice } from "@reduxjs/toolkit";

export const blocksSlice = createSlice({
  name: "blocks",
  initialState: {
    blocks: null,
    formResultSuccess: false,
  },
  reducers: {
    setBlocks: (state, action) => {
      state.blocks = action.payload;
    },
    setFormSuccess: (state, action) => {
      state.formResultSuccess = action.payload;
    },
  },
});

const { setFormSuccess } = blocksSlice.actions;

export const triggerFormSuccess = () => async (dispatch) => {
  dispatch(setFormSuccess(true));
  setTimeout(() => {
    dispatch(setFormSuccess(false));
  }, 500);
};

export default blocksSlice.reducer;
