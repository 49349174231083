import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";4
import { Button, Typography, Paper, Stack, Chip, Divider, IconButton } from "@mui/material";

import { styles } from "./RelayButton.styles";
import { useDispatch, useSelector } from "react-redux";
import { selectDevices } from "../../../../devices/devicesSlice";
import CustomModal from "../../../../../common/displays/CustomModal/CustomModal";
import RelayForm from "./RelayForm/RelayForm";
import { getActions, selectActionLocker, selectActions } from "../../../dashboardSlice";
import moment from "moment";
import ActionState from "./ActionState/ActionState";
import SyncIcon from '@mui/icons-material/Sync';

function RelayButton({ classes, index, configs }) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const [triggerModal, setTriggerModal] = useState(false);
  const devices = useSelector(selectDevices) || [];
  const actions = useSelector(selectActions) || [];
  const widgetDevices = devices.filter(item => configs.devices.includes(item.code)) || [];
  const actionLocker = useSelector(selectActionLocker);
  const [selectedDevice, setSelectedDevice] = useState();
  const [stopDevice, setStopDevice] = useState(false);


  // console.log(' device: %o', device);
  console.log(' configs: %o', configs);
  // console.log(' deviceActions: %o', deviceActions);

  useEffect(() => {
    if (devices) {
      dispatch(getActions(configs.devices.join(',')));
      let cycle = setInterval(
        () => {
          dispatch(getActions(configs.devices.join(',')));
        },
        configs?.refreshInterval ? configs?.refreshInterval * 1000 : 120000
      );
      return () => {
        clearInterval(cycle);
      };
    }
    // eslint-disable-next-line
  }, [devices]);

  const startBtnHandler = (device) => {
    setSelectedDevice(device);
    setStopDevice(false);
    setTriggerModal(true)
  }

  const stopBtnHandler = (device) => {
    setSelectedDevice(device);
    setStopDevice(true);
    setTriggerModal(true)
  }

  const refreshHandler = () => {
    dispatch(getActions(configs.devices.join(',')));
  }

  return (
    <Paper className={classes.root}>
      <Stack direction="column" spacing={4}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" style={{ margin: 0 }}>{configs.title}</Typography>
          <IconButton size="small" onClick={refreshHandler}>
            <SyncIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <Divider />
        {widgetDevices.map((device, index) => {
          const deviceActions = actions.find(item => item._id === device?.code)?.actions || [];
          const lastAction = deviceActions?.[0];
          const isActive = lastAction?.executedAt ? moment(lastAction?.executedAt).add(lastAction?.duration, "seconds").isAfter(moment()) : false;
          console.log('[%s] isActive: %s', device?.code, isActive);
          console.log('sdf %o', { actions, deviceActions, device })
          return (
            <Stack direction="column" spacing={2} key={device._id}>
              {index > 0 && <Divider />}
              <Stack direction="row" alignItems="center" justifyContent="space-between" >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Chip size="small" color="secondary" label={device?.code} />
                  <Typography>{device?.designation}</Typography>
                </Stack>
                {isActive
                  ? <Button size="small" variant="contained" disabled={actionLocker} color="warning" onClick={() => stopBtnHandler(device)}>STOP</Button>
                  : <Button size="small" variant="contained" disabled={actionLocker} onClick={() => startBtnHandler(device)}>START</Button>
                }
              </Stack>
              {deviceActions.length > 0 &&
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Typography><b>{isActive ? "Active trigger" : "Last trigger"}</b></Typography>
                  <ActionState actions={deviceActions} />
                </Stack>
              }
            </Stack>
          );
        })}
      </Stack>
      <CustomModal setOpen={setTriggerModal} open={triggerModal} size="medium" title={stopDevice ? "Turn it off" : "Turn it on"}>
        <RelayForm device={selectedDevice?._id} onSubmit={() => setTriggerModal(false)} codes={configs.devices.join(',')} stopDevice={stopDevice} />
      </CustomModal>
    </Paper>
  );
}

export default withStyles(RelayButton, styles);