import { requestHelper } from "../../../app/coreSlice";
import { getDevices } from "../../../api/apiv2/devices/getDevices";

const getDevicesCall = async ({ setDevices }, dispatch, state) => {
  let rh = requestHelper(dispatch, "GET_DEVICES");
  try {
    let project = state.core.selectedProject?._id;
    let result = await getDevices({ limit: 1000, project, sortField: "code", sortType: "ASC" });
    if (result.success) {
      let data = (result.data || []).map((item) => ({ ...item, boxId: item.box?._id || "NONE" }));
      dispatch(setDevices(data));
    }
  } catch (ex) {
    rh.error("devices", "GET_DEVICES_ERROR", ex);
  }
  rh.close();
};

export default getDevicesCall;
