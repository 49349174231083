import { requestHelper } from "../../../app/coreSlice";
import { getUsers } from "../../../api/apiv2/users/getUsers";

const getUsersCall = async (
  { clearUsersList, appendUsersList, setUsersFilter, setUsersCollectionPagination },
  dispatch,
  state,
  { clear, filter }
) => {
  console.log("getUsersCall - input: %o", { clear, filter });
  let rh = requestHelper(dispatch, "GET_USERS");
  try {
    if (clear) {
      dispatch(clearUsersList());
    }
    let nFilter = { ...filter, page: clear ? 1 : filter.page };
    dispatch(setUsersFilter(nFilter));
    let result = await getUsers(nFilter);
    console.log("result: %o", result);
    if (result.success) {
      let usersCollection = [];
      (result.data || []).forEach((user) => {
        usersCollection.push({
          ...user,
          projectsCollection: user.projects || [],
          projects: (user.projects || []).map((proj) => proj._id),
          roleObject: user.role,
          role: user.role?._id,
        });
      });

      dispatch(appendUsersList(usersCollection));
      dispatch(setUsersCollectionPagination(result.pagination));
    }
  } catch (ex) {
    rh.error("users", "GET_USERS_ERROR", ex);
  }
  rh.close();
};

export default getUsersCall;
