import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { activateTrigger } from "../../../api/nodeRedApi/triggers";

const triggersActivateCall = async ({ getTriggers }, dispatch, state, { id, active }) => {
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "TRIGGERS_ACTIVATE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await activateTrigger(api, id, active);
      dispatch(setSuccess("triggers", active ? "TRIGGERS_ACTIVATED" : "TRIGGERS_DEACTIVATED"));
      dispatch(getTriggers());
    }
  } catch (ex) {
    rh.error("triggers", "TRIGGERS_ACTIVATE", ex);
  }
  rh.close();
};

export default triggersActivateCall;
