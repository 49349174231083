import React from "react";
import Chart from "react-apexcharts";

function BatteryGauge({ batteryValue, fontSize = 20, height = 150 }) {
  const options = {
    chart: {
      type: "radialBar",
      offsetY: 0,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "95%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: fontSize + "px",
            formatter: function (val) {
              return val + "%";
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: [
        function (val) {
          if (val < 30) {
            return "#ff9e8c";
          } else if (val >= 30 && val < 55) {
            return "#fbdb7c";
          } else{
            return "#81d777";
          }
        },
      ],
    },
    labels: ["Average Results"],
  };

  return <Chart options={options} series={[parseFloat(batteryValue)]} type="radialBar" height={height} />;
}

export default BatteryGauge;
