export const styles = (theme) => ({
  root: {},
  chip: {
    height: 15,
    marginRight: 5,
    "& span": {
      padding: "0 5px",
      lineHeight: 1,
    },
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
});
