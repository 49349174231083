import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Paper, Typography, Grid, Button, Drawer, Chip, Stack } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import { styles } from "./MainChart.styles";
import MainChartFilter from "./MainChartFilter/MainChartFilter";
import CsvExport from "../../../common/charts/CsvExport/CsvExport";
import { useSelector } from "react-redux";
import {
  getDevices,
  selectDevices,
  selectIsLoading,
  selectMainChartFilter,
  selectMainChartReadings,
  setMainChartFilter,
  setMainChartReadings,
} from "../analyticsSlice";
import moment from "moment";
import DevicesChart from "./DevicesChart/DevicesChart";
import { useDispatch } from "react-redux";
import { selectSelectedProject } from "../../../app/coreSlice";
import { SelectInput } from "../../../common/inputs";

function MainChart({ classes }) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedType, setSelectedType] = useState("AVG");
  const loading = useSelector(selectIsLoading);

  const filter = useSelector(selectMainChartFilter);
  const readings = useSelector(selectMainChartReadings);
  const selectedProject = useSelector(selectSelectedProject);
  const devices = useSelector(selectDevices);

  console.log("filter: %o", filter);
  console.log("devices: %o", devices);
  console.log("readings: %o", readings);

  useEffect(() => {
    if (selectedProject) {
      console.log('!!!! got here')
      dispatch(getDevices());
      dispatch(setMainChartFilter(null));
      dispatch(setMainChartReadings(null));
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    setSelectedDevices(filter?.devices || []);
    // eslint-disable-next-line
  }, [filter]);

  const deviceClickHandler = (device) => {
    let nSelected;
    if (selectedDevices.indexOf(device) > -1) {
      nSelected = selectedDevices.filter((item) => item !== device);
    } else {
      nSelected = [...selectedDevices, device];
    }
    setSelectedDevices(nSelected);
  };

  console.log('filter: %o', filter);

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.toolbar} spacing={1}>
        <Grid item>
          {filter ? (
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Typography>{moment(filter.startDate).format("DD-MM-YYYY hh:mm")}</Typography>
              </Grid>
              <Grid item>
                <Typography>to</Typography>
              </Grid>
              <Grid item>
                <Typography>{moment(filter.endDate).format("DD-MM-YYYY hh:mm")}</Typography>
              </Grid>
              <Grid item>
                {filter.devices.map((item) => (
                  <Chip
                    key={item}
                    style={{ marginRight: 5 }}
                    color={selectedDevices.indexOf(item) > -1 ? "primary" : "default"}
                    size="small"
                    label={item}
                    onClick={() => deviceClickHandler(item)}
                  />
                ))}
              </Grid>
            </Grid>
          ) : (
            <Typography>No active filter, please select one</Typography>
          )}
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={2} alignItems="center">
            {console.log("---- %o - %o", devices, filter?.devices?.[0])}
            {filter?.devices?.length === 1 && (devices || []).find(item => item.code === filter?.devices?.[0])?.type !== "MOTION" && <SelectInput
              value={selectedType}
              onChange={(value) => setSelectedType(value)}
              options={[{ value: "MINMAX", text: "min/max" }, { value: "AVG", text: "average" }]}
            />
            }
            <Button variant="contained" size="small" color="primary" onClick={() => setDrawerFilterOpen(true)}>
              <FilterAltIcon />
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Drawer anchor="right" open={drawerFilterOpen} onClose={() => setDrawerFilterOpen(false)}>
        <div className={classes.drawer}>
          <MainChartFilter />
        </div>
      </Drawer>
      <div className={classes.root}>
        {!loading ? (
          readings &&
          Object.keys(readings).length > 0 && (
            <Paper className={classes.paper}>
              <CsvExport data={exportData(readings, selectedDevices)} fields={exportFields(selectedDevices)} />
              <DevicesChart
                data={{ selectedSensors: selectedDevices, resultSets: readings, selectedType }}
                devicesInfo={(devices || []).reduce((agg, item) => {
                  return { ...agg, [item.code]: item };
                }, {})}
              />
            </Paper>
          )
        ) : (
          <p>loading...</p>
        )}
      </div>
    </div>
  );
}

export default withStyles(MainChart, styles);

const exportFields = (selectedSensors) => {
  return [
    {
      field: "date",
      header: "Time",
    },
    ...(selectedSensors || []).map((item) => {
      return {
        field: item,
        header: item,
      };
    }),
  ];
};

const exportData = (resultSets, selectedSensors) => {
  let data = null;
  (selectedSensors || []).forEach((code) => {
    if (data === null) {
      if (resultSets[code] && resultSets[code]?.length > 0) {
        data = resultSets[code].map((item) => {
          return {
            date: item.date,
            [code]: item.avg,
          };
        });
      }
    } else {
      if (resultSets[code] && resultSets[code]?.length > 0) {
        resultSets[code].forEach((item) => {
          let d = data.find((dataItem) => dataItem.date === item.date);
          if (d) {
            d[code] = item.avg;
            data = [...data.filter((dataItem) => dataItem.date !== item.date), d];
          } else {
            d = {
              date: item.date,
              [code]: item.avg,
            };
            data = [...data, d];
          }
        });
      }
    }
    if (data) {
      data.sort((a, b) => (a.date > b.date ? -1 : 1));
    } else {
      data = [];
    }
  });

  return data;
};
