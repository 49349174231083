import { requestHelper } from "../../../app/coreSlice";
import { getLastReadingsV2 } from "../../../api/apiv2/elastic/getLastReadings";

const getLastReadingsCall = async ({ setWidgetsData }, dispatch, state, { codes, index }) => {
  let devices = state.devices.list;
  let widgetData = JSON.parse(JSON.stringify([...(state.dashboard.widgets[index]?.data || [])]));

  let rh = requestHelper(dispatch, "GET_LAST_READINGS");
  try {
    let result = await getLastReadingsV2(codes);
    dispatch(
      setWidgetsData({
        index,
        data: (result?.data || []).map((read) => {
          let device = devices.find((item) => item.code === read.code);
          return {
            ...device,
            ...read.data,
            readings: widgetData.find((item) => item.code === read.code)?.readings,
          };
        }),
      })
    );
  } catch (ex) {
    console.error(ex);
    rh.error("blocks", "GET_LAST_READINGS_ERROR", ex);
  }
  rh.close();
};

export default getLastReadingsCall;
