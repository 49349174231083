import React, { useState, useEffect } from "react";
import { withStyles } from "tss-react/mui";

import moment from "moment";
import Chart from "./Chart";
import TimeIntervalSelect from "./TimeIntervalSelect";
import DataFilterSelect from "./DataFilterSelect";
import { Grid } from "@mui/material";

import { styles } from "./LastReadingsChart.styles";

const processData = (data, timeInterval, dataFilter) => {
  // console.log("data: %o, timeInterval: %o", data, timeInterval);
  if (!data || data.length < 1) {
    return [];
  }
  let r = [];
  let lastReading = data[0];
  r.push(lastReading);
  let timeDiff = 0;

  let ri = [];

  data.forEach((item) => {
    timeDiff = moment(lastReading.date).diff(moment(item.date), "minutes");
    //console.log('timeDiff: %o', timeDiff);
    if (Math.abs(timeDiff) > timeInterval && lastReading.id !== item.id) {
      lastReading = item;
      switch (dataFilter) {
        case "mean":
          r.push({
            date: lastReading.date,
            value: (
              ri.map((item) => parseFloat(item.value)).reduce((total, current) => total + current, 0) / ri.length
            ).toFixed(2),
          });
          break;
        case "max":
          r.push({
            date: lastReading.date,
            value: ri
              .map((item) => parseFloat(item.value))
              .reduce((total, current) => (total > current ? total : current), -20)
              .toFixed(2),
          });
          break;
        case "min":
          r.push({
            date: lastReading.date,
            value: ri
              .map((item) => parseFloat(item.value))
              .reduce((total, current) => (total < current ? total : current), ri[0])
              .toFixed(2),
          });
          break;
        default:
          r.push(lastReading);
      }

      ri = [];
    } else {
      ri.push(item);
    }
  });
  if (lastReading.id !== data[data.length - 1].id) {
    r.push(data[data.length - 1]);
  }
  return r;
};

function LastReadingsChart({
  classes,
  seriesLabel,
  title,
  yaxis,
  data,
  stroke,
  height = 350,
  defaultDataFilter = "exact",
}) {
  const [filteredData, setFilteredData] = useState();
  const [series, setSeries] = useState([]);
  const [timeInterval, setTimeInterval] = useState(15);
  const [dataFilter, setDataFilter] = useState(defaultDataFilter);

  useEffect(() => {
    if (data) {
      setFilteredData(processData(data, timeInterval, dataFilter));
    }
    // eslint-disable-next-line
  }, [data, timeInterval, dataFilter]);

  useEffect(() => {
    if (filteredData) {
      // console.log("filteredData: %o", filteredData);
      setSeries([
        {
          name: seriesLabel,
          data: filteredData.map((item) => {
            return {
              x: moment(item.date).utc(true).toDate().getTime(),
              y: item.value,
            };
          }),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [filteredData]);

  const options = {
    tooltip: {
      x: {
        show: true,
        format: "HH:mm",
      },
    },
    title: {
      text: title,
      align: "left",
    },
    stroke: {
      width: 2,
      curve: "smooth",
      ...stroke,
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
    },
    yaxis: yaxis,
  };

  return (
    <div style={{ position: "relative" }}>
      <Chart options={options} series={series} type="line" height={height} />
      <div className={classes.filters}>
        <Grid container spacing={1}>
          <Grid item>
            <DataFilterSelect dataFilter={dataFilter} setDataFilter={setDataFilter} />
          </Grid>
          <Grid item>
            <TimeIntervalSelect setTimeInterval={setTimeInterval} timeInterval={timeInterval} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default withStyles(LastReadingsChart, styles);
