import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "tss-react/mui";
import { Grid, IconButton, Modal, Paper, Typography, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "./CustomModal.styles";

function CustomModal({ classes, open, setOpen, children, size, height, title }) {
  const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={classes.root}>
        <Paper className={isMobile ? classes.mobile : (size && classes[size])} sx={{ height }}>
          <Grid container direction="column" wrap="nowrap">
            <Grid item className={classes.header}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                className="CustomModalHeader"
              >
                <Grid item>
                  <Typography className={classes.title} variant="caption">
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton size="small" onClick={() => setOpen(false)}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.content}>
              {children}
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Modal>
  );
}

CustomModal.propTypes = {
  children: PropTypes.element.isRequired,
  size: PropTypes.oneOf(["large", "medium", "small", "extralarge"]),
};

export default withStyles(CustomModal, styles);
