import React from "react";
import { withStyles } from "tss-react/mui";
import { Paper, Typography } from "@mui/material";

import { styles } from "./AlertContainer.styles";
import AlertItem from "./AlertItem/AlertItem";

function AlertContainer({ classes, zone, zoneId, alerts, onActivate, loading, onEditAlert, onDeleteAlert, query }) {
  return (
    <Paper className={classes.container}>
      <Typography className={classes.containerTitle}>{zone}</Typography>
      {alerts &&
        alerts
          .filter(({designation, boxDesignation, sensorCode, sensorDesignation}) => {
            if (query) {
              return `${designation} ${boxDesignation} ${sensorCode} ${sensorDesignation}`.indexOf(query) > -1;
            }
            return true;
          })
          .map((alert, index) => (
            <AlertItem
              key={"alert_" + index}
              alert={{ ...alert, zoneId }}
              onEditAlert={onEditAlert}
              onDeleteAlert={onDeleteAlert}
              onActivate={onActivate}
              loading={loading}
            />
          ))}
    </Paper>
  );
}

export default withStyles(AlertContainer, styles);
