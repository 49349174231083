import * as Yup from "yup";
import { TextField } from "../../../../common/forms";
import PermissionSelect from "./PermissionSelect/PermissionSelect";

export function formSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("roles.RolesForm.form.nameRequired")),
  });
}

export function formFields(t) {
  let fields = [
    {
      size: 12,
      field: <TextField id="name" label={t("roles.RolesForm.form.name")} required disabled />,
    },
    {
      size: 12,
      field: <PermissionSelect id="permissions" label={t("roles.RolesForm.form.permissions")} required />,
    },
  ];

  return fields;
}
