import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./ProjectsDataGrid.styles";
import { listColumns } from "./ProjectsDataGrid.columns";
import ProjectsDataGridActions from "./ProjectsDataGrid.actions";
import CustomDataGrid from "../../../../common/displays/CustomDataGrid/CustomDataGrid";

function ProjectsDataGrid({ classes, data, filter, onFilterChange, onDelete, onEdit, pagination }) {
  const { t } = useTranslation();
  const columns = listColumns(t, classes);

  const onFilterHandler = (nFilter) => {
    onFilterChange &&
      onFilterChange({
        filter: {
          ...filter,
          ...nFilter,
        },
        clear: true,
      });
  };

  const onLoadMoreHandler = () => {
    console.log();
    if (pagination?.next) {
      onFilterChange &&
        onFilterChange({
          filter: {
            ...filter,
            page: filter.page + 1,
          },
          clear: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      {data && (
        <CustomDataGrid
          columns={columns}
          rows={data}
          onFilter={onFilterHandler}
          onLoadMore={onLoadMoreHandler}
          total={data.length}
          actionsComponent={<ProjectsDataGridActions onDelete={onDelete} onEdit={onEdit} />}
        />
      )}
    </div>
  );
}

export default withStyles(ProjectsDataGrid, styles);
