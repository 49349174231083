import { api, getToken } from "../../api";

export const deleteSmartLinkInput = async ({ smartLinkId, id }) => {
  let token = getToken();
  let result = await api.delete(
    `/api/v1/smartlinks/${smartLinkId}/input/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
