import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { removeAlert } from "../../../api/nodeRedApi/alerts";

const alertsDeleteCall = async ({ getAlerts }, dispatch, state, { id }) => {
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "ALERTS_DELETE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await removeAlert(api, id);
      dispatch(setSuccess("alerts", "ALERTS_DELETE"));
      dispatch(getAlerts());
    }
  } catch (ex) {
    rh.error("alerts", "ALERTS_DELETE_ERROR", ex);
  }
  rh.close();
};

export default alertsDeleteCall;
