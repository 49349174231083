import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Paper } from "@mui/material";

import { styles } from "./Actions.styles";
import ActionsItem from "./ActionsItem/ActionsItem";

function Actions({ classes, index, configs, blocks }) {
  // const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      {(configs?.devices || []).map((item) => (
        <ActionsItem
          key={"action_" + item}
          index={index}
          device={blocks && blocks.find((block) => block.code === item)}
        />
      ))}
    </Paper>
  );
}

export default withStyles(Actions, styles);
