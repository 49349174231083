export const styles = (theme) => ({
  root: {
    padding: 20,
  },
  paper: {
    padding: 20,
  },
  drawer: {
    width: "30vw",
    marginTop: theme.myvars.toolbarHeight,
    [theme.breakpoints.down("lg")]: {
      width: "40vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
  stoped: {
    opacity: 0.3
  },
  drawerScroll: {
    padding: 20,
    height: `calc(100vh - ${theme.myvars.toolbarHeight}px)`,
  },
  stopChip: {
    margin: "5px 0"
  }
});
