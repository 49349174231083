export const styles = (theme) => ({
  root: {
    position: "relative"
  },
  badge: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    color: "white",
    display: "flex",
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      display: "block"
    }
  },
  smallBadge: {
    width: 32,
    height: 32,
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
  device: {
    position: "absolute",
    bottom: -5,
    fontSize: 8,
    height: 15,
    backgroundColor: "#ddd",
    width: "100%",
    textAlign: "center",
    "& span": {
      margin: "0 4px",
      textOverflow: "inherit",
      padding: 0
    }
  }
});