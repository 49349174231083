export const styles = (theme) => ({
  root: {
    marginBottom: 2,
    border: "1px solid #ddd",
    padding: 5,
    borderLeft: "10px solid #ddd",
    position: "relative",
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    cursor: "pointer",
  },
  label: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: -11,
    display: "flex",
    alignItems: "center",
    "& p": {
      transform: "rotate(-180deg)",
      fontSize: 8,
      color: "#FFF",
      writingMode: "vertical-rl",
    },
  },
  last: {
    borderColor: theme.palette.primary.main,
  },
  warn: {
    borderColor: "red",
  },
  next: {
    borderColor: theme.palette.secondary.main,
  },
  run: {
    fontSize: 10,
  },
  humidity: {
    fontSize: 10,
  },
});
