import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { createDevice } from "../../../api/apiv2/devices/createDevice";

const createDeviceCall = async (
  { getDevices, setFormSuccess },
  dispatch,
  state,
  { type, code, designation, description, model, configs, boxId }
) => {
  let project = state.core.selectedProject?._id;
  let rh = requestHelper(dispatch, "DEVICE_CREATE");
  try {
    let result = await createDevice({
      type,
      code,
      designation,
      description,
      model,
      configs,
      box: boxId !== "NONE" ? boxId : undefined,
      project,
    });
    if (result.success) {
      dispatch(setSuccess("devices", "DEVICE_CREATE"));
      dispatch(getDevices());
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("devices", "DEVICE_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createDeviceCall;
