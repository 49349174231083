import * as Yup from "yup";
import { TextField } from "../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    email: Yup.string()
      .email(t("authentication.Login.form.invalidEmail"))
      .required(t("authentication.Login.form.requiredEmail")),
    password: Yup.string().required(t("authentication.Login.form.requiredPassword")),
  });
}

export function formFields(t) {
  return [
    {
      size: 12,
      field: <TextField id="email" type="email" label={t("authentication.Login.form.email")} required />,
    },
    {
      size: 12,
      field: <TextField id="password" type="password" label={t("authentication.Login.form.password")} required />,
    },
  ];
}
