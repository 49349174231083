import { api, getToken } from "../api";

export const updateTower = async ({ id, designation, duration, code, tray, pod, project }) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/towers/${id}`,
    { designation, duration, code, tray, pod, project },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
