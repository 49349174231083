import { api, getToken } from "../api";

export const getNanotagHistory = async ({ search, sortField, sortType, page, limit, select }) => {
  console.log("getNanotagHistory: %o", {});
  let token = getToken();

  let params = {};
  if (search) {
    console.log("search: %o", search);
    params = {
      ...params,
      // "code[regex]": search,
      // "code[options]": "i",
      "name[regex]": search,
      "name[options]": "i",
    };
  }

  if (sortField && sortType) {
    let field = (sortType === "ASC" ? "" : "-") + sortField;
    params = {
      ...params,
      sort: field,
    };
  }

  if (page || limit) {
    params = {
      ...params,
      limit,
      page,
    };
  }

  if (select) {
    params = {
      ...params,
      select,
    };
  }

  let result = await api.get("/api/v1/nanotags", { headers: { Authorization: `Bearer ${token}` }, params });

  return result?.data || { success: false };
};
