import React, { useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Grid } from "@mui/material";

import { styles } from "./WeatherIpma.styles";
// import { getMeteoData, selectMeteoData } from "../../../dashboardSlice";
import { selectWidgetData, getWeatherIpmaData } from "../../../dashboardCalls";
import { useDispatch, useSelector } from "react-redux";
import WeatherIpmaForecastItem from "./WeatherIpmaForecastItem/WeatherIpmaForecastItem";

function WeatherIpma({ classes, configs, index }) {
  const dispatch = useDispatch();
  const meteoData = useSelector(selectWidgetData(index));

  // console.log("meteoData: %o", meteoData);

  useEffect(() => {
    if (configs) {
      dispatch(getWeatherIpmaData(configs, index));
    }
    // eslint-disable-next-line
  }, [configs]);
  
  return (
    <Paper className={classes.root}>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Typography variant="h5">{meteoData?.properties?.localEstacao}</Typography>
          <Typography variant="caption">Dados da última leitura (max 3h) da estação metereológica</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" className={classes.lbl}>
            Vento
          </Typography>
          <Typography variant="body1">
            {meteoData?.properties?.intensidadeVentoKM}km/h ({meteoData?.properties?.descDirVento})
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" className={classes.lbl}>
            Humidade
          </Typography>
          <Typography variant="body1">{meteoData?.properties?.humidade}%</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption" className={classes.lbl}>
            Temperatura
          </Typography>
          <Typography variant="body1">{meteoData?.properties?.temperatura}ºC</Typography>
        </Grid>
        <Grid item>
          {(meteoData?.forecast || []).map((item, i) => {
            return <WeatherIpmaForecastItem key={"forecast_" + i} forecast={item} />;
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(WeatherIpma, styles);
