import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

import { styles } from "./BoxForm.styles";
import { formFields, formSchema } from "./BoxForm.schema";

import { addBox, updateBox } from "../structureSlice";
import { Form, FormFields } from "../../../common/forms";

function BoxForm({ classes, selectedProject, payload, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = async (values) => {
    console.log("values: %o", values);
    if (!values.id) {
      dispatch(addBox(selectedProject, values));
    }
    else {
      dispatch(updateBox(selectedProject, values));
    }
    onSubmit && onSubmit();
  };

  return (
    <Grid
      className={classes.root}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <div>
        <Form
          schema={formSchema(t)}
          initialValues={payload}
          onSubmit={onSubmitHandler}
          render={({ submitting }) => {
            return (
              <>
                <FormFields fields={formFields(t)} />
                <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                  Submit
                </Button>
              </>
            );
          }}
        />
      </div>
    </Grid>
  );
}

export default withStyles(BoxForm, styles);
