import { requestHelper } from "../../../app/coreSlice";
import { getFeatures } from "../../../api/apiv2/projects/getFeatures";

const getFeaturesCall = async (
  { setFeatures },
  dispatch,
  state
) => {
  let rh = requestHelper(dispatch, "GET_FEATURES");
  try {
 
    let result = await getFeatures();
    console.log("result: %o", result);
    if (result.success) {
      dispatch(setFeatures(result.data));
    }
  } catch (ex) {
    rh.error("projects", "GET_FEATURES_ERROR", ex);
  }
  rh.close();
};

export default getFeaturesCall;
