export const styles = (theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    width: "30%",
    margin: "0 auto",
    zIndex: 3000,
    right: 10,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
      width: "90%",
    },
  },
  alert: {
    marginBottom: 10,
  },
});
