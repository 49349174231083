import DashboardIcon from "@mui/icons-material/Dashboard";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ListAltIcon from "@mui/icons-material/ListAlt";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ScheduleIcon from "@mui/icons-material/Schedule";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import WorkIcon from "@mui/icons-material/Work";
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

export const getFeatureIcon = (feature) => {
  switch (feature) {
    case "DASHBOARD":
      return <DashboardIcon />;
    case "ANALYTICS":
      return <BarChartIcon />;
    case "STRUCTURE":
      return <AccountTreeIcon />;
    case "BLOCKS":
      return <ListAltIcon />;
    case "ALERTS":
      return <NotificationsActiveIcon />;
    case "SCHEDULES":
      return <ScheduleIcon />;
    case "TRIGGERS":
      return <ElectricBoltIcon />;
    case "LOGS":
      return <AssignmentIcon />;
    case "TOWERS":
      return <CalendarViewDayIcon />;
    case "SMARTLINKS":
      return <DeviceHubIcon />;

    default:
      return <WorkIcon />;
  }
};

export const getFeaturesOptions = (features) => {
  return (features || []).map((feature) => {
    return {
      value: feature,
      text: feature,
      icon: getFeatureIcon(feature),
    };
  });
};
