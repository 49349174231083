import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updateBox } from "../../../api/apiv2/boxes/updateBox";

const updateBoxCall = async ({ getBoxes, setFormSuccess }, dispatch, { id, designation, description, code }) => {
  let rh = requestHelper(dispatch, "BOX_UPDATE");
  try {
    let result = await updateBox({ id, designation, description, code });
    if (result.success) {
      dispatch(setSuccess("boxes", "BOX_UPDATE"));
      dispatch(getBoxes());
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("boxes", "BOX_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateBoxCall;
