import { createSmartLinkInput } from "../../../api/apiv2/smartlinks/inputs/createSmartLinkInput";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const createSmartLinkInputCall = async (
  { refreshSmartLink, setFormSuccess },
  dispatch,
  { smartLinkId, tag, device, configs }
) => {
  let rh = requestHelper(dispatch, "SMARTLINKINPUT_CREATE");
  try {
    let result = await createSmartLinkInput({
      smartLinkId, tag, device, configs
    });
    if (result.success) {
      dispatch(setSuccess("smartlinks", "SMARTLINKINPUT_CREATE"));
      dispatch(refreshSmartLink(result.data));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("smartlinks", "SMARTLINKINPUT_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createSmartLinkInputCall;
