import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { styles } from "./TrayForm.styles";
import { formFields, formSchema } from "./TrayForm.schema";
import { Form, FormFields } from "../../../../common/forms";
import { updateTower } from "../../towersSlice";

function TrayForm({ classes, selectedTray }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = (values) => {
    dispatch(updateTower(values));
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={selectedTray || {}}
        onSubmit={onSubmitHandler}
        render={({ submitting }) => {
          return (
            <>
              <FormFields fields={formFields(t)} />
              <br />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                Submit
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(TrayForm, styles);
