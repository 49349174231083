import { requestHelper } from "../../../app/coreSlice";
import { getRoles } from "../../../api/apiv2/roles/getRoles";

const form_getRolesCall = async ({ setFormRoles }, dispatch) => {
  let rh = requestHelper(dispatch, "GET_ROLES");
  try {
    let result = await getRoles({ limit: 10000, select: "_id,name" });

    if (result.success) {
      dispatch(setFormRoles(result.data));
    }
  } catch (ex) {
    rh.error("users", "GET_ROLES_ERROR", ex);
  }
  rh.close();
};

export default form_getRolesCall;
