export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  uptime: {
    color: "#aaaaaa",
    fontSize: 12,
  },
  icon: {
    height: 50,
  },
});
