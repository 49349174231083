import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Grid, Drawer } from "@mui/material";

import { styles } from "./SchedulesList.styles";
import {
  selectSchedules,
  getSchedules,
  activateSchedule,
  selectLoader,
  removeSchedule,
  getSchedulesInstances,
  selectSchedulesInstances,
  stopScheduleInstance,
  deleteScheduleInstance,
} from "../schedulesSlice";
import { selectStructure } from "../../structure/structureSlice";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import ScheduleForm from "./ScheduleForm/ScheduleForm";
import QuickScheduleForm from "./QuickScheduleForm/QuickScheduleForm";
import ScheduleContainer from "./ScheduleContainer/ScheduleContainer";
import moment from "moment";
import { selectSelectedProject } from "../../../app/coreSlice";
import { getBlocks, selectBlocks } from "../../blocks/blocksCalls";
import { getDevices } from "../../devices/devicesSlice";

function SchedulesList({ classes }) {
  const selectedProject = useSelector(selectSelectedProject);
  const schedules = useSelector(selectSchedules);
  const schedulesInstances = useSelector(selectSchedulesInstances);
  const loading = useSelector(selectLoader);
  const dispatch = useDispatch();
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [selectedInstance, setSelectedInstance] = useState();
  const [drawerScheduleOpen, setDrawerScheduleOpen] = useState(false);
  const [drawerQuickScheduleOpen, setDrawerQuickScheduleOpen] = useState(false);
  const structure = useSelector(selectStructure);
  const blocks = useSelector(selectBlocks);
  const [confirmRemoveScheduleDialogOpen, setConfirmRemoveScheduleDialogOpen] = useState(false);
  const [confirmStopInstanceDialogOpen, setConfirmStopInstanceDialogOpen] = useState(false);
  const [confirmDeleteInstanceDialogOpen, setConfirmDeleteInstanceDialogOpen] = useState(false);

  useEffect(() => {
    let refreshInstancesInterval;
    refreshInstancesInterval = setInterval(() => {
      dispatch(getSchedulesInstances(selectedProject));
    }, 10000);
    return () => {
      clearInterval(refreshInstancesInterval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getSchedules(selectedProject));
      dispatch(getSchedulesInstances(selectedProject));
      dispatch(getDevices());
      dispatch(getBlocks());
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  const scheduleSubmitHandler = () => {
    setDrawerScheduleOpen(false);
  };

  const quickScheduleSubmitHandler = () => {
    setDrawerQuickScheduleOpen(false);
  };

  const onActivateHandler = (id, value) => {
    // console.log("switch: %o", { id, value });
    dispatch(activateSchedule(selectedProject, id, value));
  };

  const onEditScheduleHandler = (schedule) => {
    let time = schedule.startTime.split(":");
    schedule.startTime = moment().set({ hour: time[0], minute: time[1], second: 0, millisecond: 0 });
    setSelectedSchedule(schedule);
    setDrawerScheduleOpen(true);
  };

  const onDeleteScheduleHandler = (schedule) => {
    setSelectedSchedule(schedule);
    setConfirmRemoveScheduleDialogOpen(true);
  };

  const onStopInstanceHandler = (instance) => {
    setSelectedInstance(instance);
    setConfirmStopInstanceDialogOpen(true);
  };

  const onDeleteInstanceHandler = (instance) => {
    setSelectedInstance(instance);
    setConfirmDeleteInstanceDialogOpen(true);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {schedules && (
          <Grid item sm={6} xs={12}>
            <ScheduleContainer
              onEditSchedule={onEditScheduleHandler}
              onDeleteSchedule={onDeleteScheduleHandler}
              onStopInstance={onStopInstanceHandler}
              onDeleteInstance={onDeleteInstanceHandler}
              setSelectedSchedule={setSelectedSchedule}
              setDrawerScheduleOpen={setDrawerScheduleOpen}
              setDrawerQuickScheduleOpen={setDrawerQuickScheduleOpen}
              schedules={schedules}
              schedulesInstances={schedulesInstances}
              onActivate={onActivateHandler}
              loading={loading}
            />
          </Grid>
        )}
      </Grid>
      <Drawer anchor="right" open={drawerScheduleOpen} onClose={() => setDrawerScheduleOpen(false)}>
        <div className={classes.drawer}>
          <ScheduleForm
            payload={selectedSchedule}
            selectedProject={selectedProject}
            onSubmit={scheduleSubmitHandler}
          />
        </div>
      </Drawer>
      <Drawer anchor="right" open={drawerQuickScheduleOpen} onClose={() => setDrawerQuickScheduleOpen(false)}>
        <div className={classes.drawer}>
          <QuickScheduleForm
            payload={selectedSchedule}
            structure={structure}
            blocks={blocks}
            selectedProject={selectedProject}
            onSubmit={quickScheduleSubmitHandler}
          />
        </div>
      </Drawer>
      <ConfirmationDialog
        labels={{
          title: "Delete Schedule",
          description: "Are you sure you want to delete this schedule: " + selectedSchedule?.designation,
          ok: "OK",
          cancel: "CANCEL",
        }}
        open={confirmRemoveScheduleDialogOpen}
        onCancel={() => {
          setConfirmRemoveScheduleDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmRemoveScheduleDialogOpen(false);
          dispatch(removeSchedule(selectedProject, selectedSchedule.id));
          dispatch(getSchedules(selectedProject));
          setSelectedSchedule();
        }}
      />
      <ConfirmationDialog
        labels={{
          title: "Stop schedule instance",
          description: "Are you sure you want to stop this schedule instance, this action is not reversible.",
          ok: "OK",
          cancel: "CANCEL",
        }}
        open={confirmStopInstanceDialogOpen}
        onCancel={() => {
          setConfirmStopInstanceDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmStopInstanceDialogOpen(false);
          dispatch(stopScheduleInstance(selectedProject, selectedInstance.id));
          setTimeout(() => {
            dispatch(getSchedulesInstances(selectedProject));
          }, 500);
          setSelectedInstance();
        }}
      />
      <ConfirmationDialog
        labels={{
          title: "Delete schedule instance",
          description: "Are you sure you want to delete this schedule instance, this action is not reversible.",
          ok: "OK",
          cancel: "CANCEL",
        }}
        open={confirmDeleteInstanceDialogOpen}
        onCancel={() => {
          setConfirmDeleteInstanceDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmDeleteInstanceDialogOpen(false);
          dispatch(deleteScheduleInstance(selectedProject, selectedInstance.id));
          setTimeout(() => {
            dispatch(getSchedulesInstances(selectedProject));
          }, 500);
          setSelectedInstance();
        }}
      />
    </div>
  );
}

export default withStyles(SchedulesList, styles);
