import { api, getToken } from "../api";

export const updateRole = async (id, name, permissions) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/roles/${id}`,
    { name, permissions },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
