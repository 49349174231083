import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Grid, Drawer } from "@mui/material";

import { styles } from "./Alerts.styles";
import AlertContainer from "./AlertContainer/AlertContainer";
import { selectAlerts, selectIsLoading, getAlerts, activateAlert, removeAlert } from "../alertsSlice";
import AlertForm from "./AlertForm/AlertForm";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import { selectSelectedProject } from "../../../app/coreSlice";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import { useTranslation } from "react-i18next";
import { getDevices } from "../../devices/devicesSlice";

function Alerts({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectSelectedProject);
  const alerts = useSelector(selectAlerts);
  const loading = useSelector(selectIsLoading);
  const [selectedAlert, setSelectedAlert] = useState();
  const [drawerAlertOpen, setDrawerAlertOpen] = useState(false);
  const [confirmRemoveAlertDialogOpen, setConfirmRemoveAlertDialogOpen] = useState(false);
  const [query, setQuery] = useState();

  useEffect(() => {
    if (selectedProject) {
      dispatch(getDevices());
      dispatch(getAlerts());
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  const alertSubmitHandler = () => {
    setDrawerAlertOpen(false);
  };

  const onActivateHandler = (id, value) => {
    dispatch(activateAlert(id, value));
  };

  const onEditAlertHandler = (alert) => {
    setSelectedAlert(alert);
    setDrawerAlertOpen(true);
  };

  const onDeleteAlertHandler = (alert) => {
    setSelectedAlert(alert);
    setConfirmRemoveAlertDialogOpen(true);
  };

  const onAddHandler = () => {
    setSelectedAlert();
    setDrawerAlertOpen(true);
  };

  return (
    <div>
      <Toolbar onSearchChanged={(query) => setQuery(query)} addPermission="alerts.write" onAddClick={onAddHandler} />
      <div className={classes.root}>
        <Grid container spacing={1}>
          {alerts && (
            <Grid item sm={6} xs={12}>
              <AlertContainer
                onEditAlert={onEditAlertHandler}
                onDeleteAlert={onDeleteAlertHandler}
                setSelectedAlert={setSelectedAlert}
                setDrawerAlertOpen={setDrawerAlertOpen}
                alerts={alerts}
                onActivate={onActivateHandler}
                loading={loading}
                query={query}
              />
            </Grid>
          )}
        </Grid>
        <Drawer anchor="right" open={drawerAlertOpen} onClose={() => setDrawerAlertOpen(false)}>
          <div className={classes.drawer}>
            <AlertForm payload={selectedAlert} selectedProject={selectedProject} onSubmit={alertSubmitHandler} />
          </div>
        </Drawer>
        <ConfirmationDialog
          labels={{
            title: t("alerts.Alerts.deleteConfirm.title"),
            description: t("alerts.Alerts.deleteConfirm.description", { designation: selectedAlert?.designation }),
            ok: t("alerts.Alerts.deleteConfirm.ok"),
            cancel: t("alerts.Alerts.deleteConfirm.cancel"),
          }}
          open={confirmRemoveAlertDialogOpen}
          onCancel={() => {
            setConfirmRemoveAlertDialogOpen(false);
          }}
          onConfirm={() => {
            setConfirmRemoveAlertDialogOpen(false);
            dispatch(removeAlert(selectedAlert.id));
            setSelectedAlert();
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(Alerts, styles);
