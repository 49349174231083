export const styles = theme => ({
  root: {
    padding: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    "& .MuiIconButton-root": {
      paddingTop: 0,
      paddingBottom: 5,
    },
    height: 28,
  },
  description: {
    marginBottom: 5,
    display: "block",
  }
});