import * as Yup from "yup";
import { SelectField, TextField, NumberField } from "../../../../common/forms";
import { getBoxOptions, getDeviceTypesOptions } from "./DeviceForm.options";

export function formSchema(t) {
  return Yup.object().shape({
    code: Yup.string().required(t("devices.DeviceForm.form.requiredCode")),
    designation: Yup.string().required(t("devices.DeviceForm.form.requiredDesignation")),
    description: Yup.string(),
    type: Yup.string().required(t("devices.DeviceForm.form.requiredType")),
  });
}

export function formFields(t, values, boxes) {
  // console.log("_ values: %o", values);
  return [
    {
      size: 12,
      field: <SelectField id="boxId" label={t("devices.DeviceForm.form.box")} options={getBoxOptions(boxes)} />,
    },
    {
      size: 12,
      field: (
        <SelectField id="type" label={t("devices.DeviceForm.form.type")} required options={getDeviceTypesOptions(t)} />
      ),
    },
    {
      size: 12,
      field: <TextField id="code" type="text" required label={t("devices.DeviceForm.form.code")} />,
    },
    {
      size: 12,
      field: <TextField id="designation" type="text" required label={t("devices.DeviceForm.form.designation")} />,
    },
    {
      size: 12,
      field: <TextField id="description" type="text" label={t("devices.DeviceForm.form.description")} />,
    },
    {
      size: 12,
      field: <TextField id="model" type="text" label={t("devices.DeviceForm.form.model")} />,
    },
    {
      size: 12,
      field: <NumberField id="configs.offset" decimalScale={2} label={t("devices.DeviceForm.form.offset")} />,
      hidden: (values?.type !== "TEMPERATURE" && values?.type !== "HUMIDITY") || (values?.model || "").toUpperCase() === "WH51",
    },
    {
      size: 12,
      field: <NumberField id="configs.calibrationTop" decimalScale={2} label={t("devices.DeviceForm.form.calibrationTop")} />,
      hidden: (values?.model || "").toUpperCase() !== "WH51",
    },
    {
      size: 12,
      field: <NumberField id="configs.calibrationBottom" decimalScale={2} label={t("devices.DeviceForm.form.calibrationBottom")} />,
      hidden: (values?.model || "").toUpperCase() !== "WH51",
    },
    {
      size: 12,
      field: <NumberField id="configs.waterTop" decimalScale={2} label={t("devices.DeviceForm.form.waterTop")} />,
      hidden: Boolean(values?.type !== "WATERLEVEL"),
    },
    {
      size: 12,
      field: <NumberField id="configs.waterBottom" decimalScale={2} label={t("devices.DeviceForm.form.waterBottom")} />,
      hidden: Boolean(values?.type !== "WATERLEVEL"),
    },
    {
      size: 12,
      field: <NumberField id="configs.voltageFull" decimalScale={2} label={t("devices.DeviceForm.form.voltageFull")} />,
      hidden: Boolean(values?.type !== "BATTERY"),
    },
    {
      size: 12,
      field: (
        <NumberField id="configs.voltageEmpty" decimalScale={2} label={t("devices.DeviceForm.form.voltageEmpty")} />
      ),
      hidden: Boolean(values?.type !== "BATTERY"),
    },
    {
      size: 12,
      field: <NumberField id="configs.idEstacao" decimalScale={2} label={t("devices.DeviceForm.form.idEstacao")} />,
      hidden: Boolean((values?.model || "").toUpperCase().indexOf("IPMA") !== 0),
    },
  ];
}
