import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Paper, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { styles } from "./Pod.styles";
import ActionsPopover from "../../../../common/components/displays/ActionsPopover/ActionsPopover";
import ActionsPopoverItem from "../../../../common/components/displays/ActionsPopover/ActionsPopoverItem/ActionsPopoverItem";
import Permission from "../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";

function Pod({ classes, children, box, onZoneEdit, onBoxEdit, onAddDevice }) {
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid container direction="row" justifyContent="space-between" wrap="nowrap" alignItems="flex-start">
            <Grid item>
              <Tooltip title={box.description}>
                <Typography variant="h6">{box.designation}</Typography>
              </Tooltip>
              <Tooltip title={box?.zone?.description}>
                <Typography className={classes.zone}>{box?.zone?.designation}</Typography>
              </Tooltip>
            </Grid>
            <Permission code="structure.write">
              <Grid item>
                <ActionsPopover
                  actions={[
                    <ActionsPopoverItem
                      icon={<AddIcon />}
                      label={t("devices.Pod.actions.addDevice")}
                      onClick={() => onAddDevice(box)}
                    />,
                    <ActionsPopoverItem
                      icon={<EditIcon />}
                      label={t("devices.Pod.actions.editPod")}
                      onClick={() => onBoxEdit(box)}
                    />,
                    <ActionsPopoverItem
                      icon={<EditIcon />}
                      label={t("devices.Pod.actions.editZone")}
                      onClick={() => onZoneEdit(box?.zone)}
                    />,
                  ]}
                />
              </Grid>
            </Permission>
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(Pod, styles);
