import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { createPodSetting } from "../../../api/apiv2/podsettings/createPodSetting";

const createPodSettingsCall = async (
  { getPodSettings, setFormSuccess },
  dispatch,
  state,
  { podCode, address, value }
) => {
  let rh = requestHelper(dispatch, "POD_SETTINGS_CREATE");
  try {
    const filter = state.podsettings.filter;
    let project = state.core.selectedProject?._id;
    let result = await createPodSetting(podCode, address, value, project);
    if (result.success) {
      dispatch(setSuccess("podsettings", "POD_SETTINGS_CREATE"));
      dispatch(getPodSettings({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("podsettings", "POD_SETTINGS_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createPodSettingsCall;
