import { api, getToken } from "../api";

export const createPodSetting = async (podCode, address, value, project) => {
  console.log("createPodSetting %o", { podCode, address, value, project });
  let token = getToken();
  let result = await api.post(
    "/api/v1/podsettings",
    { podCode, address, value, project },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
