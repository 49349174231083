import { api, getToken } from "../api";

export const createUser = async (name, email, role, password, projects) => {
  let token = getToken();
  let result = await api.post(
    "/api/v1/users",
    { name, email, role, password, projects },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
