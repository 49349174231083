export const styles = (theme) => ({
  root: {},
  subtitle: {
    textAlign: "center",
  },
  input: {
    "& .MuiForm-separator": {
      width: 100,
    },
  },
  paper: {
    padding: "0 10px",
  },
});
