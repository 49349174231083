import moment from "moment";
import { guid } from "../../common/utils";

export const getSchedules = async (restAPI) => {
  // let zr = await restAPI.get("/zones/get");
  let r = await restAPI.get("/schedules/get");
  let schedulesList = r?.data || [];
  console.log("schedulesList: %o", schedulesList);
  return schedulesList
    .map((item) => {
      return {
        ...item,
        active: item.active.toString() === "1" ? true : false,
      };
    });
};

export const addSchedule = async (restAPI, sensorId, designation, startTime, duration, weekdays, active) => {
  return restAPI.post("schedules/add", {
    id: guid(),
    sensorId,
    designation,
    startTime,
    duration,
    weekdays,
    active,
  });
};

export const activateSchedule = async (restAPI, id, active) => {
  return restAPI.post("schedules/activate", {
    id,
    active,
  });
};

export const updateSchedule = async (restAPI, id, sensorId, designation, startTime, duration, weekdays, active) => {
  return restAPI.post("schedules/update", {
    id,
    sensorId,
    designation,
    startTime,
    duration,
    weekdays,
    active,
  });
};

export const removeSchedule = async (restAPI, id) => {
  return restAPI.post("schedules/delete", {
    id,
  });
};

export const getSchedulesInstances = async (restAPI) => {
  let r = await restAPI.get("/schedules/instances/get");
  let schedulesList = r?.data || [];
  return schedulesList;
};

export const addScheduleInstance = async (restAPI, sensorId, startDate, duration) => {
  return restAPI.post("schedules/instances/add", {
    id: guid(),
    sensorId,
    startDate: moment(startDate).toISOString(),
    endDate: moment(startDate).add(duration, "minutes").toISOString(),
  });
};

export const stopScheduleInstance = async (restAPI, id) => {
  return restAPI.post("schedules/instances/stop", {
    id,
  });
};

export const deleteScheduleInstance = async (restAPI, id) => {
  return restAPI.post("schedules/instances/delete", {
    id,
  });
};
