import * as Yup from "yup";
import { TextField, MultiSelectField, SelectField } from "../../../../common/forms";
import { getProjectsOptions, getRolesOptions } from "./UsersForm.options";

export function formSchema(t) {
  return Yup.object().shape({
    name: Yup.string().required(t("users.UsersForm.form.nameRequired")),
    email: Yup.string().required(t("users.UsersForm.form.emailRequired")),
  });
}

export function formFields(t, roles, projects, isEdit) {
  let fields = [
    {
      size: 12,
      field: <TextField id="name" label={t("users.UsersForm.form.name")} required />,
    },
    {
      size: 12,
      field: <TextField id="email" label={t("users.UsersForm.form.email")} required />,
    },
    {
      size: 12,
      field: <TextField id="password" label={t("users.UsersForm.form.password")} required />,
      hidden: isEdit,
    },
    {
      size: 12,
      field: <SelectField id="role" label={t("users.UsersForm.form.role")} required options={getRolesOptions(roles)} />,
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="projects"
          label={t("users.UsersForm.form.projects")}
          required
          options={getProjectsOptions(projects)}
        />
      ),
    },
  ];

  return fields;
}
