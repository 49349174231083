import * as Yup from "yup";

import { TextField, SelectField, DeviceField } from "../../../../common/forms";
import { getTypeOptions, getSeverityOptions } from "./AlertForm.options";
import SeverityInfo from "./SeverityInfo/SeverityInfo";

export function formSchema(t) {
  return Yup.object().shape({
    sensorId: Yup.string().required(t("alerts.AlertForm.form.requiredDevice")),
    designation: Yup.string().required(t("alerts.AlertForm.form.requiredDesignation")),
    value: Yup.string().when("type", {
      is: "NULL",
      then: (schema) => schema,
      otherwise: (schema) => schema.required(t("alerts.AlertForm.form.requiredValue")),
    }),
  });
}

export function formFields(t, values) {
  return [
    {
      size: 12,
      field: (
        <DeviceField
          id="sensorId"
          label={t("alerts.AlertForm.form.device")}
          types={["TEMPERATURE", "HUMIDITY", "BATTERY", "WATERLEVEL"]}
          required
        />
      ),
    },
    {
      size: 12,
      field: <TextField id="designation" type="text" label={t("alerts.AlertForm.form.designation")} required />,
    },
    {
      size: 12,
      field: (
        <>
          <SelectField
            id="severity"
            label={t("alerts.AlertForm.form.severity")}
            required
            options={getSeverityOptions(t)}
          />
          <SeverityInfo severity={values.severity} />
        </>
      ),
    },
    {
      size: 12,
      field: <SelectField id="type" label={t("alerts.AlertForm.form.type")} required options={getTypeOptions(t)} />,
    },
    {
      size: 12,
      field: <TextField id="value" type="text" label={t("alerts.AlertForm.form.value")} required />,
      hidden: values.type === "NULL",
    },
  ];
}
