export const styles = (theme) => ({
  root: {},
  modal: {
    position: "absolute",
    height: 440,
    width: "50vw",
    maxWidth: 500,
    top: "15vh",
    left: "25vw",
    backgroundColor: "white",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      height: "80vh",
      width: "96vw",
      top: "15vh",
      left: "2vw",
      padding: theme.spacing(1),
    },
  },
});
