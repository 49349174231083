import React from "react";
import { useSelector } from "react-redux";
import { selectLoggedUser } from "../../../../../../features/authentication/authenticationSlice";

function Permission({ code, children }) {
  let loggedUser = useSelector(selectLoggedUser);

  let permission = extractPermissionValue(loggedUser?.role?.permissions || {}, code);

  if (permission || code === "override") {
    return <>{children}</>;
  }
  return null;
}

export default Permission;

function extractPermissionValue(userPermissions, code) {
  try {
    return code.split(".").reduce((o, i) => {
      return o[i] || false;
    }, userPermissions);
  } catch (ex) {
    return false;
  }
}
