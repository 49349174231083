import { requestHelper } from "../../../app/coreSlice";
import { dashboardSlice } from "../dashboardSlice";
import { getActions as getActionsAPI, addAction as addActionAPI } from "../../../api/nodeRedApi/actions";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";

const { setWidgetsData } = dashboardSlice.actions;

export const getActions = (index, deviceCode) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject;
  let api = getAPI(selectedProject?.code);
  let rh = requestHelper(dispatch, "GET_ACTIONS");
  try {
    let result = await getActionsAPI(api, deviceCode);
    dispatch(setWidgetsData({ index, data: { [deviceCode]: result } }));
  } catch (ex) {
    console.error(ex);
    rh.error("blocks", "GET_ACTIONS_ERROR", ex);
  }
  rh.close();
};


export const addAction = (index, deviceCode, action) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject;
  let api = getAPI(selectedProject?.code);
  let rh = requestHelper(dispatch, "ADD_ACTION");
  try {
    await addActionAPI(api, deviceCode, action);
    setTimeout(() => {
      dispatch(getActions(index, deviceCode));
    }, 5000);
    setTimeout(() => {
      dispatch(getActions(index, deviceCode));
    }, 11000);
    setTimeout(() => {
      dispatch(getActions(index, deviceCode));
    }, 130000);
  } catch (ex) {
    console.error(ex);
    rh.error("blocks", "ADD_ACTION_ERROR", ex);
  }
  rh.close();
};