import React from "react";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Button, Grid } from "@mui/material";
import ScheduleItem from "./ScheduleItem/ScheduleItem";
import ScheduleInstanceItem from "./ScheduleInstanceItem/ScheduleInstanceItem";
import moment from "moment";

import { styles } from "./ScheduleContainer.styles";
import Permission from "../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";

function ScheduleContainer({
  classes,
  zone,
  zoneId,
  setSelectedSchedule,
  setDrawerScheduleOpen,
  setDrawerQuickScheduleOpen,
  schedules,
  onActivate,
  loading,
  onEditSchedule,
  onDeleteSchedule,
  onStopInstance,
  onDeleteInstance,
  schedulesInstances,
}) {
  console.log("schedules: %o", schedules);
  return (
    <Paper className={classes.paper}>
      <Typography className={classes.containerTitle}>{zone}</Typography>

      <Grid container direction="column" spacing={1}>
        {schedules &&
          schedules.map((schedule, index) => {
            let instance = (schedulesInstances || []).find((item) => item.scheduleId === schedule.id);

            return (
              <Grid item key={"schedule_" + index}>
                <ScheduleItem
                  schedule={{ ...schedule, zoneId }}
                  onEditSchedule={onEditSchedule}
                  onDeleteSchedule={onDeleteSchedule}
                  onStopInstance={onStopInstance}
                  onActivate={onActivate}
                  instance={instance}
                  loading={loading}
                />
              </Grid>
            );
          })}
        {schedulesInstances &&
          schedulesInstances
            .filter((instance) => instance.scheduleId === "" && instance.zoneId === zoneId)
            .map((instance, index) => {
              return (
                <Grid item key={"schedule_instance_" + index}>
                  <ScheduleInstanceItem
                    key={"schedule_instance_" + index}
                    instance={instance}
                    onStopInstance={onStopInstance}
                    onDeleteInstance={onDeleteInstance}
                  />
                </Grid>
              );
            })}
      </Grid>
      <br />
      <Permission code="schedules.write">
        <Grid container spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                setSelectedSchedule({ zoneId });
                setDrawerScheduleOpen(true);
              }}
            >
              Add Schedule
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={() => {
                setSelectedSchedule({ zoneId, startDate: moment(new Date()) });
                setDrawerQuickScheduleOpen(true);
              }}
            >
              Add Quick Schedule
            </Button>
          </Grid>
        </Grid>
      </Permission>
    </Paper>
  );
}

export default withStyles(ScheduleContainer, styles);
