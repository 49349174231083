import { api, getToken } from "../api";

export const updateDevice = async ({ id, type, code, designation, description, model, configs, box }) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/devices/${id}`,
    { type, code, designation, description, model, configs, box },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
