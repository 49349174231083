import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

import { styles } from "./QuickScheduleForm.styles.js";
import { formFields, formSchema } from "./QuickScheduleForm.schema";

import { addScheduleInstance } from "../../schedulesSlice";
import { Form, FormFields } from "../../../../common/forms";

function QuickScheduleForm({ classes, onSubmit, selectedProject, payload }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = async (values) => {
    console.log("values: %o", values);
    dispatch(addScheduleInstance(selectedProject, values));
    onSubmit && onSubmit();
  };

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <div>
        <Form
          schema={formSchema(t)}
          initialValues={payload}
          onSubmit={onSubmitHandler}
          render={({ submitting }) => {
            return (
              <>
                <FormFields fields={formFields(t)} />
                <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                  Submit
                </Button>
              </>
            );
          }}
        />
      </div>
    </Grid>
  );
}

export default withStyles(QuickScheduleForm, styles);
