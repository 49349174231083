import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Typography, Stack, Tooltip } from "@mui/material";
import SyncIcon from '@mui/icons-material/Sync';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonIcon from '@mui/icons-material/Person';

import { styles } from "./ActionState.styles";
import CustomTooltip from "../../../../../../common/displays/CustomTooltip/CustomTooltip";
import moment from "moment";

function ActionState({ classes, actions = [] }) {
  // const { t } = useTranslation();
  const lastAction = actions?.[0];

  let tooltip = (
    <Stack spacing={1} className={classes.tooltip} >
      {actions.map((action, index) => {
        let icon, date, duration;

        const mDuration = moment.duration(action?.duration, 'seconds');
        if (mDuration.hours() > 0) {
          duration = `${Math.round(mDuration.asHours())} hrs`;
        } else if (mDuration.minutes() > 0) {
          duration = `${Math.round(mDuration.asMinutes())} min`;
        } else {
          duration = `${mDuration.seconds()} secs`;
        }

        if (action?.executedAt) {
          icon = <CheckCircleOutlineIcon fontSize="small" color="primary" />;
          date = action?.executedAt;
        }
        else if (action?.syncedAt) {
          icon = <SyncIcon fontSize="small" />;
          date = action?.syncedAt;
        }
        else {
          icon = <RadioButtonUncheckedIcon fontSize="small" />;
          date = action?.createdAt;
        }
        return (
          <Grid container spacing={0} key={"action_" + index} className={index === 0 ? classes.lastOne : ""} justifyContent="space-between" alignItems="center">
            <Grid item >
              <Stack>
                <Stack direction="row" spacing={2} alignItems="center" >
                  {icon}
                  <span>{action?.duration > 0 ? "ON " + duration : "OFF"}</span>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <PersonIcon fontSize="small" />
                  <span>{action?.createdBy?.name}</span>
                </Stack>
              </Stack>
            </Grid>
            <Grid item style={{ width: 60 }}>
              <Tooltip placement="top" title={
                <Stack spacing={1}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <RadioButtonUncheckedIcon fontSize="small" />
                    <span>{moment(action?.createdAt).format("DD MMM HH:mm:ss")}</span>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <SyncIcon fontSize="small" />
                    <span>{action?.syncedAt ? moment(action?.syncedAt).format("DD MMM HH:mm:ss") : "-"}</span>
                  </Stack>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <CheckCircleOutlineIcon fontSize="small" />
                    <span>{action?.executedAt ? moment(action?.executedAt).format("DD MMM HH:mm:ss") : "-"}</span>
                  </Stack>
                </Stack>
              }>
                <Stack alignItems="center">
                  <span>{moment(date).format("DD MMM")}</span>
                  <span>{moment(date).format("HH:mm:ss")}</span>
                </Stack>
              </Tooltip>
            </Grid>
          </Grid>
        );
      })}
    </Stack>
  );

  let lastActionIcon, lastActionDate;

  if (lastAction?.executedAt) {
    lastActionIcon = <CheckCircleOutlineIcon fontSize="small" color="primary" />;
    lastActionDate = moment(lastAction?.executedAt).fromNow()
  }
  else if (lastAction?.syncedAt) {
    lastActionIcon = <SyncIcon fontSize="small" />;
    lastActionDate = moment(lastAction?.syncedAt).fromNow()
  }
  else {
    lastActionIcon = <RadioButtonUncheckedIcon fontSize="small" />;
    lastActionDate = moment(lastAction?.createdAt).fromNow()
  }

  return (
    <div className={classes.root}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <CustomTooltip title={tooltip}>
          <Typography>{lastActionDate}</Typography>
        </CustomTooltip>
        {lastActionIcon}
      </Stack>
    </div>
  );
}

export default withStyles(ActionState, styles);