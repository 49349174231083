import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { deleteUser } from "../../../api/apiv2/users/deleteUser";

const deleteUserCall = async ({ getUsers }, dispatch, state, id) => {
  let rh = requestHelper(dispatch, "USER_DELETE");
  try {
    const filter = state.users.filter;
    let result = await deleteUser(id);
    if (result.success) {
      dispatch(setSuccess("users", "USER_DELETE"));
      dispatch(getUsers({ clear: true, filter }));
    }
  } catch (ex) {
    rh.error("users", "USER_DELETE_ERROR", ex);
  }
  rh.close();
};

export default deleteUserCall;
