import { requestHelper } from "../../../app/coreSlice";
import { getNanotagHistory } from "../../../api/apiv2/nanotags/getNanotagHistory";

const getNanoTagHistoryCall = async (
  { setNanoTagHistory },
  dispatch
) => {
  let rh = requestHelper(dispatch, "GET_NANOTAG_HISTORY");
  try {

    let result = await getNanotagHistory({ limit: 1000 });

    console.log("result: %o", result);
    if (result.success) {
      dispatch(setNanoTagHistory(result.data));
    }
  } catch (ex) {
    rh.error("tests", "GET_NANOTAG_HISTORY_ERROR", ex);
  }
  rh.close();
};

export default getNanoTagHistoryCall;