import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Typography, Grid, Chip, Button, CircularProgress } from "@mui/material";

import { styles } from "./ActionsItem.styles";
import { SelectInput } from "../../../../../../common/inputs";
import { useDispatch, useSelector } from "react-redux";
import { addAction, getActions, selectWidgetData } from "../../../../dashboardCalls";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";

function ActionsItem({ classes, index, device }) {
  // const { t } = useTranslation();
  const actions = useSelector(selectWidgetData(index));
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (device) {
      dispatch(getActions(index, device.code));
    }
    // eslint-disable-next-line
  }, [device]);

  // console.log("actions: %o", actions);

  const submitHandler = () => {
    if (selectedOption.length > 0) {
      console.log(device.code + " => " + selectedOption);
      dispatch(addAction(index, device.code, selectedOption));
    }
  };

  const actionLoading = (actions) => {
    return Boolean(actions && actions[device.code] && (actions[device.code] || []).find((item) => !item.executed));
  };

  return device ? (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Grid container direction="row" spacing={1}>
            <Grid item>
              <Chip className={classes.chip} size="small" color="secondary" label={device?.code} />
            </Grid>
            <Grid item>
              <Typography>{device.designation}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <SelectInput
            label="Action"
            allowEmpty
            value={selectedOption}
            options={[
              { value: "WINDOW_OPEN", text: "OPEN" },
              { value: "WINDOW_CLOSE", text: "CLOSE" },
            ]}
            onChange={(v) => setSelectedOption(v)}
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            fullWidth
            color="primary"
            variant="contained"
            onClick={submitHandler}
            disabled={actionLoading(actions)}
          >
            Submit
          </Button>
        </Grid>
        <Grid item>
          {actions &&
            actions[device.code] &&
            (actions[device.code] || []).map((action) => (
              <div key={"action_" + action.id}>
                <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Typography className={classes.date}>
                      {moment(action.created).format("DD-MM-YYYY HH:mm:ss")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.action}>
                      {action.action === "WINDOW_OPEN" ? "OPEN" : "CLOSE"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {action.completed ? (
                      <CheckCircleOutlineIcon className={classes.icon} color="primary" />
                    ) : action.executed ? (
                      <CheckCircleOutlineIcon className={classes.icon} color="secondary" />
                    ) : (
                      <CircularProgress size={12} />
                    )}
                  </Grid>
                </Grid>
              </div>
            ))}
        </Grid>
      </Grid>
    </div>
  ) : null;
}

export default withStyles(ActionsItem, styles);
