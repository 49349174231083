
const styles = theme => ({
  root: {
  },
  logo: {
    height: "100%",
    padding: "5px 0",
    marginRight: 35,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  appBar: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 200,
    left: 0,
    height: theme.myvars.toolbarHeight,
    "& .MuiToolbar-regular": {
      padding: "0 16px",
      height: theme.myvars.toolbarHeight,
      minHeight: theme.myvars.toolbarHeight,
      justifyContent: "space-between",
    },
  },
  spacer: {
    height: theme.myvars.toolbarHeight,
    display: "block",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  accountMenuPopover: {
    zIndex: theme.zIndex.drawer + 300 + " !important",
  },
  title: {
    flexGrow: 1,
    whiteSpace: "nowrap",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      color: "white",
      backgroundColor: theme.palette.secondary.main,
      overflow: "visible",
    },
    "& .MuiListItemIcon-root": {
      color: "white",
    }
  },
  drawerMenu: {
    width: theme.myvars.drawerWidth,
  },
  content: {
    marginLeft: theme.myvars.drawerWidth,
    width: "calc(100% - " + theme.myvars.drawerWidth + "px)",
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: "100vw",
    },
  },
  loader: {
    position: "absolute",
    width: "100%",
    height: 2,
    top: 0,
    right: 0,
    left: 0,
  },
  projectSelectForm: {
    color: "white",
    marginTop: 10,
    "& .MuiInput-underline:before": {
      borderColor: "white",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "white",
    }
  },
  projectSelect: {
    color: "white",
    "& .MuiSvgIcon-root": {
      color: "white"
    },
  },
  projectSelectFormMenu: {
    width: "100%",
    color: theme.palette.secondary.main,
    padding: theme.spacing(2),
  },
});
export default styles;