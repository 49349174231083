import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { styles } from "../base/_base.styles";
import moment from "moment";

function DateTimePickerInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  error,
  required,
  variant,
  format = "DD/MM/YYYY HH:mm",
  minDate,
  maxDate,
}) {
  let tempId = new Date().getTime().toString();
  let minDateMoment = undefined;
  if (!disabled && minDate) {
    minDateMoment = !moment.isMoment(minDate) ? moment(minDate) : minDate;
  }
  let maxDateMoment = undefined;
  if (!disabled && maxDate) {
    maxDateMoment = !moment.isMoment(maxDate) ? moment(maxDate) : maxDate;
  }
  return (
    <div className={classes.root + " " + className}>
      {variant === "outlined" && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <DateTimePicker
        ampm={false}
        value={value ? (moment.isMoment(value) ? value : moment(value)) : null}
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        format={format}
        disabled={disabled}
        onChange={(newValue) => {
          onChange && onChange(newValue);
        }}
        minDate={minDateMoment}
        maxDate={maxDateMoment}
        slotProps={{ textField: { fullWidth: true, id: tempId, error: error } }}
      />
    </div>
  );
}

export default withStyles(DateTimePickerInput, styles);
