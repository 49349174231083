import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updateUser } from "../../../api/apiv2/users/updateUser";

const updateUserCall = async (
  { getUsers, setFormSuccess },
  dispatch,
  state,
  { _id: id, name, email, role, projects }
) => {
  let rh = requestHelper(dispatch, "USER_UPDATE");
  try {
    const filter = state.users.filter;
    let result = await updateUser(id, name, email, role, projects);
    if (result.success) {
      dispatch(setSuccess("users", "USER_UPDATE"));
      dispatch(getUsers({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("users", "USER_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateUserCall;
