export const getDeviceTypesOptions = (t) => {
  return ["TEMPERATURE", "HUMIDITY", "RELAY", "BATTERY", "WATERLEVEL", "CURRENT_SENSOR", "RAIN", "AIRQUALITY", "DOOR", "MOTION"].map((item) => {
    return {
      value: item,
      text: t("common.enum.sensorType." + item),
    };
  });
};

export const getBoxOptions = (boxes) => {
  return [{ _id: "NONE", designation: "NONE" }, ...(boxes || [])].map((box) => {
    return {
      value: box._id,
      text: box.designation,
    };
  });
};
