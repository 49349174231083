import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Drawer } from "@mui/material";

import { styles } from "./Triggers.styles";
import TriggersForm from "./TriggersForm/TriggersForm";
import TriggerItem from "./TriggerItem/TriggerItem";
import SimpleScroll from "../../../common/components/displays/SimpleScroll/SimpleScroll";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import { selectSelectedProject } from "../../../app/coreSlice";
import { getBlocks } from "../../blocks/blocksCalls";
import { getTriggers, selectTriggers, selectFormSuccess, removeTrigger } from "../triggersSlice";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import { getDevices, selectDevices } from "../../devices/devicesSlice";

function Triggers({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectSelectedProject);
  const triggers = useSelector(selectTriggers);
  const formSuccess = useSelector(selectFormSuccess);
  const devices = useSelector(selectDevices);

  const [drawerTriggerForm, setDrawerTriggerFormOpen] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [confirmDeleteTriggerDialogOpen, setConfirmDeleteTriggerDialogOpen] = useState(false);
  const [query, setQuery] = useState();

  useEffect(() => {
    if (selectedProject) {
      dispatch(getDevices());
      dispatch(getBlocks());
      dispatch(getTriggers());
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    if (formSuccess) {
      setDrawerTriggerFormOpen(false);
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const onAddHandler = () => {
    setSelectedTrigger(null);
    setDrawerTriggerFormOpen(true);
  };

  const onEditHandler = (trigger) => {
    setSelectedTrigger(trigger);
    setDrawerTriggerFormOpen(true);
  };

  const onDeleteHandler = (trigger) => {
    setSelectedTrigger(trigger);
    setConfirmDeleteTriggerDialogOpen(true);
  };

  return (
    <div>
      <Toolbar onSearchChanged={(query) => setQuery(query)} addPermission="triggers.write" onAddClick={onAddHandler} />
      <div className={classes.root}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container spacing={1}>
              {(triggers || [])
                .filter(({ designation, input, output }) => {
                  if (query) {
                    return `${designation} ${JSON.stringify(input)} ${JSON.stringify(output)}`.indexOf(query) > -1;
                  }
                  return true;
                })
                .map((trigger) => (
                  <Grid item xs={12} sm={6} key={trigger.id}>
                    <TriggerItem trigger={trigger} onEdit={onEditHandler} onDelete={onDeleteHandler} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>

        <Drawer anchor="right" open={drawerTriggerForm} onClose={() => setDrawerTriggerFormOpen(false)}>
          <div className={classes.drawer}>
            <SimpleScroll className={classes.drawerScroll}>
              <TriggersForm devices={devices} selectedTrigger={selectedTrigger} />
            </SimpleScroll>
          </div>
        </Drawer>

        <ConfirmationDialog
          labels={{
            title: t("triggers.Triggers.deleteConfirm.title"),
            description: t("triggers.Triggers.deleteConfirm.description"),
            ok: t("triggers.Triggers.deleteConfirm.ok"),
            cancel: t("triggers.Triggers.deleteConfirm.cancel"),
          }}
          open={confirmDeleteTriggerDialogOpen}
          onCancel={() => {
            setConfirmDeleteTriggerDialogOpen(false);
          }}
          onConfirm={() => {
            setConfirmDeleteTriggerDialogOpen(false);
            dispatch(removeTrigger(selectedTrigger));
            setSelectedTrigger(null);
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(Triggers, styles);
