import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { addAlert } from "../../../api/nodeRedApi/alerts";

const alertsCreateCall = async({ getAlerts }, dispatch, state, { sensorId, designation, severity, type, value }) => {
  let rh = requestHelper(dispatch, "ALERTS_CREATE");
  let selectedProject = state.core.selectedProject?.code;
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await addAlert(api, sensorId, designation, severity, type, value, false);
      dispatch(setSuccess("alerts", "ALERTS_CREATE"));
      dispatch(getAlerts());
    }
  } catch (ex) {
    rh.error("alerts", "ALERTS_CREATE_ERROR", ex);
  }
  rh.close();
};

export default alertsCreateCall;
