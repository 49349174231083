import React from "react";
import { Chip, Grid } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

export function listColumns(t) {
  return [
    {
      field: "name",
      headerName: t("roles.RolesList.columns.name"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "permissions",
      headerName: t("roles.RolesList.columns.permissions"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={1}>
            {(Object.keys(v.permissions) || []).map((perm) =>
              v.permissions[perm].write || v.permissions[perm].read ? (
                <Grid item key={perm}>
                  <Chip
                    label={
                      <Grid container spacing={1} alignItems="center" wrap="nowrap">
                        <Grid item>{perm}</Grid>
                        {v.permissions[perm].write && v.permissions[perm].read ? (
                          <Grid item>
                            <EditIcon fontSize="small" color="primary" style={{ display: "block" }} />
                          </Grid>
                        ) : v.permissions[perm].read ? (
                          <Grid item>
                            <VisibilityIcon fontSize="small" color="primary" style={{ display: "block" }} />
                          </Grid>
                        ) : null}
                      </Grid>
                    }
                    color="default"
                    variant="outlined"
                  />
                </Grid>
              ) : null
            )}
          </Grid>
        );
        // return JSON.stringify(v.permissions);
      },
    },
  ];
}
