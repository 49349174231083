import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Switch, IconButton, Chip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { withStyles } from "tss-react/mui";
import { styles } from "./AlertItem.styles";
import Permission from "../../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";
import { selectDevices } from "../../../../devices/devicesSlice";

function AlertItem({ classes, alert, onActivate, loading, onEditAlert, onDeleteAlert }) {
  const devices = useSelector(selectDevices) || [];
  
  let deviceInfo = devices.find((item) => item._id === alert?.sensorId);

  const [hover, sethover] = useState(false);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      onMouseOver={() => sethover(true)}
      onMouseOut={() => sethover(false)}
      className={classes[alert.severity] + " " + classes.alertItem + (!alert.active ? " " + classes["disabled"] : "")}
    >
      <Grid item>
        <Grid container direction="column" alignItems="flex-start">
          <Grid item>
            <b>{alert.designation} </b>
          </Grid>
          <Grid item>
            <Chip size="small" color="secondary" label={deviceInfo?.code} />
            {deviceInfo?.box?.designation + " - " + deviceInfo?.designation}
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        <Grid container direction="column" alignItems="flex-end" spacing={1}>
          <Permission code="alerts.write">
            <Grid item>
              <div style={{ height: "20px", display: "block" }}>
                <IconButton
                  aria-label="edit"
                  disableRipple
                  size="small"
                  onClick={() => onEditAlert(alert)}
                  className={classes.icon}
                  style={hover ? { display: "inline" } : { display: "none" }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  disableRipple
                  size="small"
                  onClick={() => onDeleteAlert(alert)}
                  className={classes.icon}
                  style={hover ? { display: "inline" } : { display: "none" }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </div>
            </Grid>
          </Permission>
          <Grid item>
            <Grid container direction="row" alignItems="center" justifyContent="flex-end">
              <Grid item>
                {alert.type}
                {alert.type !== "NULL" ? ": " + alert.value : ""}
              </Grid>
              <Grid item>
                <Permission code="alerts.write">
                  <Switch
                    checked={alert.active}
                    color="primary"
                    onClick={(e) => onActivate(alert.id, e.target.checked)}
                    disabled={Boolean(loading)}
                  />
                </Permission>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(AlertItem, styles);
