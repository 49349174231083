import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updateTower } from "../../../api/apiv2/towers/updateTower";

const updateTowerCall = async (
  { getTowers, setFormSuccess },
  dispatch,
  { id, designation, duration, code, tray, pod, project }
) => {
  let rh = requestHelper(dispatch, "TOWER_UPDATE");
  try {
    let result = await updateTower({ id, designation, duration, code, tray, pod, project });
    if (result.success) {
      dispatch(setSuccess("towers", "TOWER_UPDATE"));
      dispatch(getTowers());
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("towers", "TOWER_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateTowerCall;
