import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { AppBar, Toolbar, Typography, Drawer, IconButton, ClickAwayListener, LinearProgress } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { logout } from "../../../../features/authentication/authenticationSlice";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "./Navigation.styles";
import AccountMenu from "./AccountMenu/AccountMenu";
import Sidebar from "./Sidebar/Sidebar";
import SnackBar from "./SnackBar/SnackBar";
import { selectSelectedProject, selectLoader } from "../../../coreSlice";

function Navigation({ classes, title, children }) {
  const loader = useSelector(selectLoader);
  const selectedProject = useSelector(selectSelectedProject);

  // console.log("selectedProject: %o", selectedProject);
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const mobile = !useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const uiDrawerContent = (
    <div className={classes.drawerMenu}>
      <div className={classes.spacer} />
      <Sidebar onSelect={() => setDrawerOpen(false)} selectedProject={selectedProject} />
    </div>
  );

  return (
    <ClickAwayListener onClickAway={() => setDrawerOpen(false)}>
      <div className={classes.root}>
        <AppBar position="fixed" color="primary" className={classes.appBar}>
          {loader && <LinearProgress className={classes.loader} />}
          <Toolbar>
            {mobile && (
              <IconButton color="inherit" edge="start" onClick={() => setDrawerOpen(!drawerOpen)}>
                <MenuIcon />
              </IconButton>
            )}
            <img src="/logo.svg" className={classes.logo} alt="logo" />
            {!mobile && (
              <Typography variant="h6" className={classes.title}>
                {selectedProject?.name + " - " + title}
              </Typography>
            )}
            <AccountMenu classes={classes} onLogout={() => dispatch(logout())} />
          </Toolbar>
        </AppBar>
        {!mobile && (
          <Drawer variant="persistent" anchor="left" open={true} className={classes.drawer}>
            {uiDrawerContent}
          </Drawer>
        )}
        {mobile && (
          <Drawer anchor="left" open={drawerOpen} className={classes.drawer} onClose={() => setDrawerOpen(false)}>
            {uiDrawerContent}
          </Drawer>
        )}
        <div className={classes.content}>
          <div className={classes.spacer} />
          {children}
          <SnackBar />
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default withStyles(Navigation, styles);
