import React, { useEffect, useMemo, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Stack, useMediaQuery, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { styles } from "./IOPicker.styles";
import IOItem from "./IOItem/IOItem";
import { getIOItems } from "./IOPicker.auxiliars";
import { Form, FormFields } from "../../../../common/forms";
import moment from "moment";
import Permission from "../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";

function IOPicker({ classes, mode = "INPUT", itemToUpdate, onAdd, onUpdate, onDelete }) {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState();
  const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));

  const items = useMemo(() => {
    let allItems = getIOItems(t);
    return allItems.filter(item => item.mode === mode && (!itemToUpdate || item.tag === itemToUpdate.tag));
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (itemToUpdate) {
      setSelectedItem(items.find(item => item.tag === itemToUpdate?.tag));
    }
    // eslint-disable-next-line
  }, [itemToUpdate])

  const onSubmitHandler = async (values) => {
    const { tag } = selectedItem;
    const { deviceId, ...rest } = values;
    let input = { tag, configs: { ...rest } };
    if (deviceId) {
      input.device = deviceId;
    }
    if (tag === "WEEK_SCHEDULE") {
      input.configs.startTime = moment(input.configs.startTime).format("HH:mm")
    }
    console.log('input: %o', input)
    if (itemToUpdate) {
      onUpdate && onUpdate({ ...itemToUpdate, ...input })
    }
    else {
      onAdd && onAdd(input)
    }
  };

  let initialValues = {};
  if (itemToUpdate) {
    initialValues = { ...itemToUpdate.configs };
    if (itemToUpdate?.device) {
      initialValues.deviceId = itemToUpdate.device._id;
    }
    if (selectedItem?.tag === "WEEK_SCHEDULE") {
      const timeParts = initialValues.startTime.split(":");
      const timeDate = new Date();
      timeDate.setHours(parseInt(timeParts[0], 10));
      timeDate.setMinutes(parseInt(timeParts[1], 10));
      initialValues.startTime = timeDate;
    }
  }

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={6} xs={12} hidden={isMobile && selectedItem}>
        <Grid container spacing={2}>
          {items.map(item => {
            return <Grid item xs={6} key={item.tag}>
              <IOItem
                {...item}
                selected={selectedItem?.tag === item.tag}
                onClick={() => !itemToUpdate && setSelectedItem(item)}
              />
            </Grid>
          })}
        </Grid>
      </Grid>
      <Grid item sm={6} xs={12} hidden={isMobile && !selectedItem}>
        {selectedItem && selectedItem?.fields
          ? <Form
            onSubmit={onSubmitHandler}
            schema={selectedItem?.schema}
            initialValues={initialValues}
            render={({ values, form }) => {
              return (
                <Stack spacing={4}>
                  {isMobile && (
                    <div>
                      <IconButton size="small" onClick={() => setSelectedItem(null)} color="secondary">
                        <ArrowBackIcon fontSize="inherit" />
                      </IconButton>
                    </div>
                  )}
                  <div>
                    <FormFields fields={selectedItem?.fields(values, form)} />
                  </div>
                  {
                    selectedItem?.tag === "TELEGRAM" && <div>
                      <Typography style={{ margin: 0 }} variant="body2">To get the chatId use the command /getchatid with the telegram bot: <a href="https://t.me/solarpunk_tests_bot?start=my_action" rel="noreferrer" target="_blank">@solarpunk_tests_bot</a></Typography>
                    </div>
                  }
                  <Permission code="smartlinks.write">
                    <Button variant="contained" fullWidth color="primary" type="submit">{t("smartlinks.IOPicker.form.submitBtn")}</Button>
                  </Permission>
                  {itemToUpdate &&
                    <Permission code="smartlinks.write">
                      <Button variant="outlined" fullWidth color="error" onClick={() => onDelete && onDelete(itemToUpdate)}>{t("smartlinks.IOPicker.form.deleteBtn")}</Button>
                    </Permission>
                  }
                </Stack>
              );
            }}
          />
          : <Typography alignSelf="center">{t("smartlinks.IOPicker.form.noSelection")}</Typography>}
      </Grid>
    </Grid >
  );
}

export default withStyles(IOPicker, styles);