import { requestHelper } from "../../../app/coreSlice";
import { getLastReadingsV2 } from "../../../api/apiv2/elastic/getLastReadings";

const getSwitcherLastReadingCall = async ({ setSwitcherLastReading }, dispatch) => {
  let rh = requestHelper(dispatch, "GET_LAST_READINGS");
  try {
    let result = await getLastReadingsV2(["RAK7201"]);
    // console.log("................result: %o", result);
    dispatch(setSwitcherLastReading(result?.data?.[0]?.data));
  } catch (ex) {
    console.error(ex);
    rh.error("tests", "GET_LAST_READINGS_ERROR", ex);
  }
  rh.close();
};

export default getSwitcherLastReadingCall;
