import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Paper, Typography, Grid, Switch } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./TriggerItem.styles";
import TriggerInputItem from "./TriggerInputItem/TriggerInputItem";
import ActionsPopover from "../../../../common/components/displays/ActionsPopover/ActionsPopover";
import ActionsPopoverItem from "../../../../common/components/displays/ActionsPopover/ActionsPopoverItem/ActionsPopoverItem";

import { activateTrigger } from "../../triggersSlice";
import Info from "../../../../common/components/displays/Info.js/Info";
import { selectLoader } from "../../../../app/coreSlice";
import Permission from "../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";
import TriggerOutputItem from "./TriggerOutputItem/TriggerOutputItem";

function TriggerItem({ classes, trigger, onEdit, onDelete }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoader);

  const onDeleteHandler = () => {
    onDelete && onDelete(trigger);
  };

  const onEditHandler = () => {
    onEdit && onEdit(trigger);
  };

  const onActivateHandler = () => {
    dispatch(
      activateTrigger({
        id: trigger.id,
        active: !trigger.active,
      })
    );
  };

  return (
    <Paper className={classes.root + (trigger?.active ? "" : " " + classes.inactive)} elevation={0}>
      <Grid container direction="column">
        <Grid item className={classes.header}>
          <Grid container direction="row" justifyContent="space-between" wrap="nowrap" alignItems="center">
            <Grid item>
              <Typography>{trigger.designation}</Typography>
            </Grid>
            <Grid item>
              <Permission code="triggers.write">
                <Grid container direction="row" wrap="nowrap" spacing={1} alignItems="center">
                  <Grid item>
                    <Switch
                      checked={trigger?.active}
                      size="small"
                      color="primary"
                      onClick={onActivateHandler}
                      disabled={Boolean(loading)}
                    />
                  </Grid>
                  <Grid item>
                    <ActionsPopover
                      actions={[
                        <ActionsPopoverItem
                          onClick={onDeleteHandler}
                          icon={<DeleteIcon />}
                          label={t("triggers.Triggers.item.deleteBtn")}
                        />,
                        <ActionsPopoverItem
                          onClick={onEditHandler}
                          icon={<EditIcon />}
                          label={t("triggers.Triggers.item.editBtn")}
                        />,
                      ]}
                    />
                  </Grid>
                </Grid>
              </Permission>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={8}>
              <Grid container direction="column" justifyContent="space-between" className={classes.input} spacing={1}>
                <Grid item>
                  <Typography className={classes.subtitle} variant="h6">
                    {t("triggers.TriggersForm.form.inputTitle")}
                  </Typography>
                </Grid>
                <Grid item>
                  {(trigger.input || []).map((input, index) => (
                    <div key={"input_" + index}>
                      <TriggerInputItem input={input} />
                      {index < trigger.input?.length - 1 && (
                        <Typography className={classes.and} variant="body1" color="secondary">
                          {trigger?.type}
                        </Typography>
                      )}
                    </div>
                  ))}
                </Grid>
                <Grid item>
                  <br />
                  <Info designation={t("triggers.Triggers.item.delayMsg", { delay: trigger?.delay })} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.output}>
              <TriggerOutputItem output={trigger.output} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(TriggerItem, styles);
