import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Button, Drawer } from "@mui/material";

import { styles } from "./Structure.styles";
import { usePrevious } from "../../common/hooks/usePrevious";
import ConfirmationDialog from "../../common/components/ConfirmationDialog";

import ZoneItem from "./ZoneItem/ZoneItem";
import ZoneForm from "./ZoneForm/ZoneForm";
import BoxForm from "./BoxForm/BoxForm";
import SensorDetails from "./SensorDetails/SensorDetails";
import SensorForm from "./SensorForm/SensorForm";

import {
  //selectLoader,
  selectError,
  selectStructure,
  getStructure,
  removeZone,
  removeBox,
  removeSensor,
} from "./structureSlice";
import { selectSelectedProject } from "../../app/coreSlice";
import Permission from "../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";

function Structure({ classes }) {
  //const loader = useSelector(selectLoader);
  const error = useSelector(selectError);
  const structure = useSelector(selectStructure);
  const selectedProject = useSelector(selectSelectedProject);
  const dispatch = useDispatch();

  const [drawerZoneOpen, setDrawerZoneOpen] = useState(false);
  const [zone, setZone] = useState();
  const [confirmRemoveZoneDialogOpen, setConfirmRemoveZoneDialogOpen] = useState(false);

  const [drawerBoxOpen, setDrawerBoxOpen] = useState(false);
  const [box, setBox] = useState();
  const [confirmRemoveBoxDialogOpen, setConfirmRemoveBoxDialogOpen] = useState(false);

  const [drawerSensorDetailsOpen, setDrawerSensorDetailsOpen] = useState(false);
  const [drawerSensorFormOpen, setDrawerSensorFormOpen] = useState(false);
  const [sensor, setSensor] = useState();
  const [confirmRemoveSensorDialogOpen, setConfirmRemoveSensorDialogOpen] = useState(false);

  const prevSelectedProject = usePrevious(selectedProject);

  useEffect(() => {
    if (!structure || structure.length === 0 || prevSelectedProject?.code !== selectedProject?.code) {
      dispatch(getStructure());
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  const zoneSubmitHandler = () => {
    setDrawerZoneOpen(false);
    setZone();
  };

  const boxSubmitHandler = () => {
    setDrawerBoxOpen(false);
    setBox();
  };

  const onSensorSelectedHandler = (sensor) => {
    console.log("click");
    setSensor(sensor);
    setDrawerSensorDetailsOpen(true);
  };

  const sensorSubmitHandler = () => {
    setDrawerSensorFormOpen(false);
    setSensor();
  };

  return (
    <div className={classes.root}>
      {structure.map((zone) => (
        <ZoneItem
          selectedProject={selectedProject}
          zone={zone}
          key={zone.id}
          onEditZone={() => {
            setZone(zone);
            setDrawerZoneOpen(true);
          }}
          onDeleteZone={(zone) => {
            setZone(zone);
            setConfirmRemoveZoneDialogOpen(true);
          }}
          onAddBox={(zoneId) => {
            setBox({ zoneId });
            setDrawerBoxOpen(true);
          }}
          onEditBox={(box) => {
            setBox(box);
            setDrawerBoxOpen(true);
          }}
          onDeleteBox={(box) => {
            setBox(box);
            setConfirmRemoveBoxDialogOpen(true);
          }}
          onSensorSelected={onSensorSelectedHandler}
          onAddSensor={(boxId) => {
            console.log("ADD SENSOR box: %o", boxId);
            setSensor({ boxId });
            setDrawerSensorFormOpen(true);
          }}
        />
      ))}
      {/* ZONE */}
      <Permission code="structure.write">
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => {
            setZone({});
            setDrawerZoneOpen(true);
          }}
        >
          Add New Zone
        </Button>
      </Permission>
      {error && <p>ERROR</p>}
      <Drawer anchor="right" open={drawerZoneOpen} onClose={() => setDrawerZoneOpen(false)}>
        <div className={classes.drawer}>
          <ZoneForm payload={zone} selectedProject={selectedProject} onSubmit={zoneSubmitHandler} />
        </div>
      </Drawer>
      <ConfirmationDialog
        labels={{
          title: "Delete Zone",
          description: "Are you sure you want to delete this zone: " + zone?.designation,
          ok: "OK",
          cancel: "CANCEL",
        }}
        open={confirmRemoveZoneDialogOpen}
        onCancel={() => {
          setConfirmRemoveZoneDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmRemoveZoneDialogOpen(false);
          dispatch(removeZone(selectedProject, zone.id));
          setZone();
        }}
      />

      {/* BOX */}
      <Drawer anchor="right" open={drawerBoxOpen} onClose={() => setDrawerBoxOpen(false)}>
        <div className={classes.drawer}>
          <BoxForm payload={box} selectedProject={selectedProject} onSubmit={boxSubmitHandler} />
        </div>
      </Drawer>
      <ConfirmationDialog
        labels={{
          title: "Delete Box",
          description: "Are you sure you want to delete this box: " + box?.designation,
          ok: "OK",
          cancel: "CANCEL",
        }}
        open={confirmRemoveBoxDialogOpen}
        onCancel={() => {
          setConfirmRemoveBoxDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmRemoveBoxDialogOpen(false);
          dispatch(removeBox(selectedProject, box.id));
          setZone();
        }}
      />

      {/* SENSORS */}

      <Drawer anchor="right" open={drawerSensorDetailsOpen} onClose={() => setDrawerSensorDetailsOpen(false)}>
        <div className={classes.drawer}>
          <SensorDetails
            sensor={sensor}
            selectedProject={selectedProject}
            onDeleteSensor={(sensor) => {
              setSensor(sensor);
              setConfirmRemoveSensorDialogOpen(true);
              setDrawerSensorDetailsOpen(false);
            }}
            onEditSensor={(sensor) => {
              setSensor(sensor);
              setDrawerSensorFormOpen(true);
              setDrawerSensorDetailsOpen(false);
            }}
          />
        </div>
      </Drawer>

      <Drawer anchor="right" open={drawerSensorFormOpen} onClose={() => setDrawerSensorFormOpen(false)}>
        <div className={classes.drawer}>
          <SensorForm payload={sensor} selectedProject={selectedProject} onSubmit={sensorSubmitHandler} />
        </div>
      </Drawer>
      <ConfirmationDialog
        labels={{
          title: "Delete Sensor",
          description: "Are you sure you want to delete this sensor: " + sensor?.designation,
          ok: "OK",
          cancel: "CANCEL",
        }}
        open={confirmRemoveSensorDialogOpen}
        onCancel={() => {
          setConfirmRemoveSensorDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmRemoveSensorDialogOpen(false);
          dispatch(removeSensor(selectedProject, sensor.id));
          setSensor();
        }}
      />
    </div>
  );
}

export default withStyles(Structure, styles);
