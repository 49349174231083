import * as Yup from "yup";

import { MultiSelectField, DateTimePickerField } from "../../../../common/forms";
import { getDevicesOptions } from "./MainChartFilter.options";

export function formSchema(t) {
  return Yup.object().shape({
    devices: Yup.array().ensure().max(5, "You can select only 5 devices at once"),
  });
}

export function formFields(t, values, devices) {
  // console.log("devices: %o", devices);
  return [
    {
      size: 12,
      field: (
        <MultiSelectField
          id="devices"
          label={t("analytics.MainChart.form.devices")}
          options={getDevicesOptions(devices)}
          required
        />
      ),
    },
    {
      size: 12,
      field: <DateTimePickerField id="startDate" label={t("analytics.MainChart.form.startDate")} required />,
    },
    {
      size: 12,
      field: (
        <DateTimePickerField
          id="endDate"
          label={t("analytics.MainChart.form.endDate")}
          minDate={values.startDate}
          required
        />
      ),
    },
  ];
}
