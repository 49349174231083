export const getProjectsOptions = (projects) => {
  return (projects || []).map(({ _id, name }) => {
    return {
      value: _id,
      text: name,
    };
  });
};

export const getRolesOptions = (roles) => {
  return (roles || []).map(({ _id, name }) => {
    return {
      value: _id,
      text: name,
    };
  });
};
