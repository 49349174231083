import React from "react";
import { FormControl, Typography } from "@mui/material";
import { Field } from "react-final-form";

import { withStyles } from "tss-react/mui";
import { styles } from "./WeekdaysSelector.styles";

import Weekdays from "../displays/Weekdays";
import MetaError from "../../forms/MetaError";

function WeekdaysSelector({ classes, id, label }) {
  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <div>
            <FormControl className={classes.formControl} fullWidth>
              <Typography variant="caption" style={{ marginBottom: 4 }}>{label}</Typography>
              <Weekdays edit weekdays={input.value} onChange={(val) => input.onChange && input.onChange(val)} />
            </FormControl>
            <MetaError className={classes.error} meta={meta} />
          </div>
        )}
      </Field>
    </div>
  );
}

export default withStyles(WeekdaysSelector, styles);
