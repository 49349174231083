export const styles = (theme) => ({
  root: {},
  instanceItem: {
    padding: 5,
    borderRadius: 5,
    marginBottom: 5,
    backgroundColor: "#ddd",
  },
  inuse: {
    border: "2px solid #55b849"
  },
});
