export const zonesSample = [
  {
    id: "1dbb832c-e803-475d-bafc-72942956bf91",
    designation: "Estufa Grande",
    description: "Estufa maior onde está o quadro eléctrico com o Stem",
  },
  {
    id: "2dbb832c-e803-475d-bafc-72942956bf91",
    designation: "Estufa Pequena",
    description: "Estufa mais pequena onde esta colocado o POD Alpha",
  },
];