import { api, getToken } from "../../api";

export const updateSmartLinkInput = async ({ smartLinkId, id, tag, device, configs }) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/smartlinks/${smartLinkId}/input/${id}`,
    { tag, device, configs },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
