export const styles = (theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      padding: "20px 0",
    },
  },
  paper: {
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      padding: 5,
    },
  },
  toolbar: {
    width: "100%",
    borderBottom: "1px solid #ddd",
    background: "#eee",
    padding: "10px 20px",
  },
  drawer: {
    width: "30vw",
    padding: 20,
    marginTop: theme.myvars.toolbarHeight,
    [theme.breakpoints.down("md")]: {
      width: "40vw",
    },
    [theme.breakpoints.down('sm')]: {
      width: "80vw",
    },
  },
});
