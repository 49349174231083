import { requestHelper } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { getTriggers } from "../../../api/nodeRedApi/triggers";

const triggersListCall = async ({ setTriggers }, dispatch, state) => {
  let selectedProject = state.core.selectedProject?.code;
  // const allDevices = state.structure.allDevices;
  // const blocks = state.blocks.blocks;
  // console.log("allDevices: %o, blocks: %o", allDevices, blocks);
  let rh = requestHelper(dispatch, "TRIGGERS_LIST");
  try {
    let triggers = [];
    let api = getAPI(selectedProject);
    if (api) {
      triggers = await getTriggers(api);
      // triggers = triggers.map((trigger) => {
      //   return {
      //     ...trigger,
      //     input: Array.isArray(trigger.input)
      //       ? trigger.input.map((item) => {
      //           return {
      //             ...item,
      //             deviceInfo: [...allDevices, ...(blocks || [])].find((device) => device.code === item.sensorCode),
      //           };
      //         })
      //       : [],
      //     output: {
      //       ...trigger.output,
      //       deviceInfo: [...allDevices, ...(blocks || [])].find((device) => device.code === trigger.output.sensorCode),
      //     },
      //   };
      // });
      dispatch(setTriggers(triggers));
    }
  } catch (ex) {
    rh.error("triggers", "TRIGGERS_LIST_ERROR", ex);
  }
  rh.close();
};

export default triggersListCall;
