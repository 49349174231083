import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Stack } from "@mui/material";

import { styles } from "./RelayForm.styles";
import { Form, FormFields } from "../../../../../../common/forms";
import { formFields, formSchema } from "./RelayForm.schema";
import { useDispatch } from "react-redux";
import { createAction } from "../../../../dashboardSlice";

function RelayForm({ classes, device, onSubmit, codes, stopDevice }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = (values) => {
    const { duration } = values;
    dispatch(createAction({ device, value: "1", duration, codes }));
    onSubmit && onSubmit();
  }

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema(t)}
        initialValues={{ duration: stopDevice ? 0 : 60 }}
        render={() => {
          return (
            <Stack spacing={4}>
              <div>
                <FormFields fields={formFields(t, stopDevice)} />
              </div>
              <Button variant="contained" fullWidth color="primary" type="submit">Submit</Button>
            </Stack>
          );
        }}
      />
    </div>
  );
}

export default withStyles(RelayForm, styles);