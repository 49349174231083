export const boxsSample = [
  {
    id: "111b832c-e803-475d-bafc-72942956bf91",
    designation: "Stem",
    description: "Box principal",
    zoneId: "1dbb832c-e803-475d-bafc-72942956bf91",
  },
  {
    id: "221b832c-e803-475d-bafc-72942956bf91",
    designation: "Pod Alpha",
    description: "Caixa wireless",
    zoneId: "2dbb832c-e803-475d-bafc-72942956bf91"
  },
  {
    id: "333b832c-e803-475d-bafc-72942956bf91",
    designation: "Pod Beta",
    description: "Controlo de relés",
    zoneId: "1dbb832c-e803-475d-bafc-72942956bf91"
  },
];