import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Modal } from "@mui/material";

import { styles } from "./PersonalSettings.styles";
import ChangePasswordForm from "./ChangePasswordForm/ChangePasswordForm";
import { useSelector } from "react-redux";
import { selectFormSuccess } from "../authenticationSlice";

function PersonalSettings({ classes, open }) {
  // const { t } = useTranslation();
  const [modalSettingsOpen, setModalSettingsOpen] = useState(false);
  const formSuccess = useSelector(selectFormSuccess);

  useEffect(() => {
    if (formSuccess) {
      setModalSettingsOpen(false);
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  useEffect(() => {
    setModalSettingsOpen(open);
  }, [open]);

  return (
    <div className={classes.root}>
      <Modal open={modalSettingsOpen} onClose={() => setModalSettingsOpen(false)}>
        <div className={classes.modal}>
          <Grid container>
            <Grid item>
              <ChangePasswordForm />
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
}

export default withStyles(PersonalSettings, styles);
