import { requestHelper } from "../../../app/coreSlice";
import { me } from "../../../api/apiv2/auth/me";

const meCall = async ({ setLoggedUser }, dispatch) => {
  let rh = requestHelper(dispatch, "ME");
  try {
    let result = await me();
    if (result.success) {
      dispatch(setLoggedUser(result.data));
    }
  } catch (ex) {
    rh.error("auth", "ME_ERROR", ex);
  }
  rh.close();
};

export default meCall;
