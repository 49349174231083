import { createSmartLink } from "../../../api/apiv2/smartlinks/createSmartLink";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const createSmartLinkCall = async (
  { addSmartLink, setFormSuccess },
  dispatch,
  state,
  { designation, tolerance }
) => {
  let project = state.core.selectedProject?._id;
  let rh = requestHelper(dispatch, "SMARTLINK_CREATE");
  try {
    let result = await createSmartLink({
      designation,
      tolerance,
      project,
    });
    if (result.success) {
      dispatch(setSuccess("smartlinks", "SMARTLINK_CREATE"));
      dispatch(addSmartLink(result.data));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("smartlinks", "SMARTLINK_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createSmartLinkCall;
