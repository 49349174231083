import React, { Suspense } from "react";
//import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Container from "@mui/material/Container";
import CustomThemeProvider from "../theme/CustomThemeProvider";
import "../i18n";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Login from "../../features/authentication/Login/Login";

import PrivateRoute from "./PrivateRoute/PrivateRoute";
import MainLoader from "./MainLoader/MainLoader";

//import { selectUser } from "../features/authentication/authenticationSlice";

import Dashboard from "../../features/dashboard/Dashboard/Dashboard";
import Alerts from "../../features/alerts/Alerts/Alerts";
import Structure from "../../features/structure/Structure";
import SchedulesList from "../../features/schedules/SchedulesList/SchedulesList";
import LogsList from "../../features/logs/LogsList/LogsList";
import MainChart from "../../features/analytics/MainChart/MainChart";
import Triggers from "../../features/triggers/Triggers/Triggers";
import Blocks from "../../features/blocks/Blocks/Blocks";
import Towers from "../../features/towers/Towers/Towers";
import ProjectsList from "../../features/projects/ProjectsList/ProjectsList";
import UsersList from "../../features/users/UsersList/UsersList";
import RolesList from "../../features/roles/RolesList/RolesList";
import WelcomeScreen from "./WelcomeScreen/WelcomeScreen";
import PodSettingsList from "../../features/podsettings/PodSettingsList/PodSettingsList";
import Devices from "../../features/devices/Devices/Devices";
import HeliumTest from "../../features/tests/HeliumTest/HeliumTest";
import NanoTagMap from "../../features/tests/NanoTagMap/NanoTagMap";
import SmartLinksList from "../../features/smartLinks/SmartLinksList/SmartLinksList";

function App() {
  //const user = useSelector(selectUser);
  //const dispatch = useDispatch();

  return (
    <CustomThemeProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Container maxWidth={false} disableGutters={true}>
          <Suspense fallback={<MainLoader />}>
            <Router>
              <div style={{ position: "fixed", color: "#777", fontSize: 11, zIndex: 2000, bottom: 2, left: 5 }}>
                {"v" + process.env.REACT_APP_VERSION}
              </div>
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute title="Home">
                      <WelcomeScreen />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute title="Dashboard">
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/analytics"
                  element={
                    <PrivateRoute title="Analytics">
                      <MainChart />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/structure"
                  element={
                    <PrivateRoute title="Project Structure">
                      <Structure />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/devices"
                  element={
                    <PrivateRoute title="Devices">
                      <Devices />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/blocks"
                  element={
                    <PrivateRoute title="Blocks">
                      <Blocks />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/alerts"
                  element={
                    <PrivateRoute title="Alerts">
                      <Alerts />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/schedules"
                  element={
                    <PrivateRoute title="Schedules">
                      <SchedulesList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/triggers"
                  element={
                    <PrivateRoute title="Triggers">
                      <Triggers />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/logs"
                  element={
                    <PrivateRoute title="Logs">
                      <LogsList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/towers"
                  element={
                    <PrivateRoute title="Towers">
                      <Towers />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/projects"
                  element={
                    <PrivateRoute title="Projects">
                      <ProjectsList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <PrivateRoute title="Users">
                      <UsersList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/roles"
                  element={
                    <PrivateRoute title="Roles">
                      <RolesList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/podsettings"
                  element={
                    <PrivateRoute title="Pod Settings">
                      <PodSettingsList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/helium-test"
                  element={
                    <PrivateRoute title="Helium Test">
                      <HeliumTest />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/nanotag"
                  element={
                    <PrivateRoute title="NanoTag Test">
                      <NanoTagMap />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/smartlinks"
                  element={
                    <PrivateRoute title="Smart Links">
                      <SmartLinksList />
                    </PrivateRoute>
                  }
                />
                <Route path="/login" element={<Login />} />
              </Routes>
            </Router>
          </Suspense>
        </Container>
      </LocalizationProvider>
    </CustomThemeProvider>
  );
}

export default App;
