import { createSlice } from "@reduxjs/toolkit";
import getTowersCall from "./_sliceCalls/getTowersCall";
import updateTowerCall from "./_sliceCalls/updateTowerCall";

export const towersSlice = createSlice({
  name: "towers",
  initialState: {
    towers: null,
    formSuccess: false,
  },
  reducers: {
    setTowers: (state, action) => {
      state.towers = action.payload;
    },
    setFormSuccess: (state, action) => {
      state.formSuccess = action.payload;
    },
  },
});

export default towersSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectTowers = (state) => state.towers.towers;
export const selectFormSuccess = (state) => state.towers.formSuccess;

// CALLS
export const getTowers = () => (dispatch, getState) => {
  getTowersCall(towersSlice.actions, dispatch, getState());
};

export const updateTower =
  ({ _id: id, designation, duration, code, tray, pod, project }) =>
  (dispatch) => {
    updateTowerCall({ ...towersSlice.actions, getTowers }, dispatch, {
      id,
      designation,
      duration,
      code,
      tray,
      pod,
      project: project._id,
    });
  };
