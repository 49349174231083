export const styles = theme => ({
  root: {
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
  },
  description: {
    marginBottom: 5,
    display: "block",
    fontSize: 13,
  },
  label: {
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "#999",
    fontSize: 14,
  },
  value: {
    color: theme.palette.secondary.main,
    fontSize: 14,
  }
});