import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Divider, Grid, Paper, Typography } from "@mui/material";

import { styles } from "./SocialCluster.styles";

import { getLastReadings } from "../../../dashboardSlice";
import { selectWidgetData } from "../../../dashboardCalls";
import { selectDevices } from "../../../../devices/devicesSlice";
import DeviceReadingChip from "../../../../../common/displays/DeviceReadingChip/DeviceReadingChip";

function SocialCluster({ classes, configs, index }) {
  const dispatch = useDispatch();
  const readings = useSelector(selectWidgetData(index));
  const devices = useSelector(selectDevices);

  useEffect(() => {
    if (configs && devices?.length > 0) {
      dispatch(getLastReadings(configs?.devices, index));
      let cycle = setInterval(
        () => {
          dispatch(getLastReadings(configs?.devices, index));
        },
        configs?.refreshInterval ? configs?.refreshInterval * 1000 : 120000
      );
      return () => {
        clearInterval(cycle);
      };
    }
    // eslint-disable-next-line
  }, [devices]);

  return (
    <Paper className={classes.root}>
      <Typography>{configs?.title}</Typography>
      {(configs?.items || []).map((item, i) => {
        let devicesReadings = readings?.filter(ele => (item?.devices || []).includes(ele.code)) || [];
        return (
          <div key={"soc-clust-" + i}>
            <br />
            <Typography>{item.type}</Typography>
            <Divider />
            <Grid container spacing={2} style={{ padding: "8px 0" }}>
              {devicesReadings.map((read, j) => {
                const device = devices.find(item => item.code === read.code);
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6} key={"soc-clust-read-" + i + "-" + j}>
                    <div style={{ width: "100%" }}>
                      <DeviceReadingChip device={device} read={read} fullWidth />
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        )
      })}
    </Paper>
  );
}

export default withStyles(SocialCluster, styles);