import { configureStore } from "@reduxjs/toolkit";
import coreReducer from "./coreSlice";
import authenticationReducer from "../features/authentication/authenticationSlice";
import structureReducer from "../features/structure/structureSlice";
import alertsReducer from "../features/alerts/alertsSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import schedulesReducer from "../features/schedules/schedulesSlice";
import logsReducer from "../features/logs/logsSlice";
import analyticsReducer from "../features/analytics/analyticsSlice";
import triggersReducer from "../features/triggers/triggersSlice";
import blocksReducer from "../features/blocks/blocksSlice";
import towersReducer from "../features/towers/towersSlice";
import projectsReducer from "../features/projects/projectsSlice";
import usersReducer from "../features/users/usersSlice";
import rolesReducer from "../features/roles/rolesSlice";
import podsettingsReducer from "../features/podsettings/podsettingsSlice";
import devicesReducer from "../features/devices/devicesSlice";
import testsReducer from "../features/tests/testsSlice";
import smartLinksReducer from "../features/smartLinks/smartLinksSlice";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    structure: structureReducer,
    alerts: alertsReducer,
    schedules: schedulesReducer,
    core: coreReducer,
    dashboard: dashboardReducer,
    logs: logsReducer,
    analytics: analyticsReducer,
    triggers: triggersReducer,
    blocks: blocksReducer,
    towers: towersReducer,
    projects: projectsReducer,
    users: usersReducer,
    roles: rolesReducer,
    podsettings: podsettingsReducer,
    devices: devicesReducer,
    tests: testsReducer,
    smartlinks: smartLinksReducer
  },
});
