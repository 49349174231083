import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Drawer } from "@mui/material";

import { styles } from "./ProjectsList.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProject,
  getProjects,
  selectFormSuccess,
  selectProjectsFilter,
  selectProjectsList,
  selectProjectsListPagination,
} from "../projectsSlice";
import ProjectsDataGrid from "./ProjectsDataGrid/ProjectsDataGrid";
import ProjectsForm from "./ProjectsForm/ProjectsForm";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import SimpleScroll from "../../../common/components/displays/SimpleScroll/SimpleScroll";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";

function ProjectsList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectsList = useSelector(selectProjectsList);
  const projectsPagination = useSelector(selectProjectsListPagination);
  const filter = useSelector(selectProjectsFilter);
  const formSuccess = useSelector(selectFormSuccess);
  const [drawerProjectForm, setDrawerProjectFormOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [confirmDeleteProjectDialogOpen, setConfirmDeleteProjectDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getProjects({ clear: true, filter }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formSuccess) {
      setDrawerProjectFormOpen(false);
      setSelectedProject();
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const onAddHandler = () => {
    setSelectedProject();
    setDrawerProjectFormOpen(true);
  };

  const onDeleteHandler = (project) => {
    setSelectedProject(project);
    setConfirmDeleteProjectDialogOpen(true);
  };

  const onEditHandler = (project) => {
    setSelectedProject(project);
    setDrawerProjectFormOpen(true);
  };

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search: search ? search.trim() : undefined,
    };
    dispatch(getProjects({ clear: true, filter: nFilter }));
  };

  const onFilterChangeHandler = (options) => {
    dispatch(getProjects(options));
  };

  return (
    <div>
      <Toolbar onSearchChanged={onSearchHandler} addPermission="override" onAddClick={onAddHandler} />
      <div className={classes.root}>
        <ProjectsDataGrid
          data={projectsList}
          onDelete={onDeleteHandler}
          onEdit={onEditHandler}
          onFilterChange={onFilterChangeHandler}
          pagination={projectsPagination}
          filter={filter}
        />

        <Drawer anchor="right" open={drawerProjectForm} onClose={() => setDrawerProjectFormOpen(false)}>
          <div className={classes.drawer}>
            <SimpleScroll className={classes.drawerScroll}>
              <ProjectsForm selectedProject={selectedProject} />
            </SimpleScroll>
          </div>
        </Drawer>

        <ConfirmationDialog
          labels={{
            title: t("projects.ProjectsList.deleteConfirm.title"),
            description: t("projects.ProjectsList.deleteConfirm.description"),
            ok: t("projects.ProjectsList.deleteConfirm.ok"),
            cancel: t("projects.ProjectsList.deleteConfirm.cancel"),
          }}
          open={confirmDeleteProjectDialogOpen}
          onCancel={() => {
            setConfirmDeleteProjectDialogOpen(false);
            setSelectedProject(null);
          }}
          onConfirm={() => {
            setConfirmDeleteProjectDialogOpen(false);
            dispatch(deleteProject(selectedProject._id));
            setSelectedProject(null);
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(ProjectsList, styles);
