import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Drawer } from "@mui/material";

import { styles } from "./Devices.styles";
import { deleteDevice, getBoxes, getDevices, selectBoxes, selectDevices, selectFormSuccess } from "../devicesSlice";
import { selectSelectedProject } from "../../../app/coreSlice";
import Pod from "./Pod/Pod";
import DeviceItem from "./DeviceItem/DeviceItem";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import ZoneForm from "./ZoneForm/ZoneForm";
import BoxForm from "./BoxForm/BoxForm";
import DeviceForm from "./DeviceForm/DeviceForm";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import { selectLoggedUser } from "../../authentication/authenticationSlice";

function Devices({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector(selectDevices);
  const boxes = useSelector(selectBoxes);
  const selectedProject = useSelector(selectSelectedProject);
  const formSuccess = useSelector(selectFormSuccess);
  let loggedUser = useSelector(selectLoggedUser);

  const [query, setQuery] = useState("");
  const [drawerZoneOpen, setDrawerZoneOpen] = useState(false);
  const [zone, setZone] = useState();
  const [drawerBoxOpen, setDrawerBoxOpen] = useState(false);
  const [box, setBox] = useState();
  const [drawerDeviceOpen, setDrawerDeviceOpen] = useState(false);
  const [device, setDevice] = useState();
  const [confirmRemoveDeviceDialogOpen, setConfirmRemoveDeviceDialogOpen] = useState(false);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getDevices());
      dispatch(getBoxes());
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    if (formSuccess) {
      setDrawerZoneOpen(false);
      setDrawerBoxOpen(false);
      setDrawerDeviceOpen(false);
      setDevice();
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const onSearchHandler = (qry) => {
    setQuery(qry);
  };

  const onAddHandler = () => {
    setDevice({ boxId: "NONE" });
    setDrawerDeviceOpen(true);
  };

  const deviceHasQuery = (device) => {
    return (
      device.designation.indexOf(query) > -1 || device.model.indexOf(query) > -1 || device.code.indexOf(query) > -1
    );
  };

  const onZoneEditHandler = (zone) => {
    setZone(zone);
    setDrawerZoneOpen(true);
  };

  const onBoxEditHandler = (box) => {
    setBox(box);
    setDrawerBoxOpen(true);
  };

  const onDeviceEditHandler = (device) => {
    if (loggedUser?.role?.permissions?.structure?.write) {
      setDevice(device);
      setDrawerDeviceOpen(true);
    }
  };

  const onAddDeviceHandler = (box) => {
    setDevice({ boxId: box?._id });
    setDrawerDeviceOpen(true);
  };

  return (
    <div>
      <Toolbar onSearchChanged={onSearchHandler} fastSearch addPermission="structure.write" onAddClick={onAddHandler} />
      <div className={classes.root}>
        <Grid container spacing={2}>
          {(devices || [])
            .filter((ele) => !ele.box && deviceHasQuery(ele))
            .map((device) => (
              <Grid item xs={4} md={2} key={device._id}>
                <DeviceItem device={device} onClick={onDeviceEditHandler} />
              </Grid>
            ))}
          <Grid item xs={12}></Grid>
          {(boxes || []).map((box) => (
            <Grid item xs={12} md={6} key={box._id}>
              <Pod
                box={box}
                onZoneEdit={onZoneEditHandler}
                onBoxEdit={onBoxEditHandler}
                onAddDevice={onAddDeviceHandler}
              >
                <Grid container spacing={1}>
                  {(devices || [])
                    .filter((ele) => ele.box?._id === box._id && deviceHasQuery(ele))
                    .map((device) => (
                      <Grid item xs={4} key={device._id}>
                        <DeviceItem device={device} onClick={onDeviceEditHandler} />
                      </Grid>
                    ))}
                </Grid>
              </Pod>
            </Grid>
          ))}
        </Grid>
      </div>
      <Drawer anchor="right" open={drawerZoneOpen} onClose={() => setDrawerZoneOpen(false)}>
        <div className={classes.drawer}>
          <ZoneForm zone={zone} />
        </div>
      </Drawer>
      <Drawer anchor="right" open={drawerBoxOpen} onClose={() => setDrawerBoxOpen(false)}>
        <div className={classes.drawer}>
          <BoxForm box={box} />
        </div>
      </Drawer>
      <Drawer anchor="right" open={drawerDeviceOpen} onClose={() => setDrawerDeviceOpen(false)}>
        <div className={classes.drawer}>
          <DeviceForm device={device} onDelete={() => setConfirmRemoveDeviceDialogOpen(true)} />
        </div>
      </Drawer>
      <ConfirmationDialog
        labels={{
          title: t("devices.Devices.deleteConfirm.title"),
          description: t("devices.Devices.deleteConfirm.description"),
          ok: t("devices.Devices.deleteConfirm.ok"),
          cancel: t("devices.Devices.deleteConfirm.cancel"),
        }}
        open={confirmRemoveDeviceDialogOpen}
        onCancel={() => {
          setConfirmRemoveDeviceDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmRemoveDeviceDialogOpen(false);
          dispatch(deleteDevice(device?._id));
          setDevice();
        }}
      />
    </div>
  );
}

export default withStyles(Devices, styles);
