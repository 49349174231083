import React from "react";
import { Chip, Grid } from "@mui/material";
import moment from "moment";

export function listColumns(t) {
  return [
    {
      field: "name",
      headerName: t("users.UsersList.columns.name"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "email",
      headerName: t("users.UsersList.columns.email"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "role",
      headerName: t("users.UsersList.columns.role"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.roleObject?.name;
      },
    },
    {
      field: "projects",
      headerName: t("users.UsersList.columns.projects"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return (
          <Grid container spacing={1}>
            {(v.projectsCollection || []).map((proj) => (
              <Grid item key={proj.code}>
                <Chip label={proj.name} />
              </Grid>
            ))}
          </Grid>
        );
      },
    },
    {
      field: "lastLoginAt",
      headerName: t("users.UsersList.columns.lastLoginAt"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.lastLoginAt ? moment(v.lastLoginAt).format("DD/MM/YYYY HH:mm") : "";
      },
    },
  ];
}
