import { api, getToken } from "../api";

export const createAction = async (device, value, duration, project) => {
  console.log("createAction %o", { device, value, duration, project });
  let token = getToken();
  let result = await api.post(
    "/api/v1/actions",
    { device, value, duration, project },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
