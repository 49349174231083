import React, { useState } from "react";
import { Grid, Button, IconButton } from "@mui/material";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";

import { withStyles } from "tss-react/mui";
import { styles } from "./ScheduleInstanceItem.styles";
function ScheduleInstanceItem({ classes, instance, onStopInstance, onDeleteInstance }) {
  const [hover, sethover] = useState(false);

  let timeStatus = "";
  if (instance.running) {
    timeStatus = "turning off " + moment(instance.endDate).fromNow();
  } else {
    timeStatus = moment(instance.startDate).isAfter()
      ? "turning on " + moment(instance.startDate).fromNow()
      : "turning on soon";
  }

  return (
    <div className={classes.instanceItem + " " + (instance.running && classes["inuse"])}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        onMouseOver={() => sethover(true)}
        onMouseOut={() => sethover(false)}
      >
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" style={{ height: 26 }}>
            <Grid item>
              <b>Quick schedule</b>
            </Grid>
            <Grid item>
              {!instance.running && (
                <IconButton
                  aria-label="edit"
                  disableRipple
                  size="small"
                  onClick={() => onDeleteInstance(instance)}
                  className={classes.icon}
                  style={hover ? { display: "inline" } : { display: "none" }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>{instance.boxDesignation + " - " + instance.sensorDesignation}</Grid>
            {instance.running && (
              <Grid item>
                <b>running</b>{" "}
                <Button
                  className={classes.smallerBtn}
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => onStopInstance(instance)}
                >
                  Stop
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <Grid container direction="column" alignItems="center">
            <Grid item></Grid>
            <Grid item>{timeStatus}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(ScheduleInstanceItem, styles);
