import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Typography, Paper } from "@mui/material";
import arrayMutators from "final-form-arrays";

import { styles } from "./TriggersForm.styles";
import { formSchema, formFields, formFields2, formInputFields, formOutputFields } from "./TriggersForm.schema";
import { Form, FormArrayFields, FormFields } from "../../../../common/forms";

import { useDevices } from "../../../../common/hooks";
import { addTrigger, updateTrigger } from "../../triggersSlice";

function TriggersForm({ classes, selectedTrigger }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useDevices();

  const onSubmitHandler = (values) => {
    console.log("values: %o", values);
    if (selectedTrigger) {
      dispatch(updateTrigger(values));
    } else {
      dispatch(addTrigger(values));
    }
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={selectedTrigger || { type: "AND", delay: 1, input: [{ sign: "gt" }], output: { duration: 1 } }}
        mutators={arrayMutators}
        onSubmit={onSubmitHandler}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(t)} />
              <br />
              <Paper elevation={2} className={classes.paper}>
                <Typography className={classes.subtitle} variant="h6">
                  {t("triggers.TriggersForm.form.inputTitle")}
                </Typography>
                <FormArrayFields
                  name="input"
                  className={classes.input}
                  defaultItem={{ sign: "gt" }}
                  formFields={formInputFields(t)}
                />
              </Paper>
              <br />
              <Paper elevation={2} className={classes.paper}>
                <Typography className={classes.subtitle} variant="h6">
                  {t("triggers.TriggersForm.form.outputTitle")}
                </Typography>
                <FormFields fields={formOutputFields(t, values, devices)} />
              </Paper>
              <br />
              <FormFields fields={formFields2(t)} />
              <br />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                Submit
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(TriggersForm, styles);
