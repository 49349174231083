

export const getTypeOptions = (t) => {
  return ["MIN", "MAX", "NULL"].map((item) => {
    return {
      value: item,
      text: t("common.enum.alertType." + item),
    };
  });
};

export const getSeverityOptions = (t) => {
  return ["HIGH", "MEDIUM", "LOW"].map((item) => {
    return {
      value: item,
      text: t("common.enum.sensorSeverity." + item),
    };
  });
};
