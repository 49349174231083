export const styles = theme => ({
  root: {
  },
  chip: {
    minWidth: 24,
    "& .MuiChip-label": {
      paddingLeft: 5,
      paddingRight: 5
    }
  }
});