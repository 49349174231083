import React from "react";
import { withStyles } from "tss-react/mui";
import { MenuItem, Select } from "@mui/material";

import { styles } from "./TimeIntervalSelect.styles";

function TimeIntervalSelect({ classes, style, timeInterval, setTimeInterval }) {
  return (
    <Select style={style} className={classes.root} autoWidth value={timeInterval} onChange={(e) => setTimeInterval(e.target.value)}>
      <MenuItem value={5}>5 min</MenuItem>
      <MenuItem value={15}>15 min</MenuItem>
      <MenuItem value={30}>30 min</MenuItem>
    </Select>
  );
}

export default withStyles(TimeIntervalSelect, styles);
