import { requestHelper } from "../../../app/coreSlice";
import { getPodSettings } from "../../../api/apiv2/podsettings/getPodSettings";

const getPodSettingsCall = async (
  { clearPodSettingsList, appendPodSettingsList, setPodSettingsFilter, setPodSettingsCollectionPagination },
  dispatch,
  state,
  { clear, filter }
) => {
  console.log("getPodSettingsCall - input: %o", { clear, filter });
  let rh = requestHelper(dispatch, "GET_POD_SETTINGS");
  try {
    if (clear) {
      dispatch(clearPodSettingsList());
    }
    let project = state.core.selectedProject?._id;
    let nFilter = { ...filter, page: clear ? 1 : filter.page, project };
    dispatch(setPodSettingsFilter(nFilter));
    let result = await getPodSettings(nFilter);
    console.log("result: %o", result);
    if (result.success) {
      let podSettingsCollection = [];
      (result.data || []).forEach((user) => {
        podSettingsCollection.push({
          ...user,
          projectsCollection: user.projects || [],
          projects: (user.projects || []).map((proj) => proj._id),
          roleObject: user.role,
          role: user.role?._id,
        });
      });

      dispatch(appendPodSettingsList(podSettingsCollection));
      dispatch(setPodSettingsCollectionPagination(result.pagination));
    }
  } catch (ex) {
    rh.error("podSettings", "GET_POD_SETTINGS_ERROR", ex);
  }
  rh.close();
};

export default getPodSettingsCall;
