import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "tss-react/mui";
import { Table, TableContainer, Button, Paper } from "@mui/material";

import { styles } from "./CustomDataGrid.styles";
import CustomDataGridHeader from "./CustomDataGridHeader";
import CustomDataGridBody from "./CustomDataGridBody";
import CustomDataGridFooter from "./CustomDataGridFooter";
import CustomDataGridBodySkeleton from "./CustomDataGridBodySkeleton";

function CustomDataGrid({
  classes,
  localFilters,
  columns,
  rows,
  actionsComponent,
  loading,
  onFilter,
  onLoadMore,
  onRowSelected,
  total,
  footer,
  innerRow,
  rowKeyField,
  hasHeader,
  className,
}) {
  const [filteredRows, setFilteredRows] = useState(rows);
  const [filterActive, setFilterActive] = useState(false);

  const [filter, setFilter] = useState(null);

  const onSortHandler = (field) => {
    const nFilter = {
      ...filter,
      sortField: field,
      sortType:
        filter?.sortField === field
          ? filter?.sortType === "DESC"
            ? "ASC"
            : filter?.sortType === "ASC"
            ? "DESC"
            : null
          : "DESC",
    };

    setFilter(nFilter);
    if (localFilters) {
      let fRows = [...rows];
      fRows.sort((a, b) => {
        if (nFilter.sortType === "DESC" && a[nFilter.sortField] < b[nFilter.sortField]) {
          return -1;
        } else if (nFilter.sortType === "ASC" && a[nFilter.sortField] > b[nFilter.sortField]) {
          return 1;
        }
        return 0;
      });
      setFilteredRows(fRows);
    } else {
      setFilterActive(true);
      onFilter && onFilter(nFilter);
    }
  };

  const loader = useRef(null);
  const loadMoreBtn = useRef();

  useEffect(() => {
    // initialize IntersectionObserver
    // and attaching to Load More div
    const observer = new IntersectionObserver(
      (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
          loadMoreBtn?.current && loadMoreBtn.current.click();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );
    if (loader.current) {
      observer.observe(loader.current);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (localFilters) {
      if (filteredRows) {
        setFilteredRows(rows);
      }
    } else {
      if (rows && rows.length > 0) {
        if (filteredRows) {
          setFilteredRows(rows);
        }
      }
    }
    // eslint-disable-next-line
  }, [rows, total, filterActive]);

  return (
    <div className={classes.root + (className ? " " + className : "")}>
  
      <TableContainer component={Paper}>
        <Table id="gridRoot" className={classes.table} size="small">
          {hasHeader !== false && (
            <CustomDataGridHeader
              columns={columns}
              filter={filter}
              onSort={onSortHandler}
              actionsComponent={actionsComponent}
            />
          )}
          <CustomDataGridBody
            keyField={rowKeyField}
            columns={columns}
            rows={localFilters ? filteredRows : rows}
            actionsComponent={actionsComponent}
            onRowSelected={onRowSelected}
            innerRow={innerRow}
          />
          {loading && <CustomDataGridBodySkeleton columns={columns} rows="20" actionsComponent={actionsComponent} />}
          {footer && <CustomDataGridFooter colspan={columns.length}>{footer}</CustomDataGridFooter>}
        </Table>
      </TableContainer>
      <div className="loading" ref={loader}>
        <Button
          variant="contained"
          onClick={() => {
            if (!filterActive && rows && rows.length > 0) {
              onLoadMore && onLoadMore();
            }
            setFilterActive(false);
          }}
          style={{ display: "none" }}
          ref={loadMoreBtn}
        >
          Load More
        </Button>
      </div>
    </div>
  );
}

export default withStyles(CustomDataGrid, styles);
