import * as Yup from "yup";
import { DeviceField, SelectField, TextField } from "../../../../../common/forms";

export const formSchema = (t) => {
  return Yup.object().shape({
    deviceId: Yup.string().required(),
    option: Yup.string().required(),
    duration: Yup.string().when("option", {
      is: "OFF",
      then: (schema) => schema,
      otherwise: (schema) => schema.required().not(["0"], "duration is a required field"),
    }),
  });
}

export const formFields = ({ t, values, form }) => {
  const relayOptions = [
    { value: "ON", text: "ON" },
    { value: "OFF", text: "OFF" }
  ]
  return [
    {
      size: 12,
      field: <DeviceField
        id="deviceId"
        label="Relay device"
        types={["RELAY"]}
        required
      />,
    },
    {
      size: 6,
      field: <SelectField
        id="option"
        label="Option"
        options={relayOptions}
        onChange={(v) => {
          if (v === "OFF") {
            form.change("duration", 0);
          }
        }}
        required
      />,
    },
    {
      size: 6,
      field: <TextField
        id="duration"
        label="Duration (secs)"
        required={values?.option !== "OFF"}
        disabled={values?.option === "OFF"}
      />,
    },
  ];
}


const NO_READING = {
  formSchema, formFields
}

export default NO_READING