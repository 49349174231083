export const styles = (theme) => ({
  root: {
    cursor: "default",
    "&:hover": {
      color: "#555",
    },
  },
  icon: {
    height: 16,
    width: 16,
  },
  text: {
    fontSize: 12,
  },
});
