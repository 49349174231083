import * as Yup from "yup";
import { NumberField, TextField } from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    code: Yup.string(),
    designation: Yup.string(),
  });
}

export function formFields(t) {
  return [
    {
      size: 12,
      field: <TextField id="designation" label="Designation" />,
    },
    {
      size: 12,
      field: <TextField id="code" label="Code" disabled />,
    },
    {
      size: 12,
      field: <NumberField id="duration" label="Duration (seg)" decimalScale={0} />,
    },
  ];
}
