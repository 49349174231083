import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { TextField, InputAdornment } from "@mui/material";

import { styles } from "./SearchBox.styles";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function SearchBox({ classes, onEnter, fast, placeholder }) {
  const [query, setQuery] = useState("");

  const onChangeHandler = (e) => {
    setQuery(e.target.value);
    if (fast) {
      onEnter && onEnter(e.target.value);
    }
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13 && ((query && query.length > 2) || query === "")) {
      onEnter && onEnter(query);
    }
  };

  const onClearHandler = () => {
    setQuery("");
    onEnter && onEnter(null);
  };

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        placeholder={placeholder}
        margin="none"
        variant="outlined"
        size="small"
        value={query}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" onClick={() => onEnter && onEnter(query)}>
              <SearchIcon style={{ cursor: "pointer" }} />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              <HighlightOffIcon onClick={onClearHandler} className={classes.clear} />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default withStyles(SearchBox, styles);
