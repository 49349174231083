import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updatePodSetting } from "../../../api/apiv2/podsettings/updatePodSetting";

const updatePodSettingCall = async (
  { getPodSettings, setFormSuccess },
  dispatch,
  state,
  { _id: id, podCode, address, value }
) => {
  let rh = requestHelper(dispatch, "POD_SETTINGS_UPDATE");
  try {
    const filter = state.podsettings.filter;
    let result = await updatePodSetting(id, podCode, address, value);
    if (result.success) {
      dispatch(setSuccess("podsettings", "POD_SETTINGS_UPDATE"));
      dispatch(getPodSettings({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("podsettings", "POD_SETTINGS_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updatePodSettingCall;
