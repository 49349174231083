import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updateRole } from "../../../api/apiv2/roles/updateRole";

const updateRoleCall = async (
  { getRoles, setFormSuccess },
  dispatch,
  state,
  { _id: id, name, permissions }
) => {
  let rh = requestHelper(dispatch, "ROLE_UPDATE");
  try {
    const filter = state.roles.filter;
    let result = await updateRole(id, name, permissions);
    if (result.success) {
      dispatch(setSuccess("roles", "ROLE_UPDATE"));
      dispatch(getRoles({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("roles", "ROLE_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateRoleCall;
