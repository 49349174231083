import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updateDevice } from "../../../api/apiv2/devices/updateDevice";

const updateDeviceCall = async (
  { getDevices, setFormSuccess },
  dispatch,
  { id, type, code, designation, description, model, configs, boxId }
) => {
  let rh = requestHelper(dispatch, "DEVICE_UPDATE");
  try {
    let result = await updateDevice({
      id,
      type,
      code,
      designation,
      description,
      model,
      configs,
      box: boxId !== "NONE" ? boxId : null,
    });
    if (result.success) {
      dispatch(setSuccess("devices", "DEVICE_UPDATE"));
      dispatch(getDevices());
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("devices", "DEVICE_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateDeviceCall;
