export const styles = (theme) => ({
  root: {
    "& .MuiSelect-select": {
      padding: "0 !important",
      paddingLeft: "10px !important",
      paddingRight: "32px !important",
      fontSize: 12,
    },
  },
});
