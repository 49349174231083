import { api, getToken, storeToken } from "../api";

export const updatePassword = async (currentPassword, newPassword) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/auth/updatepassword`,
    { currentPassword, newPassword },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  if (result?.data?.token) {
    storeToken(result?.data?.token);
  }
  return result?.data || { success: false };
};
