export const styles = (theme) => ({
  root: {},
  alertItem: {
    padding: 5,
    borderRadius: 5,
    marginBottom: 5,
  },
  HIGH: {
    backgroundColor: "#ffdada",
  },
  MEDIUM: {
    backgroundColor: "#ffffd2",
  },
  LOW: {
    backgroundColor: "#def6de",
  },
  disabled: {
    backgroundColor: "#ddd",
  },
});
