import { requestHelper } from "../../../app/coreSlice";
import { getDevices } from "../../../api/apiv2/devices/getDevices";

const getDevicesCall = async ({ setDevices }, dispatch, state) => {
  let rh = requestHelper(dispatch, "GET_DEVICES");
  console.log('state.core.selectedProject: %o', state.core.selectedProject)
  let project = state.core.selectedProject?._id;
  try {
    let result = await getDevices({ project, limit: 100 });
    console.log("result: %o", result);
    if (result.success) {
      dispatch(setDevices(result.data));
    }
  } catch (ex) {
    rh.error("devices", "GET_DEVICES_ERROR", ex);
  }
  rh.close();
};

export default getDevicesCall;
