import React from "react";
import { withStyles } from "tss-react/mui";
import { MenuItem, Select } from "@mui/material";

import { styles } from "./DataFilterSelect.styles";

function DataFilterSelect({ classes, style, dataFilter, setDataFilter }) {
  return (
    <Select
      style={style}
      className={classes.root}
      autoWidth
      value={dataFilter}
      onChange={(e) => setDataFilter(e.target.value)}
    >
      <MenuItem value="min">min</MenuItem>
      <MenuItem value="mean">mean</MenuItem>
      <MenuItem value="max">max</MenuItem>
      <MenuItem value="exact">exact</MenuItem>
    </Select>
  );
}

export default withStyles(DataFilterSelect, styles);
