export const styles = theme => ({
  root: {
    marginBottom: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    height: 28,
  },
  description: {
    marginBottom: 5,
    display: "block",
  },
  divider: {
    marginTop: 20,
  },
  icon: {
    padding: 0,
    marginBottom: 3,
  }
});