import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

import { styles } from "./MyMap.styles";

const dCenter = {
  lat: 40.1358783,
  lng: -7.4642608,
};

const containerStyle = {
  width: '100%',
  height: 'calc(100vh - 100px)'
};

function MyMap({ classes, center = dCenter, markers, zoom = 10 }) {
  // const { t } = useTranslation();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC4gaNqVccZZ7tcmHNQIEL2XHQjRkTrsB4"
  })


  // const [map, setMap] = useState(null)

  // const onLoad = useCallback(function callback(map) {
  //   // This is just an example of getting and using the map instance!!! don't just blindly copy!
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      // onLoad={onLoad}
      // onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      {markers}
    </GoogleMap>
  ) : <></>
}

export default withStyles(MyMap, styles);