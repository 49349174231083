import FakeCollection from "./fakeCollection";

import { zonesSample } from "./data/zonesSample";
import { boxsSample } from "./data/boxsSample";
import { sensorsSample } from "./data/sensorsSample";

const zones = new FakeCollection("fk_zones", zonesSample);
const boxs = new FakeCollection("fk_boxs", boxsSample);
const sensors = new FakeCollection("fk_sensors", sensorsSample);

export const getZones = () => {
  return zones.get();
};

export const addZone = (designation, description) => {
  return zones.add({designation, description})
}

export const updateZone = (id, designation, description) => {
  return zones.add({id, designation, description})
}

export const removeZone = (id) => {
  return zones.remove(id)
}

export const getBoxs = (zoneId) => {
  let boxsCollection = boxs.get();
  return boxsCollection.filter((item) => item.zoneId === zoneId);
};

export const getBox = (boxId) => {
  return boxs.get(boxId);
}

export const addBox = (designation, description, zoneId) => {
  return boxs.add({designation, description, zoneId})
}

export const updateBox = (id, designation, description, zoneId) => {
  return boxs.add({id, designation, description, zoneId})
}

export const removeBox = (id) => {
  return boxs.remove(id)
}

export const getSensors = (boxId) => {
  let sensorsCollection = sensors.get();
  return sensorsCollection.filter((item) => item.boxId === boxId);
};

export const getSensor = (sensorId) => {
  return sensors.get(sensorId);
};

export const addSensor = (designation, description, type, model, code, config, boxId) => {
  return sensors.add({designation, description, type, model, code, config, boxId})
}

export const updateSensor = (id, designation, description, type, model, code, config, boxId) => {
  return sensors.add({id, designation, description, type, model, code, config, boxId})
}

export const removeSensor = (id) => {
  return sensors.remove(id)
}
