import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { styles } from "./RolesForm.styles";
import { Form, FormFields } from "../../../../common/forms";
import { formFields, formSchema } from "./RolesForm.schema";
import { createRole, updateRole } from "../../rolesSlice";
import { permissionsBaseValues } from "./PermissionSelect/PermissionSelect";

function RolesForm({ classes, selectedRole }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = (values) => {
    if (selectedRole) {
      dispatch(updateRole(values));
    } else {
      dispatch(createRole(values));
    }
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={selectedRole || { permissions: permissionsBaseValues(), name: "" }}
        onSubmit={onSubmitHandler}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(t)} />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                {t("roles.RolesForm.form.submit")}
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(RolesForm, styles);
