import { api, getToken } from "../api";

export const getReadings = async ({ code, startDate, endDate, interval }) => {
  console.log(">>>>>>>>>>>>>>> ELASTIC API - getReadings: %o", { code, startDate, endDate, interval });
  const token = getToken();
  try {
    let result = await api.post(
      "/api/v1/elastic/readings/get",
      { code, startDate, endDate, interval },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("%c::: getReadings RESULT ::: %o", "color:#55B849; font-weight:bold", result);
    return (result?.data?.data?.aggregations?.histogram?.buckets || [])
      .filter((item) => item?.avg?.value !== null || item?.max?.value !== null || item?.min?.value !== null)
      .map((item) => {
        return {
          date: item?.key_as_string,
          max: Math.round(item?.max?.value * 100) / 100,
          min: Math.round(item?.min?.value * 100) / 100,
          avg: Math.round(item?.avg?.value * 100) / 100,
          count: item?.doc_count,
          id: item.key,
        };
      });
  } catch (ex) {
    console.log("ERROR: %o", ex);
  }
};
