import { requestHelper } from "../../../app/coreSlice";
import { dashboardSlice } from "../dashboardSlice";
import { getForecast, getLastData } from "../../../api/ipma/ipmaApi";

const { setWidgetsData } = dashboardSlice.actions;

export const getWeatherIpmaData = (config, index) => async (dispatch) => {
  let rh = requestHelper(dispatch, "GET_WEATHER_IPMA");
  try {
    let result = await getLastData(config.lastMeasuresStationID);
    let forecast = await getForecast(config.forecastGlobalIdLocal);
    // console.log("getMeteoData result: %o", result);
    // console.log("getMeteoData forecast: %o", forecast);
    dispatch(setWidgetsData({ index, data: { ...result, forecast: forecast?.data } }));
  } catch (ex) {
    console.error(ex);
    rh.error("blocks", "GET_WEATHER_IPMA_ERROR", ex);
  }
  rh.close();
};
