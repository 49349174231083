export const styles = (theme) => ({
  root: {},
  paper: {
    padding: 10,
  },
  containerTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 5,
  },
});
