import React from "react";
import { withStyles } from "tss-react/mui";
import { TextField, Typography } from "@mui/material";

import { styles } from "./_base.styles";

function TextInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  type = "text",
  error,
  required,
  variant = "outlined",
  rows = 0,
  maxLength
}) {
  return (
    <div className={classes.root + " " + className}>
      {variant === "outlined" && label && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <TextField
        id={id}
        fullWidth
        type={type}
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        error={error}
        label={variant === "outlined" ? undefined : label + (required ? " *" : "")}
        onChange={(e) => onChange && onChange(maxLength ? e.target.value.slice(0, maxLength) : e.target.value)}
        value={value}
        disabled={disabled}
        variant={variant}
        onFocus={(e) => e.target && e.target.select && e.target.select()}
        size="small"
        multiline={rows > 0}
        rows={rows}
      />
    </div>
  );
}

export default withStyles(TextInput, styles);
