export const styles = () => ({
  root: {
    padding: 20,
  },
  list: {
    height: 'calc(100vh - 100px)',
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.3)",
    },
    "& li": {
      cursor: "pointer"
    }
  }
});