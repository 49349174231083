export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 13,
    fontWeight: "bold",
  },
  description: {
    fontSize: 10,
    display: "block",
    "& .MuiChip-root": {
      height: 15,
      marginRight: 5,
      "& span": {
        padding: "0 5px",
        lineHeight: 1,
      },
    },
  },
  separator: {
    display: "block",
    width: "33%",
    borderBottom: "1px solid #ddd",
    margin: "5px auto",
  },
  relayInfo: {
    fontSize: 30,
    textAlign: "center",
    fontWeight: "bold",
  },
  readingBox: {
    position: "relative",
  },
  alertIcon: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  modal: {
    position: "absolute",
    height: 440,
    width: "50vw",
    top: "15vh",
    left: "25vw",
    backgroundColor: "white",
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      height: "80vh",
      width: "96vw",
      top: "15vh",
      left: "2vw",
      padding: theme.spacing(1),
    },
  },
});
