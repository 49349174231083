import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

import { styles } from "./DeviceForm.styles";
import { formFields, formSchema } from "./DeviceForm.schema";

import { Form, FormFields } from "../../../../common/forms";
import { createDevice, selectBoxes, updateDevice } from "../../devicesSlice";

function DeviceForm({ classes, device, onDelete }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const boxes = useSelector(selectBoxes);

  const onSubmitHandler = async (values) => {
    if (!values._id) {
      dispatch(createDevice(values));
    } else {
      dispatch(updateDevice(values));
    }
  };

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <div>
        <Form
          schema={formSchema(t)}
          initialValues={device}
          onSubmit={onSubmitHandler}
          render={({ submitting, values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields(t, values, boxes)} />
                </Grid>
                <Grid item>
                  <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                  {t("devices.DeviceForm.form.submit")}
                  </Button>
                </Grid>
                {values?._id && (
                  <Grid item>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="primary"
                      disabled={submitting}
                      onClick={() => onDelete()}
                    >
                      {t("devices.DeviceForm.form.delete")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            );
          }}
        />
      </div>
    </Grid>
  );
}

export default withStyles(DeviceForm, styles);
