import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { deleteDevice } from "../../../api/apiv2/devices/deleteDevice";

const deleteDeviceCall = async ({ getDevices, setFormSuccess }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "DEVICE_DELETE");
  try {
    console.log("deleteDeviceCall id: %o", id);
    let result = await deleteDevice(id);
    if (result.success) {
      dispatch(setSuccess("devices", "DEVICE_DELETE"));
      dispatch(getDevices());
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("devices", "DEVICE_DELETE_ERROR", ex);
  }
  rh.close();
};

export default deleteDeviceCall;
