import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Grid } from "@mui/material";

import { styles } from "./WeatherIpmaForecastItem.styles";
import moment from "moment";
import { WiRaindrop } from "react-icons/wi";

function WeatherIpmaForecastItem({ classes, forecast }) {
  return (
    <Grid container className={classes.root} alignItems="center" justifyContent="space-between">
      <Grid item className={classes.day}>
        <Typography>{moment(forecast.forecastDate).format("DD")}</Typography>
        <Typography>{moment(forecast.forecastDate).format("MMM")}</Typography>
      </Grid>
      <Grid item>
        <img
          className={classes.weatherType}
          alt={forecast.idWeatherType}
          src={
            "https://www.ipma.pt/bin/icons/svg/weather/w_ic_d_" + ("0" + forecast.idWeatherType).slice(-2) + "anim.svg"
          }
        />
      </Grid>
      <Grid item className={classes.temp}>
        <Typography>{parseFloat(forecast.tMin).toFixed(0)}ºC</Typography>
        <Typography>{parseFloat(forecast.tMax).toFixed(0)}ºC</Typography>
      </Grid>
      <Grid item className={classes.perc}>
        <Typography>
          <WiRaindrop fontSize="16px" />
        </Typography>
        <Typography>{parseFloat(forecast.precipitaProb).toFixed(0)}%</Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(WeatherIpmaForecastItem, styles);
