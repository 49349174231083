import React from "react";
import { withStyles } from "tss-react/mui";
import { Tooltip, Typography, Grid } from "@mui/material";

import { styles } from "./DeviceInfo.styles";
import SensorIcon from "../SensorIcon";

function DeviceInfo({ classes, designation, description, type }) {
  let info = (
    <Grid className={classes.root} container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
      <Grid item style={{ display: "flex" }}>
        <SensorIcon type={type} style={{ display: "flex" }} />
      </Grid>
      <Grid item>
        <Typography variant="body2">{designation}</Typography>
      </Grid>
    </Grid>
  );
  return description ? (
    <Tooltip title={description} placement="bottom">
      {info}
    </Tooltip>
  ) : (
    info
  );
}

export default withStyles(DeviceInfo, styles);
