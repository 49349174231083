const styles = (theme) => ({
  root: {
    height: "100vh",
    backgroundColor: "#fffcf1",
    backgroundImage: "url('/images/back.jpg')",
    backgroundSize: "cover",
  },
  container: {
    maxWidth: 800,
    height: "100vh",
    margin: "0 auto",
  },
  paper: {
    padding: 20,
    margin: 20,
  },
  logo: {
    width: "100%",
    margin: "-12% 0"
  },
  loader: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  error: {
    color: theme.palette.error.main,
    textAlign: "center",
    fontSize: 12,
    minHeight: 18,
    marginBottom: 24,
  },
});
export default styles;
