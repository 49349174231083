import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { CardSelectInput } from "../../inputs";

function CardSelectField({ classes, id, label, disabled, required, options, xs, textAlign = "left", onChange }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v);
    onChange && onChange(v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <CardSelectInput
              label={label}
              disabled={disabled}
              required={required}
              options={options}
              xs={xs}
              selectedValue={input.value}
              textAlign={textAlign}
              onChange={(v) => onChangeHandler(v, input)}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(CardSelectField, styles);
