import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { createUser } from "../../../api/apiv2/users/createUser";

const createUserCall = async ({ getUsers, setFormSuccess }, dispatch, state, { name, email, role, password, projects }) => {
  let rh = requestHelper(dispatch, "USER_CREATE");
  try {
    const filter = state.users.filter;
    let result = await createUser(name, email, role, password, projects);
    if (result.success) {
      dispatch(setSuccess("users", "USER_CREATE"));
      dispatch(getUsers({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("users", "USER_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createUserCall;
