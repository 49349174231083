import { api, getToken } from "../api";

export const updatePodSetting = async (id, podCode, address, value) => {
  console.log("updatePodSetting %o", { id, podCode, address, value });
  let token = getToken();
  let result = await api.put(
    `/api/v1/podsettings/${id}`,
    { podCode, address, value },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
