import moment from "moment";

export const getSeriesForCollection = (data, sensorsInfo) => {
  let series = [];
  if (data.selectedSensors.length === 1 && data.selectedType === "MINMAX") {
    let selectedSensor = data.selectedSensors[0];
    console.log('selectedSensor: %o', selectedSensor);
    const { type } = sensorsInfo[selectedSensor] || {};
    if (type === "MOTION") {
      series.push({
        name: `(${selectedSensor}) ${sensorsInfo[selectedSensor]?.designation}`,
        type: "bar",
        data: (data.resultSets[selectedSensor] || []).map((item) => {
          return getSerieData(item.date, item.count);
        }),
      })
    }
    if (type === "DOOR") {
      series.push({
        name: `(${selectedSensor}) ${sensorsInfo[selectedSensor]?.designation}`,
        type: "line",
        data: (data.resultSets[selectedSensor] || []).map((item) => {
          return getSerieData(item.date, item.count);
        }),

      })
    }
    else {
      series = [
        {
          name: "min",
          data: (data.resultSets[selectedSensor] || []).map((item) => {
            return getSerieData(item.date, item.min);
          }),
        },
        {
          name: "max",
          data: (data.resultSets[selectedSensor] || []).map((item) => {
            return getSerieData(item.date, item.max);
          }),
        },
      ];
    }
  } else {
    data.selectedSensors.forEach((sensor) => {
      const { type } = sensorsInfo[sensor] || {};
      if (type === "MOTION") {
        series.push({
          name: `(${sensor}) ${sensorsInfo[sensor]?.designation}`,
          type: "bar",
          data: (data.resultSets[sensor] || []).map((item) => {
            return getSerieData(item.date, item.count);
          }),
        });
      }
      else {
        series.push({
          name: `(${sensor}) ${sensorsInfo[sensor]?.designation}`,
          data: (data.resultSets[sensor] || []).map((item) => {
            return getSerieData(item.date, item.avg);
          }),
        });
      }
    });
  }
  return series;
};

const getSerieData = (date, value) => {
  return {
    x: moment(date).utc(true).toDate().getTime(),
    y: value
  }
}

export const getYaxisForCollection = (data, sensorsInfo) => {
  let yaxis;
  if (data.selectedSensors.length === 1) {
    let selectedSensor = data.selectedSensors[0];
    yaxis = {
      title: {
        text: `(${selectedSensor}) ${sensorsInfo[selectedSensor]?.designation}`,
      },
    };
  } else {
    yaxis = [];
    let typesLabeled = [];
    data.selectedSensors.forEach((sensor) => {
      let minmax = getYaxisMinMax(sensorsInfo[sensor]?.type);
      yaxis.push({
        show: typesLabeled.indexOf(sensorsInfo[sensor]?.type) === -1,
        title: {
          text: `${sensorsInfo[sensor]?.type}`,
        },
        ...minmax,
      });
      typesLabeled.push(sensorsInfo[sensor]?.type);
    });

  }
  return yaxis;
};

const getYaxisMinMax = (type) => {
  console.log('getYaxisMinMax - type: %o', type)
  switch (type) {
    case "TEMPERATURE":
      return {
        min: -20,
        max: 70,
      };
    case "RELAY":
    case "DOOR":
      return {
        min: 0,
        max: 1,
      };
    case "MOTION":
      return {
        min: 0,
        max: 10,
      };
    default:
      return {
        min: 0,
        max: 100,
      };
  }
};

export const getMarkersForCollection = (data, sensorsInfo) => {
  let selectedSensors = data.selectedSensors.map((item) => sensorsInfo[item]);

  let size = [];
  (selectedSensors || []).forEach((item) => {
    if (["RELAY"].includes(item?.type)) {
      size.push(5);
    } else {
      size.push(0);
    }
  });

  return {
    size,
  };
};

export const getStrokesForCollection = (data, sensorsInfo) => {
  let selectedSensors = data.selectedSensors.map((item) => sensorsInfo[item]);

  let width = [];
  let curve = [];
  (selectedSensors || []).forEach((item) => {
    if (["RELAY"].includes(item?.type)) {
      width.push(0);
    } else {
      width.push(2);
    }
    if (item?.type === "DOOR") {
      curve.push("stepline")
    }
    else {
      curve.push("smooth")
    }
  });

  return {
    width,
    curve,
  };
};
