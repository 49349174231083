import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, IconButton } from "@mui/material";
import FormFields from "./FormFields";
import { FieldArray } from "react-final-form-arrays";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import { styles } from "./fields/_base.styles";

function FormArrayFields({ classes, className, name, formFields, defaultItem = {}, max }) {
  return (
    <div className={className}>
      <FieldArray name={name}>
        {({ fields }) => {
          return (
            <div>
              {(fields || []).map((item, index) => (
                <div key={name + "_" + index}>
                  <FormFields fields={formFields(item)} />

                  <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <div className={classes.arraySpace}></div>
                    </Grid>
                    <Grid item>
                      <div className={classes.separator + " MuiForm-separator"}></div>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => fields.remove(index)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              ))}
              {(!max || (fields || []).length < max) && (
                <IconButton
                  style={{ margin: "0 auto", display: "block" }}
                  onClick={() => fields.push(defaultItem)}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
              )}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default withStyles(FormArrayFields, styles);
