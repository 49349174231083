import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

export default function NavLink(props) {
  const { icon, primary, to, onClick, children, activeClass } = props;
  const location = useLocation();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref) => (
        <Link ref={ref} to={to} {...linkProps} />
      )),
    [to]
  );

  return (
    <li className={location.pathname === to ? activeClass : null}>
      <ListItem button component={CustomLink} onClick={onClick}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} />
        {children}
      </ListItem>
    </li>
  );
}
