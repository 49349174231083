import React, { forwardRef, useState, useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { MenuItem, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from "prop-types";

import { styles } from "./_base.styles";

function NumberWithSelectInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  selectValue,
  onSelectChange,
  disabled,
  error,
  required,
  options,
  decimalScale,
  allowNegative,
  textAlign = "right",
  maxLength = 9,
  selectWidth = 64,
  allowNull,
  note,
}) {
  if (!options || options.length === 0) {
    return null;
  }
  let selectValueParsed = selectValue || (allowNull ? '' : options[0]?.value);

  const onChangeHandler = (value) => {
    if (disabled) {
      return;
    }
    let nValue = value;
    if (maxLength) {
      nValue = value.slice(0, maxLength);
    }
    onChange && onChange(nValue);
  };

  const focusHandler = (e) => {
    if (e?.target?.select) {
      setTimeout(() => {
        e.target.select()
      }, 50);
    }
  }

  return (
    <div className={classes.root + (className ? " " + className : "")}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
        {label && (
          <Typography
            style={{ marginBottom: 8, marginTop: 8 }}
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        )}
        {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
      </Stack>
      <div className={classes.textselectContainer + (disabled ? " " + classes.disabledInput : "")}>
        <TextField
          id={id}
          fullWidth
          InputProps={{
            inputComponent: NumericFormatCustom,
            style: { paddingRight: selectWidth },
          }}
          // eslint-disable-next-line
          inputProps={{
            decimalScale,
            allowNegative,
            textAlign,
            maxLength,
          }}
          type="text"
          className={classes.input}
          error={error}
          onChange={(e) => onChangeHandler(e.target.value)}
          value={value}
          disabled={disabled}
          size="small"
          onFocus={focusHandler}
        />
        <Select
          id={id}
          value={selectValueParsed}
          onChange={(e) => onSelectChange && onSelectChange(e.target.value)}
          className={classes.textselect}
          error={error}
          disabled={disabled}
        >
          {(options || []).map((item, index) => (
            <MenuItem key={"option_" + index} value={item.value} disabled={item.disabled}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default withStyles(NumberWithSelectInput, styles);

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, decimalScale, allowNegative = false, textAlign, maxLength, id, value, ...other } = props;
  const [lValue, setValue] = useState(value || "");

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value])

  return (
    <NumericFormat
      {...other}
      id={id + "_input"}
      getInputRef={ref}
      value={lValue}
      onChange={(e) => {
        let nValue = e.target.value;
        if (nValue !== lValue) {
          if (nValue) {
            let hasTrailingDot = nValue.endsWith('.');
            nValue = parseFloat(nValue).toString();
            if (hasTrailingDot) {
              nValue += ".";
            }
          }

          if (maxLength && nValue && nValue.length > maxLength) {
            nValue = nValue.slice(0, maxLength);
          }
          setTimeout(() => {
            e.target.value = nValue;
          }, 10);

          setValue(nValue);
          onChange({
            target: {
              name: props.name,
              value: nValue,
            },
          });
        }
      }}
      onBlur={(e) => e.target.value = lValue}
      style={{ textAlign }}
      allowNegative={allowNegative}
      valueIsNumericString
      decimalScale={decimalScale}
      allowLeadingZeros={false}
    />
  );
});

NumberWithSelectInput.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  decimalScale: PropTypes.number,
  allowNegative: PropTypes.bool,
  textAlign: PropTypes.string,
  maxLength: PropTypes.number,
  note: PropTypes.string,
  selectValue: PropTypes.string,
  onSelectChange: PropTypes.func,
  options: PropTypes.array,
  selectWidth: PropTypes.number,
  allowNull: PropTypes.bool,
};
