export const sensorsSample = [
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf91",
    designation: "Temperatura quadro",
    description: "Sensor que esta dentro do quadro eléctrico, perto do raspberry Pi",
    type: "TEMPERATURE",
    model: "DHT23",
    boxId: "111b832c-e803-475d-bafc-72942956bf91",
    code: "001",
    config: "{\"offset\": 0}",
  },
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf92",
    designation: "Humidade quadro",
    description: "Sensor que esta dentro do quadro eléctrico, perto do raspberry Pi",
    type: "HUMIDITY",
    model: "DHT23",
    boxId: "111b832c-e803-475d-bafc-72942956bf91",
    code: "002",
    config: "{\"offset\": 0}",
  },
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf93",
    designation: "Temperatura Interna",
    description: "Sensor interno na caixa",
    type: "TEMPERATURE",
    model: "DHT23",
    boxId: "221b832c-e803-475d-bafc-72942956bf91",
    code: "003",
    config: "{\"offset\": 0}",
  },
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf94",
    designation: "Humidade Interna",
    description: "Sensor interno na caixa",
    type: "HUMIDITY",
    model: "DHT23",
    boxId: "221b832c-e803-475d-bafc-72942956bf91",
    code: "004",
    config: "{\"offset\": 0}",
  },
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf95",
    designation: "Temperatura 1",
    description: "Sensor externo na caixa",
    type: "TEMPERATURE",
    model: "??",
    boxId: "221b832c-e803-475d-bafc-72942956bf91",
    code: "005",
    config: "{\"offset\": 0}",
  },
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf96",
    designation: "Temperatura 2",
    description: "Sensor externo na caixa",
    type: "TEMPERATURE",
    model: "??",
    boxId: "221b832c-e803-475d-bafc-72942956bf91",
    code: "006",
    config: "{\"offset\": 0}",
  },
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf97",
    designation: "Relé Água",
    description: "Relé 1 - ligada à bomba de agua",
    type: "RELAY",
    model: "??",
    boxId: "333b832c-e803-475d-bafc-72942956bf91",
    code: "007",
    config: "{\"offset\": 0}",
  },
  {
    id: "9dbb832c-e803-475d-bafc-72942956bf98",
    designation: "Relé Ventoinhas",
    description: "Relé 2 - ligada às ventoinha",
    type: "RELAY",
    model: "??",
    boxId: "333b832c-e803-475d-bafc-72942956bf91",
    code: "008",
    config: "{\"offset\": 0}",
  },
];