import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Paper, Typography, Grid, Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./BlockItem.styles";
import ActionsPopover from "../../../../common/components/displays/ActionsPopover/ActionsPopover";
import ActionsPopoverItem from "../../../../common/components/displays/ActionsPopover/ActionsPopoverItem/ActionsPopoverItem";
import Permission from "../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";
import WindowConfigs from "./WindowConfigs/WindowConfigs";

function BlockItem({ classes, block, onDelete, onEdit, devices }) {
  // const { t } = useTranslation();
  const onDeleteHandler = () => {
    onDelete && onDelete(block);
  };

  const onEditHandler = () => {
    onEdit && onEdit(block);
  };

  let configsDetails;

  switch (block?.type) {
    case "WINDOW":
      configsDetails = <WindowConfigs block={block} devices={devices} />;
      break;

    default:
      configsDetails = null;
      break;
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Grid container direction="column">
        <Grid item className={classes.header}>
          <Grid container direction="row" justifyContent="space-between" wrap="nowrap" alignItems="center">
            <Grid item>
              <Grid container direction="row" spacing={1}>
                <Grid item>
                  <Chip className={classes.chip} size="small" color="secondary" label={block?.code} />
                </Grid>
                <Grid item>
                  <Typography>{block.designation}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Permission code="blocks.write">
                <ActionsPopover
                  actions={[
                    <ActionsPopoverItem onClick={onDeleteHandler} icon={<DeleteIcon />} label="Delete" />,
                    <ActionsPopoverItem onClick={onEditHandler} icon={<EditIcon />} label="Edit" />,
                  ]}
                />
              </Permission>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.config}>
          {configsDetails}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withStyles(BlockItem, styles);
