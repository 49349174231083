import { requestHelper } from "../../../app/coreSlice";
import { getBoxes } from "../../../api/apiv2/boxes/getBoxes";

const getBoxesCall = async ({ setBoxes }, dispatch, state) => {
  let rh = requestHelper(dispatch, "GET_BOXES");
  try {
    let project = state.core.selectedProject?._id;
    let result = await getBoxes({ limit: 1000, project });
    if (result.success) {
      dispatch(setBoxes(result.data));
    }
  } catch (ex) {
    rh.error("devices", "GET_BOXES_ERROR", ex);
  }
  rh.close();
};

export default getBoxesCall;
