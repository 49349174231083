import React from "react";
import { withStyles } from "tss-react/mui";
import { Paper } from "@mui/material";

import { styles } from "./Blank.styles";

function Blank({ classes, children }) {
  return (
    <Paper className={classes.root}>
      {children}
    </Paper>
  );
}

export default withStyles(Blank, styles);