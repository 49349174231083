import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Button } from "@mui/material";

import { styles } from "./ChangePasswordForm.styles";
import { formFields, formSchema } from "./ChangePasswordForm.schema";
import { Form, FormFields } from "../../../../common/forms";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../authenticationSlice";

function ChangePasswordForm({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = (values) => {
    dispatch(updatePassword(values));
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={{}}
        onSubmit={onSubmitHandler}
        render={({ submitting }) => {
          return (
            <>
              <Typography variant="h4">{t("authentication.ChangePasswordForm.form.title")}</Typography>
              <br />
              <FormFields fields={formFields(t)} />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                {t("authentication.ChangePasswordForm.form.submit")}
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(ChangePasswordForm, styles);
