import React from "react";
import Chart from "react-apexcharts";

function TemperatureGauge({ temperatureValue, fontSize = 20, height = 150 }) {
  const options = {
    chart: {
      type: "radialBar",
      offsetY: 0,
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "95%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: fontSize + "px",
            formatter: function () {
              return parseFloat(temperatureValue).toFixed(1) + "ºC";
            },
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      colors: [
        function () {
          if (temperatureValue < 10) {
            return "#aed5f3";
          } else if (temperatureValue >= 10 && temperatureValue < 30) {
            return "#81d777";
          } else if (temperatureValue >= 30 && temperatureValue < 40) {
            return "#fbdb7c";
          } else {
            return "#ff9e8c";
          }
        },
      ],
    },
    labels: ["Average Results"],
  };

  let series = [((temperatureValue + 10) * 100) / 60];

  return <Chart options={options} series={series} type="radialBar" height={height} />;
}

export default TemperatureGauge;
