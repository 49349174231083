import * as Yup from "yup";
import { TextField, NumberField } from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    podCode: Yup.string().required(t("podsettings.PodSettingsForm.form.podCodeRequired")),
    address: Yup.string().required(t("podsettings.PodSettingsForm.form.addressRequired")),
    value: Yup.string().required(t("podsettings.PodSettingsForm.form.valueRequired")),
  });
}

export function formFields(t) {
  let fields = [
    {
      size: 12,
      field: <TextField id="podCode" label={t("podsettings.PodSettingsForm.form.podCode")} required />,
    },
    {
      size: 12,
      field: (
        <NumberField id="address" decimalScale={0} label={t("podsettings.PodSettingsForm.form.address")} required />
      ),
    },
    {
      size: 12,
      field: <NumberField id="value" decimalScale={0} label={t("podsettings.PodSettingsForm.form.value")} required />,
    },
  ];

  return fields;
}
