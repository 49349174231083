import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

import { styles } from "./BoxForm.styles";
import { formFields, formSchema } from "./BoxForm.schema";

import { Form, FormFields } from "../../../../common/forms";
import { updateBox } from "../../devicesSlice";

function BoxForm({ classes, box }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = async (values) => {
    dispatch(updateBox(values));
  };

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <div>
        <Form
          schema={formSchema(t)}
          initialValues={box}
          onSubmit={onSubmitHandler}
          render={({ submitting }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields(t)} />
                </Grid>
                <Grid item>
                  <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                    {t("devices.BoxForm.form.submit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </Grid>
  );
}

export default withStyles(BoxForm, styles);
