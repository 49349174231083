import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, IconButton } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import { styles } from "./CsvExport.styles";

function CsvExport({ classes, data, fields }) {
  console.log("data: %o", data);
  if (!data) {
    return null;
  }
  let filteredData = (data || []).map((item) => {
    let nItem = {};
    fields?.forEach(({ header, field, customValue }) => {
      let key = header || field;
      let value = customValue ? customValue(item) : item[field];
      nItem[key] = value !== undefined ? value.toString() : "";
    });
    return nItem;
  });

  const onClickHandler = () => {
    JSONToCSVConvertor(filteredData);
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={onClickHandler}>
        <InsertDriveFileOutlinedIcon />
      </IconButton>
      <Typography className={classes.label}>CSV</Typography>
    </div>
  );
}

export default withStyles(CsvExport, styles);

function JSONToCSVConvertor(data, fileName) {
  let CSV = "";

  // headers
  let row = "";
  let keys = Object.keys(data[0]);

  keys.forEach((k) => {
    row += k + ",";
  });
  row = row.slice(0, -1);
  CSV += row + "\r\n";

  // data
  data.forEach((item) => {
    row = "";
    keys.forEach((k) => {
      row += '"' + item[k] + '",';
    });
    row = row.slice(0, -1);
    CSV += row + "\r\n";
  });

  if (CSV === "") {
    return;
  }

  let uri = "data:text/csv;charset=utf-8," + escape(CSV);
  let link = document.createElement("a");
  link.href = uri;
  link.style = "visibility:hidden";
  link.download = fileName ? fileName + ".csv" : "export.csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
