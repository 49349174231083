import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { deleteSmartLink } from "../../../api/apiv2/smartlinks/deleteSmartLink";

const deleteSmartLinkCall = async ({ removeSmartLink, setFormSuccess }, dispatch, { id }) => {
  let rh = requestHelper(dispatch, "SMARTLINK_DELETE");
  try {
    let result = await deleteSmartLink(id);
    if (result.success) {
      dispatch(setSuccess("smartlinks", "SMARTLINK_DELETE"));
      dispatch(removeSmartLink(id));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("smartlinks", "SMARTLINK_DELETE_ERROR", ex);
  }
  rh.close();
};

export default deleteSmartLinkCall;
