import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Drawer } from "@mui/material";

import { styles } from "./RolesList.styles";
import {
  deleteRole,
  getRoles,
  selectFormSuccess,
  selectRolesFilter,
  selectRolesList,
  selectRolesListPagination,
} from "../rolesSlice";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import RolesDataGrid from "./RolesDataGrid/RolesDataGrid";
import SimpleScroll from "../../../common/components/displays/SimpleScroll/SimpleScroll";
import RolesForm from "./RolesForm/RolesForm";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";

function RolesList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const rolesList = useSelector(selectRolesList);
  const rolesPagination = useSelector(selectRolesListPagination);
  const filter = useSelector(selectRolesFilter);
  const formSuccess = useSelector(selectFormSuccess);
  const [drawerRoleForm, setDrawerRoleFormOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [confirmDeleteRoleDialogOpen, setConfirmDeleteRoleDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(getRoles({ clear: true, filter }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formSuccess) {
      setDrawerRoleFormOpen(false);
      setSelectedRole();
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  // const onAddHandler = () => {
  //   setSelectedRole();
  //   setDrawerRoleFormOpen(true);
  // };

  const onDeleteHandler = (project) => {
    setSelectedRole(project);
    setConfirmDeleteRoleDialogOpen(true);
  };

  const onEditHandler = (project) => {
    setSelectedRole(project);
    setDrawerRoleFormOpen(true);
  };

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search: search ? search.trim() : undefined,
    };
    dispatch(getRoles({ clear: true, filter: nFilter }));
  };

  const onFilterChangeHandler = (options) => {
    dispatch(getRoles(options));
  };

  return (
    <div>
      <Toolbar
        onSearchChanged={onSearchHandler}
        addPermission="override"
        // onAddClick={onAddHandler}
      />
      <div className={classes.root}>
        <RolesDataGrid
          data={rolesList}
          onDelete={onDeleteHandler}
          onEdit={onEditHandler}
          onFilterChange={onFilterChangeHandler}
          pagination={rolesPagination}
          filter={filter}
        />
        <Drawer anchor="right" open={drawerRoleForm} onClose={() => setDrawerRoleFormOpen(false)}>
          <div className={classes.drawer}>
            <SimpleScroll className={classes.drawerScroll}>
              <RolesForm selectedRole={selectedRole} />
            </SimpleScroll>
          </div>
        </Drawer>

        <ConfirmationDialog
          labels={{
            title: t("roles.RolesList.deleteConfirm.title"),
            description: t("roles.RolesList.deleteConfirm.description"),
            ok: t("roles.RolesList.deleteConfirm.ok"),
            cancel: t("roles.RolesList.deleteConfirm.cancel"),
          }}
          open={confirmDeleteRoleDialogOpen}
          onCancel={() => {
            setConfirmDeleteRoleDialogOpen(false);
            setSelectedRole(null);
          }}
          onConfirm={() => {
            setConfirmDeleteRoleDialogOpen(false);
            dispatch(deleteRole(selectedRole._id));
            setSelectedRole(null);
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(RolesList, styles);
