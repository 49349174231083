import { guid, getLocalStorage, setLocalStorage } from "../../common/utils/utils";

export default class FakeCollection {
  constructor(key, defaultEntry) {
    this.key = key;
    this.collection = getLocalStorage(key) || defaultEntry;
    //console.log('----- key: [%s] collection created: %o', this.key, this.collection);
  }

  get = (id) => {
    //console.log('----------- this.colletion: %o', this.collection);
    if (id) {
      return JSON.parse(JSON.stringify(this.collection.find(item => item.id === id)));
    }
    else {
      return JSON.parse(JSON.stringify(this.collection));
    }
  };

  add = (obj) => {
    console.log('----- add obj: %o, this.collection: %o', obj, this.collection)
    if (obj.id) {
      this.collection = [ ...this.collection.filter(item => item.id !== obj.id), obj];
    }
    else {
      obj.id = guid();
      this.collection = [ ...this.collection, obj ];
    }
    setLocalStorage(this.key, this.collection);
    return JSON.parse(JSON.stringify(this.collection));
  };

  remove = (id) => {
    this.collection = [ ...this.collection.filter(item => item.id !== id)];
    setLocalStorage(this.key, this.collection);
    return JSON.parse(JSON.stringify(this.collection));
  }
}