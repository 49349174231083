export const styles = (theme) => ({
  root: {
    border: "2px solid " + theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    minHeight: 160,
    backgroundColor: "#FFF",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddf1db",
      transform: "scale(1.02)",
    },
  },
  designation: {
    lineHeight: 1.4,
    display: "block",
    letterSpacing: 0,
  },
});
