import { api, getToken } from "../api";

export const updateProject = async (id, code, name, features) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/projects/${id}`,
    { code, name, features },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
