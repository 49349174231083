import { createSlice } from "@reduxjs/toolkit";
import alertsListCall from "./_sliceCalls/alertsList";
import alertsCreateCall from "./_sliceCalls/alertsCreate";
import alertsActivateCall from "./_sliceCalls/alertsActivate";
import alertsUpdateCall from "./_sliceCalls/alertsUpdate";
import alertsDeleteCall from "./_sliceCalls/alertsDelete";

export const alertsSlice = createSlice({
  name: "alerts",
  initialState: {
    alerts: null,
  },
  reducers: {
    setAlerts: (state, action) => {
      state.alerts = action.payload;
    },
  },
});

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectAlerts = (state) => state.alerts.alerts;

// CALLS
export const getAlerts = () => (dispatch, getState) => {
  alertsListCall(alertsSlice.actions, dispatch, getState());
};

export const addAlert = (fields) => (dispatch, getState) => {
  alertsCreateCall({ getAlerts }, dispatch, getState(), fields);
};

export const updateAlert =
  ({ id, sensorId, designation, severity, type, value, active }) =>
  (dispatch, getState) => {
    alertsUpdateCall({ getAlerts }, dispatch, getState(), { id, sensorId, designation, severity, type, value, active });
  };

export const activateAlert = (id, active) => async (dispatch, getState) => {
  alertsActivateCall({ getAlerts }, dispatch, getState(), { id, active });
};

export const removeAlert = (id) => async (dispatch, getState) => {
  alertsDeleteCall({ getAlerts }, dispatch, getState(), { id });
};

export default alertsSlice.reducer;
