import React from "react";
import { Grid, Tooltip } from "@mui/material";
import { getFeatureIcon } from "../ProjectsForm/ProjectsForm.options";

export function listColumns(t, classes) {
  return [
    {
      field: "code",
      headerName: t("projects.ProjectsList.columns.code"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "name",
      headerName: t("projects.ProjectsList.columns.name"),
      align: "left",
      headerAlign: "left",
    },
    {
      field: "features",
      headerName: t("projects.ProjectsList.columns.features"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        let features = [...(v?.features || [])];
        features.sort();
        return (
          <Grid container>
            {features.map((feature) => (
              <Grid item key={feature}>
                <Tooltip title={feature}>{getFeatureIcon(feature)}</Tooltip>
              </Grid>
            ))}
          </Grid>
        );
      },
    },
  ];
}
