import * as Yup from "yup";
import { TextField, MultiSelectField } from "../../../../common/forms";
import { getFeaturesOptions } from "./ProjectsForm.options";

export function formSchema(t) {
  return Yup.object().shape({
    code: Yup.string().required(t("projects.ProjectsForm.form.codeRequired")),
    name: Yup.string().required(t("projects.ProjectsForm.form.nameRequired")),
    features: Yup.array(),
  });
}

export function formFields(t, features) {
  let fields = [
    {
      size: 12,
      field: <TextField id="code" label={t("projects.ProjectsForm.form.code")} required />,
    },
    {
      size: 12,
      field: <TextField id="name" label={t("projects.ProjectsForm.form.name")} required />,
    },
    {
      size: 12,
      field: (
        <MultiSelectField
          id="features"
          label={t("projects.ProjectsForm.form.features")}
          options={getFeaturesOptions(features)}
        />
      ),
    },
  ];

  return fields;
}
