import { guid } from "../../common/utils";

export const getAlerts = async (restAPI) => {
  // let zr = await restAPI.get("/zones/get");
  let r = await restAPI.get("/alerts/get");
  let alertsList = r?.data || [];
  return alertsList.map((item) => {
    return {
      ...item,
      active: item.active.toString() === "1" ? true : false,
    };
  });
};

export const addAlert = async (restAPI, sensorId, designation, severity, type, value, active) => {
  return restAPI.post("alerts/add", {
    id: guid(),
    sensorId,
    designation,
    severity,
    type,
    value,
    active,
  });
};

export const activateAlert = async (restAPI, id, active) => {
  return restAPI.post("alerts/activate", {
    id,
    active,
  });
};

export const updateAlert = async (restAPI, id, sensorId, designation, severity, type, value, active) => {
  return restAPI.post("alerts/update", {
    id,
    sensorId,
    designation,
    severity,
    type,
    value,
    active,
  });
};

export const removeAlert = async (restAPI, id) => {
  return restAPI.post("alerts/delete", {
    id,
  });
};
