import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Typography, Grid, Chip } from "@mui/material";

import { styles } from "./WindowConfigs.styles";

function WindowConfigs({ classes, block, devices }) {
  // const { t } = useTranslation();

  let openRelay = devices.find((item) => item._id === block?.config?.openRelay);

  console.log("openRelay: %o", openRelay);

  const deviceInfo = (deviceId) => {
    let device = devices.find((item) => item._id === deviceId);
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item>
          <Chip className={classes.chip} size="small" color="secondary" label={device?.code} />
        </Grid>
        <Grid item>
          <Typography>{device?.designation}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography>{block.type}</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={1} >
          <Grid item xs={4}>
            <b>OPEN</b>
          </Grid>
          <Grid item xs={8}>
            {deviceInfo(block?.config?.openRelay)}
          </Grid>
          <Grid item xs={4}>
            <b>CLOSE</b>
          </Grid>
          <Grid item xs={8}>
            {deviceInfo(block?.config?.closeRelay)}
          </Grid>
          <Grid item xs={4}>
            <b>Time</b>
          </Grid>
          <Grid item xs={8}>
            <Typography>{parseInt(block?.config?.timeToOpen)} s</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(WindowConfigs, styles);
