import { Grid, IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import { useUser } from "../../../../common/hooks";

export default function UsersDataGridActions({ row, onDelete, onEdit }) {
  const { t } = useTranslation();
  // const user = useUser();

  return (
    <Grid container spacing={1} justifyContent="flex-end" wrap="nowrap">
      <Grid item>
        <Tooltip title={t("users.UsersList.actions.edit")}>
          <IconButton onClick={() => onEdit(row)} color="secondary" size="small">
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip title={t("users.UsersList.actions.delete")}>
          <IconButton onClick={() => onDelete(row)} color="secondary" size="small">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
