import * as Yup from "yup";
import { DeviceField, SelectField, TextField } from "../../../../../common/forms";

export const formSchema = (t) => {
  return Yup.object().shape({
    deviceId: Yup.string().required(),
    value: Yup.string().required(),
    time: Yup.string().required(),
  });
}

export const formFields = (t) => {
  return [
    {
      size: 12,
      field: <DeviceField
        id="deviceId"
        label={t("alerts.AlertForm.form.device")}
        types={["TEMPERATURE", "HUMIDITY", "BATTERY", "WATERLEVEL"]}
        required
      />,
    },
    {
      size: 6,
      field: <SelectField
        id="comparer"
        label="Comparer"
        options={getComparerOptions()}
        required
      />,
    },
    {
      size: 6,
      field: <TextField
        id="value"
        label="Value"
        required
      />,
    },
    {
      size: 6,
      field: <TextField
        id="time"
        label="Time"
        required
      />,
    },
  ];
}

const READ_RANGE = {
  formSchema, formFields
}
export default READ_RANGE;

const getComparerOptions = () => {
  return [
    {
      value: "GT",
      text: "greater than",
    },
    {
      value: "LT",
      text: "lower than",
    }
  ];
}