import React from "react";
import { withStyles } from "tss-react/mui";
import { Tooltip, Typography, Grid } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { styles } from "./Info.styles";

function Info({ classes, designation, description }) {
  let info = (
    <Grid className={classes.root} container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
      <Grid item style={{ display: "flex" }}>
        <InfoOutlinedIcon className={classes.icon} />
      </Grid>
      <Grid item>
        <Typography className={classes.text} variant="body2">{designation}</Typography>
      </Grid>
    </Grid>
  );
  return description ? (
    <Tooltip title={description} placement="bottom">
      {info}
    </Tooltip>
  ) : (
    info
  );
}

export default withStyles(Info, styles);
