import React from "react";
import { withStyles } from "tss-react/mui";
import { Grid, Typography, Chip } from "@mui/material";

import { styles } from "./DeviceItem.styles";
import SensorIcon from "../../../../common/components/displays/SensorIcon";

function DeviceItem({ classes, device, onClick }) {
  return (
    <Grid
      className={classes.root}
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      onClick={() => onClick(device)}
    >
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <SensorIcon type={device.type} />
          </Grid>
          <Grid item>
            <Chip size="small" color="secondary" label={device.code} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Typography variant="caption" className={classes.designation}>
          {device.designation}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{device.model || "N/A"}</Typography>
      </Grid>
    </Grid>
  );
}

export default withStyles(DeviceItem, styles);
