import axios from "axios";

export const spAPI = {
  get: async (username, password, url) => {
    let result = await axios.get("https://api.solarpunk.pt" + url, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      auth: { username, password },
    });
    return result?.data;
  },
};

