import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./Toolbar.styles";
import { Grid, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Permission from "../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";
import SearchBox from "../../inputs/custom/SearchBox/SearchBox";

function Toolbar({ classes, onSearchChanged, onAddClick, addPermission, fastSearch, leftActions, rightActions }) {
  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.root}>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <SearchBox fast={fastSearch} onEnter={onSearchChanged} />
          </Grid>
          {(leftActions || []).map((action, index) => (
            <Grid item key={`leftAction_${index}`}>
              {action}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={1} alignItems="center">
          {(rightActions || []).map((action, index) => (
            <Grid item key={`rightAction_${index}`}>
              {action}
            </Grid>
          ))}
          {onAddClick && (
            <Grid item>
              <Permission code={addPermission}>
                <Button variant="contained" size="small" color="primary" onClick={onAddClick}>
                  <AddIcon />
                </Button>
              </Permission>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(Toolbar, styles);
