import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { updateAlert } from "../../../api/nodeRedApi/alerts";

const alertsUpdateCall = async (
  { getAlerts },
  dispatch,
  state,
  { id, sensorId, designation, severity, type, value, active }
) => {
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "ALERTS_UPDATE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await updateAlert(api, id, sensorId, designation, severity, type, value, active);
      dispatch(setSuccess("alerts", "ALERTS_UPDATE"));
      dispatch(getAlerts());
    }
  } catch (ex) {
    rh.error("alerts", "ALERTS_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default alertsUpdateCall;
