const theme = {
  palette: {
    primary: {
      main: "#55B849",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#263438",
    },
    text: {
      primary: "#000",
      secondary: "#97A1AF",
    },
    background: {
      default: "#f1f2f2",
      paper: "#FFF",
      input: "#FBFCFD",
      error: "#FAEEED",
      errorDark: "#f5d5d2",
      menuActive: "#EEF2FF",
    },
    action: {
      active: "#D2D9E4",
      hover: "#ddd", // options hover
      selected: "#55B84929", // selected
      selectedOpacity: 0.16,
      disabled: "#D2D9E4",
      disabledBackground: "#f9f9f9",
      focus: "#55B84929",
    },
  },
  spacing: 4,
  myvars: {
    drawerWidth: 180,
    toolbarHeight: 60,
  },
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 30,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          "& span": {
            fontSize: 14,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: "1400 !important",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "8.5px 14px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          minWidth: 40,
        },
      },
    },
  },
};

export default theme;
