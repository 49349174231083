import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, LinearProgress, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { styles } from "./Tray.styles";
import ReadingsTooltip from "./ReadingsTooltip/ReadingsTooltip";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";

function Tray({ classes, item, last, next, onClick }) {
  // const { t } = useTranslation();
  // console.log("item: %o", item);

  return (
    <div
      onClick={() => onClick(item)}
      className={
        classes.root +
        (item.last || last
          ? " " + classes.last
          : item.warn
            ? " " + classes.warn
            : item.next || next
              ? " " + classes.next
              : "")
      }
    >
      {item.last || last ? (
        <div className={classes.label}>
          <Typography>LAST</Typography>
        </div>
      ) : item.warn ? (
        <div className={classes.label}>
          <Typography>{!item.code ? "INVALID" : "FAILED"}</Typography>
        </div>
      ) : item.next || next ? (
        <div className={classes.label}>
          <Typography>NEXT</Typography>
        </div>
      ) : (
        ""
      )}
      <Grid container direction="column">
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <b>({item.tray})</b>
            </Grid>
            {item.designation ? (
              <Grid item>
                <Tooltip title={item.code}>
                  <Typography>{item.designation}</Typography>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item>
                <Typography>{item.code}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        {(item.lastReading || item.lastReadingAt) && (
          <Grid item>
            <CustomTooltip title={item?.readings?.length > 0 ? <ReadingsTooltip readings={item?.readings} checkcount={`${item.readingsCheckCount} / ${item.topCheckCount}`} /> : moment(item.lastReading || item.lastReadingAt).format("DD/MM/YYYY HH:mm")}>
              <Typography className={classes.run}>
                {moment(item.lastReading || item.lastReadingAt).fromNow() +
                  " @ " +
                  moment(item.lastReading || item.lastReadingAt).format("HH:mm") +
                  " (" +
                  item.duration +
                  "s)"}
              </Typography>
              <LinearProgress color={item.readingsCheckCount < (item.topCheckCount - 9) ? "error" : item.readingsCheckCount < (item.topCheckCount - 3) ? "warning" : "success"} variant="determinate" value={item.readingsCheckCount / item.topCheckCount * 100} />
            </CustomTooltip>
          </Grid>
        )}
        {item.humidity && (
          <Grid item>
            <Typography variant="body1" className={classes.humidity}>
              humidity: {item.humidity}%
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default withStyles(Tray, styles);
