import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { getToken } from "../../../api/apiv2/api";
import { getLocalStorage, parseJwt } from "../../../common/utils/utils";

import moment from "moment";

import Navigation from "./Navigation/Navigation";
import { useEffect } from "react";
import {
  logout,
  refreshLoggedUser,
  selectLoggedUser,
  setLoginError,
} from "../../../features/authentication/authenticationSlice";
import { selectSelectedProject, setSelectedProject } from "../../coreSlice";

const isTokenValid = () => {
  let token = getToken();
  if (!token) {
    return null;
  }
  let parsedToken = parseJwt(token);
  let timeDiff = moment(new Date(parsedToken.exp * 1000)).diff(moment.now());
  if (timeDiff > 0) {
    return parsedToken.id;
  }
  return "EXPIRED";
};

export default function PrivateRoute({ children, title }) {
  const userId = isTokenValid();
  const dispatch = useDispatch();
  const loggedUser = useSelector(selectLoggedUser);
  const selectedProject = useSelector(selectSelectedProject);

  // const user_old = useSelector(selectUser);
  let location = useLocation();

  useEffect(() => {
    if (userId && !loggedUser && userId !== "EXPIRED") {
      dispatch(refreshLoggedUser());
    } else {
      if (userId === "EXPIRED") {
        dispatch(logout());
        dispatch(setLoginError("SESSION_EXPIRED"));
      }
    }
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (loggedUser && !selectedProject) {
      const storedProjectCode = getLocalStorage("selectedProject");
      let projectsList = loggedUser.projects || [];
      let proj =
        projectsList.find((project) => {
          return project.code === storedProjectCode;
        }) ||
        (projectsList.length > 0 && projectsList[0]);

      dispatch(setSelectedProject(proj));
    }
    // eslint-disable-next-line
  }, [loggedUser]);

  if (!userId) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (loggedUser) {
    // console.log("LOGGED USER: %o --- location: %o", loggedUser, location);
    let hasPermission = false;
    switch (location.pathname) {
      case "/alerts":
        if (loggedUser?.role?.permissions?.alerts?.read) {
          hasPermission = true;
        }
        break;
      case "/blocks":
        if (loggedUser?.role?.permissions?.blocks?.read) {
          hasPermission = true;
        }
        break;
      case "/charts":
      case "/analytics":
        if (loggedUser?.role?.permissions?.charts?.read) {
          hasPermission = true;
        }
        break;
      case "/dashboard":
        if (loggedUser?.role?.permissions?.dashboard?.read) {
          hasPermission = true;
        }
        break;
      case "/logs":
        if (loggedUser?.role?.permissions?.logs?.read) {
          hasPermission = true;
        }
        break;
      case "/projects":
        if (loggedUser?.role?.permissions?.projects?.read) {
          hasPermission = true;
        }
        break;
      case "/roles":
        if (loggedUser?.role?.permissions?.roles?.read) {
          hasPermission = true;
        }
        break;
      case "/schedules":
        if (loggedUser?.role?.permissions?.schedules?.read) {
          hasPermission = true;
        }
        break;
      case "/structure":
        if (loggedUser?.role?.permissions?.structure?.read) {
          hasPermission = true;
        }
        break;
      case "/triggers":
        if (loggedUser?.role?.permissions?.triggers?.read) {
          hasPermission = true;
        }
        break;
      case "/users":
        if (loggedUser?.role?.permissions?.users?.read) {
          hasPermission = true;
        }
        break;
      case "/podsettings":
        if (loggedUser?.role?.permissions?.podsettings?.read) {
          hasPermission = true;
        }
        break;
      default:
        hasPermission = true;
    }
    if (!hasPermission) {
      return <Navigate to="/" replace />;
    }
  }

  return loggedUser ? (
    <div>
      <Navigation title={title}>{children}</Navigation>
    </div>
  ) : null;
}
