export const styles = (theme) => ({
  root: {},
  action: {
    fontSize: 10,
    fontWeight: "bold",
  },
  date: {
    fontSize: 10,
  },
  icon: {
    height: 15,
    width: 15,
  },
});
