export const styles = (theme) => ({
  root: {
    padding: 0,
  },
  inactive: {
    color: "#999999",
    backgroundColor: "#fafafa",
    "& .MuiChip-root": {
      backgroundColor: "#999999 !important",
    },
  },
  input: {
    padding: 10,
  },
  header: {
    borderBottom: "1px solid #ddd",
    padding: 10,
  },
  info: {
    padding: 10,
    borderTop: "1px solid #ddd",
  },
  output: {
    padding: 10,
    borderLeft: "1px solid #ddd",
  },
  chip: {
    height: 15,
    marginRight: 5,
    "& span": {
      padding: "0 5px",
      lineHeight: 1,
    },
  },
  subtitle: {
    fontSize: 14,
    fontWeight: "bold",
  },
  and: {
    display: "block",
    textAlign: "center",
    fontSize: 12,
    fontWeight: "bold",
    color: "#999",
  },
});
