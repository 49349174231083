import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { updatePassword } from "../../../api/apiv2/auth/updatePassword";

const updatePasswordCall = async (
  { setFormSuccess },
  dispatch,
  { currentPassword, newPassword }
) => {
  let rh = requestHelper(dispatch, "PASSWORD_UPDATE");
  try {
    let result = await updatePassword(currentPassword, newPassword);
    if (result.success) {
      dispatch(setSuccess("authentication", "PASSWORD_UPDATE"));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("authentication", "PASSWORD_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updatePasswordCall;
