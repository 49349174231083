import * as Yup from "yup";
import { CardSelectField, DeviceField, NumberField, SelectField, TextField } from "../../../../common/forms";
import { getSignOptions, getTriggerTypeOptions } from "./TriggersForm.options";

export function formSchema(t) {
  return Yup.object().shape({
    type: Yup.string().required(),
    designation: Yup.string().required(t("triggers.TriggersForm.form.requiredDesignation")),
    delay: Yup.number()
      .min(1, t("triggers.TriggersForm.form.minDelay"))
      .required(t("triggers.TriggersForm.form.requiredDelay")),
    input: Yup.array()
      .ensure()
      .min(1, t("triggers.TriggersForm.form.minInput"))
      .of(
        Yup.object().shape({
          sensorCode: Yup.string().required(t("triggers.TriggersForm.form.requiredDevice")),
          value: Yup.number().required(t("triggers.TriggersForm.form.requiredInputValue")),
        })
      ),
    output: Yup.object().shape({
      sensorCode: Yup.string().required(t("triggers.TriggersForm.form.requiredDevice")),
    }),
  });
}

export function formFields(t) {
  return [
    {
      size: 12,
      field: <TextField id="designation" label={t("triggers.TriggersForm.form.designation")} required />,
    },
    {
      size: 12,
      field: (
        <CardSelectField
          id="type"
          label={t("triggers.TriggersForm.form.triggerType")}
          options={getTriggerTypeOptions(t)}
          xs={6}
        />
      ),
    },
  ];
}

export function formFields2(t) {
  return [
    {
      size: 12,
      field: <NumberField id="delay" label={t("triggers.TriggersForm.form.delay")} decimalScale={0} required />,
    },
  ];
}

export const formInputFields = (t) => (item) => {
  return [
    {
      size: 12,
      field: (
        <DeviceField
          id={`${item}.sensorCode`}
          valueKey="code"
          label={t("triggers.TriggersForm.form.outputDevice")}
          types={["TEMPERATURE", "HUMIDITY", "BATTERY", "WATERLEVEL"]}
        />
      ),
    },
    {
      size: 4,
      field: (
        <CardSelectField id={`${item}.sign`} textAlign="center" label="&nbsp;" options={getSignOptions()} xs={6} />
      ),
    },
    {
      size: 8,
      field: <NumberField id={`${item}.value`} label={t("triggers.TriggersForm.form.inputValue")} decimalScale={2} />,
    },
  ];
};

export function formOutputFields(t, values, devices) {
  let fields = [
    {
      size: 12,
      field: (
        <DeviceField
          id="output.sensorCode"
          valueKey="code"
          label={t("triggers.TriggersForm.form.outputDevice")}
          types={["BLOCK"]}
        />
      ),
    },
  ];
  // console.log("devices: %o", devices);
  // console.log("values.output: %o", values.output);
  // console.log("selected device: %o", devices[values.output.sensorId]);

  let selectedDevice = devices[values.output.sensorCode];

  switch (selectedDevice?.type) {
    case "WINDOW":
      fields = [
        ...fields,
        {
          size: 12,
          field: (
            <SelectField
              id="output.action"
              label="Action"
              allowEmpty
              options={[
                { value: "WINDOW_OPEN", text: "OPEN" },
                { value: "WINDOW_CLOSE", text: "CLOSE" },
              ]}
            />
          ),
        },
      ];
      break;
    default:
      fields = [
        ...fields,
        {
          size: 12,
          field: (
            <NumberField decimalScale={0} id="output.value" label={t("triggers.TriggersForm.form.durationMinutes")} />
          ),
        },
      ];
      break;
  }

  return fields;
}
