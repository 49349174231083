import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import { styles } from "./WelcomeScreen.styles";

function WelcomeScreen({ classes }) {
  // const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography variant="h3">Welcome</Typography>
    </div>
  );
}

export default withStyles(WelcomeScreen, styles);
