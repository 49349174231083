export const styles = (theme) => ({
  root: {
    borderRadius: 5,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: "pointer",
    "&:last-child": {
      marginBottom: 0
    },
    "&:hover": {
      borderWidth: 2,
      padding: 7,
      background: "#ddf1db80",
    }
  },
  selected: {
    background: "#ddf1db80",
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  icon: {
    display: "block"
  },
  icon2: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    color: "white",
    display: "flex",
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      display: "block"
    }
  },
  modeConnector: {
    fontSize: 16,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    width: 10,
    textAlign: "center"
  },
  disabled: {
    borderColor: theme.palette.action.disabled,
    " *": {
      color: theme.palette.action.disabled + " !important"
    }
  },
  deviceInfo: {
    fontSize: 10,
    margin: 0
  }
});