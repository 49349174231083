import { api, getToken } from "../api";

export const deleteProject = async (id) => {
  let token = getToken();
  let result = await api.delete(
    `/api/v1/projects/${id}`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
