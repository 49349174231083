import { api, getToken } from "../api";

export const createSmartLink = async ({ designation, tolerance, project }) => {
  let token = getToken();
  let result = await api.post(
    "/api/v1/smartlinks",
    {
      designation,
      tolerance,
      active: true,
      server: true,
      inputMode: "AND",
      project
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
