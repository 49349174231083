import { api, getToken } from "../api";

export const updateUser = async (id, name, email, role, projects) => {
  let token = getToken();
  let result = await api.put(
    `/api/v1/users/${id}`,
    { name, email, role, projects },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
