import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from "@mui/material";

import themeJson from "./defaultTheme";

export default function CustomThemeProvider({ children, darkMode }) {
  themeJson.palette.mode = darkMode ? "dark" : "light";
  const theme = createTheme(themeJson);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
