import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withStyles } from "tss-react/mui";
import { Grid, Paper, Button, LinearProgress, Typography } from "@mui/material";

import styles from "./Login.styles";
import { formSchema, formFields } from "./Login.schema";

import { login, selectError, selectLoggedUser } from "../authenticationSlice";
import { selectLoader } from "../../../app/coreSlice";
import { Form, FormFields } from "../../../common/forms";

function Login({ classes }) {
  const { t } = useTranslation();
  const loader = useSelector(selectLoader);
  const error = useSelector(selectError);
  const loggedUser = useSelector(selectLoggedUser);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: "/dashboard" } };

  // useEffect(() => {
  //   dispatch(refreshSession());
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (loggedUser) {
      console.log("loggedUser: %o -> navigation to %o", loggedUser, from);
      navigate(from);
    }
    // eslint-disable-next-line
  }, [loggedUser]);

  const onLoginSubmit = (data) => {
    // console.log('data: %o', data);
    dispatch(login(data.email, data.password));
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <Form
              schema={formSchema(t)}
              initialValues={{}}
              onSubmit={onLoginSubmit}
              render={({ submitting }) => (
                <>
                  <img src="./logo_square.png" alt="logo" className={classes.logo} />
                  <Typography variant="h5" align="center" component="h1">
                    {t("authentication.Login.form.title")}
                  </Typography>
                  <br />
                  <FormFields fields={formFields(t)} />
                  <Grid container>
                    <Grid item xs={12}>
                      {<div className={classes.error}>{error && t("authentication.Login.form." + error)}</div>}
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={submitting || !!loader}
                      >
                        {t("authentication.Login.form.submit")}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            />
            {loader && <LinearProgress className={classes.loader} />}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(Login, styles);
