import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack } from "@mui/material";

import { styles } from "./IOSnip.styles";
import IOBadge from "../IOBadge/IOBadge";
import Weekdays from "../../../../common/components/displays/Weekdays";

function IOSnip({ classes, item }) {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const { tag, configs, device } = item;

  return (
    <div className={classes.root}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <IOBadge small tag={tag} />
        <Stack>
          {tag === "READ_RANGE" && configs &&
            <Typography>{`${device?.code} ${configs.comparer === "GT" ? ">" : "<"} ${configs.value} (${configs.time} min)`}</Typography>
          }
          {tag === "NO_READING" && configs &&
            <Typography>{`${device?.code} (${configs.time} min)`}</Typography>
          }
          {tag === "WEEK_SCHEDULE" && configs &&
            <Stack direction="row" spacing={1}>
              <Weekdays weekdays={configs.weekdays} />
              <Typography>{`@ ${configs.startTime}`}</Typography>
            </Stack>
          }
          {tag === "TELEGRAM" && configs &&
            <Typography>Telegram message</Typography>
          }
          {tag === "EMAIL" && configs &&
            <Typography>Email message</Typography>
          }
          {tag === "ACTION" && configs &&
            <Typography>{`${device?.code} (${configs.duration} secs)`}</Typography>
          }
        </Stack>
      </Stack>
    </div>
  );
}

export default withStyles(IOSnip, styles);