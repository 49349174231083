import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Popover, IconButton, List, ListItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { styles } from "./ActionsPopover.styles";

function ActionsPopover({ classes, actions }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div >
      <IconButton color="secondary" onClick={handleClick} size="small">
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      {actions && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <List className={classes.root}>
            {(actions || []).map((action, index) => {
              return (
                <ListItem className={classes.item} disableGutters disablePadding key={index} onClick={handleClose}>
                  {action}
                </ListItem>
              );
            })}
          </List>
        </Popover>
      )}
    </div>
  );
}

export default withStyles(ActionsPopover, styles);
