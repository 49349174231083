import * as Yup from "yup";
import { SelectField, TextField, NumberField } from "../../../common/forms";
import { getDeviceTypesOptions } from "./SensorForm.options";

export function formFields(t, values) {
  // console.log("_ values: %o", values);
  return [
    {
      size: 12,
      field: (
        <SelectField
          id="type"
          label={t("structure.SensorForm.form.type")}
          required
          options={getDeviceTypesOptions(t)}
        />
      ),
    },
    {
      size: 12,
      field: <TextField id="code" type="text" label={t("structure.SensorForm.form.code")} />,
    },
    {
      size: 12,
      field: <TextField id="designation" type="text" label={t("structure.SensorForm.form.designation")} required />,
    },
    {
      size: 12,
      field: <TextField id="description" type="text" label={t("structure.SensorForm.form.description")} />,
    },
    {
      size: 12,
      field: <TextField id="model" type="text" label={t("structure.SensorForm.form.model")} />,
    },
    {
      size: 12,
      field: <NumberField id="config.offset" decimalScale={2} label={t("structure.SensorForm.form.offset")} />,
      hidden: Boolean(values?.type !== "TEMPERATURE" && values?.type !== "HUMIDITY"),
    },
    {
      size: 12,
      field: <NumberField id="config.waterTop" decimalScale={2} label={t("structure.SensorForm.form.waterTop")} />,
      hidden: Boolean(values?.type !== "WATERLEVEL"),
    },
    {
      size: 12,
      field: (
        <NumberField id="config.waterBottom" decimalScale={2} label={t("structure.SensorForm.form.waterBottom")} />
      ),
      hidden: Boolean(values?.type !== "WATERLEVEL"),
    },
    {
      size: 12,
      field: (
        <NumberField id="config.voltageFull" decimalScale={2} label={t("structure.SensorForm.form.voltageFull")} />
      ),
      hidden: Boolean(values?.type !== "BATTERY"),
    },
    {
      size: 12,
      field: (
        <NumberField id="config.voltageEmpty" decimalScale={2} label={t("structure.SensorForm.form.voltageEmpty")} />
      ),
      hidden: Boolean(values?.type !== "BATTERY"),
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({
    designation: Yup.string().required(t("structure.SensorForm.form.requiredDesignation")),
    description: Yup.string(),
    type: Yup.string().required(t("structure.SensorForm.form.requiredType")),
  });
}
