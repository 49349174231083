import * as Yup from "yup";
import { TextField, DateTimePickerField, DeviceField } from "../../../../common/forms";

export function formFields(t) {
  return [
    {
      size: 12,
      field: (
        <DeviceField
          id="sensorId"
          label={t("schedules.QuickSchedule.form.sensor")}
          types={["RELAY", "BLOCK"]}
          required
        />
      ),
    },
    {
      size: 12,
      field: <DateTimePickerField id="startDate" label={t("schedules.QuickSchedule.form.startDate")} required />,
    },
    {
      size: 12,
      field: <TextField id="duration" type="number" label={t("schedules.QuickSchedule.form.duration")} required />,
    },
  ];
}

export function formSchema(t) {
  return Yup.object().shape({
    sensorId: Yup.string().required(t("schedules.QuickSchedule.form.requiredSensor")),
    startDate: Yup.string().required(t("schedules.QuickSchedule.form.requiredStartDate")),
    duration: Yup.number().required(t("schedules.QuickSchedule.form.requiredDuration")),
  });
}
