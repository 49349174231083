import React from "react";
import { Paper } from "@mui/material";

import LastReadingsChart from "./components/LastReadingsChart";

function HumidityChart({ classes, data }) {
  if (!data) {
    return null;
  }

  return (
    <Paper style={{ padding: 20 }}>
      <LastReadingsChart
        title="Last 24h readings"
        defaultDataFilter="max"
        data={data}
        seriesLabel="Humidity"
        yaxis={{
          title: {
            text: "Humidity",
          },
          min: 0,
        }}
        height={350}
      />
    </Paper>
  );
}

export default HumidityChart;
