import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Drawer, Grid, Paper, useMediaQuery } from "@mui/material";

import { styles } from "./SmartLinksList.styles";
import { deleteSmartLink, listSmartLinks, selectSmartLinks } from "../smartLinksSlice";
import SmarLinkItem from "./SmarLinkItem/SmarLinkItem";
import SmartLinkDetails from "./SmartLinkDetails/SmartLinkDetails";
import { selectSelectedProject } from "../../../app/coreSlice";
import { getDevices } from "../../devices/devicesSlice";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import SmartLinkForm from "./SmartLinkForm/SmartLinkForm";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";

function SmartLinksList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedProject = useSelector(selectSelectedProject);
  const smartlinks = useSelector(selectSmartLinks) || [];
  const [selectedSmartLinkId, setSelectedSmartLinkId] = useState(null);
  const [query, setQuery] = useState("");
  const [drawerSmartLinkOpen, setDrawerSmartLinkOpen] = useState(false);
  const [confirmRemoveSmartLinkDialogOpen, setConfirmRemoveSmartLinkDialogOpen] = useState(false);
  const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (selectedProject) {
      dispatch(getDevices());
      dispatch(listSmartLinks());
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  const hasQuery = (item) => {
    return (
      !query || item.designation.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  const onSearchHandler = (qry) => {
    setQuery(qry);
  };

  const onAddHandler = () => {
    setSelectedSmartLinkId(null)
    setDrawerSmartLinkOpen(true);
  };

  return (
    <div>
      <Toolbar onSearchChanged={onSearchHandler} fastSearch addPermission="smartlinks.write" onAddClick={onAddHandler} />
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item sm={8} xs={12} hidden={isMobile && selectedSmartLinkId}>
            <Paper className={classes.paper}>
              {smartlinks.filter(item => hasQuery(item)).map(item => (
                <SmarLinkItem key={item._id} selected={selectedSmartLinkId === item._id} smartlink={item} onSelect={(sm) => setSelectedSmartLinkId(sm._id)} />
              ))}
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12} hidden={isMobile && !selectedSmartLinkId}>
            <Paper className={classes.paper}>
              <SmartLinkDetails
                smartlink={smartlinks.find(item => item._id === selectedSmartLinkId)}
                onEdit={() => setDrawerSmartLinkOpen(true)}
                onDelete={() => setConfirmRemoveSmartLinkDialogOpen(true)}
                onBack={() => setSelectedSmartLinkId(null)}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Drawer anchor="right" open={drawerSmartLinkOpen} onClose={() => setDrawerSmartLinkOpen(false)}>
        <div className={classes.drawer}>
          <SmartLinkForm smartlink={smartlinks.find(item => item._id === selectedSmartLinkId)} onSubmit={() => setDrawerSmartLinkOpen(false)} />
        </div>
      </Drawer>
      <ConfirmationDialog
        labels={{
          title: t("smartlinks.SmartLinksList.deleteConfirm.title"),
          description: t("smartlinks.SmartLinksList.deleteConfirm.description"),
          ok: t("smartlinks.SmartLinksList.deleteConfirm.ok"),
          cancel: t("smartlinks.SmartLinksList.deleteConfirm.cancel"),
        }}
        open={confirmRemoveSmartLinkDialogOpen}
        onCancel={() => {
          setConfirmRemoveSmartLinkDialogOpen(false);
        }}
        onConfirm={() => {
          setConfirmRemoveSmartLinkDialogOpen(false);
          dispatch(deleteSmartLink(selectedSmartLinkId));
        }}
      />
    </div>
  );
}

export default withStyles(SmartLinksList, styles);