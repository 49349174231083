export const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  drawer: {
    width: "30vw",
    marginTop: theme.myvars.toolbarHeight,
    [theme.breakpoints.down("lg")]: {
      width: "40vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
  drawerScroll: {
    padding: 20,
    height: `calc(100vh - ${theme.myvars.toolbarHeight}px)`,
  },
});