import { requestHelper } from "../../../app/coreSlice";
import { dashboardSlice } from "../dashboardSlice";
import { getWidgets as getWidgetsAPI } from "../../../api/spAPI/widgets";

const { setWidgets } = dashboardSlice.actions;

export const getWidgets = () => async (dispatch, getState) => {
  dispatch(setWidgets([]));
  let state = getState();
  let selectedProject = state.core.selectedProject;
  let user = state.core.user;
  let rh = requestHelper(dispatch, "GET_WIDGETS");
  try {
    let result = await getWidgetsAPI(user, selectedProject);
    dispatch(setWidgets(result?.widgets));
  } catch (ex) {
    console.error(ex);
    rh.error("blocks", "GET_WIDGETS_ERROR", ex);
  }
  rh.close();
};
