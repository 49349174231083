import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Grid } from "@mui/material";

import { styles } from "./SensorForm.styles";
import { formFields, formSchema } from "./SensorForm.schema";

import { addSensor, updateSensor } from "../structureSlice";
import { Form, FormFields } from "../../../common/forms";

function SensorForm({ classes, selectedProject, payload, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmitHandler = async (values) => {
    console.log("values: %o", values);
    if (!values.id) {
      dispatch(addSensor(selectedProject, values));
    } else {
      dispatch(updateSensor(selectedProject, values));
    }
    onSubmit && onSubmit();
  };

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <div>
        <Form
          schema={formSchema(t)}
          initialValues={payload}
          onSubmit={onSubmitHandler}
          render={({ submitting, values }) => {
            return (
              <>
                <FormFields fields={formFields(t, values)} />
                <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                  Submit
                </Button>
              </>
            );
          }}
        />
      </div>
    </Grid>
  );
}

export default withStyles(SensorForm, styles);
