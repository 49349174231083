import { requestHelper } from "../../../app/coreSlice";
import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { getAlerts } from "../../../api/nodeRedApi/alerts";

const alertsListCall = async ({ setAlerts }, dispatch, state) => {
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "ALERTS_LIST");
  try {
    let alerts = [];
    let api = getAPI(selectedProject);
    if (api) {
      alerts = await getAlerts(api);
    }
    alerts.sort((a, b) => (a.designation > b.designation && 1) || -1);

    dispatch(setAlerts(alerts));
  } catch (ex) {
    rh.error("alerts", "ALERTS_LIST_ERROR", ex);
  }
  rh.close();
};

export default alertsListCall;
