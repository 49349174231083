import React, { forwardRef, useState } from "react";
import { withStyles } from "tss-react/mui";
import { InputAdornment, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { NumericFormat } from "react-number-format";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from "prop-types";

import { styles } from "./_base.styles";

function NumberInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  disabled,
  required,
  error,
  decimalScale,
  allowNegative,
  endAdornment,
  startAdornment,
  textAlign = "left",
  maxLength = 9,
  allowLeadingZeros,
  note,
}) {

  const onChangeHandler = (value) => {
    if (disabled) {
      return;
    }
    onChange && onChange(value);
  };

  return (
    <div className={classes.root + (className ? " " + className : "")}>
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="flex-end">
        {label && (
          <Typography
            style={{ marginBottom: 8, marginTop: 8 }}
            component="span"
            variant="caption"
            htmlFor={id}
            color="textPrimary"
            className={disabled ? classes.disabledLabel : ""}
          >
            {label}
            {required && <span> *</span>}
          </Typography>
        )}
        {note && <Tooltip title={note} placement="left"><InfoOutlinedIcon color="primary" className={classes.noteIcon} /></Tooltip>}
      </Stack>
      <TextField
        fullWidth
        InputProps={{
          inputComponent: NumericFormatCustom,
          endAdornment: endAdornment ? (
            <InputAdornment className={classes.adornment} position="end">
              {endAdornment}
            </InputAdornment>
          ) : undefined,
          startAdornment: startAdornment ? (
            <InputAdornment className={classes.adornment} position="start">
              {startAdornment}
            </InputAdornment>
          ) : undefined,
        }}
        // eslint-disable-next-line
        inputProps={{
          id: id,
          decimalScale: decimalScale,
          allowNegative: allowNegative,
          allowLeadingZeros: allowLeadingZeros,
          textAlign: textAlign,
          maxLength: maxLength
        }}
        className={classes.input + (disabled ? " " + classes.disabledInput : "")}
        onChange={(e) => onChangeHandler(e.target.value)}
        value={value}
        disabled={disabled}
        error={error}
        size="small"
        onFocus={(e) => e.target && e.target.select && e.target.select()}
      />
    </div>
  );
}

export default withStyles(NumberInput, styles);

const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, decimalScale, allowNegative = false, allowLeadingZeros = false, textAlign, maxLength, id, value, ...other } = props;
  const [lValue, setValue] = useState(value || "");

  return (
    <NumericFormat
      {...other}
      id={id + "_input"}
      getInputRef={ref}
      value={lValue}
      onChange={(e) => {
        let nValue = e.target.value;
        if (nValue !== lValue) {
          if (!allowLeadingZeros && nValue) {
            let hasTrailingDot = nValue.endsWith('.');
            nValue = parseFloat(nValue).toString();
            if (hasTrailingDot) {
              nValue += ".";
            }
          }
          
          if (maxLength && nValue && nValue.length > maxLength) {
            nValue = nValue.slice(0, maxLength);
          }
          setTimeout(() => {
            e.target.value = nValue;
          }, 10);
          
          setValue(nValue);
          onChange({
            target: {
              name: props.name,
              value: nValue,
            },
          });
        }
      }}
      onBlur={(e) => e.target.value = lValue}
      style={{ textAlign }}
      allowNegative={allowNegative}
      valueIsNumericString
      decimalScale={decimalScale}
      allowLeadingZeros={allowLeadingZeros}
    />
  );
});

NumberInput.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.bool,
  decimalScale: PropTypes.number,
  allowNegative: PropTypes.bool,
  endAdornment: PropTypes.string,
  startAdornment: PropTypes.string,
  textAlign: PropTypes.string,
  maxLength: PropTypes.number,
  allowLeadingZeros: PropTypes.bool,
  note: PropTypes.string
};
