import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Divider, Stack, IconButton, FormControlLabel, Switch, Box, ButtonGroup, Button, Tooltip, useMediaQuery } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { styles } from "./SmartLinkDetails.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import IOSnip from "../../_common/IOSnip/IOSnip";
import ActionsPopover from "../../../../common/components/displays/ActionsPopover/ActionsPopover";
import ActionsPopoverItem from "../../../../common/components/displays/ActionsPopover/ActionsPopoverItem/ActionsPopoverItem";
import EditIcon from "@mui/icons-material/Edit";
import RemoveIcon from "@mui/icons-material/Remove";
import IOPicker from "../../_common/IOPicker/IOPicker";
import { createSmartLinkInput, createSmartLinkOutput, deleteSmartLinkInput, deleteSmartLinkOutput, updateSmartLink, updateSmartLinkInput, updateSmartLinkOutput } from "../../smartLinksSlice";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from "moment";
import CustomTooltip from "../../../../common/displays/CustomTooltip/CustomTooltip";
import Permission from "../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";

function SmartLinkDetails({ classes, smartlink, onEdit, onDelete, onBack }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [inputModal, setInputModal] = useState(false);
  const [inputUpdateModal, setInputUpdateModal] = useState(false);
  const [outputModal, setOutputModal] = useState(false);
  const [outputUpdateModal, setOutputUpdateModal] = useState(false);
  const [inputToUpdate, setInputToUpdate] = useState();
  const [outputToUpdate, setOutputToUpdate] = useState();

  const isMobile = !useMediaQuery(theme => theme.breakpoints.up('sm'));

  const onInputAddHandler = (input) => {
    console.log('IN (ADD) input: %o', { input, smartlink });
    dispatch(createSmartLinkInput({ smartLinkId: smartlink._id, ...input }))
    // dispatch(updateSmartLink({ ...smartlink, input: [...(smartlink.input || []), input] }));
    setInputModal(false);
  }

  const onInputUpdateHandler = (input) => {
    console.log('IN (UPDATE) input: %o', input);
    dispatch(updateSmartLinkInput({ smartLinkId: smartlink._id, ...input }));
    setInputUpdateModal(false);
  }

  const onInputDeleteHandler = (input) => {
    dispatch(deleteSmartLinkInput({ smartLinkId: smartlink._id, id: input._id }));
    setInputUpdateModal(false);
  }

  const onOutputAddHandler = (output) => {
    console.log('OUT (ADD) output: %o', output);
    dispatch(createSmartLinkOutput({ smartLinkId: smartlink._id, ...output }))
    setOutputModal(false);
  }

  const onOutputUpdateHandler = (output) => {
    console.log('OUT (UPDATE) output: %o', output);
    dispatch(updateSmartLinkOutput({ smartLinkId: smartlink._id, ...output }))
    setOutputUpdateModal(false);
  }

  const onOutputDeleteHandler = (input) => {
    dispatch(deleteSmartLinkOutput({ smartLinkId: smartlink._id, id: input._id }));
    setOutputUpdateModal(false);
  }

  const onActiveChangeHandler = () => {
    dispatch(updateSmartLink({ ...smartlink, active: !smartlink.active }));
  }

  const onInputModeChangeHandler = () => {
    dispatch(updateSmartLink({ ...smartlink, inputMode: smartlink.inputMode === "AND" ? "OR" : "AND" }));
  }

  return smartlink ? (
    <div className={classes.root} >
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            {isMobile && <IconButton size="small" onClick={onBack} color="secondary"><ArrowBackIcon fontSize="inherit" /></IconButton>}
            <Typography className={classes.title}>{smartlink.designation}</Typography>
          </Stack>
          <Permission code="smartlinks.write">
            <ActionsPopover
              actions={[
                <Box style={{ paddingLeft: 8, height: 38, display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={<Switch size="small" checked={smartlink.active} onClick={onActiveChangeHandler} />}
                    label={t("smartlinks.SmartLinkDetails.actions.active")}
                  />
                </Box>,
                <ActionsPopoverItem
                  icon={<EditIcon />}
                  label={t("smartlinks.SmartLinkDetails.actions.edit")}
                  onClick={() => onEdit && onEdit(smartlink)}
                />,
                <ActionsPopoverItem
                  icon={<RemoveIcon />}
                  label={t("smartlinks.SmartLinkDetails.actions.delete")}
                  onClick={() => onDelete && onDelete(smartlink)}
                />,
              ]}
            />
          </Permission>
        </Stack>
        <Divider />
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography style={{ margin: 0 }} variant="body2"><b>{t("smartlinks.SmartLinkDetails.details.tolerance")}</b></Typography>
            <Typography style={{ margin: 0 }} variant="body2">{smartlink.tolerance} min</Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography style={{ margin: 0 }} variant="body2"><b>{t("smartlinks.SmartLinkDetails.details.lastTrigger")}</b></Typography>
            <CustomTooltip title={smartlink.lastTrigger ? moment(smartlink.lastTrigger).format("DD-MM-YYYY HH:mm:ss") : undefined}>
              <Typography style={{ margin: 0 }} variant="body2">
                {smartlink.lastTrigger ? moment(smartlink.lastTrigger).fromNow() : t("smartlinks.SmartLinkDetails.details.noTrigger")}
              </Typography>
            </CustomTooltip>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>{t("smartlinks.SmartLinkDetails.details.inputsTitle")}</Typography>
            <Permission code="smartlinks.write">
              <IconButton onClick={() => setInputModal(true)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Permission>
          </Stack>
          <Permission code="smartlinks.write">
            <Tooltip title={t("smartlinks.SmartLinkDetails.details.noTrigger")}>
              <ButtonGroup
                disableElevation
                disableFocusRipple
                fullWidth
                size="small"
              >
                <Button onClick={onInputModeChangeHandler} variant={smartlink.inputMode === "AND" ? "contained" : "outlined"}>
                  {t("smartlinks.SmartLinkDetails.details.inputModeAND")}
                </Button>
                <Button onClick={onInputModeChangeHandler} variant={smartlink.inputMode === "OR" ? "contained" : "outlined"}>
                  {t("smartlinks.SmartLinkDetails.details.inputModeOR")}
                </Button>
              </ButtonGroup>
            </Tooltip>
          </Permission>
          {(smartlink.inputs || []).map((item, index) => (
            <div key={"input_" + index} onClick={() => {
              setInputUpdateModal(true);
              console.log('setInputToUpdate: %o', item);
              setInputToUpdate(item);
            }}>
              <IOSnip item={item} />
            </div>
          ))}
        </Stack>
        <br />
        <Divider />
        <Stack direction="column" spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>{t("smartlinks.SmartLinkDetails.details.outputsTitle")}</Typography>
            <Permission code="smartlinks.write">
              <IconButton onClick={() => setOutputModal(true)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Permission>
          </Stack>
          {(smartlink.outputs || []).map((item, index) => (
            <div key={"output_" + index} onClick={() => {
              setOutputUpdateModal(true);
              console.log('setOutputToUpdate: %o', item);
              setOutputToUpdate(item);
            }}>
              <IOSnip item={item} />
            </div>
          ))}
        </Stack>
      </Stack>
      <CustomModal setOpen={setInputModal} open={inputModal} size="extralarge" title={t("smartlinks.SmartLinkDetails.modal.addInputTitle")}>
        <IOPicker mode="INPUT" onAdd={onInputAddHandler} />
      </CustomModal>
      <CustomModal setOpen={setInputUpdateModal} open={inputUpdateModal} size="extralarge" title={t("smartlinks.SmartLinkDetails.modal.updateInputTitle")}>
        <IOPicker mode="INPUT" onUpdate={onInputUpdateHandler} itemToUpdate={inputToUpdate} onDelete={onInputDeleteHandler} />
      </CustomModal>
      <CustomModal setOpen={setOutputModal} open={outputModal} size="extralarge" title={t("smartlinks.SmartLinkDetails.modal.addOutputTitle")}>
        <IOPicker mode="OUTPUT" onAdd={onOutputAddHandler} />
      </CustomModal>
      <CustomModal setOpen={setOutputUpdateModal} open={outputUpdateModal} size="extralarge" title={t("smartlinks.SmartLinkDetails.modal.updateOutputTitle")}>
        <IOPicker mode="OUTPUT" onUpdate={onOutputUpdateHandler} itemToUpdate={outputToUpdate} onDelete={onOutputDeleteHandler} />
      </CustomModal>
    </div>
  ) : null
}

export default withStyles(SmartLinkDetails, styles);