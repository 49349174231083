import { requestHelper } from "../../../app/coreSlice";
import { getProjects } from "../../../api/apiv2/projects/getProjects";

const getProjectsCall = async (
  { clearProjectsList, appendProjectsList, setProjectsFilter, setProjectsCollectionPagination },
  dispatch,
  state,
  { clear, filter }
) => {
  console.log("getProjectsCall - input: %o", { clear, filter });
  let rh = requestHelper(dispatch, "GET_PROJECTS");
  try {
    if (clear) {
      dispatch(clearProjectsList());
    }
    let nFilter = { ...filter, page: clear ? 1 : filter.page };
    dispatch(setProjectsFilter(nFilter));
    let result = await getProjects(nFilter);
    console.log("result: %o", result);
    if (result.success) {
      dispatch(appendProjectsList(result.data));
      dispatch(setProjectsCollectionPagination(result.pagination));
    }
  } catch (ex) {
    rh.error("projects", "GET_PROJECTS_ERROR", ex);
  }
  rh.close();
};

export default getProjectsCall;
