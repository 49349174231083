import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Drawer } from "@mui/material";

import { styles } from "./Blocks.styles";
import BlocksForm from "../../blocks/Blocks/BlocksForm/BlocksForm";
import SimpleScroll from "../../../common/components/displays/SimpleScroll/SimpleScroll";
import { selectSelectedProject } from "../../../app/coreSlice";
import { getBlocks, selectFormResultSuccess, selectBlocks, removeBlock } from "../blocksCalls";
import BlockItem from "./BlockItem/BlockItem";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import { getDevices, selectDevices } from "../../devices/devicesSlice";

function Blocks({ classes }) {
  // const { t } = useTranslation();
  const dispatch = useDispatch();
  const [drawerBlockForm, setDrawerBlockFormOpen] = useState(false);
  const selectedProject = useSelector(selectSelectedProject);

  const formSuccess = useSelector(selectFormResultSuccess);
  const blocks = useSelector(selectBlocks);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [confirmDeleteBlockDialogOpen, setConfirmDeleteBlockDialogOpen] = useState(false);
  const [query, setQuery] = useState();
  const devices = useSelector(selectDevices);

  // console.log("blocks: %o", blocks);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getDevices());
      dispatch(getBlocks());
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    if (formSuccess) {
      setDrawerBlockFormOpen(false);
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const onAddHandler = () => {
    setSelectedBlock();
    setDrawerBlockFormOpen(true);
  };

  const onEditHandler = (block) => {
    setSelectedBlock(block);
    setDrawerBlockFormOpen(true);
  };

  const onDeleteHandler = (block) => {
    setSelectedBlock(block);
    setConfirmDeleteBlockDialogOpen(true);
  };

  return (
    <div>
      <Toolbar onSearchChanged={(query) => setQuery(query)} addPermission="blocks.write" onAddClick={onAddHandler} />

      <div className={classes.root}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Grid container spacing={1}>
              {(blocks || [])
                .filter(({ code, designation, type }) => {
                  if (query) {
                    return `${designation} ${code} ${type}`.indexOf(query) > -1;
                  }
                  return true;
                })
                .map((item) => (
                  <Grid item xs={4} key={item.id}>
                    <BlockItem block={item} onEdit={onEditHandler} onDelete={onDeleteHandler} devices={devices} />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>

        <Drawer anchor="right" open={drawerBlockForm} onClose={() => setDrawerBlockFormOpen(false)}>
          <div className={classes.drawer}>
            <SimpleScroll className={classes.drawerScroll}>
              <BlocksForm selectedBlock={selectedBlock} />
            </SimpleScroll>
          </div>
        </Drawer>

        <ConfirmationDialog
          labels={{
            title: "Delete block",
            description: "Are you sure you want to delete this block, this action is not reversible.",
            ok: "OK",
            cancel: "CANCEL",
          }}
          open={confirmDeleteBlockDialogOpen}
          onCancel={() => {
            setConfirmDeleteBlockDialogOpen(false);
          }}
          onConfirm={() => {
            console.log("confirm delete");
            setConfirmDeleteBlockDialogOpen(false);
            dispatch(removeBlock(selectedBlock));
            setSelectedBlock(null);
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(Blocks, styles);
