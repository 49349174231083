import { createSlice } from "@reduxjs/toolkit";

import analyticsReadingsCall from "./_sliceCalls/analyticsReadings";
import getDevicesCall from "./_sliceCalls/getDevicesCall";

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    mainChartFilter: null,
    mainChartReadings: null,
    loader: null,
    error: null,
    resultSets: {},
    devices: [],
  },
  reducers: {
    setMainChartFilter: (state, action) => {
      state.mainChartFilter = action.payload;
    },
    setMainChartReadings: (state, action) => {
      state.mainChartReadings = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setResultSets: (state, action) => {
      state.resultSets[action.payload.sensor] = action.payload.data;
    },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
  },
});

export const { setLoader, setError, setResultSets, setMainChartFilter, setMainChartReadings } = analyticsSlice.actions;

export default analyticsSlice.reducer;

// SELECTS
export const selectLoader = (state) => state.analytics.loader;
export const selectError = (state) => state.analytics.error;
export const selectResultSets = (state) => state.analytics.resultSets;

export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectMainChartFilter = (state) => state.analytics.mainChartFilter;
export const selectMainChartReadings = (state) => state.analytics.mainChartReadings;
export const selectDevices = (state) => state.analytics.devices;

// CALLS
export const analyticsReadings =
  ({ devices, startDate, endDate }) =>
  async (dispatch, getState) => {
    analyticsReadingsCall(analyticsSlice.actions, dispatch, getState(), { devices, startDate, endDate });
  };

export const getDevices = () => async (dispatch, getState) => {
  getDevicesCall(analyticsSlice.actions, dispatch, getState());
};
