import { useSelector } from "react-redux";
import { selectBlocks } from "../../features/blocks/blocksCalls";
import { selectDevices } from "../../features/devices/devicesSlice";

export const useDevices = () => {
  const devices = useSelector(selectDevices);
  const blocks = useSelector(selectBlocks);
  // console.log("devices: %o", devices);
  // console.log("blocks: %o", blocks);
  return {
    ...(devices || []).reduce((agg, item) => {
      return { ...agg, [item.code]: item, [item.id]: item };
    }, {}),
    ...(blocks || []).reduce((agg, item) => {
      return { ...agg, [item.code]: item, [item.id]: item };
    }, {}),
  };
};
