import { api, getToken } from "../../api";

export const createSmartLinkOutput = async ({ smartLinkId, tag, configs, device }) => {
  let token = getToken();
  let result = await api.post(
    `/api/v1/smartlinks/${smartLinkId}/output`,
    {
      tag, configs, device
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return result?.data || { success: false };
};
