import { updateSmartLinkInput } from "../../../api/apiv2/smartlinks/inputs/updateSmartLinkInput";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const updateSmartLinkInputCall = async (
  { refreshSmartLink, setFormSuccess },
  dispatch,
  { smartLinkId, id, tag, device, configs }
) => {
  let rh = requestHelper(dispatch, "SMARTLINKINPUT_UPDATE");
  try {
    let result = await updateSmartLinkInput({
      smartLinkId, id, tag, device, configs 
    });
    if (result.success) {
      dispatch(setSuccess("smartlinks", "SMARTLINKINPUT_UPDATE"));
      dispatch(refreshSmartLink(result.data));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("smartlinks", "SMARTLINKINPUT_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateSmartLinkInputCall;
