export const schedulesSample = [
  {
    id: "1dbb8000-e803-475d-bafc-72942956b001",
    sensorId: "9dbb832c-e803-475d-bafc-72942956bf91",
    designation: "Rega UM",
    startTime: "10:00",
    duration: 20,
    weekdays: "mon, tue, wed, thu, fri, sat, sun",
    active: true,
  },
]