import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, TextField, Autocomplete, Box } from "@mui/material";

import { styles } from "./_base.styles";

function AutoCompleteInput({
  classes,
  required,
  className,
  label,
  value,
  onChange,
  disabled,
  options,
  defaultValue,
  variant = "outlined",
}) {
  let selectedValue = value || defaultValue || null;
  let tempId = new Date().getTime().toString(); // trick to avoid browser autocomplete

  return (
    <div className={classes.root + " " + className}>
      {variant === "outlined" && label && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={tempId}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <Autocomplete
        className={classes.autocomplete}
        options={options}
        disabled={disabled}
        value={selectedValue}
        renderInput={(params) => {
          return (
            <TextField
              id={tempId}
              {...params}
              fullWidth
              label={variant === "outlined" ? undefined : label + (required ? " *" : "")}
              // size="small"
              variant={variant}
              disabled={disabled}
              className={classes.input + (disabled ? " " + classes.disabledInput : "")}
              onFocus={(e) => e.target && e.target.select && e.target.select()}
              inputProps={{
                ...params.inputProps,
                id: tempId,
                autoComplete: "off", // disable autocomplete and autofill
              }}
            />
          );
        }}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value || option?.value === value?.value;
        }}
        getOptionLabel={(option) => {
          let selectedObj = options.find((item) => item.value === option || item.value === option?.value);
          return selectedObj?.text || "";
        }}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={"ac_" + option.value}>
            {option.component || option.text}
          </Box>
        )}
        // allows the input text to search the content, and not just de begin of the text
        filterOptions={(options, state) => {
          return options.filter((item) => item.text.toLowerCase().indexOf(state.inputValue.toLowerCase()) > -1);
        }}
        onChange={(e, v) => {
          onChange && onChange(v?.value);
        }}
      />
    </div>
  );
}

export default withStyles(AutoCompleteInput, styles);
