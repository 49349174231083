import React from "react";
import { withStyles } from "tss-react/mui";
import { MenuItem, Select, TextField, Typography } from "@mui/material";

import { styles } from "./_base.styles";

function TextWithSelectInput({
  classes,
  id,
  className,
  label,
  value,
  onChange,
  selectValue,
  onSelectChange,
  disabled,
  error,
  required,
  variant = "outlined",
  options,
  selectWidth = 64,
}) {
  if (!options || options.length === 0) {
    return null;
  }
  let selectValueParsed = selectValue || options[0]?.value;
  return (
    <div className={classes.root + " " + className}>
      {variant === "outlined" && label && (
        <Typography
          component="span"
          variant="caption"
          htmlFor={id}
          color="textPrimary"
          className={disabled ? classes.disabledLabel : ""}
        >
          {label}
          {required && <span> *</span>}
        </Typography>
      )}
      <div className={classes.textselectContainer + (disabled ? " " + classes.disabledInput : "")}>
        <TextField
          id={id}
          fullWidth
          type="text"
          className={classes.input}
          error={error}
          label={variant === "outlined" ? undefined : label + (required ? " *" : "")}
          onChange={(e) => onChange && onChange(e.target.value)}
          value={value}
          disabled={disabled}
          variant={variant}
          size="small"
          InputProps={{
            style: { paddingRight: selectWidth },
          }}
        />
        <Select
          id={id}
          value={selectValueParsed}
          onChange={(e) => onSelectChange && onSelectChange(e.target.value)}
          className={classes.textselect}
          error={error}
          disabled={disabled}
        >
          {(options || []).map((item, index) => (
            <MenuItem key={"option_" + index} value={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default withStyles(TextWithSelectInput, styles);
