import { updateSmartLink } from "../../../api/apiv2/smartlinks/updateSmartLink";
import { requestHelper, setSuccess } from "../../../app/coreSlice";

const updateSmartLinkCall = async (
  { refreshSmartLink, setFormSuccess },
  dispatch,
  { id, designation, tolerance, active, inputMode }
) => {
  let rh = requestHelper(dispatch, "SMARTLINK_UPDATE");
  try {
    let result = await updateSmartLink({
      id, designation, tolerance, active, inputMode
    });
    if (result.success) {
      dispatch(setSuccess("smartlinks", "SMARTLINK_UPDATE"));
      dispatch(refreshSmartLink(result.data));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("smartlinks", "SMARTLINK_UPDATE_ERROR", ex);
  }
  rh.close();
};

export default updateSmartLinkCall;
