import React from "react";
import { Paper } from "@mui/material";

import LastReadingsChart from "./components/LastReadingsChart";

function RelayChart({ classes, data }) {
  if (!data) {
    return null;
  }
  const data1 = data.filter((item) => parseInt(item.value) !== 0 && parseInt(item.value) !== 1);
  let data2 = data.filter((item) => parseInt(item.value) === 0 || parseInt(item.value) === 1).reverse();
  if (data2 && data2.length > 0) {
    data2.unshift({
      id: "prev-state",
      date: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000).toISOString(),
      value: parseInt(data2[0].value) === 0 ? 1 : 0,
    });
    data2.push({
      id: "current-state",
      date: new Date().toISOString(),
      value: data2[data2.length - 1].value,
    });
  }
  // console.log("data1: %o", data1);
  // console.log("data2: %o", data2);
  return (
    <Paper style={{ padding: 20 }}>
      <LastReadingsChart
        title="Last 24h readings"
        defaultDataFilter="max"
        data={data1}
        seriesLabel="Consume (mA)"
        yaxis={{
          title: {
            text: "Consume (mA)",
          },
          min: 0,
        }}
        height={250}
      />
      <LastReadingsChart
        data={data2}
        yaxis={{
          min: 0,
          max: 1,
          tickAmount: 1,
          labels: {
            formatter: (value) => {
              return parseInt(value) === 0 ? "OFF" : "ON";
            },
          },
        }}
        stroke={{
          curve: "stepline",
        }}
        height={100}
        timeInterval={0}
      />
    </Paper>
  );
}

export default RelayChart;
