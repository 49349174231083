import { api, getToken } from "../api";

export const getTowers = async ({ project }) => {
  let token = getToken();

  let params = {
    project,
    limit: 1000,
  };

  let result = await api.get("/api/v1/towers", { headers: { Authorization: `Bearer ${token}` }, params });

  return result?.data || { success: false };
};
