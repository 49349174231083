import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Drawer } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { styles } from "./PodSettingsList.styles";
import {
  deletePodSetting,
  getPodSettings,
  selectFormSuccess,
  selectIsLoading,
  selectPodSettingsFilter,
  selectPodSettingsList,
  selectPodSettingsListPagination,
} from "../podsettingsSlice";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import PodSettingsDataGrid from "./PodSettingsDataGrid/PodSettingsDataGrid";
import SimpleScroll from "../../../common/components/displays/SimpleScroll/SimpleScroll";
import PodSettingsForm from "./PodSettingsForm/PodSettingsForm";
import ConfirmationDialog from "../../../common/components/ConfirmationDialog";
import { selectSelectedProject } from "../../../app/coreSlice";

function PodSettingsList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectIsLoading);
  const podsettingsList = useSelector(selectPodSettingsList);
  const podsettingsPagination = useSelector(selectPodSettingsListPagination);
  const filter = useSelector(selectPodSettingsFilter);
  const formSuccess = useSelector(selectFormSuccess);
  const [drawerPodSettingsForm, setDrawerPodSettingsFormOpen] = useState(false);
  const [selectedPodSetting, setSelectedPodSetting] = useState(null);
  const [confirmDeletePodSettingDialogOpen, setConfirmDeletePodSettingDialogOpen] = useState(false);
  const selectedProject = useSelector(selectSelectedProject);

  useEffect(() => {
    if (selectedProject) {
      dispatch(getPodSettings({ clear: true, filter }));
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  useEffect(() => {
    if (formSuccess) {
      setDrawerPodSettingsFormOpen(false);
      setSelectedPodSetting();
    }
    // eslint-disable-next-line
  }, [formSuccess]);

  const onAddHandler = () => {
    setSelectedPodSetting();
    setDrawerPodSettingsFormOpen(true);
  };

  const onDeleteHandler = (project) => {
    setSelectedPodSetting(project);
    setConfirmDeletePodSettingDialogOpen(true);
  };

  const onEditHandler = (project) => {
    setSelectedPodSetting(project);
    setDrawerPodSettingsFormOpen(true);
  };

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search: search ? search.trim() : undefined,
    };
    dispatch(getPodSettings({ clear: true, filter: nFilter }));
  };

  const onFilterChangeHandler = (options) => {
    dispatch(getPodSettings(options));
  };

  const onRefreshHandler = () => {
    dispatch(getPodSettings({ clear: true, filter }));
  };

  return (
    <div>
      <Toolbar
        onSearchChanged={onSearchHandler}
        addPermission="override"
        onAddClick={onAddHandler}
        rightActions={[
          <Button variant="contained" size="small" color="primary" onClick={onRefreshHandler} disabled={loading}>
            <RefreshIcon />
          </Button>,
        ]}
      />
      <div className={classes.root}>
        <PodSettingsDataGrid
          data={podsettingsList}
          onDelete={onDeleteHandler}
          onEdit={onEditHandler}
          onFilterChange={onFilterChangeHandler}
          pagination={podsettingsPagination}
          filter={filter}
        />
        <Drawer anchor="right" open={drawerPodSettingsForm} onClose={() => setDrawerPodSettingsFormOpen(false)}>
          <div className={classes.drawer}>
            <SimpleScroll className={classes.drawerScroll}>
              <PodSettingsForm selectedPodSetting={selectedPodSetting} />
            </SimpleScroll>
          </div>
        </Drawer>

        <ConfirmationDialog
          labels={{
            title: t("podsettings.PodSettingsList.deleteConfirm.title"),
            description: t("podsettings.PodSettingsList.deleteConfirm.description"),
            ok: t("podsettings.PodSettingsList.deleteConfirm.ok"),
            cancel: t("podsettings.PodSettingsList.deleteConfirm.cancel"),
          }}
          open={confirmDeletePodSettingDialogOpen}
          onCancel={() => {
            setConfirmDeletePodSettingDialogOpen(false);
            setSelectedPodSetting(null);
          }}
          onConfirm={() => {
            setConfirmDeletePodSettingDialogOpen(false);
            dispatch(deletePodSetting(selectedPodSetting._id));
            setSelectedPodSetting(null);
          }}
        />
      </div>
    </div>
  );
}

export default withStyles(PodSettingsList, styles);
