import React from "react";
import { withStyles } from "tss-react/mui";
import { classJoin } from "../../utils";

import { styles } from "./Scroll.styles";

function Scroll({ classes, className, style, children, inverted }) {
  return (
    <div className={classJoin([classes.root, className, inverted ? classes.inverted : undefined])} style={style}>
      {children}
    </div>
  );
}

export default withStyles(Scroll, styles);
