import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { deletePodSetting } from "../../../api/apiv2/podsettings/deletePodSetting";

const deletePodSettingCall = async ({ getPodSettings }, dispatch, state, id) => {
  let rh = requestHelper(dispatch, "POD_SETTINGS_DELETE");
  try {
    const filter = state.podsettings.filter;
    let result = await deletePodSetting(id);
    if (result.success) {
      dispatch(setSuccess("podsettings", "POD_SETTINGS_DELETE"));
      dispatch(getPodSettings({ clear: true, filter }));
    }
  } catch (ex) {
    rh.error("podsettings", "POD_SETTINGS_DELETE_ERROR", ex);
  }
  rh.close();
};

export default deletePodSettingCall;
