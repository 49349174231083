import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Typography, List, ListItem } from "@mui/material";
import { Marker } from '@react-google-maps/api';

import { styles } from "./NanoTagMap.styles";
import { selectNanoTagHistory, getNanoTagHistory } from "../testsSlice";
import MyMap from "./MyMap/MyMap";


const svgMarker = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <path fill="#55B849" d="M12 0C5.373 0 0 5.373 0 12c0 5.256 3.744 9.632 8.875 11.082V24l3.125-3.01L15.125 24v-0.918C20.256 21.632 24 17.256 24 12c0-6.627-5.373-12-12-12zm0 17.5c-2.481 0-4.5-2.019-4.5-4.5s2.019-4.5 4.5-4.5 4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zm0-7c-1.381 0-2.5 1.119-2.5 2.5s1.119 2.5 2.5 2.5 2.5-1.119 2.5-2.5-1.119-2.5-2.5-2.5z"/>
</svg>
`;

const markerIcon = {
  url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgMarker)}`
};

const svgHotspotMarker = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <path fill="#FF4500" d="M12 0C5.373 0 0 5.373 0 12c0 5.256 3.744 9.632 8.875 11.082V24l3.125-3.01L15.125 24v-0.918C20.256 21.632 24 17.256 24 12c0-6.627-5.373-12-12-12zm0 17.5c-2.481 0-4.5-2.019-4.5-4.5s2.019-4.5 4.5-4.5 4.5 2.019 4.5 4.5-2.019 4.5-4.5 4.5zm0-7c-1.381 0-2.5 1.119-2.5 2.5s1.119 2.5 2.5 2.5 2.5-1.119 2.5-2.5-1.119-2.5-2.5-2.5z"/>
  <path fill="#FF4500" d="M12 2.019c1.071 0 2.138 0.146 3.109 0.424-1.045 0.713-1.877 1.545-2.467 2.466-0.041-0.005-0.081-0.013-0.122-0.018-0.044 0.006-0.087 0.014-0.131 0.019-0.58-0.911-1.401-1.729-2.435-2.447 0.97-0.278 1.936-0.424 3.006-0.424z"/>
  <path fill="#FF4500" d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm0 20c-4.963 0-9-4.037-9-9s4.037-9 9-9 9 4.037 9 9-4.037 9-9 9z"/>
</svg>
`;

const hotspotMarkerIcon = {
  url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgHotspotMarker)}`,
};



function NanoTagMap({ classes }) {
  const dispatch = useDispatch();
  const history = useSelector(selectNanoTagHistory);
  const [center, setCenter] = useState();
  const [zoom, setZoom] = useState(9);
  const [markers, setMarkers] = useState();

  useEffect(() => {
    dispatch(getNanoTagHistory());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (history && history.length > 0) {
      setZoom(12);
    }
    // eslint-disable-next-line
  }, [history])

  const onClickHandler = (item) => {
    console.log('CLICK: %o', item);
    let nanoPosition = {
      lat: item.position.latitude,
      lng: item.position.longitude,
    }
    setCenter(nanoPosition);
    setZoom(10);

    let nMarkers = [];
    nMarkers.push(<Marker position={nanoPosition} key="nano" icon={markerIcon} />);

    item.hotspots.forEach((hs, index) => {
      nMarkers.push(<Marker position={{
        lat: hs.latitude,
        lng: hs.longitude,
      }} key={"hs" + (index + 1)} icon={hotspotMarkerIcon} label={hs.rssi.toString()} />);
    })

    setMarkers(nMarkers);
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <List className={classes.list}>
            {
              history.map(item => (
                <ListItem key={item._id} onClick={() => onClickHandler(item)}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography><b>{item.createdAt}</b></Typography>
                    </Grid>
                    <Grid item>
                      <Typography>Temperature {item.temperature}ºC</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
            }
          </List>
        </Grid>
        <Grid item xs={8}>
          <MyMap center={center} markers={markers} zoom={zoom} />
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(NanoTagMap, styles);