import { getAPI } from "../../../api/nodeRedApi/axiosApi";
import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { triggerFormSuccess } from "../blocksSlice";
import { getBlocks } from "../blocksCalls";
import { addBlock as addBlockAPI } from "../../../api/nodeRedApi/blocks";

const addBlock =
  ({ designation, code, type, config }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "ADD_BLOCK");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await addBlockAPI(api, designation, code, type, config);
        dispatch(setSuccess("blocks", "ADD_BLOCK_SUCCESS"));
        dispatch(triggerFormSuccess(true));
        dispatch(getBlocks());
      }
    } catch (ex) {
      console.log(ex);
      rh.error("blocks", "ADD_BLOCK_ERROR", ex);
    }
    rh.close();
  };

export default addBlock;
