import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./DeviceField.styles";
import SelectField from "../../fields/SelectField";
import { selectBlocks } from "../../../../features/blocks/blocksCalls";
import { useSelector } from "react-redux";
import { selectDevices } from "../../../../features/devices/devicesSlice";

function DeviceField({ classes, types, valueKey = "_id", ...props }) {
  const devices = useSelector(selectDevices);
  const blocks = useSelector(selectBlocks);

  let options = (devices || [])
    .filter((device) => types.indexOf(device.type) > -1)
    .map((device) => {
      return { value: device[valueKey], text: device.code + " - " + device.designation };
    });

  if (types.indexOf("BLOCK") > -1 && blocks) {
    options = options.concat(
      (blocks || []).map((block) => {
        return { value: block[valueKey], text: block.code + " - " + block.designation };
      })
    );
  }

  return <SelectField {...props} options={options} />;
}

export default withStyles(DeviceField, styles);
