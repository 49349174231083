import { listSmartLinks } from "../../../api/apiv2/smartlinks/listSmartLinks";
import { requestHelper } from "../../../app/coreSlice";

const listSmartLinksCall = async ({ setSmartLinksCollection }, dispatch, state) => {
  let rh = requestHelper(dispatch, "LIST_SMARTLINKS");
  try {
    let project = state.core.selectedProject?._id;
    let result = await listSmartLinks({ limit: 1000, project, sortField: "code", sortType: "ASC" });
    if (result.success) {
      dispatch(setSmartLinksCollection(result.data));
    }
  } catch (ex) {
    rh.error("devices", "LIST_SMARTLINKS_ERROR", ex);
  }
  rh.close();
};

export default listSmartLinksCall;
