export const styles = (theme) => ({
  root: {},
  header: {
    borderBottom: "1px solid #ddd",
    padding: 10,
  },
  config: {
    padding: 10,
  },
});
