import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./ScheduleForm.styles.js";
import { formFields, formSchema } from "./ScheduleForm.schema";

import { addSchedule, updateSchedule } from "../../schedulesSlice";
import { Form, FormFields } from "../../../../common/forms";
import { selectDevices } from "../../../devices/devicesSlice.js";
import { selectBlocks } from "../../../blocks/blocksCalls.js";

function ScheduleForm({ classes, onSubmit, selectedProject, payload }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const devices = useSelector(selectDevices);
  const blocks = useSelector(selectBlocks);

  const onSubmitHandler = async (values) => {
    console.log("values: %o", values);
    if (!values.id) {
      dispatch(addSchedule(selectedProject, values));
    } else {
      dispatch(updateSchedule(selectedProject, values));
    }
    onSubmit && onSubmit();
  };

  return (
    <Grid className={classes.root} container direction="row" justifyContent="center" alignItems="center">
      <div>
        <Form
          schema={formSchema(t)}
          initialValues={payload}
          onSubmit={onSubmitHandler}
          render={({ submitting, values }) => {
            return (
              <>
                <FormFields fields={formFields(t, devices, blocks, values)} />
                <br />
                <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
                  Submit
                </Button>
              </>
            );
          }}
        />
      </div>
    </Grid>
  );
}

export default withStyles(ScheduleForm, styles);
