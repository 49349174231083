import React from "react";
import { withStyles } from "tss-react/mui";

import { styles } from "./DevicesChart.styles";
import Chart from "../../../../common/charts/components/Chart";

import { getMarkersForCollection, getSeriesForCollection, getStrokesForCollection, getYaxisForCollection } from "../../analyticsUtils";

function DevicesChart({ classes, data, devicesInfo }) {
  // console.log("devicesInfo: %o", devicesInfo);

  const series = getSeriesForCollection(data, devicesInfo);
  // console.log("series: %o", series);

  const yaxis = getYaxisForCollection(data, devicesInfo);
  console.log("yaxis: %o", yaxis);

  const markers = getMarkersForCollection(data, devicesInfo);
  const stroke = getStrokesForCollection(data, devicesInfo);

  const options = {
    tooltip: {
      x: {
        show: true,
        format: "HH:mm",
        shared: true,
        intersect: false
      },
    },
    title: {
      text: "Chart",
      align: "left",
    },
    stroke,
    markers,
    xaxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
    },
    yaxis,
  };
  return (
    <div className={classes.root}>
      <Chart options={options} series={series} type="line" height={600} />
    </div>
  );
}

export default withStyles(DevicesChart, styles);
