import React, { cloneElement } from "react";
import { TableBody, TableRow, TableCell } from "@mui/material";

export default function CustomDataGridBody({ columns, rows, keyField, actionsComponent, onRowSelected, innerRow }) {
  let k = keyField;
  const onRowSelectedHandler = (row) => {
    if (row && onRowSelected) {
      onRowSelected(row);
    }
  };
  return (
    <TableBody>
      {rows.map((row, row_index) => {
        return (
          <React.Fragment key={"frag_" + (k && row[k] ? row[k] : row_index)}>
            <TableRow
              key={"row_" + (k && row[k] ? row[k] : row_index)}
              className={"CdgRow" + (row.className ? " " + row.className : "")}
              onClick={() => onRowSelectedHandler(row)}
            >
              {columns.map((col) => {
                return !col.hidden ? (
                  <TableCell hidden={col.hidden} key={"tbody_" + col.field + "_" + row_index} align={col.align}>
                    {col.customValue ? col.customValue(row) : row[col.field]}
                  </TableCell>
                ) : null;
              })}
              {actionsComponent && (
                <TableCell key={"tbody_actions_" + row_index} align="right">
                  {cloneElement(actionsComponent, { row })}
                </TableCell>
              )}
            </TableRow>
            {innerRow && innerRow(row) && (
              <TableRow key={row[k] + "_inner_" + row_index} className="CdgRow">
                <TableCell
                  colSpan={actionsComponent ? columns.length + 1 : columns.length}
                  style={{ paddingRight: 12, paddingLeft: 12 }}
                >
                  {innerRow(row, row_index)}
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </TableBody>
  );
}
