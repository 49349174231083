import { api, getToken } from "../api";

export const getDevices = async ({ project, search, sortField, sortType, page, limit }) => {
  let token = getToken();

  let params = {
    project
  };
  if (search) {
    console.log("search: %o", search);
    params = {
      ...params,
      "name[regex]": search,
      "name[options]": "i",
    };
  }

  if (sortField && sortType) {
    let field = (sortType === "ASC" ? "" : "-") + sortField;
    params = {
      ...params,
      sort: field,
    };
  }

  if (page || limit)
    params = {
      ...params,
      limit,
      page,
    };

  let result = await api.get("/api/v1/devices", { headers: { Authorization: `Bearer ${token}` }, params });

  return result?.data || { success: false };
};
