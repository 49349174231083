import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Grid, Button, IconButton } from "@mui/material";

import { styles } from "./BoxItem.styles";
import SensorItem from "./SensorItem/SensorItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Permission from "../../../../app/App/PrivateRoute/Navigation/Sidebar/Permission/Permission";

function BoxItem({ classes, selectedProject, box, onEditBox, onDeleteBox, onSensorSelected, onAddSensor }) {
  const [hover, sethover] = useState(false);
  return (
    <Paper className={classes.root} onMouseOver={() => sethover(true)} onMouseOut={() => sethover(false)}>
      <Typography variant="h3" className={classes.title}>
        {box.designation}
        <Permission code="structure.write">
          <IconButton
            aria-label="edit"
            disableRipple
            size="small"
            onClick={() => onEditBox(box)}
            className={classes.icon}
            style={hover ? { display: "inline" } : { display: "none" }}
            // style={{ display: "inline" }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="edit"
            disableRipple
            size="small"
            onClick={() => onDeleteBox(box)}
            className={classes.icon}
            style={hover ? { display: "inline" } : { display: "none" }}
            // style={{ display: "inline" }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Permission>
      </Typography>
      <Typography variant="h6" className={classes.description}>
        {box.description}
      </Typography>
      <Grid container spacing={1}>
        {box.sensors.map((sensor) => (
          <Grid item xs={6} sm={4} key={sensor.id}>
            <SensorItem selectedProject={selectedProject} sensor={sensor} onSensorSelected={onSensorSelected} />
          </Grid>
        ))}
        <Permission code="structure.write">
          <Grid item xs={6} sm={4}>
            <Button variant="contained" size="small" color="primary" onClick={() => onAddSensor(box.id)}>
              Add New Sensor
            </Button>
          </Grid>
        </Permission>
      </Grid>
    </Paper>
  );
}

export default withStyles(BoxItem, styles);
