import { createSlice } from "@reduxjs/toolkit";
import createPodSettingCall from "./_sliceCalls/createPodSettingCall";
import deletePodSettingCall from "./_sliceCalls/deletePodSettingCall";

import getPodSettingsCall from "./_sliceCalls/getPodSettingsCall";
import updatePodSettingCall from "./_sliceCalls/updatePodSettingCall";

export const podsettingsSlice = createSlice({
  name: "podsettings",
  initialState: {
    collection: [],
    collectionPagination: {},
    filter: {
      search: "",
      sortField: "createdAt",
      sortType: "DESC",
      limit: 25,
      page: 1,
    },
    formResultSuccess: false,
    formRoles: null,
    formProjects: null,
  },
  reducers: {
    clearPodSettingsList: (state) => {
      state.collection = [];
    },
    appendPodSettingsList: (state, action) => {
      state.collection = [...state.collection, ...action.payload];
    },
    setFormSuccess: (state, action) => {
      state.formResultSuccess = action.payload;
    },
    setPodSettingsFilter: (state, action) => {
      state.filter = action.payload;
    },
    setPodSettingsCollectionPagination: (state, action) => {
      state.collectionPagination = action.payload;
    },
    setFormRoles: (state, action) => {
      state.formRoles = action.payload;
    },
    setFormProjects: (state, action) => {
      state.formProjects = action.payload;
    },
  },
});

export default podsettingsSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectPodSettingsList = (state) => state.podsettings.collection;
export const selectPodSettingsFilter = (state) => state.podsettings.filter;
export const selectFormSuccess = (state) => state.podsettings.formResultSuccess;
export const selectPodSettingsListPagination = (state) => state.podsettings.collectionPagination;
export const selectFeatures = (state) => state.podsettings.features;
export const selectFormRoles = (state) => state.podsettings.formRoles;
export const selectFormProjects = (state) => state.podsettings.formProjects;

// CALLS
export const getPodSettings =
  ({ clear, filter }) =>
  (dispatch, getState) => {
    getPodSettingsCall(podsettingsSlice.actions, dispatch, getState(), { clear, filter });
  };

export const createPodSetting = (fields) => (dispatch, getState) => {
  createPodSettingCall({ ...podsettingsSlice.actions, getPodSettings }, dispatch, getState(), fields);
};

export const deletePodSetting = (id) => (dispatch, getState) => {
  deletePodSettingCall({ getPodSettings }, dispatch, getState(), id);
};

export const updatePodSetting = (fields) => (dispatch, getState) => {
  updatePodSettingCall({ ...podsettingsSlice.actions, getPodSettings }, dispatch, getState(), fields);
};
