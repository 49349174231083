import { createSlice } from "@reduxjs/toolkit";
import { sleep } from "../../common/utils/utils";
import { getAPI } from "../../api/nodeRedApi/axiosApi";
import { requestHelper, setSuccess } from "../../app/coreSlice";

import {
  getSchedules as getSchedulesDUMMY,
  addSchedule as addScheduleDUMMY,
  updateSchedule as updateScheduleDUMMY,
  removeSchedule as removeScheduleDUMMY,
  activateSchedule as activateScheduleDUMMY,
} from "../../api/fakeApi/schedules";
import {
  getSchedules as getSchedulesAPI,
  addSchedule as addScheduleAPI,
  updateSchedule as updateScheduleAPI,
  removeSchedule as removeScheduleAPI,
  activateSchedule as activateScheduleAPI,
  getSchedulesInstances as getSchedulesInstancesAPI,
  addScheduleInstance as addScheduleInstanceAPI,
  stopScheduleInstance as stopScheduleInstanceAPI,
  deleteScheduleInstance as deleteScheduleInstanceAPI,
} from "../../api/nodeRedApi/schedules";
import moment from "moment";

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState: {
    loader: null,
    error: null,
    schedules: null,
    schedulesInstances: null,
  },
  reducers: {
    setLoader: (state, action) => {
      state.loader = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setSchedules: (state, action) => {
      state.schedules = action.payload;
    },

    setSchedulesInstances: (state, action) => {
      state.schedulesInstances = action.payload;
    },
  },
});

export const { setLoader, setError, setSchedules, setSchedulesInstances } = schedulesSlice.actions;

export const getSchedules = (project) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "GET_SCHEDULES");
  try {
    console.log("GET");
    let schedules = [];
    let api = getAPI(selectedProject);
    if (api) {
      schedules = await getSchedulesAPI(api);
    } else {
      schedules = getSchedulesDUMMY();
      await sleep(1000);
    }
    schedules.sort((a, b) => (a.designation > b.designation && 1) || -1);

    dispatch(setSchedules(schedules));
  } catch (ex) {
    rh.error("structure", "GET_SCHEDULES_ERROR", ex);
  }
  rh.close();
};

export const addSchedule =
  (project, { sensorId, designation, startTime, duration, weekdays }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "Add_SCHEDULE");
    try {
      let startTimeStr = moment(startTime).format("HH:mm");
      console.log("startTime: %o", startTime);
      let api = getAPI(selectedProject);
      if (api) {
        await addScheduleAPI(api, sensorId, designation, startTimeStr, duration, weekdays, true);
      } else {
        addScheduleDUMMY(sensorId, designation, startTimeStr, duration, weekdays, true);
        await sleep(1000);
      }
      dispatch(setSuccess("schedules", "SCHEDULE_ADDED"));

      dispatch(getSchedules(project));
    } catch (ex) {
      rh.error("structure", "Add_SCHEDULE_ERROR", ex);
    }
    rh.close();
  };

export const updateSchedule =
  (project, { id, sensorId, designation, startTime, duration, weekdays, active }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "UPDATE_SCHEDULE");
    try {
      console.log("UPDATE");
      let startTimeStr = moment(startTime).format("HH:mm");
      let api = getAPI(selectedProject);
      if (api) {
        await updateScheduleAPI(api, id, sensorId, designation, startTimeStr, duration, weekdays, active);
      } else {
        updateScheduleDUMMY(id, sensorId, designation, startTimeStr, duration, weekdays, active);
        await sleep(1000);
      }
      dispatch(setSuccess("schedules", "SCHEDULE_UPDATED"));

      dispatch(getSchedules(project));
    } catch (ex) {
      rh.error("structure", "UPDATE_SCHEDULE_ERROR", ex);
    }
    rh.close();
  };

export const activateSchedule = (project, id, active) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "ACTIVATE_SCHEDULE");
  try {
    console.log("UPDATE");

    let api = getAPI(selectedProject);
    if (api) {
      await activateScheduleAPI(api, id, active);
    } else {
      activateScheduleDUMMY(id, active);
      await sleep(1000);
    }
    dispatch(setSuccess("schedules", "SCHEDULE_UPDATED"));

    dispatch(getSchedules(project));
  } catch (ex) {
    rh.error("structure", "ACTIVATE_SCHEDULE_ERROR", ex);
  }
  rh.close();
};

export const removeSchedule = (project, id) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "REMOVE_SCHEDULE");
  try {
    console.log("REMOVE id: %o", id);
    let api = getAPI(selectedProject);
    if (api) {
      await removeScheduleAPI(api, id);
    } else {
      removeScheduleDUMMY(id);
      await sleep(1000);
    }
    dispatch(setSuccess("schedules", "SCHEDULE_REMOVED"));

    dispatch(getSchedules(project));
  } catch (ex) {
    rh.error("structure", "REMOVE_SCHEDULE_ERROR", ex);
  }
  rh.close();
};

export const getSchedulesInstances = (project) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "GET_SCHEDULE_INSTANCE");
  try {
    //console.log("GET");
    //dispatch(setLoader("LOADING"));
    let schedulesInstances = [];
    let api = getAPI(selectedProject);
    if (api) {
      schedulesInstances = await getSchedulesInstancesAPI(api);
    } else {
      await sleep(1000);
    }

    dispatch(setSchedulesInstances(schedulesInstances));
    //dispatch(setLoader(null));
  } catch (ex) {
    rh.error("structure", "GET_SCHEDULE_INSTANCE_ERROR", ex);
  }
  rh.close();
};

export const addScheduleInstance =
  (project, { sensorId, startDate, duration }) =>
  async (dispatch, getState) => {
    let state = getState();
    let selectedProject = state.core.selectedProject?.code;
    let rh = requestHelper(dispatch, "ADD_SCHEDULE_INSTANCE");
    try {
      let api = getAPI(selectedProject);
      if (api) {
        await addScheduleInstanceAPI(api, sensorId, startDate, duration);
      } else {
        await sleep(1000);
      }
      dispatch(setSuccess("schedules", "INSTANCE_CREATED"));
    } catch (ex) {
      rh.error("structure", "ADD_SCHEDULE_INSTANCE_ERROR", ex);
    }
    rh.close();
  };

export const stopScheduleInstance = (project, id) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "STOP_SCHEDULE_INSTANCE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await stopScheduleInstanceAPI(api, id);
    } else {
      await sleep(1000);
    }
    dispatch(setSuccess("schedules", "INSTANCE_STOPED"));
  } catch (ex) {
    rh.error("structure", "STOP_SCHEDULE_INSTANCE_ERROR", ex);
  }
  rh.close();
};

export const deleteScheduleInstance = (project, id) => async (dispatch, getState) => {
  let state = getState();
  let selectedProject = state.core.selectedProject?.code;
  let rh = requestHelper(dispatch, "DELETE_SCHEDULE_INSTANCE");
  try {
    let api = getAPI(selectedProject);
    if (api) {
      await deleteScheduleInstanceAPI(api, id);
    } else {
      await sleep(1000);
    }
    dispatch(setSuccess("schedules", "INSTANCE_STOPED"));
  } catch (ex) {
    rh.error("structure", "DELETE_SCHEDULE_INSTANCE_ERROR", ex);
  }
  rh.close();
};

export const selectLoader = (state) => state.schedules.loader;
export const selectError = (state) => state.schedules.error;
export const selectSchedules = (state) => state.schedules.schedules;
export const selectSchedulesInstances = (state) => state.schedules.schedulesInstances;

export default schedulesSlice.reducer;
