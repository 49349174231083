import { requestHelper } from "../../../app/coreSlice";
import { getRoles } from "../../../api/apiv2/roles/getRoles";

const getRolesCall = async (
  { clearRolesList, appendRolesList, setRolesFilter, setRolesCollectionPagination },
  dispatch,
  state,
  { clear, filter }
) => {
  console.log("getRolesCall - input: %o", { clear, filter });
  let rh = requestHelper(dispatch, "GET_ROLES");
  try {
    if (clear) {
      dispatch(clearRolesList());
    }
    let nFilter = { ...filter, page: clear ? 1 : filter.page };
    dispatch(setRolesFilter(nFilter));
    let result = await getRoles(nFilter);
    console.log("result: %o", result);
    if (result.success) {
      dispatch(appendRolesList(result.data));
      dispatch(setRolesCollectionPagination(result.pagination));
    }
  } catch (ex) {
    rh.error("roles", "GET_ROLES_ERROR", ex);
  }
  rh.close();
};

export default getRolesCall;
