import React from "react";
import { withStyles } from "tss-react/mui";
import { List } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BarChartIcon from "@mui/icons-material/BarChart";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NavLink from "./NavLink/NavLink";
import PsychologyIcon from "@mui/icons-material/Psychology";
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

import { styles } from "./Sidebar.styles";
import Permission from "./Permission/Permission";
import Scroll from "../../../../../common/displays/Scroll/Scroll";

function Sidebar({ classes, onSelect, selectedProject }) {
  // const [subMenuOpen, setSubMenuOpen] = useState(false);
  // console.log(":::::::::: %o", selectedProject);

  const feat = (feature) => {
    return (selectedProject?.features || []).indexOf(feature) > -1;
  };
  return (
    <Scroll style={{ height: "calc(100vh - 60px)", marginRight: -3 }} inverted>
      <List>
        {feat("DASHBOARD") && (
          <Permission code="dashboard.read">
            <NavLink
              activeClass={classes.active}
              icon={<DashboardIcon />}
              primary="Dashboard"
              to="/dashboard"
              onClick={onSelect}
            />
          </Permission>
        )}
        {feat("ANALYTICS") && (
          <Permission code="charts.read">
            <NavLink
              activeClass={classes.active}
              icon={<BarChartIcon />}
              primary="Analytics"
              to="/analytics"
              onClick={onSelect}
            />
          </Permission>
        )}

        {feat("STRUCTURE") && (
          <Permission code="structure.read">
            <NavLink
              activeClass={classes.active}
              icon={<AccountTreeIcon />}
              primary="Devices"
              to="/devices"
              onClick={onSelect}
            />
          </Permission>
        )}
        {feat("BLOCKS") && (
          <Permission code="blocks.read">
            <NavLink
              activeClass={classes.active}
              icon={<ListAltIcon />}
              primary="Blocks"
              to="/blocks"
              onClick={onSelect}
            />
          </Permission>
        )}
        {feat("SMARTLINKS") && (
          <Permission code="smartlinks.read">
            <NavLink
              activeClass={classes.active}
              icon={<DeviceHubIcon />}
              primary="Smart Links"
              to="/smartlinks"
              onClick={onSelect}
            />
          </Permission>
        )}

        {feat("ALERTS") && (
          <Permission code="alerts.read">
            <NavLink
              activeClass={classes.active}
              icon={<NotificationsActiveIcon />}
              primary="Alerts"
              to="/alerts"
              onClick={onSelect}
            />
          </Permission>
        )}
        {feat("SCHEDULES") && (
          <Permission code="schedules.read">
            <NavLink
              activeClass={classes.active}
              icon={<ScheduleIcon />}
              primary="Schedules"
              to="/schedules"
              onClick={onSelect}
            />
          </Permission>
        )}
        {feat("TRIGGERS") && (
          <Permission code="triggers.read">
            <NavLink
              activeClass={classes.active}
              icon={<ElectricBoltIcon />}
              primary="Triggers"
              to="/triggers"
              onClick={onSelect}
            />
          </Permission>
        )}
        {feat("TOWERS") && (
          <Permission code="towers.read">
            <NavLink
              activeClass={classes.active}
              icon={<CalendarViewDayIcon />}
              primary="Towers"
              to="/towers"
              onClick={onSelect}
            />
          </Permission>
        )}
        {feat("LOGS") && (
          <Permission code="logs.read">
            <NavLink
              activeClass={classes.active}
              icon={<AssignmentIcon />}
              primary="Logs"
              to="/logs"
              onClick={onSelect}
            />
          </Permission>
        )}
        <Permission code="podsettings.read">
          <NavLink
            activeClass={classes.active}
            icon={<PsychologyIcon />}
            primary="Pod Settings"
            to="/podsettings"
            onClick={onSelect}
          />
        </Permission>
        <br />
        <Permission code="projects.read">
          <NavLink
            activeClass={classes.active}
            icon={<WorkIcon />}
            primary="Projects"
            to="/projects"
            onClick={onSelect}
          />
        </Permission>
        <Permission code="users.read">
          <NavLink activeClass={classes.active} icon={<GroupIcon />} primary="Users" to="/users" onClick={onSelect} />
        </Permission>
        <Permission code="roles.read">
          <NavLink
            activeClass={classes.active}
            icon={<ManageAccountsIcon />}
            primary="Roles"
            to="/roles"
            onClick={onSelect}
          />
        </Permission>
        <br />
      </List>
    </Scroll>
  );
}

export default withStyles(Sidebar, styles);
