import React from "react";
import { withStyles } from "tss-react/mui";
import { Tooltip, Typography, Grid } from "@mui/material";
import RouterIcon from "@mui/icons-material/Router";

import { styles } from "./BoxInfo.styles";

function BoxInfo({ classes, designation, description }) {
  let info = (
    <Grid className={classes.root} container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
      <Grid item style={{display: "flex"}}>
        <RouterIcon />
      </Grid>
      <Grid item>
        <Typography variant="body2">{designation}</Typography>
      </Grid>
    </Grid>
  );
  return description ? (
    <Tooltip title={description} placement="bottom">
      {info}
    </Tooltip>
  ) : (
    info
  );
}

export default withStyles(BoxInfo, styles);
