import { requestHelper } from "../../../app/coreSlice";
import { asyncForEach } from "../../../common/utils";
import { getReadings } from "../../../api/apiv2/elastic/getReadings";
import moment from "moment";

const analyticsReadingsCall = async (
  { setMainChartFilter, setMainChartReadings },
  dispatch,
  state,
  { devices, startDate, endDate }
) => {
  // let selectedProject = state.core.selectedProject;
  let rh = requestHelper(dispatch, "ANALYTICS_READINGS");
  dispatch(setMainChartFilter({ devices: (devices || []).map(item => item.code), startDate, endDate }));
  let readings = {};
  try {
    console.log('----->   %o', devices);
    let duration = Math.abs(moment(startDate).diff(moment(endDate), "hours"));
    await asyncForEach(devices || [], async (device) => {
      let result = await getReadings({
        code: device.code,
        startDate: startDate,
        endDate: endDate,
        interval: (duration > 24 && device.type !== "DOOR") ? "hour" : "minute",
      });
      readings[device.code] = result;
    });
    dispatch(setMainChartReadings(readings));
  } catch (ex) {
    rh.error("analytics", "ANALYTICS_READINGS_ERROR", ex);
  }
  rh.close();
};

export default analyticsReadingsCall;
