import { createSlice } from "@reduxjs/toolkit";
import { login as loginCall } from "../../api/apiv2";

import { setSelectedProject, requestHelper } from "../../app/coreSlice";
import meCall from "./_sliceCalls/meCall";
import { clearToken } from "../../api/apiv2/api";
import updatePasswordCall from "./_sliceCalls/updatePasswordCall";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    loginError: null,
    loggedUser: null,
    formResultSuccess: false,
  },
  reducers: {
    setLoginError: (state, action) => {
      state.loginError = action.payload;
    },
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
    },
    setFormSuccess: (state, action) => {
      state.formResultSuccess = action.payload;
    },
  },
});

export const { setLoginError, setLoggedUser } = authenticationSlice.actions;

export default authenticationSlice.reducer;

// SELECTS
export const selectError = (state) => state.authentication.loginError;
export const selectLoggedUser = (state) => state.authentication.loggedUser;
export const selectFormSuccess = (state) => state.authentication.formResultSuccess;

export const login = (username, password) => async (dispatch) => {
  dispatch(setLoginError());
  let rh = requestHelper(dispatch, "LOGIN");
  try {
    await loginCall(username, password);
    meCall(authenticationSlice.actions, dispatch);
  } catch (ex) {
    dispatch(setLoginError("FAILED_LOGIN"));
    // rh.error("authentication", "LOGIN_ERROR", ex);
  }
  rh.close();
};

export const logout = () => async (dispatch) => {
  clearToken();
  dispatch(setLoggedUser());
  dispatch(setSelectedProject());
};

export const refreshLoggedUser = () => (dispatch) => {
  meCall(authenticationSlice.actions, dispatch);
};

export const updatePassword =
  ({ currentPassword, newPassword }) =>
  (dispatch) => {
    updatePasswordCall(authenticationSlice.actions, dispatch, { currentPassword, newPassword });
  };
