import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { createAction } from "../../../api/apiv2/actions/createAction";

const createActionCall = async (
  { setActionLocker, getActions },
  dispatch,
  state,
  { device, value, duration, codes }
) => {
  let rh = requestHelper(dispatch, "ACTION_CREATE");
  try {
    let project = state.core.selectedProject?._id;
    let result = await createAction(device, value, duration, project);
    if (result.success) {
      dispatch(setSuccess("actions", "ACTION_CREATE"));
      dispatch(setActionLocker(true));
      setTimeout(() => {
        // get the created
        dispatch(getActions(codes));
      }, 500);
      setTimeout(() => {
        // get the synced
        dispatch(getActions(codes));
        dispatch(setActionLocker(false));
      }, 12000);
    }
  } catch (ex) {
    rh.error("actions", "ACTION_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createActionCall;
