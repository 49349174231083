import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { AutoCompleteInput } from "../../inputs";

function AutoCompleteField({ classes, id, label, disabled, required, options }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(v === undefined ? null : v);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <>
              <AutoCompleteInput
                id={id}
                required={required}
                label={label}
                value={input.value || ""}
                onChange={(value) => onChangeHandler(value, input)}
                disabled={disabled}
                options={options}
                variant={"outlined"}
              />
              <div className={classes.error}>
                <MetaError meta={meta} />
              </div>
            </>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(AutoCompleteField, styles);
