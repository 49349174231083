import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Grid, Button, Typography } from "@mui/material";
import Masonry from "@mui/lab/Masonry";

import { styles } from "./Dashboard.styles";
import { selectWidgets } from "../dashboardCalls";
import WeatherIpma from "./widgets/WeatherIpma/WeatherIpma";
import LastReadings from "./widgets/LastReadings/LastReadings";
import { selectSelectedProject } from "../../../app/coreSlice";
import RaspberryInfo from "./widgets/RaspberryInfo/RaspberryInfo";
import { getBlocks, selectBlocks } from "../../blocks/blocksCalls";
import Actions from "./widgets/Actions/Actions";
import { getWidgets } from "../dashboardSlice";
import { getDevices } from "../../devices/devicesSlice";
import Blank from "./widgets/Blank/Blank";
import { useNavigate } from "react-router-dom";
import RelayButton from "./widgets/RelayButton/RelayButton";
import SocialCluster from "./widgets/SocialCluster/SocialCluster";

function Dashboard({ classes }) {
  const widgets = useSelector(selectWidgets);
  const dispatch = useDispatch();
  const blocks = useSelector(selectBlocks);
  const selectedProject = useSelector(selectSelectedProject);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedProject) {
      dispatch(getWidgets());
      dispatch(getDevices());
      if (selectedProject?.code === "UPFARMING") {
        dispatch(getBlocks());
      }
    }
    // eslint-disable-next-line
  }, [selectedProject]);

  const getWidgetComponent = (widget, index) => {
    switch (widget.type) {
      case "WEATHER-IPMA":
        return <WeatherIpma configs={widget.configs} index={index} />;
      case "READINGS":
        return <LastReadings configs={widget.configs} index={index} />;
      case "SOCIAL-CLUSTER":
        return <SocialCluster configs={widget.configs} index={index} />;
      case "RPI-STATUS":
        return <RaspberryInfo index={index} />;
      case "ACTIONS":
        return <Actions index={index} configs={widget.configs} blocks={blocks} />;
      case "RELAY":
        return <RelayButton index={index} configs={widget.configs} />;
      default:
        return <b>{widget.type}</b>;
    }
  };

  return (
    <div className={classes.root}>
      <Masonry columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }} spacing={1}>
        {(widgets || []).map((item, index) => (
          <div key={`widget_${index}`}>{getWidgetComponent(item, index)}</div>
        ))}
        {selectedProject?.code === "TESTBERRY1" && (
          <Blank>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="h5">HELIUM TESTS</Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => navigate("/helium-test")}>Helium Switch</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={() => navigate("/nanotag")}>Nano Tag</Button>
              </Grid>
            </Grid>
          </Blank>
        )}
      </Masonry>
    </div>
  );
}

export default withStyles(Dashboard, styles);
