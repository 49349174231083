import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { Typography, Paper, Tabs, Tab, Grid, Tooltip, Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import moment from "moment";
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";

import { styles } from "./LogsList.styles";
import { getLogs, selectFilter, selectLogs, setFilter } from "../logsSlice";

import { useFirstLoad } from "../../../common/hooks";
import Toolbar from "../../../common/displays/Toolbar/Toolbar";
import Scroll from "../../../common/displays/Scroll/Scroll";
import DateTimePickerInput from "../../../common/inputs/base/DateTimePickerInput";

import { getBoxes, selectBoxes } from "../../devices/devicesSlice";
import { SelectInput } from "../../../common/inputs";
import CustomModal from "../../../common/displays/CustomModal/CustomModal";

function LogsList({ classes }) {
  const logs = useSelector(selectLogs);
  const dispatch = useDispatch();
  const [search, setSearch] = useState();
  const messagesEndRef = useRef(null);
  const filter = useSelector(selectFilter);
  const boxes = useSelector(selectBoxes);
  const [jsonModal, setJsonModal] = useState(false);
  const [selectedJson, setSelectedJson] = useState({})

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useFirstLoad(() => {
    dispatch(getLogs(filter));
  });

  useEffect(() => {
    scrollToBottom();
    // eslint-disable-next-line
  }, [logs]);

  useEffect(() => {
    if (filter?.type === "inout") {
      dispatch(getBoxes());
    }
    // eslint-disable-next-line
  }, [filter.type]);

  const boxOptions = useMemo(() => {
    if (boxes) {
      return [{ value: null, text: "Clear" }, ...(boxes || []).map(({ code, designation }) => ({ value: code, text: designation }))]
    }
  }, [boxes]);

  const highLightSearch = (log) => {
    let nLog = log.replace("&lt;", "<");
    if (search && search.length > 2) {
      let r = nLog.split(search);
      return r.map((item, i) => {
        return (
          <span>
            {item}
            {i + 1 < r.length && <span className={classes.highlight}>{search}</span>}
          </span>
        );
      });
    }
    return nLog;
  };

  const onSearchHandler = (s) => {
    setSearch(s);
  };

  const onRefreshHandler = () => {
    dispatch(getLogs(filter));
  };

  const showJsonLogHandler = (json) => {
    console.log('showJsonLogHandler');
    if (json) {
      setSelectedJson(json);
      setJsonModal(true);
    }
  }

  const printLogs = (logsArray) => {
    // console.log("logsArray: %o", logsArray);
    let currentDate = null;
    let logsArrayRev = [...logsArray].reverse();
    return logsArrayRev.map((item, i) => {
      if (moment(currentDate).get("D") !== moment(item.logDate || item.createdAt).get("D")) {
        currentDate = item.logDate || item.createdAt;
        return (
          <div key={"log_" + new Date(item.logDate || item.createdAt).getTime()}>
            <Typography className={classes.logDay} key={"log_day_" + i}>
              -- {moment(item.logDate || item.createdAt).format("DD MMM")} --
            </Typography>
            {item.logJson ? (
              <Tooltip title={JSON.stringify(item.logJson)}>
                <Typography className={classes.log} key={"log_" + i}>
                  {moment(item.logDate || item.createdAt).format("HH:mm:ss")} {highLightSearch(item.log)}
                </Typography>
              </Tooltip>
            ) : (
              <Typography className={classes.log} key={"log_" + i}>
                {moment(item.logDate || item.createdAt).format("HH:mm:ss")} {highLightSearch(item.log)}
              </Typography>
            )}
          </div>
        );
      }
      return <Typography className={classes.log} key={"log_" + i} onClick={() => showJsonLogHandler(item.logJson)}>
        {moment(item.logDate || item.createdAt).format("HH:mm:ss")} {highLightSearch(item.log)}
      </Typography>
    });
  };

  let toolbarRightActions = [
    <DateTimePickerInput
      className={classes.dateInput}
      value={filter?.startDate}
      onChange={(value) =>
        dispatch(
          setFilter({
            ...filter,
            startDate: moment(value).toISOString(),
            endDate: moment(value).add(1, "days").toISOString(),
          })
        )
      }
    />,
    <Typography>to</Typography>,
    <DateTimePickerInput
      className={classes.dateInput}
      value={filter?.endDate}
      onChange={(value) => dispatch(setFilter({ ...filter, endDate: moment(value).toISOString() }))}
      maxDate={filter?.type === "inout" ? moment(filter?.startDate).add(1, "days") : null}
      minDate={filter?.startDate}
    />,
    <Button variant="contained" color="primary" onClick={onRefreshHandler}>
      <Refresh />
    </Button>,
  ]

  if (filter?.type === "inout") {
    toolbarRightActions = [<SelectInput
      className={classes.podSelect}
      value={filter?.pod || ""}
      options={boxOptions}
      onChange={(value) => {
        console.log('value: %o', value);
        dispatch(setFilter({ ...filter, pod: value }))
      }}
    />, ...toolbarRightActions]
  }

  return (
    <div>
      <Toolbar
        fastSearch
        onSearchChanged={onSearchHandler}
        rightActions={toolbarRightActions}
      />
      <div className={classes.root}>
        <Grid container spacing={1} direction="column" alignItems="stretch">
          <Grid item>
            <Paper>
              <Tabs
                value={filter?.type}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, v) => {
                  dispatch(getLogs({ ...filter, type: v }));
                }}
              >
                <Tab value="log" label="Logs" />
                <Tab value="inout" label="Communications" />
                <Tab value="debug" label="Debug" />
                <Tab value="error" label="Errors" />
                <Tab value="helium" label="Helium" />
              </Tabs>
              <Scroll style={{ height: "calc(100vh - 210px)" }}>
                <div className={classes.logsContainer}>
                  {printLogs(logs || [])}
                  <br />
                  <br />
                  <div ref={messagesEndRef} />
                </div>
              </Scroll>
            </Paper>
          </Grid>
        </Grid>
        <CustomModal open={jsonModal} setOpen={setJsonModal}>
          <div className={classes.jsonContainer}>
            <JSONInput
              id="selected-log"
              placeholder={selectedJson}
              viewOnly
              locale={locale}
              confirmGood={false}
              theme="light_mitsuketa_tribute"
            />
          </div>
        </CustomModal>
      </div>
    </div>
  );
}

export default withStyles(LogsList, styles);
