export const styles = (theme) => ({
  root: {
    padding: 0,
    width: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "21px",
      boxShadow: "inset 0 0 21px rgba(0,0,0,.3)",
    },
  },
});
