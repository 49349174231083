import React from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";

import { styles } from "./SmartLinkForm.styles";
import { formFields, formSchema } from "./SmartLinkForm.schema";

import { Form, FormFields } from "../../../../common/forms"
import { createSmartLink, updateSmartLink } from "../../smartLinksSlice";

function SmartLinkForm({ classes, onSubmit, smartlink }) {
  const dispatch = useDispatch()
  const { t } = useTranslation();

  const onSubmitHandler = async (values) => {
    console.log("values: %o", values);
    if (smartlink) {
      dispatch(updateSmartLink(values));
    }
    else {
      dispatch(createSmartLink(values));
    }
    onSubmit && onSubmit();
  };

  return (
    <div className={classes.root}>
      <Form
        onSubmit={onSubmitHandler}
        schema={formSchema({ t })}
        initialValues={smartlink || {}}
        render={() => {
          return (
            <Stack spacing={2}>
              <div>
                <FormFields fields={formFields({ t })} />
              </div>
              <Typography className={classes.note}>{t("smartlinks.SmartLinksForm.form.formNote")}</Typography>
              <br/>
              <Button variant="contained" fullWidth color="primary" type="submit">
                {t("smartlinks.SmartLinksForm.form.submitBtn")}
              </Button>
            </Stack>
          );
        }}
      />

    </div>
  );
}

export default withStyles(SmartLinkForm, styles);