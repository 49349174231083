import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
  Divider,
  Grid,
  FormControl,
  // Select,
  // MenuItem,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import { selectSelectedProject, setSelectedProject } from "../../../../coreSlice";
import { selectLoggedUser } from "../../../../../features/authentication/authenticationSlice";
import PersonalSettings from "../../../../../features/authentication/PersonalSettings/PersonalSettings";

function AccountMenu({ classes, onLogout }) {
  const selectedProject = useSelector(selectSelectedProject);
  const loggedUser = useSelector(selectLoggedUser);

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChangeProject = (event) => {
    let project = (loggedUser?.projects || []).find((item) => item.code === event.target.value);
    if (project) {
      dispatch(setSelectedProject(project));
    }
  };

  const onSettingsHandler = () => {
    console.log("settings");
    setAnchorEl(null);
    setSettingsModalOpen(true);
  };

  return (
    <Grid container direction="row" justifyContent="flex-end" style={{ width: 40 }}>
      <IconButton color="inherit" onClick={handlePopoverOpen}>
        <AccountCircle />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        className={classes.accountMenuPopover}
      >
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={loggedUser?.name} secondary={loggedUser?.email} />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button onClick={onSettingsHandler}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Profile Settings" />
          </ListItem>
        </List>
        <Divider />
        {Array.isArray(loggedUser?.projects) && loggedUser?.projects.length > 1 && (
          <div>
            <FormControl className={classes.projectSelectFormMenu} size="small">
              <FormLabel component="legend">Selected Project</FormLabel>
              <RadioGroup
                aria-label="project"
                name="project"
                value={selectedProject?.code}
                onChange={handleChangeProject}
              >
                {loggedUser?.projects.map((project) => {
                  return (
                    <FormControlLabel
                      key={"project_" + project.code}
                      value={project.code}
                      control={<Radio />}
                      label={project.code}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
            <Divider />
          </div>
        )}
        <List>
          <ListItem button onClick={onLogout}>
            <ListItemIcon>
              <MeetingRoomIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
      <PersonalSettings open={settingsModalOpen} />
    </Grid>
  );
}

export default AccountMenu;
