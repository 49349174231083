import { requestHelper } from "../../../app/coreSlice";
import moment from "moment";
import { getReadings } from "../../../api/apiv2/elastic/getReadings";

const getLastDayReadingsCall = async ({ setWidgetsData }, dispatch, state, { code, index }) => {
  let widgetData = JSON.parse(JSON.stringify([...(state.dashboard.widgets[index]?.data || [])]));

  let rh = requestHelper(dispatch, "GET_LAST_DAY_READINGS");
  try {
    let endDate = moment.utc().toISOString();
    let startDate = moment.utc().subtract(1, "day").toISOString();

    let result = await getReadings({ code, startDate, endDate, interval: "minute" });
    let devicePos = widgetData.findIndex((item) => item.code === code);
    widgetData[devicePos].readings = result.map(({ date, avg, id }) => {
      return {
        date,
        value: avg,
        id: id,
      };
    });

    // console.log("widgetData[devicePos].readings: %o", widgetData[devicePos].readings);

    dispatch(
      setWidgetsData({
        index,
        data: widgetData,
      })
    );
  } catch (ex) {
    console.error(ex);
    rh.error("blocks", "GET_LAST_DAY_READINGS_ERROR", ex);
  }
  rh.close();
};

export default getLastDayReadingsCall;
