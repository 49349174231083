import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Chip } from "@mui/material";

import { styles } from "./Weekdays.styles";

function Weekdays({ classes, edit, weekdays, onChange }) {
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const { t } = useTranslation();
  const wd = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  const onWeekdayClick = (d) => {
    let nWeekdays = selectedWeekdays.find((item) => item === d)
      ? [...selectedWeekdays.filter((item) => item !== d)]
      : [...selectedWeekdays.filter((item) => item !== d), d]
    setSelectedWeekdays(nWeekdays);
    onChange && onChange(nWeekdays.join(', '));
  };

  useEffect(() => {
    let nWeekdays = weekdays.split(",").map((item) => item.trim()).filter(item => item !== "");
    setSelectedWeekdays(nWeekdays);
    // eslint-disable-next-line
  }, [weekdays]);

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {wd.map((d) => {
          return (
            <Grid item key={"week_" + d}>
              <Chip
                className={classes.chip}
                color="primary"
                size="small"
                variant={selectedWeekdays.find((item) => item === d) ? "default" : "outlined"}
                label={t("common.enum.weekdays." + d)}
                onClick={edit ? () => onWeekdayClick(d) : null}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withStyles(Weekdays, styles);
