export const styles = (theme) => ({
  root: {
    width: 240,
    background: "white",
  },
  clear: {
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
});
