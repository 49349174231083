export const styles = (theme) => ({
  root: {
    width: "100%",
  },
  day: {
    backgroundColor: theme.palette.primary.main,
    padding: "2px 5px",
    margin: "2px 0",
    textAlign: "center",
    textTransform: "uppercase",
    borderRadius: 3,
    color: "#FFFFFF",
    "& p": {
      fontWeight: "bold",
      lineHeight: "16px",
      fontSize: "14px",
    },
  },
  weatherType: {
    height: 40,
    display: "block",
  },
  temp: {
    padding: "2px 5px",
    margin: "2px",
    textAlign: "center",
    "& p": {
      fontWeight: "bold",
      lineHeight: "18px",
      fontSize: "14px",
    },
  },
  perc: {
    textAlign: "center",
    "& svg": {
      fontSize: "32px",
      fill: "#9cc3de",
      display: "block",
      margin: "0 auto",
      lineHeight: "6px",
      marginTop: "-10px",
      marginBottom: "-10px",
    },
    "& p": {
      lineHeight: "16px",
      fontSize: "14px",
    },
  },
});
