import * as Yup from "yup";
import { TextField } from "../../../../../common/forms";

export const formSchema = (t) => {
  return Yup.object().shape({
    message: Yup.string().required(),
  });
}

export const formFields = (t) => {
  return [
    {
      size: 12,
      field: <TextField
        id="chatId"
        label="chat ID"
        required
      />,
    },
    {
      size: 12,
      field: <TextField
        id="message"
        label="Message"
        rows={3}
        required
      />,
    },
  ];
}


const TELEGRAM = {
  formSchema, formFields
}

export default TELEGRAM