import React, { useState, useEffect } from "react";
import { Grid, Paper } from "@mui/material";

// import Chart from "react-apexcharts";
import moment from "moment";

import Chart from "./components/Chart";

const processData = (data, timeInterval = 15) => {
  let r = [];
  let lastReading = data[0];
  r.push(lastReading);
  let timeDiff = 0;

  data.forEach((item) => {
    timeDiff = moment(lastReading.date).diff(moment(item.date), "minutes");
    if (timeDiff > timeInterval) {
      lastReading = item;
      r.push(lastReading);
    }
  });
  r.push(data[data.length - 1]);
  return r;
};

function WaterLevelChart({ classes, data }) {
  const [filteredData, setFilteredData] = useState();
  const [series, setSeries] = useState([]);

  const options = {
    tooltip: {
      x: {
        show: true,
        format: "HH:mm",
      },
    },

    title: {
      text: "Last 24h readings",
      align: "left",
    },
    xaxis: {
      type: "datetime",
      title: {
        text: "Time",
      },
      min: new Date().getTime() - 1 * 24 * 60 * 60 * 1000,
      max: new Date().getTime(),
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: "Water Level (%)",
          style: {
            color: "#77B6EA",
          },
        },
        min: 0,
        max: 100,
        axisBorder: {
          show: true,
          color: "#77B6EA",
        },
        labels: {
          style: {
            colors: "#77B6EA",
          },
        },
      },
      {
        axisBorder: {
          show: true,
          color: "#545454",
        },
        title: {
          text: "Distance (raw)",
        },
        max: 1000,
      },
    ],
  };

  useEffect(() => {
    if (!filteredData && data) {
      setFilteredData(processData(data, 30));
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (filteredData) {
      console.log("filteredData: %o", filteredData);
      setSeries([
        {
          name: "% Full",
          data: filteredData.map((item) => {
            return {
              x: moment(item.date).utc(true).toDate().getTime(),
              y: (parseFloat(item.value) * 100).toFixed(0),
            };
          }),
        },
        {
          name: "cm (measured)",
          data: filteredData.map((item) => {
            return {
              x: moment(item.date).utc(true).toDate().getTime(),
              y: parseFloat(item.rawValue).toFixed(0),
            };
          }),
        },
      ]);
    }
    // eslint-disable-next-line
  }, [filteredData]);

  console.log("series: %o", series);

  if (!data) {
    return null;
  }

  return (
    <Paper style={{ padding: 20 }}>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Chart options={options} series={series} type="line" height={350} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default WaterLevelChart;
