export const styles = (theme) => ({
  root: {
    padding: 10,
    backgroundColor: "#e7f5e5",
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
  },
  description: {
    fontSize: 12,
    marginBottom: 5,
    display: "block",
    "& .MuiChip-root": {
      height: 15,
      marginRight: 5,
      "& span": {
        padding: "0 5px",
        lineHeight: 1,
      },
    },
  },
});
