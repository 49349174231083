import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Paper, Typography, Grid, Chip, Tooltip, Modal, CircularProgress } from "@mui/material";

import { styles } from "./LastReadings.styles";

import { useDispatch, useSelector } from "react-redux";
import TemperatureGauge from "./TemperatureGauge/TemperatureGauge";
import HumidityGauge from "./HumidityGauge/HumidityGauge";
import SensorIcon from "../../../../../common/components/displays/SensorIcon";
import BatteryGauge from "./BatteryGauge/BatteryGauge";
import moment from "moment";
import { FiAlertTriangle } from "react-icons/fi";
import HumidityChart from "../../../../../common/charts/HumidityChart";
import TemperatureChart from "../../../../../common/charts/TemperatureChart";
import RelayChart from "../../../../../common/charts/RelayChart";
import WaterLevelChart from "../../../../../common/charts/WaterLevelChart";
import BatteryChart from "../../../../../common/charts/BatteryChart";

import { selectLoader } from "../../../../../app/coreSlice";

import { selectWidgetData } from "../../../dashboardCalls";
import { getLastDayReadings, getLastReadings } from "../../../dashboardSlice";
import { selectDevices } from "../../../../devices/devicesSlice";

function LastReadings({ classes, configs, index }) {
  const dispatch = useDispatch();
  const [modalReadingsOpen, setModalReadingsOpen] = useState(false);
  const [selectedSensor, setSelectedSensor] = useState();
  const loading = useSelector(selectLoader);
  const readings = useSelector(selectWidgetData(index));
  const devices = useSelector(selectDevices);

  // console.log("LastReadings[%s] readings: %o", index, readings);

  useEffect(() => {
    if (configs && devices?.length > 0) {
      dispatch(getLastReadings(configs?.devices, index));
      let cycle = setInterval(
        () => {
          dispatch(getLastReadings(configs?.devices, index));
        },
        configs?.refreshInterval ? configs?.refreshInterval * 1000 : 120000
      );
      return () => {
        clearInterval(cycle);
      };
    }
    // eslint-disable-next-line
  }, [devices]);

  const selectedDevice =
    readings && readings.length > 0 && selectedSensor
      ? readings.find((item) => item.code === selectedSensor)
      : undefined;

  const wasXminutesAgo = (date, minutes) => {
    if (!date) {
      return false;
    }
    return moment(date) < moment(new Date()).subtract(minutes, "minutes");
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={1}>
        {(readings || []).map((read) => {
          return (
            <Grid
              item
              xs={12}
              key={read.code}
              onClick={() => {
                setSelectedSensor(read.code);
                dispatch(getLastDayReadings(read.code, index));
                setModalReadingsOpen(true);
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="h5" className={classes.title}>
                    <SensorIcon type={read.type} /> {read.designation}
                  </Typography>
                  <Typography variant="h6" className={classes.description}>
                    <Chip size="small" color="secondary" label={read.code} />
                    <br />
                    {read.description}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip
                    title={
                      <div>
                        {"Last Reading: " + moment(read.date).fromNow()}
                        <br />
                        {"(" + moment(read.date).format("YYYY-MM-DD[T]HH:mm:ss") + ")"}
                      </div>
                    }
                    placement="right-start"
                  >
                    <div className={classes.readingBox}>
                      {wasXminutesAgo(read.date, 30) && (
                        <FiAlertTriangle color="#e7bb36" className={classes.alertIcon} />
                      )}
                      {read.type === "TEMPERATURE" && read.value && (
                        <TemperatureGauge temperatureValue={read.value} fontSize={18} height={120} />
                      )}
                      {read.type === "HUMIDITY" && read.value && (
                        <HumidityGauge humidityValue={read.value} fontSize={18} height={120} />
                      )}
                      {read.type === "BATTERY" && read.value && (
                        <BatteryGauge batteryValue={read.value} fontSize={18} height={120} />
                      )}
                      {read.type === "RELAY" && read.value && (
                        <Typography variant="h5" className={classes.relayInfo}>
                          {read.value}
                        </Typography>
                      )}
                      {read.type === "WATERLEVEL" && read.value && (
                        <HumidityGauge humidityValue={read.value * 100} fontSize={18} height={120} />
                      )}
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.separator}></div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        {selectedSensor && selectedDevice && (
          <Modal open={modalReadingsOpen} onClose={() => setModalReadingsOpen(false)}>
            <div className={classes.modal}>
              {selectedDevice.type === "TEMPERATURE" && !loading && (
                <TemperatureChart data={selectedDevice?.readings} />
              )}
              {selectedDevice.type === "HUMIDITY" && !loading && <HumidityChart data={selectedDevice?.readings} />}
              {selectedDevice.type === "WATERLEVEL" && !loading && <WaterLevelChart data={selectedDevice?.readings} />}
              {selectedDevice.type === "BATTERY" && !loading && <BatteryChart data={selectedDevice?.readings} />}
              {selectedDevice.type === "RELAY" && !loading && (
                <RelayChart data={selectedDevice?.readings} sensor={selectedDevice} />
              )}
              {loading && <CircularProgress />}
            </div>
          </Modal>
        )}
      </Grid>
    </Paper>
  );
}

export default withStyles(LastReadings, styles);
