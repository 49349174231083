import React from "react";
import { withStyles } from "tss-react/mui";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";

import { styles } from "./ConfirmationDialog.styles";

function ConfirmationDialog({ classes, labels, open, onCancel, onConfirm }) {
  const { title, description, ok, cancel } = labels;
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: classes.dialog
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {cancel}
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            {ok}
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}

export default withStyles(ConfirmationDialog, styles);