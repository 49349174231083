import { createSlice } from "@reduxjs/toolkit";
import createUserCall from "./_sliceCalls/createUserCall";
import deleteUserCall from "./_sliceCalls/deleteUserCall";
import form_getProjectsCall from "./_sliceCalls/form_getProjectsCall";
import form_getRolesCall from "./_sliceCalls/form_getRolesCall";

import getUsersCall from "./_sliceCalls/getUsersCall";
import updateUserCall from "./_sliceCalls/updateUserCall";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    collection: [],
    collectionPagination: {},
    filter: {
      search: "",
      sortField: "name",
      sortType: "ASC",
      limit: 25,
      page: 1,
    },
    formResultSuccess: false,
    formRoles: null,
    formProjects: null,
  },
  reducers: {
    clearUsersList: (state) => {
      state.collection = [];
    },
    appendUsersList: (state, action) => {
      state.collection = [...state.collection, ...action.payload];
    },
    setFormSuccess: (state, action) => {
      state.formResultSuccess = action.payload;
    },
    setUsersFilter: (state, action) => {
      state.filter = action.payload;
    },
    setUsersCollectionPagination: (state, action) => {
      state.collectionPagination = action.payload;
    },
    setFormRoles: (state, action) => {
      state.formRoles = action.payload;
    },
    setFormProjects: (state, action) => {
      state.formProjects = action.payload;
    },
  },
});

export default usersSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectUsersList = (state) => state.users.collection;
export const selectUsersFilter = (state) => state.users.filter;
export const selectFormSuccess = (state) => state.users.formResultSuccess;
export const selectUsersListPagination = (state) => state.users.collectionPagination;
export const selectFeatures = (state) => state.users.features;
export const selectFormRoles = (state) => state.users.formRoles;
export const selectFormProjects = (state) => state.users.formProjects;

// CALLS
export const getUsers =
  ({ clear, filter }) =>
  (dispatch, getState) => {
    getUsersCall(usersSlice.actions, dispatch, getState(), { clear, filter });
  };

export const createUser = (fields) => (dispatch, getState) => {
  createUserCall({ ...usersSlice.actions, getUsers }, dispatch, getState(), fields);
};

export const deleteUser = (id) => (dispatch, getState) => {
  deleteUserCall({ getUsers }, dispatch, getState(), id);
};

export const updateUser = (fields) => (dispatch, getState) => {
  updateUserCall({ ...usersSlice.actions, getUsers }, dispatch, getState(), fields);
};

export const getFormRoles = () => (dispatch) => {
  form_getRolesCall(usersSlice.actions, dispatch);
};

export const getFormProjects = () => (dispatch) => {
  form_getProjectsCall(usersSlice.actions, dispatch);
};
