import React, { useEffect } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { styles } from "./ProjectsForm.styles";
import { Form, FormFields } from "../../../../common/forms";
import { formFields, formSchema } from "./ProjectsForm.schema";
import { useDispatch } from "react-redux";
import { createProject, getFeatures, selectFeatures, updateProject } from "../../projectsSlice";
import { useSelector } from "react-redux";

function ProjectsForm({ classes, selectedProject }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const features = useSelector(selectFeatures);

  useEffect(() => {
    if (!features) {
      dispatch(getFeatures());
    }
    // eslint-disable-next-line
  }, []);

  const onSubmitHandler = (values) => {
    if (selectedProject) {
      dispatch(updateProject(values));
    } else {
      dispatch(createProject(values));
    }
  };

  return (
    <div className={classes.root}>
      <Form
        schema={formSchema(t)}
        initialValues={selectedProject || {}}
        onSubmit={onSubmitHandler}
        render={({ submitting, values }) => {
          return (
            <>
              <FormFields fields={formFields(t, features)} />
              <br />
              <Button fullWidth variant="contained" color="primary" type="submit" disabled={submitting}>
              {t("projects.ProjectsForm.form.submit")}
              </Button>
            </>
          );
        }}
      />
    </div>
  );
}

export default withStyles(ProjectsForm, styles);
