import { createSlice } from "@reduxjs/toolkit";

import getSwitcherLastReadingCall from "./_sliceCalls/getSwitcherLastReadingCall";
import getNanoTagHistoryCall from "./_sliceCalls/getNanoTagHistoryCall";

export const testsSlice = createSlice({
  name: "tests",
  initialState: {
    switcherState: {
      "Mg==": false,
      "MQ==": false,
      "Mw==": false,
      "NA==": false,
    },
    switcherLastReading: null,
    nanoTagHistory: []
  },
  reducers: {
    setSwitcherLastReading: (state, action) => {
      console.log("data: %o", action.payload);
      if (state.switcherLastReading?.id !== action.payload?.id) {
        state.switcherState = {
          ...state.switcherState,
          [action.payload.rawValue]: !state.switcherState?.[action.payload.rawValue],
        };
      }
      state.switcherLastReading = action.payload;
    },
    setNanoTagHistory: (state, action) => {
      state.nanoTagHistory = action.payload;
    }
  },
});

export default testsSlice.reducer;

// SELECTS
export const selectIsLoading = (state) => state.core.loader?.length > 0;
export const selectSwitcherState = (state) => state.tests.switcherState;
export const selectNanoTagHistory = (state) => state.tests.nanoTagHistory;

// CALLS
export const getSwitcherLastReading = () => (dispatch) => {
  getSwitcherLastReadingCall(testsSlice.actions, dispatch);
};

export const getNanoTagHistory = () => (dispatch) => {
  getNanoTagHistoryCall(testsSlice.actions, dispatch);
};