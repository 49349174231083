
import SensorsIcon from '@mui/icons-material/Sensors';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import TelegramIcon from '@mui/icons-material/Telegram';
import MailIcon from '@mui/icons-material/Mail';
import { ImSwitch } from "react-icons/im";

import READ_RANGE from "./schemas/READ_RANGE.schema";
import WEEK_SCHEDULE from "./schemas/WEEK_SCHEDULE.schema";
import NO_READING from "./schemas/NO_READING.schema";
import TELEGRAM from "./schemas/TELEGRAM.schema";
import EMAIL from "./schemas/EMAIL.schema";
import ACTION from "./schemas/ACTION.schema";

export const getIOItems = (t) => {
  return [
    {
      title: t("smartlinks.IOPicker.READ_RANGE.title"),
      description: t("smartlinks.IOPicker.READ_RANGE.description"),
      icon: <SensorsIcon fontSize="large" />,
      tag: "READ_RANGE",
      mode: "INPUT",
      schema: READ_RANGE.formSchema(t),
      fields: () => READ_RANGE.formFields(t),
    },
    {
      title: t("smartlinks.IOPicker.WEEK_SCHEDULE.title"),
      description: t("smartlinks.IOPicker.WEEK_SCHEDULE.description"),
      icon: <AccessTimeIcon fontSize="large" />,
      tag: "WEEK_SCHEDULE",
      mode: "INPUT",
      schema: WEEK_SCHEDULE.formSchema(t),
      fields: () => WEEK_SCHEDULE.formFields(t),
    },
    {
      title: t("smartlinks.IOPicker.NO_READING.title"),
      description: t("smartlinks.IOPicker.NO_READING.description"),
      icon: <DoNotDisturbAltIcon fontSize="large" />,
      tag: "NO_READING",
      mode: "INPUT",
      schema: NO_READING.formSchema(t),
      fields: () => NO_READING.formFields(t),
    },
    {
      title: t("smartlinks.IOPicker.TELEGRAM.title"),
      description: t("smartlinks.IOPicker.TELEGRAM.description"),
      icon: <TelegramIcon fontSize="large" />,
      tag: "TELEGRAM",
      mode: "OUTPUT",
      schema: TELEGRAM.formSchema(t),
      fields: () => TELEGRAM.formFields(t),
    },
    {
      title: t("smartlinks.IOPicker.EMAIL.title"),
      description: t("smartlinks.IOPicker.EMAIL.description"),
      icon: <MailIcon fontSize="large" />,
      tag: "EMAIL",
      mode: "OUTPUT",
      schema: EMAIL.formSchema(t),
      fields: () => EMAIL.formFields(t),
    },
    {
      title: t("smartlinks.IOPicker.ACTION.title"),
      description: t("smartlinks.IOPicker.ACTION.description"),
      icon: <ImSwitch fontSize="large" />,
      tag: "ACTION",
      mode: "OUTPUT",
      schema: ACTION.formSchema(t),
      fields: (values, form) => ACTION.formFields({ t, values, form }),
    }
  ]
} 
