import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    designation: Yup.string().required(t("devices.BoxForm.form.requiredDesignation")),
    description: Yup.string(),
  });
}

export function formFields(t) {
  return [
    {
      size: 12,
      field: (
        <TextField id="code" type="code" label={t("devices.BoxForm.form.code")} required={true} />
      ),
    },
    {
      size: 12,
      field: (
        <TextField id="designation" type="designation" label={t("devices.BoxForm.form.designation")} required={true} />
      ),
    },
    {
      size: 12,
      field: <TextField id="description" type="description" label={t("devices.BoxForm.form.description")} />,
    },
  ];
}
