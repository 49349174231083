export const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    minWidth: 160
  },
  item: {
    "&:hover": {
      background: "#ddf1db80",
      borderRadius: 5
    }
  }
});
