import axios from "axios";

const SESSION_ID = "WJT";

export const api = axios.create({
  baseURL: "https://api-v2.solarpunk.pt",
  // baseURL: "http://localhost:3010",
  headers: {
    "content-type": "application/json",
  },
});

api.interceptors.request.use(
  function (config) {
    console.log("config: %o", config);
    console.log(
      "%c::: [API] %s %s ::: %o",
      "color:#498fb8; font-weight:bold",
      (config?.method || "").toUpperCase(),
      config?.url || "",
      config?.params || config?.data || {}
    );
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    console.log(
      "%c::: [API] %s %s ::: %o",
      "color:#55B849; font-weight:bold",
      (response?.config?.method || "").toUpperCase(),
      response?.config?.url,
      response?.data
    );
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const storeToken = (token) => {
  localStorage.setItem(SESSION_ID, token);
};

export const clearToken = () => {
  localStorage.removeItem(SESSION_ID);
};

export const getToken = () => {
  return localStorage.getItem(SESSION_ID);
};
