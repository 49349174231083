import React from "react";
import { withStyles } from "tss-react/mui";
import { Field } from "react-final-form";
import MetaError from "../MetaError";
import { styles } from "./_base.styles";
import { DateTimePickerInput } from "../../inputs";
import moment from "moment";

function DateTimePickerField({ classes, id, label, disabled, required, format, minDate, maxDate }) {
  const onChangeHandler = (v, input) => {
    input.onChange && input.onChange(moment.isMoment(v) ? v.format("YYYY-MM-DDTHH:mm:ss") : undefined);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => (
          <>
            <DateTimePickerInput
              className={classes.input}
              id={id}
              label={label}
              value={input.value}
              onChange={(value) => onChangeHandler(value, input)}
              disabled={disabled}
              required={required}
              error={Boolean(meta.error && meta.touched)}
              variant={"outlined"}
              format={format}
              minDate={minDate}
              maxDate={maxDate}
            />
            <div className={classes.error}>
              <MetaError meta={meta} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
}

export default withStyles(DateTimePickerField, styles);
