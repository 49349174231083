import { requestHelper, setSuccess } from "../../../app/coreSlice";
import { createProject } from "../../../api/apiv2/projects/createProject";

const createProjectCall = async ({ getProjects, setFormSuccess }, dispatch, state, { code, name, features }) => {
  let rh = requestHelper(dispatch, "PROJECT_CREATE");
  try {
    const filter = state.projects.filter;
    let result = await createProject(code, name, features);
    if (result.success) {
      dispatch(setSuccess("projects", "PROJECT_CREATE"));
      dispatch(getProjects({ clear: true, filter }));
      dispatch(setFormSuccess(true));
      setTimeout(() => {
        dispatch(setFormSuccess(false));
      }, 500);
    }
  } catch (ex) {
    rh.error("projects", "PROJECT_CREATE_ERROR", ex);
  }
  rh.close();
};

export default createProjectCall;
