import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Chip } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { styles } from "./TriggerInputItem.styles";
import BoxInfo from "../../../../../common/components/displays/BoxInfo/BoxInfo";
import DeviceInfo from "../../../../../common/components/displays/DeviceInfo/DeviceInfo";
import { selectDevices } from "../../../../devices/devicesSlice";
import { selectBlocks } from "../../../../blocks/blocksCalls";

function TriggerInputItem({ classes, input }) {
  const { t } = useTranslation();
  const devices = useSelector(selectDevices) || [];
  const blocks = useSelector(selectBlocks) || [];
  let deviceInfo = [...devices, ...blocks].find((item) => item.code === input?.sensorCode);

  return (
    <Grid className={classes.root} container direction="column">
      <Grid item>
        <Grid className={classes.root} container direction="row" wrap="nowrap" alignItems="center">
          <Grid item>
            <Chip className={classes.chip} size="small" color="secondary" label={deviceInfo?.code} />
          </Grid>
          <Grid item>
            {input.sign === "gt" ? (
              <ArrowForwardIosIcon className={classes.signIcon} />
            ) : (
              <ArrowBackIosNewIcon className={classes.signIcon} />
            )}
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {input.value} {t("common.enum.sensorTypeSymbol." + deviceInfo?.type)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid className={classes.root} container direction="row" wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <BoxInfo designation={deviceInfo.boxDesignation} description={deviceInfo?.boxDescription} />
          </Grid>
          <Grid item>
            <DeviceInfo
              designation={deviceInfo?.designation}
              description={deviceInfo?.description}
              type={deviceInfo?.type}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(TriggerInputItem, styles);
