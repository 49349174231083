import { guid } from "../../common/utils";

export const getActions = async (restAPI, deviceCode) => {
  let r = await restAPI.get("/actions/get/" + deviceCode);
  return r?.data || [];
};

export const addAction = async (restAPI, deviceCode, action) => {
  let obj = {
    id: guid(),
    deviceCode,
    action,
  };
  return restAPI.post("actions/add", obj);
};
