import * as Yup from "yup";
import WeekdaysSelector from "../../../../common/components/formFields/WeekdaysSelector";
import { SelectField, TextField, TimePickerField, NumberField, DeviceField } from "../../../../common/forms";
// import { getSensorOptions } from "../QuickScheduleForm/QuickScheduleForm.options";

export function formFields(t, devices, blocks, { sensorId }) {
  let fields = [
    {
      size: 12,
      field: (
        <DeviceField
          id="sensorId"
          label={t("schedules.ScheduleForm.form.sensor")}
          types={["RELAY", "BLOCK"]}
          required
        />
      ),
    },
    {
      size: 12,
      field: <TextField id="designation" type="text" label={t("schedules.ScheduleForm.form.designation")} required />,
    },
    {
      size: 12,
      field: <TimePickerField id="startTime" label={t("schedules.ScheduleForm.form.startTime")} required />,
    },

    {
      size: 12,
      field: <WeekdaysSelector id="weekdays" type="text" label={t("schedules.ScheduleForm.form.weekdays")} />,
    },
  ];

  // console.log("devices: %o, blocks: %o", devices, blocks);
  let selectedDevice = (devices || []).find((item) => item._id === sensorId);
  if (!selectedDevice) {
    selectedDevice = (blocks || []).find((item) => item.id === sensorId);
    if (selectedDevice) {
      console.log(selectedDevice);
    }
  }

  switch (selectedDevice?.type) {
    case "RELAY":
      fields = fields.concat([
        {
          size: 12,
          field: (
            <NumberField
              id="duration"
              type="text"
              label={t("schedules.ScheduleForm.form.duration")}
              required
              decimalScale={0}
            />
          ),
        },
      ]);
      break;
    case "WINDOW":
      fields = fields.concat([
        {
          size: 12,
          field: (
            <SelectField
              id="config.action"
              label="Action"
              options={[
                { value: "OPEN", text: "Open" },
                { value: "CLOSE", text: "Close" },
              ]}
            />
          ),
        },
        // {
        //   size: 12,
        //   field: (
        //     <SelectField
        //       id="config.percentage"
        //       label="Percentage"
        //       required
        //       options={[
        //         { value: "100", text: "100%" },
        //         { value: "75", text: "75%" },
        //         { value: "25", text: "25%" },
        //       ]}
        //     />
        //   ),
        // },
      ]);
      break;
    default:
      break;
  }

  // switch

  return fields;
}

export function formSchema(t) {
  return Yup.object().shape({
    sensorId: Yup.string().required(t("schedules.ScheduleForm.form.requiredSensor")),
    designation: Yup.string().required(t("schedules.ScheduleForm.form.requiredDesignation")),
    startTime: Yup.string().required(t("schedules.ScheduleForm.form.requiredStartTime")),
  });
}
