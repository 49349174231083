import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Chip } from "@mui/material";

import { styles } from "./TriggerOutputItem.styles";
import DeviceInfo from "../../../../../common/components/displays/DeviceInfo/DeviceInfo";
import BoxInfo from "../../../../../common/components/displays/BoxInfo/BoxInfo";
import { selectDevices } from "../../../../devices/devicesSlice";
import { selectBlocks } from "../../../../blocks/blocksCalls";

function TriggerOutputItem({ classes, output }) {
  const { t } = useTranslation();
  const isBlock = output?.sensorCode?.slice(3, 4) === "B";
  const devices = useSelector(selectDevices) || [];
  const blocks = useSelector(selectBlocks) || [];
  let deviceInfo = [...devices, ...blocks].find((item) => item.code === output?.sensorCode);

  return (
    <Grid className={classes.root} container direction="column" spacing={1}>
      <Grid item>
        <Typography className={classes.subtitle} variant="h6">
          {t("triggers.TriggersForm.form.outputTitle")}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Chip className={classes.chip} size="small" color="secondary" label={deviceInfo?.code} />
          </Grid>
          {output?.action === "WINDOW_OPEN" ? (
            <Grid item>
              <Typography variant="body1">{t("triggers.Triggers.action.WINDOW_OPEN")}</Typography>
            </Grid>
          ) : output?.action === "WINDOW_CLOSE" ? (
            <Grid item>
              <Typography variant="body1">{t("triggers.Triggers.action.WINDOW_CLOSE")}</Typography>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Typography variant="body1">ON</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{output?.value} min</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {!isBlock && (
        <>
          <Grid item>
            <BoxInfo designation={deviceInfo?.boxDesignation} description={deviceInfo?.boxDescription} />
          </Grid>
          <Grid item>
            <DeviceInfo
              designation={deviceInfo?.designation}
              description={deviceInfo?.description}
              type={deviceInfo?.type}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default withStyles(TriggerOutputItem, styles);
