import * as Yup from "yup";
import { TimePickerField } from "../../../../../common/forms";
import WeekdaysSelector from "../../../../../common/components/formFields/WeekdaysSelector";

export const formSchema = (t) => {
  return Yup.object().shape({
    weekdays: Yup.string().required(t("smartlinks.IOPicker.form.weekdaysRequired")),
    startTime: Yup.string().required(t("smartlinks.IOPicker.form.startTimeRequired")),
  });
}

export const formFields = (t) => {
  return [
    {
      size: 6,
      field: <WeekdaysSelector id="weekdays" type="text" label={t("smartlinks.IOPicker.form.weekdays")} />,
    },
    {
      size: 6,
      field: <TimePickerField id="startTime" label={t("smartlinks.IOPicker.form.startTime")} required />,
    },
  ];
}

const WEEK_SCHEDULE = {
  formSchema, formFields
}

export default WEEK_SCHEDULE