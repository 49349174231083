import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Stack } from "@mui/material";

import { styles } from "./ReadingsTooltip.styles";
import moment from "moment";

function ReadingsTooltip({ classes, readings = [], checkcount }) {
  const parsedReadings = readings.slice(0, 30);
  return (
    <div className={classes.root}>
        {checkcount && <b>{checkcount}</b>}
      <Stack alignItems="flex-end" spacing={1}>
        {parsedReadings.map((read, index) => {
          let prevReading = index > 0 ? parsedReadings[index - 1] : null;
          return (
            <div key={"read_" + index}>
              <Typography className={classes.reading}>{`${!prevReading || moment(prevReading).day() !== moment(read).day() ? moment(read).format("DD MMM") + " - " : ""}${moment(read).format("HH:mm:ss")}`}</Typography>
            </div>
          )
        }
        )}
      </Stack>
    </div>
  );
}

export default withStyles(ReadingsTooltip, styles);