import FakeCollection from "./fakeCollection";
import { schedulesSample } from "./data/schedulesSample";
import { getZones, getSensors, getBoxs } from "./project";

const schedulesCollection = new FakeCollection("fk_schedules", schedulesSample);

export const getSchedules = () => {
  let schedulesList = schedulesCollection.get();
  let zones = getZones();

  let schedules = zones.map((zone) => {
    let zoneBoxs = getBoxs(zone.id);
    let boxSensors = zoneBoxs
      .map((item) => {
        let sensors = getSensors(item.id);
        return sensors.map((item) => {
          return {
            ...item,
            boxDesignation: item.designation,
          };
        });
      })
      .flat();
    let sensorsIds = boxSensors.map((item) => item.id);

    var obj = {
      zone: zone.designation,
      zoneId: zone.id,
      schedules: schedulesList.filter((item) => {
        return sensorsIds.indexOf(item.sensorId) > -1;
      }),
    };

    obj.schedules = obj.schedules.map((alert) => {
      var sensor = boxSensors.find(
        (item) => item.id === alert.sensorId
      );

      return {
        ...alert,
        boxId: sensor.boxId,
        boxDesignation: sensor.boxDesignation,
        sensorDesignation: sensor.designation,
      };
    });
    return obj;
  });
  return schedules;
};

export const addSchedule = (
  sensorId,
  designation,
  severity,
  type,
  value,
  active
) => {
  return schedulesCollection.add({
    sensorId,
    designation,
    severity,
    type,
    value,
    active,
  });
};

export const updateSchedule = (
  id,
  sensorId,
  designation,
  severity,
  type,
  value,
  active
) => {
  return schedulesCollection.add({
    id,
    sensorId,
    designation,
    severity,
    type,
    value,
    active,
  });
};

export const activateSchedule = (id, active) => {
  console.log('....activateSchedule: %o', {id, active});
  return schedulesCollection.add({ ...schedulesCollection.get(id), active });
};

export const removeSchedule = (id) => {
  return schedulesCollection.remove(id);
};
