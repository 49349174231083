import * as Yup from "yup";
import { TextField } from "../../../../common/forms";

export function formSchema(t) {
  return Yup.object().shape({
    currentPassword: Yup.string().required(t("authentication.ChangePasswordForm.form.currentPasswordRequired")),
    newPassword: Yup.string().required(t("authentication.ChangePasswordForm.form.newPasswordRequired")),
    newPasswordConfirm: Yup.string().required(t("authentication.ChangePasswordForm.form.newPasswordConfirmRequired")).oneOf(
      [Yup.ref("newPassword"), null],
      t("authentication.ChangePasswordForm.form.matchPassword"))
  });
}

export function formFields(t) {
  let fields = [
    {
      size: 12,
      field: (
        <TextField
          id="currentPassword"
          type="password"
          label={t("authentication.ChangePasswordForm.form.currentPassword")}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="newPassword"
          type="password"
          label={t("authentication.ChangePasswordForm.form.newPassword")}
          required
        />
      ),
    },
    {
      size: 12,
      field: (
        <TextField
          id="newPasswordConfirm"
          type="password"
          label={t("authentication.ChangePasswordForm.form.newPasswordConfirm")}
          required
        />
      ),
    },
  ];

  return fields;
}
